import { useEffect, useContext } from "react";
import { Route } from "react-router-dom";

import { AppContexts } from "../../providers/";

import AppLayout from "./app-layout";

import Header from "../standard/header";
import Footer from "../standard/footer";

// import styled components
import Loading from "../common/Loading";

// Expiremental
import ViewportBox from "../ui-kit/components/viewport-box";
import LineClamp from "../ui-kit/components/line-clamp";

const tone = new Audio(
  "https://s3.us-east-2.amazonaws.com/uploads.ruebarue.com/notification-tone.mp3",
);

const AppLoader = () => {
  console.log("Loading App");

  const { user, inbox, refresh, loading } = useContext(AppContexts.AuthContext);
  const { notification, getNotification } = useContext(AppContexts.UtilContext);

  useEffect(() => {
    console.log("Refreshing");
    refresh();
  }, []);

  useEffect(() => {
    if (!!window.socket && !!inbox) {
      console.log("Joining", inbox.inbox_id);
      window.socket.emit("join", { inbox_id: inbox.inbox_id });
      window.socket.on("disconnect", () => {
        console.log("reconnecting");
        window.socket.emit("join", { inbox_id: inbox.inbox_id });
      });

      window.socket.on("incoming", (resp) => {
        getNotification(resp);
      });
    } else {
      window.addEventListener("socket-connect", () => {
        if (!!inbox) {
          window.socket.emit("join", { inbox_id: inbox.inbox_id });
          window.socket.on("disconnect", () => {
            window.socket.emit("join", { inbox_id: inbox.inbox_id });
          });

          window.socket.on("incoming", (resp) => {
            getNotification(resp);
          });
        }
      });
    }
  }, [inbox]);

  useEffect(() => {
    if (!!notification && window.Notification?.permission == "granted") {
      let { last_msg } = notification;
      let { sender } = last_msg;

      if (Array.isArray(sender)) {
        sender = sender[sender.length - 1];
      }



      // let message = (messages || []).at(-1);
      // let guest_reservation = (guest_reservations || []).at(-1);

      // let first_name = "";
      // let last_name = "";
      // if (!!contact && contact?.category !== "leads") {
      //   first_name = contact?.first_name;
      //   last_name = contact?.last_name;
      // } else {
      //   if (!!guest_reservation) {
      //     first_name = guest_reservation.first_name;
      //     last_name = guest_reservation.last_name;
      //   } else {
      //     first_name = contact?.first_name || "";
      //     last_name = contact?.last_name || "";
      //   }
      // }

      if (last_msg.sender_type === "guest") {
        let title = `New message from ${sender?.first_name || ""} ${
          sender?.last_name || ""
        }`;
        let text = last_msg?.content || "";
        let img = "/images/logo.png";
        let note = new window.Notification(title, { body: text, icon: img });

        tone.play();
      }

      getNotification(null);
    }
  }, [notification]);

  useEffect(() => {
    // Expiremental. Trying to load custom components for UI Kit
    window.UIkit.component("ViewportBox", ViewportBox);
    window.UIkit.component("LineClamp", LineClamp);
  }, []);

  return (
    <>
      <Header />
      <Route
        path="/"
        render={(props) => (!loading ? <AppLayout {...props} /> : <Loading />)}
      />
      <Footer />
    </>
  );
};

export default AppLoader;
