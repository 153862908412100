// Import base components
import http from "../http.js";

const SavedResponseService = {
  selectSavedResponseList: () => http.get("/messaging/saved_responses"),
  insertSavedResponse: (response) =>
    http.post("/messaging/saved_responses", response),
  updateSavedResponse: (response) =>
    http.put(`/messaging/saved_responses/${response.id}`, response),
  deleteSavedResponse: (response) =>
    http.delete(`/messaging/saved_responses/${response.id}`),
};

export default SavedResponseService;
