import React from "react";

const Checkboxes = ({ options, index, answer = [], disabled = true, other }) => {
  let otherOption = { ...other, answer: "Other" };
  let renderOptions = other ? [...options, otherOption] : options;
  return (
    <div className="uk-form-controls uk-margin-small-top">
      {renderOptions
        ?.filter((option) => !option.deleted)
        .map((option, i) => (
          <div key={i}>
            <label htmlFor={`survey${index}-${i}`} className="hms-checkbox">
              <input
                type="checkbox"
                id={`survey${index}-${i}`}
                name={`checkbox${index}`}
                disabled={disabled}
                defaultChecked={answer.indexOf(option.answer) > -1}
              />
              <span className="hms-checkmark"></span>
              {option.answer}
            </label>
          </div>
        ))}
    </div>
  );
};

export default Checkboxes;
