// Import package components
import { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Image, InputContainer, Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";

import RecordsPerPage from "../../common/RecordsPerPage";
import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import { AppContexts } from "../../../providers";

const AutoSentSMS = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { sms, startDate, endDate, getAutomatedSms } = ReportsContext;

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    } else {
      getAutomatedSms(
        startDate,
        endDate,
        page,
        filter ? filter.trim() : filter,
        recordsPerPage,
      );
    }
  }, [startDate, endDate, filter, page, recordsPerPage]);

  const pagedSMS = useMemo(
    () => sms?.slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [sms, page, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Automated Text Sent</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Guest Name Or Property ID"
          onFilterChange={handleFilterChange}
        />
        <div className="secondary">{sms?.length} Automated Text Sent</div>
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>SENT AT</th>
              <th>MESSAGE NAME</th>
              <th>GUEST NAME</th>
              <th>GUEST PHONE</th>
              <th>CHECK-IN DATE</th>
              <th>PROPERTY ID</th>
            </tr>
          </thead>
          <tbody>
            {pagedSMS?.length ? (
              pagedSMS?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {moment
                      .utc(item.created_at)
                      .local()
                      .format("MM/DD/YYYY hh:mm A")}
                  </td>
                  <td>{item.name}</td>
                  <td>
                    {item.first_name} {item.last_name}
                  </td>
                  <td>{item.phone}</td>
                  <td>
                    {moment.utc(item.check_in).local().format("MMM DD, YYYY")}
                  </td>
                  <td className="reports-table-wrap-overflow">
                    <a
                      target="_blank"
                      href={`https://guide.ruebarue.com/rental/${item.rental_id}`}
                    >
                      {item.pms_id}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={sms?.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default AutoSentSMS;
