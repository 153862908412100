const Column = ({ size, children }) => {
  const sizeClass = {
    one: "uk-width-1-2",
    two: "uk-width-1-1",
    auto: "uk-width-1-2",
  };

  return <div className={`${sizeClass[size]}`}>{children}</div>;
};

export default Column;
