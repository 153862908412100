// Import package components
import classNames from "classnames";

// Import UI components
import uiClassNames from "./ClassNames";

export function Link(props) {
  const setClassNames = () => {
    return classNames({
      [`uk-link-${props.type}`]: !!props.type,
      [`${uiClassNames(props)}`]: true,
    });
  };

  return (
    <a
      //id={props.id ? props.id : null}
      //style={props.style ? props.style : null}
      //data-uk-toggle={props.toggleOptions ? props.toggleOptions : null}
      target={props.target || null}
      href={props.href}
      className={setClassNames()}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  );
}
