import React, { useEffect, useContext } from "react";
import { iconList } from "react-icomoon";
import { AppContexts } from "../../../providers";
import GuideIcon from "../../common/GuideIcon";
import iconSet from "../../../assets/icons/ruebarue/selection.json";
import UIkit from "uikit";

const IconList = () => {
  const { selectedSection, updateSection } = useContext(
    AppContexts.RentalsContext,
  );

  const { refresh } = useContext(AppContexts.AuthContext);

  const handleSelect = async (icon) => {
    if (!selectedSection.welcome_guide_question_id) {
      return;
    }

    await updateSection({
      ...selectedSection,
      icon: `icon-${icon}`,
    });

    await refresh();

    UIkit.modal("#iconList-modal").hide();
  };

  return (
    <div
      id="iconList-modal"
      className="uk-modal-full hms-modals uk-modal"
      uk-offcanvas="overlay: true; flip: true;"
    >
      <div className="uk-offcanvas-bar">
        <button
          className="uk-offcanvas-close uk-close-large"
          type="button"
          data-uk-close
        ></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Pick an Icon</h2>
        </div>
        <div className="uk-modal-body uk-margin">
          {iconList(iconSet).map((i) =>
            i === (selectedSection?.icon || "").replace("icon-", "") ? (
              <div
                className="uk-inline icon-select"
                key={i}
                style={{ backgroundColor: "#f05252" }}
                onClick={() => handleSelect(i)}
              >
                <GuideIcon icon={`icon-${i}`} color="white" />
              </div>
            ) : (
              <div
                className="uk-inline icon-select"
                key={i}
                onClick={() => handleSelect(i)}
                uk-tooltip={i.charAt(0).toUpperCase() + i.slice(1)}
              >
                <GuideIcon icon={`icon-${i}`} />
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default IconList;
