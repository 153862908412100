import React, { useState, useEffect, useContext, useMemo } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GoogleAutoComplete from "../../common/GoogleAutoComplete";
import { AppContexts } from "../../../providers";
import clsx from "clsx";
import MasterConfirm from "../../common/Confirm";
import UIkit from "uikit";

const schema = yup.object().shape({
  body: yup.string().required(),
});

const AddUpsell = () => {
  const { uploadFile, place, genUUID } = useContext(AppContexts.UtilContext);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const { destinationId, tabId, isNew, section } = DestinationContext;
  const [placeId, setPlaceId] = useState(null);
  const [attachImage, setAttachImage] = useState({});
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleRegistration = async (data) => {
    let attachments_buf = [];
    let image_url = "";

    let newRecommendation = {
      google_place_id: null,
      address: null,
      lat: null,
      lng: null,
      display_as: "upsell",
      destination_id: null,
      tab_id: null,
      name: null,
      tip: null,
      price: null,
      attachments: [],
    };

    newRecommendation = {
      ...newRecommendation,
      destination_id: destinationId,
      tab_id: tabId.type,
      name: data.title,
      tip: data.body,
      external_link: data.booking_url,
      price: Number.parseFloat(data.price.replace(/[^0-9|\.]/g, "")).toFixed(2).toString(),
    };
    if (placeId) {
      let destination_place = await place(placeId);
      newRecommendation = {
        ...newRecommendation,
        google_place_id: destination_place.place_id,
        address: destination_place.formatted_address,
        lat: destination_place.geometry.location.lat,
        lng: destination_place.geometry.location.lng,
      };
    }
    if (data.image_file.length > 0) {
      image_url = await uploadFile(
        data.image_file[0],
        `recommendation/attachments/${genUUID()}`,
      );
    }
    if (!!attachImage?.name) {
      if (image_url) {
        attachments_buf.push({ ...attachImage, url: image_url });
      } else {
        attachments_buf.push(attachImage);
      }
    }

    if (data.video !== "") {
      attachments_buf.push({
        name: "",
        original_name: "",
        parent_type: "recommendation",
        type: "you_tube",
        url: data.video,
      });
    }
    newRecommendation = { ...newRecommendation, attachments: attachments_buf };
    let updatedRec = {
      ...section,
      ...newRecommendation,
      destination_id: destinationId,
    };
    console.log("Rec:", updatedRec);
    const result =
      isNew === true
        ? await DestinationContext.createRecommendation(updatedRec)
        : await DestinationContext.updateRecommendation(updatedRec);

    if (result == null) {
      UIkit.notification(
        isNew === true
          ? "Upsell Block Add Failed"
          : "Upsell Block Update Failed",
        "danger",
      );
    } else {
      UIkit.modal("#destination-upsell-modal").hide();
      UIkit.notification(
        isNew === true
          ? "Upsell Block Added Successfully"
          : "Upsell Block Updated Successfully",
        "success",
      );
    }
    setAutoCompleteValue("");
    DestinationContext.getSelectedDestination(destinationId);
  };

  useEffect(() => {
    setAttachImage({});
    setValue("title", section?.name);
    setValue("body", section?.tip);
    setValue("booking_url", section?.external_link);
    setAutoCompleteValue(section?.address);
    setValue("price", section?.price);

    setValue("video", null);
    let attachments = section?.attachments;
    if (attachments && attachments.length > 0) {
      if (attachments.findIndex((attah) => attah.type === "image") >= 0) {
        setAttachImage(
          attachments[attachments.findIndex((attah) => attah.type === "image")],
        );
      }
      if (attachments.findIndex((attah) => attah.type === "you_tube") >= 0) {
        setValue(
          "video",
          attachments[
            attachments.findIndex((attah) => attah.type === "you_tube")
          ].url,
        );
      }
    }
  }, [section]);

  const image_file = useMemo(
    () => getValues("image_file"),
    [watch("image_file")],
  );

  const deleteImage = () => {
    setValue("image_file", null);
    setAttachImage({});
  };

  const handleClose = () => {
    setAutoCompleteValue("");
  };

  const handleErrors = (error) => {
    console.log(error);
  };

  return (
    <div
      id="destination-upsell-modal"
      className="uk-modal-full hms-modals uk-modal uk-open"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          />
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {isNew === true ? "Add a " : "Edit "}
              Upsell
            </h2>
          </div>
          <div className="uk-modal-body">
            <form
              data-uk-grid
              onSubmit={handleSubmit(handleRegistration, handleErrors)}
              onReset={reset}
            >
              <div className="uk-width-1-1@s">
                <label className="uk-form-label">Title</label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    className="uk-input"
                    {...register("title")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1">
                <label className="uk-form-label">Text</label>
                <div className="uk-form-controls">
                  <textarea
                    className={clsx("uk-textarea", {
                      error: errors.body,
                    })}
                    rows="5"
                    placeholder="What would you like to let your guests know?"
                    {...register("body")}
                  ></textarea>
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label">Address</label>
                <div className="uk-form-controls">
                  <GoogleAutoComplete
                    width="100%"
                    onSelect={(_, value) => setPlaceId(value.text)}
                    onChange={(e) => setAutoCompleteValue(e)}
                    address={autoCompleteValue}
                  />
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label">Price</label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="$"
                    {...register("price")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label">Booking Link</label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    className="uk-input"
                    {...register("booking_url")}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <label className="uk-form-label">Photo</label>
                <div className="js-upload uk-placeholder hms-file-uploader uk-text-center">
                  <div data-uk-form-custom>
                    {!!attachImage?.name ? (
                      <>
                        <input
                          key="thumnail_key"
                          type="file"
                          accept="image/*"
                          {...register("image_file")}
                          onChange={(evt) =>
                            setAttachImage({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "image",
                              url: "",
                            })
                          }
                        />
                        {attachImage?.name}
                      </>
                    ) : (
                      <>
                        <div>
                          <Image
                            src="/images/upload-image.svg"
                            className="hms-navbar"
                          />
                        </div>
                        <input
                          key="thumnail_key"
                          type="file"
                          accept="image/*"
                          {...register("image_file")}
                          onChange={(evt) =>
                            setAttachImage({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "image",
                              url: "",
                            })
                          }
                        />
                        <span></span>
                      </>
                    )}
                  </div>
                  <div>
                    {!!attachImage?.name && (
                      <MasterConfirm onDelete={deleteImage} />
                    )}
                  </div>
                </div>
                <progress
                  id="js-progressbar"
                  className="uk-progress"
                  value="0"
                  max="100"
                  hidden
                ></progress>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label">Video</label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="YouTube or Vimeo"
                    {...register("video")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1 uk-margin-top">
                <button className="uk-button hms-btn hms-red-btn" type="submit">
                  Save
                </button>
                <button
                  className="uk-button hms-btn uk-offcanvas-close"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUpsell;
