export const classes = (...args) => {
  let list = [];

  args.map((arg) => {
    if (typeof arg === "object") {
      Object.keys(arg).map((k) => {
        if (!!arg[k]) {
          list.push(k);
        }
      });
    } else if (typeof arg === "string") {
      list.push(arg);
    } else {
      list.push(arg.toString());
    }
  });

  return list.join(" ");
};
