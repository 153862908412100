import { createContext, useState } from "react";
import rentalsService from "../services/rentals.service";
import UIkit from "uikit";

export const RentalsContext = createContext();
export const RentalsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [rentals, setRentals] = useState([]);
  const [success, setSuccess] = useState(false);
  const [selectedFlags, setSelectedFlags] = useState([]);
  const [selectedRentals, setSelectedRentals] = useState([]);
  const [rental, setRental] = useState({});
  const [isNew, setIsNew] = useState(true);
  const [error, setPMSError] = useState(null);

  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const get = () => {
    setLoading(true);
    return rentalsService
      .get()
      .then((resp, err) => {
        setLoading(false);
        setRentals(resp.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  let rentalsCtx = {
    loading,
    rentals,
    success,
    error,
    setPMSError,
    isNew,
    setIsNew,
    setSuccess,
    selectedFlags,
    setSelectedFlags,
    selectedRentals,
    setSelectedRentals,
    rental,
    setRental,
    selectedTab,
    setSelectedTab,
    selectedSection,
    setSelectedSection,
    get,
    getRental: async (id) => {
      setLoading(true);
      let result = await rentalsService.getRental(id);
      if (result.data) {
        setRental(result.data);
      }
      setLoading(false);
    },

    searchRentals: async (criteria) => {
      setLoading(true);
      const resp = await rentalsService.search(criteria);
      setRentals(resp.data);
      setLoading(false);
    },

    createRental: async (objRental) => {
      setLoading(true);
      try {
        let result = await rentalsService.create(objRental);
        setRental(result.data);
        if (result.data) {
          setSuccess(true);
        }
        setLoading(false);
      } catch (e) {
        if (e?.response?.data?.message) setPMSError(e.response.data.message);
      }
    },

    updateRental: async (rental_id, objRental) => {
      setLoading(true);
      // let index = rentals.findIndex((rental) => rental.rental_id == rental_id);
      let result = await rentalsService.update(rental_id, objRental);
      setRental(result.data);
      if (result.data) {
        setRentals((prev) =>
          prev.map((item) =>
            +item.rental_id === +rental_id
              ? { ...result.data, updated_at: item.updated_at }
              : item,
          ),
        );
        return result.data;
      }
      setLoading(false);
    },

    deleteRental: async (id) => {
      setLoading(true);
      let index = rentals.findIndex((rental) => rental.rental_id == id);
      let result = await rentalsService.delete(id);
      if (result.data) {
        setRentals((prev) => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1),
        ]);
      }
      setLoading(false);
    },

    createSection: (section) => {
      setLoading(true);
      return rentalsService
        .create_section(section)
        .then((res) => {
          setLoading(false);
          return [res.data, null];
        })
        .catch((err) => {
          setLoading(false);
          return [null, err];
        });
    },

    updateSection: async (section) => {
      setLoading(true);
      return rentalsService
        .update_section(section)
        .then((res) => {
          setLoading(false);
          return [res.data, null];
        })
        .catch((err) => {
          setLoading(false);
          return [null, err];
        });
    },

    deleteSection: async (id) => {
      setLoading(true);

      return rentalsService
        .delete_section(id)
        .then((res) => {
          setLoading(false);
          return [res.data, null];
        })
        .catch((err) => {
          setLoading(false);
          return [null, err];
        });
    },
  };

  return (
    <RentalsContext.Provider value={rentalsCtx}>
      {children}
    </RentalsContext.Provider>
  );
};
