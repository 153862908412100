import { Image } from "uikit-react";
import { SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <div className="uk-sortable-handle">
    <button className="uk-button drag-handle" uk-tooltip="Move">
      <Image src="../images/move.svg" />
    </button>
  </div>
));

export default DragHandle;
