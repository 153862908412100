import { createContext, useState } from "react";

import templatesService from "../services/templates.service";

export const TemplatesContext = createContext();
export const TemplatesProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState({});
  const [response, setResponse] = useState({});
  const [msgTemplate, setMsgTemplate] = useState({});

  const get = (types) => {
    setLoading(true);
    return templatesService
      .get(types)
      .then((resp, err) => {
        setLoading(false);
        setTemplates({ ...templates, ...resp.data });
        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const set = (templates, type) => {
    setLoading(true);
    return templatesService
      .set(templates)
      .then((resp, err) => {
        setLoading(false);
        if (type == "saved_responses") {
          get(["saved_responses"]);
        } else {
          get(["msg_templates"]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const initResponse = () => {
    setResponse({
      name: "",
      response: "",
    });
  };

  const initMsgTemplate = () => {
    setMsgTemplate({});
  };

  const deleteTemplate = async (id, type) => {
    try {
      await templatesService.deleteTemplate(id, type);

      let newTemplates = {};
      if (type === "saved_responses") {
        Object.keys(templates).map((key) => {
          newTemplates[key] = templates[key].filter(
            (tpl) => tpl.saved_response_id !== id,
          );
        });
      } else {
        Object.keys(templates).map((key) => {
          newTemplates[key] = templates[key].filter((tpl) => tpl.id !== id);
        });
      }
      setTemplates(newTemplates);
    } catch (err) {
      console.log(err);
    }
  };

  let templatesCtx = {
    loading,
    templates,
    isNew,
    setIsNew,
    get,
    set,
    response,
    setResponse,
    msgTemplate,
    setMsgTemplate,
    initResponse,
    initMsgTemplate,
    deleteTemplate,
  };

  return (
    <TemplatesContext.Provider value={{ templatesCtx: templatesCtx }}>
      {children}
    </TemplatesContext.Provider>
  );
};
