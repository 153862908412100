import { useContext, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";

import { classes } from "../../../utils/classes";
import { AppContexts } from "../../../providers";
import { Link } from "../../ui-kit/Link";

import ImageInput from "../../common/ImageInput";

// const defaultImg = "/images/upload-image.svg"

const UpdateImageForm = (props) => {
  let { user, update_profile_image } = useContext(AppContexts.AuthContext);
  let { uploadFile } = useContext(AppContexts.UtilContext);

  let [status, setStatus] = useState("initial");
  let [upload, setUpload] = useState(null);

  let profileImage = useMemo(() => {
    return (
      (user?.user?.attachments || []).find((a) => a.type === "image") || null
    );
  }, [user]);

  const handleFile = async (file) => {
    setUpload(file);
    setStatus("dirty");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (status !== "dirty") {
      return;
    }

    setStatus("pending");
    let attachment = null;
    if (upload !== null) {
      let uploadUrl = await uploadFile(
        upload,
        `users/profile/${user.user.user_id}`,
      );

      attachment = {
        type: "image",
        name: upload.name,
        original_name: upload.name,
        url: uploadUrl,
      };
    }

    await update_profile_image(attachment)
      .then((resp) => {
        setStatus("complete");
        setUpload(null);
        UIkit.notification("The photo has been updated", "success");
      })
      .catch((err) => {
        setStatus("error");
        setUpload(null);
        UIkit.notification(`Failed: ${err}`, "error");
      });
  };

  return (
    <form className="auth uk-form-stacked" onSubmit={handleSubmit}>
      {status === "complete" && (
        <p>
          An email has been sent to the email you provided with instructions on
          how to complete updating your email.
        </p>
      )}

      {status === "error" && (
        <p>
          There was an error processing your request, please contact{" "}
          <a href="mainto:support@ruebarue.com">support@ruebarue.com</a> for
          assistance
        </p>
      )}

      <div className="uk-width-1-1">
        <div>
          <label for="profile-upload">
            <div className="uk-form-label">Upload Your Profile Picture</div>
            <ImageInput
              label=""
              attachment={profileImage}
              handler={handleFile}
            />
          </label>
        </div>

        <button
          className={`uk-button hms-btn hms-red-btn ${
            status === "pending" ? "disabled" : ""
          }`}
        >
          Upload Profile Image
        </button>
      </div>
    </form>
  );
};

export default UpdateImageForm;
