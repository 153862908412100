import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { classes } from "../../../utils/classes";
import { AppContexts } from "../../../providers";
import { Link } from "../../ui-kit/Link";

import UIkit from "uikit";

const UpdateForm = (props) => {
  const { handleSubmit } = useForm();

  let authCtx = useContext(AppContexts.AuthContext);

  let [creds, setCreds] = useState({
    email: "",
    token: window.location.search.split("=")[1],
    password: "",
    password_confirmation: "",
  });

  let [errors, setErrors] = useState([]);
  let [submitted, setSubmitted] = useState(false);
  let [completed, setCompleted] = useState(false);

  let [valid, setValid] = useState({
    minLength: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
    match: false,
  });

  useEffect(() => {
    let validation = Object.keys(valid).reduce((a, c) => {
      a[c] = false;
      return a;
    }, {});
    let { password, password_confirmation } = creds;

    if (password === "" && password_confirmation === "") {
      return setValid(validation);
    }

    if (password === password_confirmation) {
      validation.match = true;
    }

    if (password.length >= 8) {
      validation.minLength = true;
    }

    if (/[A-Z]+/g.test(password)) {
      validation.upper = true;
    }

    if (/[a-z]+/g.test(password)) {
      validation.lower = true;
    }

    if (/[0-9]+/g.test(password)) {
      validation.number = true;
    }

    if (/[!@#\$%\^&_]/g.test(password)) {
      validation.special = true;
    }

    setValid(validation);
  }, [creds]);

  const reset = () => {
    let errs = [];

    Object.keys(creds).map((k) => {
      if (!creds[k]) {
        errs.push(k);
      }
    });

    setErrors(errs);

    if (errs.length !== 0) {
      return;
    }

    authCtx
      .update_password(creds)
      .then((resp, err) => {
        setCreds({
          email: "",
          token: window.location.search.split("=")[1],
          password: "",
          password_confirmation: "",
        });

        UIkit.notification(
          "Your password has been successfully updated",
          "success",
        );

        setTimeout(() => {
          window.location.assign("/auth/login");
        }, 5000);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const update = (field, e) => {
    setCreds({ ...creds, [field]: e.target.value });

    if (errors.includes(field)) {
      setErrors(errors.filter((k) => k !== field));
    }
  };

  return (
    <div className="page-box sign-in auth">
      <h2 className="uk-heading-small">Reset Password</h2>
      <div className="uk-margin">
        <form className="uk-form-stacked" onSubmit={handleSubmit(reset)}>
          <div
            className={classes(
              { error: errors.includes("email") },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                value={creds.email}
                onInput={(e) => {
                  update("email", e);
                }}
              />
            </div>
          </div>

          <div
            className={classes(
              { error: errors.includes("password") },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="password"
                value={creds.password}
                onInput={(e) => {
                  update("password", e);
                }}
              />
            </div>
          </div>

          <div
            className={classes(
              { error: errors.includes("password_confirmation") },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Password Confirmation</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="password"
                value={creds.password_confirmation}
                onInput={(e) => {
                  update("password_confirmation", e);
                }}
              />
            </div>
          </div>

          <div className="uk-margin">
            Password Rules:
            <ul className="password-validation">
              <li className={classes({ complete: valid.minLength })}>
                At least 8 characters
              </li>
              <li className={classes({ complete: valid.upper })}>
                At least one capital letter
              </li>
              <li className={classes({ complete: valid.lower })}>
                At least one lowercase letter
              </li>
              <li className={classes({ complete: valid.number })}>
                At least one number
              </li>
              <li className={classes({ complete: valid.special })}>
                At least one of the following !@#$%^&_
              </li>
              <li className={classes({ complete: valid.match })}>
                Passwords must match
              </li>
            </ul>
          </div>

          {!completed ? (
            <button className="uk-button uk-button-# hms-btn hms-red-btn">
              Reset Password
            </button>
          ) : (
            <p>Your password has been reset</p>
          )}

          <div className="uk-margin">
            Need an account? <Link to="signup">Sign Up</Link>
          </div>
          <div className="uk-margin">
            Return to <Link to="login">Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateForm;
