import React from "react";

const ShortAnswer = ({ answer, disabled = true }) => {
  return (
    <div className="uk-form-controls uk-margin-small-top">
      <textarea
        className="uk-textarea uk-width-2-3"
        rows="3"
        disabled={disabled}
      >
        {answer}
      </textarea>
    </div>
  );
};

export default ShortAnswer;
