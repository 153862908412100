// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const ContactDelete = () => {
  const contactContext = useContext(AppContexts.ContactsContext);
  const { contact, selectedContacts, batchFlag } = contactContext;

  const handleDelete = () => {
    if (batchFlag) {
      selectedContacts.forEach(
        async (contact) => await contactContext.deleteContact(contact),
      );
    } else {
      contactContext.deleteContact(contact);
    }
    UIkit.modal("#modal-contact-delete").hide();
  };

  return (
    <>
      <Dialog id="modal-contact-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">
            Delete
            {batchFlag ? " Contacts" : " Contact"}
          </h2>
          <p>
            Are you sure you want to delete{" "}
            <b>
              {batchFlag
                ? `${selectedContacts.length} contact${
                    selectedContacts.length === 1 ? "" : "s"
                  }`
                : `${contact.first_name} ${contact.last_name}`}
            </b>
            ?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default ContactDelete;
