import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "uikit-react";
import { AppContexts } from "../../../providers";

import MultipleChoice from "../question_types/multiple-choice";
import ShortAnswer from "../question_types/short-answer";
import Checkboxes from "../question_types/checkboxes";
import Range from "../question_types/range";

const SurveyPreview = () => {
  const surveysContext = useContext(AppContexts.SurveysContext);
  const { get, settings } = useContext(AppContexts.SettingsContext);
  const history = useHistory();

  useEffect(async () => {
    document.title = "Preview Survey - RueBaRue";
    document.getElementById("ruebarue-navbar").hidden = true;
    let survey_id = history.location.pathname.split("/")[2];
    await get(["branding"]);
    surveysContext.getSurvey(survey_id);
  }, []);

  const survey = useMemo(() => {
    if (!surveysContext.survey) {
      return {};
    } else {
      let temp = surveysContext.survey;
      let realQuestions = [];
      if (surveysContext.survey.question_order) {
        let orders = surveysContext.survey.question_order.split(",");
        realQuestions = orders
          .map((order_id) => {
            return surveysContext.survey.questions?.find(
              ({ survey_question_id, deleted }) =>
                +survey_question_id == +order_id && deleted == false,
            );
          })
          .filter((item) => item != undefined)
          .filter((item) => !item.deleted);
      }
      temp.questions = realQuestions;
      realQuestions.forEach((question) => {
        let realOptions = [];
        if (question.option_order) {
          let orders = question.option_order.split(",");
          realOptions = orders
            .map((order_id) => {
              return question.question_options?.find(
                ({ survey_question_option_id, type, deleted }) => {
                  return (
                    (+survey_question_option_id == +order_id ||
                      +type == +order_id) &&
                    deleted == false
                  );
                },
              );
            })
            .filter((item) => item != undefined)
            .filter((item) => !item.deleted);
          question.question_options = realOptions;
        }
      });
      return temp;
    }
  }, [surveysContext.survey]);

  return (
    <>
      <div id="surveys-preview-template">
        <div className="hms-logo">
          <a
            href={settings.branding?.logo_url}
            target="_blank"
            className="hms-logo-url"
          >
            <Image
              src={settings.branding?.logo_image_url}
              alt="Ruebarue Vacation"
            />
          </a>
        </div>
        <div id="hms-page-title">
          <div className="uk-flex">
            <div>
              <div className="uk-flex uk-flex-middle">
                <h1 className="uk-heading-small">{survey?.name}</h1>
              </div>
              <div className="uk-flex uk-flex-middle">
                <h3>{survey?.intro}</h3>
              </div>
            </div>
          </div>
        </div>
        <div id="hms-main-body">
          <div>
            <div className="uk-width-1-1">
              {survey?.questions?.map((question, no) => (
                <div
                  className="uk-card uk-card-small uk-card-default hms-property-card"
                  key={no}
                >
                  <div className="uk-flex uk-flex-between">
                    <div className="hms-property-card-body">
                      <div className="hms-property-card-content">
                        <h4 className="card-heading-small">
                          {question?.question}
                        </h4>
                        <div className="hms-small-note">
                          {question?.help_text}
                        </div>
                        <div>
                          {question?.question_type === "multiple-choice" && (
                            <MultipleChoice
                              options={question?.question_options}
                              index={no}
                              disabled={false}
                            />
                          )}
                          {question?.question_type === "short-answer" && (
                            <ShortAnswer disabled={false} />
                          )}
                          {question?.question_type === "checkboxes" && (
                            <Checkboxes
                              options={question?.question_options}
                              index={no}
                              disabled={false}
                            />
                          )}
                          {question?.question_type === "range-5" && (
                            <Range
                              question={question}
                              index={no}
                              type={5}
                              disabled={false}
                            />
                          )}
                          {question?.question_type === "range-10" && (
                            <Range
                              question={question}
                              index={no}
                              type={10}
                              disabled={false}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="uk-flex uk-flex-between uk-flex-top">
              <div className="hms-guest-btn">
                <button className="uk-button hms-btn hms-red-btn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyPreview;
