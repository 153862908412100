import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Image } from "uikit-react";
import UIkit from "uikit";
import { CSVLink } from "react-csv";

import { Link } from "../../../../ui";
import TableLoading from "../../../common/Loading/table_loading";

const TagDetail = ({ tag }) => {
  const detailRenderer = () => {
    return (
      <>
        <tr>
          <td>Master Home Guide</td>
          <td>{tag?.welcome_count}</td>
        </tr>
        <tr>
          <td>Home Guides</td>
          <td>{tag?.rental_count}</td>
        </tr>
        <tr>
          <td>Area Guides</td>
          <td>{tag?.destination_count}</td>
        </tr>
        <tr>
          <td>Scheduler</td>
          <td>{tag?.scheduled_count}</td>
        </tr>
        <tr>
          <td>Alerts</td>
          <td>{tag?.alert_count}</td>
        </tr>
        <tr>
          <td>Work Orders</td>
          <td>{tag?.workorder_count}</td>
        </tr>
        <tr>
          <td>Guests</td>
          <td>{tag?.reservation_count}</td>
        </tr>
      </>
    );
  };

  return (
    <div
      id="modal-tags-detail"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className='uk-offcanvas-bar'>
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          />
          <div className="uk-modal-header">
            <span className="uk-modal-title">{tag?.name}</span>
          </div>
          <div className="uk-modal-body uk-flex">
            <table className="uk-table uk-table-hover uk-table-divider hms-table hms-table-auto">
              <thead>
                <tr>
                  <th className="uk-table-expand">TYPE</th>
                  <th className="uk-table-expand">COUNT</th>
                </tr>
              </thead>
              <tbody>{detailRenderer(tag)}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagDetail;
