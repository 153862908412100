import { useEffect } from "react";
import Recommendation from "../common/recommendation";

const SortableList = ({
  items,
  orders,
  onSortEnd,
  destinationTabs,
  section,
}) => {
  useEffect(() => {
    let container = document.querySelector(".drag-container");
  
    const handleSortEnd = () => {
      let ids = [...container.querySelectorAll(".drag-item")].map((el) => {
        return parseInt(el.dataset["id"]);
      });

      onSortEnd(ids);
    };

    container.addEventListener("stop", handleSortEnd);
  
    return () => {
      container.removeEventListener("stop", handleSortEnd);
    };
  }, [onSortEnd]);

  let mapIndex = 0;

  return (
    <ul
      uk-sortable="handle: .uk-sortable-handle"
      className="drag-container uk-list"
    >
      {/* {orders?.map((order, index) => {
        const item = items?.find((item) => item.recommendation_id === order);
        let hasLoc = !!item.lat && !!item.lng;

        let rec = (
          <Recommendation
            key={index}
            rec={item}
            orderNo={hasLoc ? mapIndex : null}
            tabId={destinationTabs?.at(section)}
          />
        );

        if (hasLoc) {
          mapIndex++;
        }

        return rec;
      })} */}
      {items?.map((item) => {
        let hasLoc = !!item.lat && !!item.lng;
        let index = orders?.find((order) => order === item.recommendation_id);

        let rec = (
          <Recommendation
            key={index}
            rec={item}
            orderNo={hasLoc ? mapIndex : null}
            tabId={destinationTabs?.at(section)}
          />
        );

        if (hasLoc) {
          mapIndex++;
        }

        return rec;
      })}
    </ul>
  );
};

export default SortableList;
