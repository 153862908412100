// Import package components
import classNames from "classnames";

// Import UI components
import uiClassNames from "./ClassNames";

export function Button(props) {
  const isLink = () => {
    return !!props.href;
  };

  const setClassNames = () => {
    return classNames("uk-button", {
      //[`uk-button-default`]: !props.color,
      [`uk-button-${props.color}`]: !!props.color,
      [`uk-button-${props.size}`]: !!props.size,
      [`${uiClassNames(props)}`]: true,
    });
  };

  return isLink() ? (
    <a
      //id={props.id ? props.id : null}
      //style={props.style ? props.style : null}
      //data-uk-toggle={props.toggleOptions}
      href={props.href}
      //onClick={props.onClick}
      //className={_setClassNames()}
    >
      {props.children}
    </a>
  ) : (
    <button
      //id={props.id ?? null}
      //style={props.style ?? null}
      className={setClassNames()}
      type="button"
      //data-uk-toggle={props.toggleOptions}
      //onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
