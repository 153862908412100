import moment from "moment";
import React, { useContext, useState, useEffect, useMemo } from "react";

import { AppContexts } from "../../providers";

// Import common components
import ReservationNumber from "../common/ReservationNumber";
import ReservationType from "../common/ReservationType";

const GuestDetails = (props) => {
  const contextScheduler = useContext(AppContexts.SchedulerContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalsContext = useContext(AppContexts.RentalsContext);
  const { user } = useContext(AppContexts.AuthContext);

  let { contact = null, guest_reservations = [] } = props;

  let [index, setIndex] = useState(0);
  let [selected_reservation, setReservation] = useState([]);

  useEffect(() => {
    setReservation([guest_reservations?.[index]]);
  }, [guest_reservations, index]);

  let useContact = useMemo(() => {
    setIndex(0);
    if (!!contact && !!contact.category && contact.category !== "leads") {
      return true;
    } else {
      if (!!contact && contact.category === "leads") {
        return !guest_reservations || guest_reservations.length === 0;
      } else {
        return false;
      }
    }
  }, [contact, guest_reservations]);

  const incrementIndex = () => {
    index < guest_reservations.length - 1 ? setIndex(index + 1) : setIndex(0);
  };

  const decrementIndex = () => {
    index > 0 ? setIndex(index - 1) : setIndex(guest_reservations.length - 1);
  };

  const getIndexClass = (idx) => {
    if (idx === index) {
      return "hms-selected-guest-detail";
    } else if (idx < index) {
      return "hms-prev-guest-details";
    } else if (idx > index) {
      return "hms-next-guest-detail";
    }
  };

  // if (!contact.contact_id && !guest_reservations?.length) {
  //   return null;
  // }

  return (
    <aside
      className="uk-margin-left uk-width-1-4@m thread-reservations"
      uk-viewport-box=""
    >
      <div className="uk-card uk-card-default uk-card-small uk-card-body hms-msg-cards hms-booking-info">
        {!useContact && guest_reservations.length > 1 && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="hms-msg-booking-title"
              style={{ textAlign: "center" }}
            >
              Reservation {index + 1} of {guest_reservations.length}
            </div>
            <div>
              <span
                uk-icon="arrow-left"
                className="uk-icon-button"
                onClick={decrementIndex}
              ></span>
              <span
                uk-icon="arrow-right"
                className="uk-icon-button"
                onClick={incrementIndex}
              ></span>
            </div>
          </div>
        )}
        {useContact ? (
          <div className="hms-selected-guest-detail">
            <div className="guest-chat-info-wrapper">
              <div className="uk-margin guest-chat-info">
                <div className="hms-booking-tag uk-margin-small-bottom">
                  {contact.category
                    ?.toLowerCase()
                    .replace(/\b\w/g, (s) => s.toUpperCase())}
                </div>
                <div className="chat-guest-key">Name</div>
                <div className="chat-guest-value">
                  {[contact.first_name || " ", contact.last_name || ""].join(
                    " ",
                  )}
                </div>
              </div>
              <div className="uk-margin guest-chat-info">
                <div className="chat-guest-key">Phone</div>
                <div className="chat-guest-value">{contact.phone || ""}</div>
              </div>
              <div className="uk-margin guest-chat-info">
                <div className="chat-guest-key">Email</div>
                <div className="chat-guest-value">{contact.email || ""}</div>
              </div>
            </div>
          </div>
        ) : (
          Array.isArray(selected_reservation) &&
          selected_reservation.length > 0 &&
          selected_reservation.map((reservation, idx) => (
            <div className={getIndexClass(idx)}>
              <div className="guest-chat-info-wrapper">
                <div className="hms-booking-tag uk-margin-small-bottom">
                  <ReservationType reservation={reservation} />
                </div>
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Name</div>
                  <div className="chat-guest-value">
                    {reservation?.first_name + " " + reservation?.last_name}
                  </div>
                </div>
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Phone</div>
                  <div className="chat-guest-value">
                    {reservation?.formatted_phone}
                  </div>
                </div>
                {reservation?.email && (
                  <div className="uk-margin guest-chat-info">
                    <div className="chat-guest-key">Email</div>
                    <div className="chat-guest-value">{reservation?.email}</div>
                  </div>
                )}
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Check-In & Check-Out</div>
                  <div className="chat-guest-value">
                    {moment(reservation?.check_in).format("MMM DD, YYYY")} -{" "}
                    {moment(reservation?.check_out).format("MMM DD, YYYY")}
                  </div>
                </div>
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Property ID</div>
                  <div className="chat-guest-value">{reservation?.pms_id}</div>
                </div>
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Address</div>
                  <div className="chat-guest-value">
                    {reservation?.pms_address}
                  </div>
                </div>
                {reservation?.door_code && (
                  <div className="uk-margin guest-chat-info">
                    <div className="chat-guest-key">Door Code</div>
                    <div className="chat-guest-value">
                      {reservation?.door_code}
                    </div>
                  </div>
                )}
                <div className="uk-margin guest-chat-info">
                  <div className="chat-guest-key">Reservation Number</div>
                  <div className="chat-guest-value">
                    <ReservationNumber
                      hostID={reservation?.host_id}
                      pms={user?.details?.pms}
                      resevationID={reservation?.reservation_id}
                      nativeID={reservation?.native_id}
                    />
                  </div>
                </div>
                {reservation?.source && (
                  <div className="uk-margin guest-chat-info">
                    <div className="chat-guest-key">Booking Source</div>
                    <div className="chat-guest-value">
                      {reservation?.source}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </aside>
  );
};

export default GuestDetails;
