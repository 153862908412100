import { useState, useCallback, useEffect, useContext } from "react";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const DestinationShare = () => {
  const { shared, setShared } = useContext(AppContexts.DestinationContext);

  const { shareGuide } = useContext(AppContexts.UtilContext);

  let [sms, setSMS] = useState("");
  let [copied, setCopied] = useState(false);

  const handleInput = (e) => {
    setSMS(e.target.value);
  };

  const handleCopy = async (e) => {
    e.preventDefault();

    await navigator.clipboard.writeText(
      `https://guide.ruebarue.com/destination/${shared?.destination_id || ""}`,
    );

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleShare = async (e) => {
    e.preventDefault();

    if (sms === "") {
      return;
    }

    let result = await shareGuide({
      guide_type: "destination",
      guide_id: shared.destination_id,
      phone: sms,
    });

    if (!!result) {
      UIkit.modal("#shareDestinationGuide-modal").hide();
      UIkit.notification("Area Guide Sent", "success");
    } else {
      UIkit.notification("Failed Sending Area Guide", "error");
    }
  };

  const errorClass = (key) => {
    return [];
  };

  return (
    <div
      id="shareDestinationGuide-modal"
      className="uk-modal-full hms-modals hms-modals-small uk-modal uk-open uk-dark"
      uk-offcanvas="overlay: true; flip: true;"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Share Area Guide</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleCopy}
              >
                <div className="uk-width-1-1">
                  <div className="uk-width-3-4 uk-display-inline-block">
                    <label className="uk-form-label">Area Guide URL</label>
                    <div className="uk-form-controls">
                      <input
                        className={[
                          "uk-input",
                          ...errorClass("first_name"),
                        ].join(" ")}
                        type="text"
                        placeholder=""
                        value={`https://guide.ruebarue.com/destination/${
                          shared?.destination_id || 0
                        }`}
                        disabled
                      />
                    </div>
                  </div>
                  <button
                    className="uk-button hms-btn hms-red-btn uk-display-inline-block uk-margin-left"
                    type="submit"
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </form>
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleShare}
              >
                <div className="uk-width-1-1">
                  <div className="uk-width-3-4 uk-display-inline-block">
                    <label className="uk-form-label">Text Area Guide</label>
                    <div className="uk-form-controls">
                      <input
                        className={[
                          "uk-input",
                          ...errorClass("first_name"),
                        ].join(" ")}
                        placeholder=""
                        value={sms}
                        onInput={handleInput}
                      />
                    </div>
                  </div>
                  <button
                    className="uk-button hms-btn hms-red-btn uk-display-inline-block uk-margin-left"
                    type="submit"
                  >
                    Share
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationShare;
