// Import package components
import { useMemo, useEffect, useContext } from "react";

// Import base components
import { Image } from "uikit-react";
import { AppContexts } from "../../../providers";

const Responses = ({ onChange }) => {
  const {
    templatesCtx: { get, templates },
  } = useContext(AppContexts.TemplatesContext);

  const responses = useMemo(() => {
    if (Object.keys(templates).length == 0) {
      return [];
    } else {
      if (!templates.saved_responses) {
        return [];
      } else {
        return templates.saved_responses.sort((prev, next) =>
          prev.name.localeCompare(next.name),
        );
      }
    }
  }, [templates]);

  useEffect(() => {
    get(["saved_responses"]);
  }, []);

  return (
    <div>
      <button uk-tooltip="Click to select from a Saved Response" type="button">
        <Image src="/images/reply-with.svg" />
      </button>
      <div data-uk-dropdown="mode: click" id="messages-response">
        <ul className="uk-nav uk-dropdown-nav tags-selector">
          {responses.length > 0 ? (
            responses.map((response, index) => (
              <li
                key={index}
                className="messages-li"
                uk-tooltip="Click to Insert"
                onClick={() => onChange(response.response)}
              >
                <div>{response.name}</div>
                <label uk-line-clamp="lines: 1">{response.response}</label>
              </li>
            ))
          ) : (
            <div style={{ padding: "30px 25px" }}>No saved responses. <a href="/saved-responses">Add Now.</a></div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Responses;
