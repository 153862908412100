// Import package components
import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { AppContexts } from "../../../providers";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import MacroDelete from "./macro_delete";
import { host } from "../../../http.js";

const APITag = () => {
  const { set, settings, setDeleted, active } = useContext(
    AppContexts.SettingsContext,
  );
  const { pmsFields, getPmsFields, loading } = useContext(
    AppContexts.UtilContext,
  );
  const { handleSubmit } = useForm();

  const [api, setApi] = useState({
    pms_sync: true,
    property_sync: true,
  });
  const [customMacros, setCustomMacros] = useState([]);
  const [fields, setFields] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [newMacro, setNewMacro] = useState(null);
  const [selectedMacro, setSelectedMacro] = useState(null);
  const [updatedMacro, setUpdatedMacro] = useState(null);

  useEffect(() => {
    document.title = "Settings | API - RueBaRue";
    if (!!settings.integration) {
      setApi(settings.integration || {});
    }

    if (!!settings.custom_macros) {
      setCustomMacros(settings.custom_macros || []);
    }
  }, [settings]);

  useEffect(() => {
    setFields(pmsFields);
  }, [pmsFields]);

  useEffect(() => {
    if (!pmsFields) {
      getPmsFields();
    }
  }, []);

  const handleSave = () => {
    set(["integration"], {
      integration: api,
    }).then((res) => {
      setDirty(false);
    });
  };

  const handleUpdate = (field, evt) => {
    setDirty(true);
    setApi({ ...api, [field]: evt.target.value });
  };

  const handleToggle = (field, evt) => {
    set(["integration"], {
      integration: { ...api, [field]: !api[field] },
    });
  };

  const selectMacroField = (e) => {
    var t = e.target,
      v = t.value,
      d = t.options[t.selectedIndex].innerHTML,
      c = t.options[t.selectedIndex].parentNode.label,
      n = newMacro?.name || "";

    if (!n) {
      n = `[${d
        .toLowerCase()
        .replace("_", " ")
        .replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase())
        .replace(/[\W_]+/g, "")}]`;
    }

    setNewMacro({
      category: c,
      field: v,
      macro: n,
    });
  };

  const updateMacroField = (e) => {
    var t = e.target,
      v = t.value,
      d = t.options[t.selectedIndex].innerHTML,
      c = t.options[t.selectedIndex].parentNode.label,
      n = updatedMacro?.name || "";

    if (!n) {
      n = `[${d
        .toLowerCase()
        .replace("_", " ")
        .replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase())
        .replace(/[\W_]+/g, "")}]`;
    }

    setUpdatedMacro((prev) => {
      return {
        ...prev,
        category: c,
        field: v,
        macro: n,
      };
    });
  };

  const updateMacroName = (cb, obj, e) => {
    var t = e.target,
      n = `[${t.value
        .replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase())
        .replace(/[\W_]+/g, "")}]`;

    cb({ ...obj, macro: n });

    var caretPos = t.selectionStart,
      textLength = t.value.length;

    if (caretPos === 1) {
      caretPos++;
    } else if (caretPos === textLength) {
      caretPos--;
    }

    t.setSelectionRange(caretPos, caretPos);
  };

  const addMacro = async () => {
    await set(["custom_macros"], {
      custom_macros: [...settings.custom_macros, newMacro],
    }).then((res) => {
      setDirty(false);
      setNewMacro(null);
    });
    setNewMacro(null);
  };

  const saveMacro = async () => {
    let newMacros = [...settings.custom_macros];
    let idx = newMacros.findIndex(
      (nm) => nm.id.toString() === updatedMacro.id.toString(),
    );
    newMacros[idx] = updatedMacro;

    await set(["custom_macros"], {
      custom_macros: newMacros,
    }).then((res) => {
      setDirty(false);
      setSelectedMacro(null);
      setUpdatedMacro(null);
    });
    setSelectedMacro(null);
    setUpdatedMacro(null);
  };

  const getMacroName = useCallback(
    (macro) => {
      if (!pmsFields || !macro) {
        return "";
      }

      if (!!pmsFields[macro.category]) {
        return pmsFields[macro.category][macro.field];
      } else {
        return "Macro No Longer Exists";
      }
    },
    [pmsFields, customMacros],
  );

  return (
    <li
      className={`setting-api-tab-content ${
        active === "api" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h2>API</h2>
        <form
          className="uk-grid-small"
          uk-grid
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="uk-width-1-2 uk-margin-bottom">
            <div className="uk-width-1-1 hms-headings">
              <div className="uk-form-label">
                Property Management System{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Contact support@ruebarue.com to add or switch your Property Management Software."
                />
              </div>

              <div>
                <span>{api.pms}</span>{" "}
                {settings?.integration?.pms === "OwnerRez" &&
                  !settings?.integration?.ownerrez_token && (
                    <a href={`${host}/v2/oauth/ownerrez/signin/`}>
                      Click to sign into OwnerRez
                    </a>
                  )}
              </div>
            </div>
          </div>
          <div className="uk-width-1-1@s uk-margin-bottom">
            <div className="uk-width-1-1 hms-headings">
              <div className="uk-form-label">
                Guest Reservations{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Import runs every hour on the hour and takes 1-30 minutes to
                complete."
                />
              </div>
            </div>
            <div className="uk-width-1-2 uk-margin-top uk-margin-large-bottom">
              <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
                <div className="hms-headings">Import Reservations</div>
                <div className="autosend-checkbox">
                  <label
                    htmlFor="apiSyncReservations"
                    className="setting-switch"
                  >
                    <input
                      type="checkbox"
                      id="apiSyncReservations"
                      checked={api.pms_sync}
                      onChange={() => {
                        handleToggle("pms_sync");
                      }}
                    />
                    <span className="setting-slider setting-round"></span>
                  </label>
                </div>
              </div>
              {api.pms === "LiveRez" && (
                <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
                  <div>Import Long Term Rentals</div>
                  <div className="autosend-checkbox">
                    <label htmlFor="apiLongTRental" className="setting-switch">
                      <input
                        type="checkbox"
                        id="apiLongTRental"
                        checked={api.exclude_long_term}
                        onChange={() => {
                          handleToggle("exclude_long_term");
                        }}
                      />
                      <span className="setting-slider setting-round"></span>
                    </label>
                  </div>
                </div>
              )}
              {api.pms === "LiveRez" && (
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>Import Seasonal Long Term Rentals</div>
                  <div className="autosend-checkbox">
                    <label
                      htmlFor="apiSeasLongTRental"
                      className="setting-switch"
                    >
                      <input
                        type="checkbox"
                        id="apiSeasLongTRental"
                        checked={api.exclude_seasonal_long_term}
                        onChange={() => {
                          handleToggle("exclude_seasonal_long_term");
                        }}
                      />
                      <span className="setting-slider setting-round"></span>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-bottom hms-headings">
            <div className="uk-form-label">
              Property Macros{" "}
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="Map property fields from your rental software to property macros, which can be used in the master home guide, home guides or schedule messages. Import runs daily at 3 am EST."
              />
            </div>
          </div>
          <div className="uk-width-1-2 uk-margin-top uk-margin-large-bottom">
            <div className="uk-flex uk-flex-between uk-flex-middle">
              <div className="hms-headings">Import Property Fields</div>
              <div className="autosend-checkbox">
                <label htmlFor="apiSyncProperty" className="setting-switch">
                  <input
                    type="checkbox"
                    id="apiSyncProperty"
                    checked={api.property_sync}
                    onChange={() => {
                      handleToggle("property_sync");
                    }}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </div>
          <ul className="uk-list uk-list-divider uk-width-1-1">
            {(customMacros || []).map((cm, index) => {
              return (
                <li
                  className="uk-grid uk-margin-small"
                  key={`customMacros_${index}`}
                >
                  {!!updatedMacro && updatedMacro.id === cm.id ? (
                    <>
                      <div className="uk-width-3-4 uk-flex uk-flex-middle">
                        <div className="uk-width-1-2">{getMacroName(cm)}</div>
                        <div className="uk-width-1-2 uk-margin-right">
                          <input
                            value={updatedMacro?.macro || ""}
                            onChange={(e) => {
                              updateMacroName(setUpdatedMacro, updatedMacro, e);
                            }}
                            className="uk-input"
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="uk-width-auto uk-margin-right">
                        <button
                          type="button"
                          className="uk-button hms-simple-icon-btn"
                          onClick={saveMacro}
                        >
                          <span
                            uk-icon="check"
                            className="icon-affirm"
                            onClick={saveMacro}
                          ></span>
                        </button>
                        <button
                          type="button"
                          className="uk-button hms-simple-icon-btn"
                          onClick={() => {
                            setUpdatedMacro(null);
                          }}
                        >
                          <span
                            className="icon-decline"
                            uk-icon="close"
                            onClick={() => {
                              setUpdatedMacro(null);
                            }}
                          ></span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="uk-width-3-4 uk-flex uk-flex-bottom">
                        <div className="uk-width-1-2">{getMacroName(cm)}</div>
                        <div className="uk-width-1-2">{cm.macro}</div>
                      </div>
                      <div className="uk-width-auto uk-margin-right">
                        <button
                          type="button"
                          className="uk-button hms-simple-icon-btn"
                          onClick={() => {
                            setUpdatedMacro(cm);
                          }}
                        >
                          <Image src="/images/edit-icon.svg" />
                        </button>
                        <button
                          type="button"
                          className="uk-button hms-simple-icon-btn"
                          uk-toggle="target: #macro-delete"
                          onClick={() => {
                            setDeleted(cm);
                          }}
                        >
                          <Image src="/images/delete-icon.svg" />
                        </button>
                      </div>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
          {!selectedMacro && (
            <div className="uk-width-1-1 uk-margin-top">
              <div className="add-dest-gd-tab-wrapper">
                <div className="uk-flex uk-flex-bottom">
                  <div className="uk-width-2-6">
                    <div className="uk-form-label">
                      Property Fields
                      {loading ? <div data-uk-spinner="ratio: 0.5;"></div> : ""}
                    </div>
                    <div className="uk-form-controls w-100">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select onChange={selectMacroField} disabled={loading}>
                          <option value="">Select an API Field</option>
                          {Object.keys(pmsFields || {}).map((groupName) => {
                            let fields = pmsFields[groupName];
                            return (
                              <optgroup label={groupName} key={groupName}>
                                {Object.keys(fields).map((fieldKey, index) => {
                                  if (
                                    !!customMacros.find(
                                      (macros) =>
                                        macros.field.toString() ===
                                        fieldKey.toString(),
                                    )
                                  ) {
                                    return (
                                      <React.Fragment
                                        key={`fragment_${index}`}
                                      ></React.Fragment>
                                    );
                                  } else {
                                    return (
                                      <option
                                        key={`pmsField_${index}`}
                                        value={fieldKey}
                                      >
                                        {fields[fieldKey].toString()}
                                      </option>
                                    );
                                  }
                                })}
                              </optgroup>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span>
                            {!newMacro || !newMacro.field || !newMacro.category
                              ? "Select an API Field"
                              : Object.keys(pmsFields[newMacro.category])?.find(
                                  (item) => item === newMacro.field,
                                )}
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-3-6 uk-margin-left">
                    <label className="uk-form-label">Macro Name</label>
                    <div className="uk-form-controls">
                      <input
                        value={newMacro?.macro || ""}
                        onChange={(e) => {
                          updateMacroName(setNewMacro, newMacro, e);
                        }}
                        className="uk-input"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="uk-margin-left">
                    <button
                      className="uk-button hms-btn hms-gray-btn btn-no-margin"
                      type="button"
                      onClick={addMacro}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
      <MacroDelete />
    </li>
  );
};

export default APITag;
