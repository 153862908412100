import { httpV2 } from "../http.js";

const get = (types) => {
  return httpV2.get(`/templates?types=${types.join("+")}`);
};

const set = (templates) => {
  return httpV2.put("/templates", templates);
};

const deleteTemplate = (id, type) => {
  return httpV2.delete(`/templates/${id}`, { params: { type } });
};

const TemplatesService = {
  get,
  set,
  deleteTemplate,
};

export default TemplatesService;
