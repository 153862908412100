// Import package components
import { useContext, useEffect, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import moment from "moment";

// Import base components
import { AppContexts } from "../../../providers";
import { Form, FormRow, FormInputWrapper } from "../../../ui";
// Import common components
import Tags from "../../common/Tags";
import Properties from "../../common/Properties";
import ImageRadio from "../../../ui/ImageRadio";
import ConvertUTCDateToLocalDate from "../../common/ConvertUTCDateToLocalDate";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().required(),
});

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  category: "owner",
  tags: [],
  rentals: [],
};

const ContactAdd = () => {
  const contactContext = useContext(AppContexts.ContactsContext);
  const { user } = useContext(AppContexts.AuthContext);

  const {
    contact,
    setContact,
    flagIsNew,
    listContacts,
    insertContact,
    updateContact,
    setIsEditing,
    flagIsProcessing,
  } = contactContext;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
    trigger,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const rentals = useMemo(() => getValues("rentals"), [watch("rentals")]);
  const tags = useMemo(() => getValues("tags"), [watch("tags")]);
  const category = useMemo(() => getValues("category"), [watch("category")]);

  useEffect(() => {
    setIsEditing(isDirty);
  }, [isDirty]);

  useEffect(() => {
    clearErrors();

    Object.keys(defaultValues).forEach((formname) => {
      setValue(
        formname,
        contact[formname] ? contact[formname] : defaultValues[formname],
      );
    });

    if (Object.keys(contact).length === 0) {
      clearForms();
    }
  }, [JSON.stringify(contact)]);

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  const handleRegister = useCallback(
    async (formdata) => {
      if (
        listContacts.find((contact) => contact.phone === formdata.phone) &&
        flagIsNew
      ) {
        UIkit.notification("This phone number already exists.", "error");
        return;
      }
      if (!flagIsNew) {
        const exceptSelf = listContacts.filter(
          (item) => item.id !== contact.id,
        );
        if (exceptSelf.find((contact) => contact.phone === formdata.phone)) {
          UIkit.notification("This phone number already exists.", "error");
          return;
        }
      }
      let real_formdata = { ...formdata, account_id: user.account_id };
      try {
        const result = await (flagIsNew
          ? insertContact(real_formdata)
          : updateContact({ ...real_formdata, id: contact.id }));
        if (result) {
          UIkit.modal("#modal-contact-add").hide();
          UIkit.notification(
            flagIsNew === true ? "Contact Added" : "Contact Updated",
            "success",
          );
          reset(defaultValues);
        } else {
          UIkit.notification("Failed", "error");
        }
      } catch (err) {
        UIkit.notification("Failed", "error");
      }
    },
    [flagIsNew, contact.id, JSON.stringify(tags), JSON.stringify(rentals)],
  );

  const handleError = useCallback((err) => console.log(err), []);

  return (
    <div
      id="modal-contact-add"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {flagIsNew === true ? "Add a" : "Edit"} Contact
            </h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <Form
                onSubmit={handleSubmit(handleRegister, handleError)}
                data-uk-grid="true"
              >
                <input
                  type="hidden"
                  {...register("account_id", {
                    value: user?.preferences?.host_id,
                  })}
                />
                <FormRow>
                  <ImageRadio
                    url="images/owner.svg"
                    activeUrl="images/owner-on.svg"
                    value={category === "owner"}
                    onChange={() => setValue("category", "owner")}
                    title={"owner"}
                  />
                  <ImageRadio
                    url="images/housekeeper.svg"
                    activeUrl="images/housekeeper-on.svg"
                    value={category === "housekeeper"}
                    onChange={() => setValue("category", "housekeeper")}
                    title={"housekeeper"}
                  />
                  <ImageRadio
                    url="images/maintenance.svg"
                    activeUrl="images/maintenance-on.svg"
                    value={category === "maintenance"}
                    onChange={() => setValue("category", "maintenance")}
                    title={"maintenance"}
                  />
                  <ImageRadio
                    url="images/vendor.svg"
                    activeUrl="images/vendor-on.svg"
                    value={category === "vendor"}
                    onChange={() => setValue("category", "vendor")}
                    title={"vendor"}
                  />
                  <ImageRadio
                    url="images/leads.svg"
                    activeUrl="images/leads-on.svg"
                    value={category === "lead" || category === "leads"}
                    onChange={() => setValue("category", "lead")}
                    title={"lead"}
                  />
                  <ImageRadio
                    url="images/other.svg"
                    activeUrl="images/other-on.svg"
                    value={category === "other"}
                    onChange={() => setValue("category", "other")}
                    title={"other"}
                  />
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="First Name*">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.first_name,
                        })}
                        type="text"
                        {...register("first_name")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                  <FormInputWrapper label="Last Name*">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.last_name,
                        })}
                        type="text"
                        {...register("last_name")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="Mobile Phone*">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.phone,
                        })}
                        type="text"
                        {...register("phone")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                  <FormInputWrapper label="Email">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.email,
                        })}
                        type="email"
                        {...register("email")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow className="uk-width-1-1">
                  <div className="hms-add-tag">
                    <FormInputWrapper label="Properties" size="two">
                      <Properties
                        value={rentals}
                        onChange={(editedTags) =>
                          setValue("rentals", editedTags)
                        }
                      />
                    </FormInputWrapper>
                  </div>
                </FormRow>
                <FormRow className="uk-width-1-1">
                  <div className="hms-add-tag">
                    <FormInputWrapper label="Tags" size="two">
                      <Tags
                        value={tags}
                        onChange={(editedTags) => setValue("tags", editedTags)}
                      />
                    </FormInputWrapper>
                  </div>
                </FormRow>
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    {flagIsProcessing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={() => setContact({})}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
              {flagIsNew === false ? (
                <div className="s-msg-last-updated">
                  {"Last Updated: " +
                    moment(contact.updated_at).format("MM/DD/YYYY hh:mm a")}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAdd;
