import React, { useState, useEffect, useContext, useMemo } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GoogleAutoComplete from "../../common/GoogleAutoComplete";
import { AppContexts } from "../../../providers";
import clsx from "clsx";
import MasterConfirm from "../../common/Confirm";
import UIkit from "uikit";

const schema = yup.object().shape({
  body: yup.string().required(),
});

const AddEvent = () => {
  const { uploadFile, place, genUUID } = useContext(AppContexts.UtilContext);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const { destinationId, tabId, isNew, section } = DestinationContext;
  const [placeId, setPlaceId] = useState(null);
  const [attachImage, setAttachImage] = useState({});
  const [attachPDF, setAttachPDF] = useState({});
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleRegistration = async (data) => {
    let attachments_buf = [];
    let image_url = "";
    let pdf_url = "";

    let newRecommendation = {
      google_place_id: null,
      address: null,
      lat: null,
      lng: null,
      display_as: "event",
      destination_id: destinationId,
      tab_id: tabId.type,
      name: data.title,
      tip: data.body,
      external_link: data.event_url,
      start_date: data.start_date,
      end_date: data.end_date,
      start_time: data.start_time,
      end_time: data.end_time,
      attachments: [],
    };
    if (placeId) {
      let destination_place = await place(placeId);
      newRecommendation = {
        ...newRecommendation,
        google_place_id: destination_place.place_id,
        address: destination_place.formatted_address,
        lat: destination_place.geometry.location.lat,
        lng: destination_place.geometry.location.lng,
      };
    }
    if (data.image_file.length > 0) {
      image_url = await uploadFile(
        data.image_file[0],
        `recommendation/attachments/${genUUID()}`,
      );
    }
    if (data.pdf_file.length > 0) {
      pdf_url = await uploadFile(
        data.pdf_file[0],
        `recommendation/attachments/${genUUID()}`,
      );
    }
    if (!!attachImage?.name) {
      if (image_url) {
        attachments_buf.push({ ...attachImage, url: image_url });
      } else {
        attachments_buf.push(attachImage);
      }
    }

    if (!!attachPDF?.name) {
      if (pdf_url) {
        attachments_buf.push({
          ...attachPDF,
          name: data.attachment_label,
          url: pdf_url,
        });
      } else {
        attachments_buf.push(attachPDF);
      }
    }

    if (data.video !== "") {
      attachments_buf.push({
        name: "",
        original_name: "",
        parent_type: "recommendation",
        type: "you_tube",
        url: data.video,
      });
    }
    newRecommendation = { ...newRecommendation, attachments: attachments_buf };
    let updatedRec = {
      ...section,
      ...newRecommendation,
      destination_id: destinationId,
    };
    if (updatedRec.google) delete updatedRec.google;
    const result =
      isNew === true
        ? await DestinationContext.createRecommendation(updatedRec)
        : await DestinationContext.updateRecommendation(updatedRec);

    if (result == null) {
      UIkit.notification(
        isNew === true ? "Event Block Add Failed" : "Event Block Update Failed",
        "danger",
      );
    } else {
      UIkit.modal("#destination-event-modal").hide();
      UIkit.notification(
        isNew === true
          ? "Event Block Added Successfully"
          : "Event Block Updated Successfully",
        "success",
      );
    }
    setAutoCompleteValue("");
    DestinationContext.getSelectedDestination(destinationId);
  };

  useEffect(() => {
    setAttachImage({});
    setAttachPDF({});
    setValue("title", section?.name);
    setValue("body", section?.tip);
    setValue("start_date", section?.start_date);
    setValue("start_time", section?.start_time);
    setValue("end_date", section?.end_date);
    setValue("end_time", section?.end_time);
    setValue("event_url", section?.external_link);
    setAutoCompleteValue(section?.address);
    setValue("video", null);
    setValue("attachment_label", "");
    let attachments = section?.attachments;
    if (attachments && attachments.length > 0) {
      if (attachments.findIndex((attah) => attah.type === "image") >= 0) {
        setAttachImage(
          attachments[attachments.findIndex((attah) => attah.type === "image")],
        );
      }
      if (attachments.findIndex((attah) => attah.type === "file") >= 0) {
        setAttachPDF(
          attachments[attachments.findIndex((attah) => attah.type === "file")],
        );
        setValue(
          "attachment_label",
          attachments[attachments.findIndex((attach) => attach.type === "file")]
            .name,
        );
      }
      if (attachments.findIndex((attah) => attah.type === "you_tube") >= 0) {
        setValue(
          "video",
          attachments[
            attachments.findIndex((attah) => attah.type === "you_tube")
          ].url,
        );
      }
    }
  }, [section]);

  const image_file = useMemo(() => getValues("image_file"), [
    watch("image_file"),
  ]);

  const deleteImage = () => {
    setValue("image_file", null);
    setAttachImage({});
  };

  const deletePDF = () => {
    setValue("pdf_file", null);
    setAttachPDF({});
  };

  const handleClose = () => {
    setAutoCompleteValue("");
  };

  const handleErrors = (error) => {
    console.log(error);
  };

  return (
    <div
      id="destination-event-modal"
      className="uk-modal-full hms-modals uk-modal uk-open"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          />
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {isNew === true ? "Add a " : "Edit "}
              Event
            </h2>
          </div>
          <div className="uk-modal-body">
            <form
              data-uk-grid
              onSubmit={handleSubmit(handleRegistration, handleErrors)}
              onReset={reset}
            >
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-title">
                  TITLE
                </label>
                <div className="uk-form-controls">
                  <input
                    id="event-title"
                    type="text"
                    className="uk-input"
                    {...register("title")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-body">
                  Description
                </label>
                <div className="uk-form-controls">
                  <textarea
                    id="event-body"
                    className={clsx("uk-textarea", {
                      error: errors.body,
                    })}
                    rows="5"
                    {...register("body")}
                  ></textarea>
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <div className="uk-flex uk-flex-middle">
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="event-start-date">
                      Start Date
                    </label>
                    <div className="uk-form-controls">
                      <div className="uk-inline hms-date-picker">
                        <input
                          className="uk-input"
                          id="event-start-date"
                          type="date"
                          {...register("start_date")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="event-start-time">
                      Start Time
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="event-start-time"
                        type="time"
                        {...register("start_time")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <div className="uk-flex uk-flex-middle">
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="event-end-date">
                      End Date
                    </label>
                    <div className="uk-form-controls">
                      <div className="uk-inline hms-date-picker">
                        <input
                          className="uk-input"
                          id="event-end-date"
                          type="date"
                          {...register("end_date")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="event-end-time">
                      End Time
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="event-end-time"
                        type="time"
                        {...register("end_time")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-url">
                  Event Link
                </label>
                <div className="uk-form-controls">
                  <input
                    id="event-url"
                    type="text"
                    className="uk-input"
                    {...register("event_url")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-address">
                  Event Address
                </label>
                <div className="uk-form-controls">
                  <GoogleAutoComplete
                    id="event-address"
                    width="100%"
                    onSelect={(_, value) => setPlaceId(value.text)}
                    onChange={(e) => setAutoCompleteValue(e)}
                    address={autoCompleteValue}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <label className="uk-form-label" htmlFor="event-photo">
                  Photo
                </label>
                <div className="js-upload uk-placeholder hms-file-uploader uk-text-center">
                  <div data-uk-form-custom>
                    {!!attachImage?.name ? (
                      <>
                        <input
                          key="thumnail_key"
                          type="file"
                          accept="image/*"
                          {...register("image_file")}
                          onChange={(evt) =>
                            setAttachImage({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "image",
                              url: "",
                            })
                          }
                        />
                        {attachImage?.name}
                      </>
                    ) : (
                      <>
                        <div>
                          <Image
                            src="/images/upload-image.svg"
                            className="hms-navbar"
                          />
                        </div>
                        <input
                          key="thumnail_key"
                          type="file"
                          accept="image/*"
                          {...register("image_file")}
                          onChange={(evt) =>
                            setAttachImage({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "image",
                              url: "",
                            })
                          }
                        />
                        <span></span>
                      </>
                    )}
                  </div>
                  <div>
                    {!!attachImage?.name && (
                      <MasterConfirm onDelete={deleteImage} />
                    )}
                  </div>
                </div>
                <progress
                  id="js-progressbar"
                  className="uk-progress"
                  value="0"
                  max="100"
                  hidden
                ></progress>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-video">
                  Video
                </label>
                <div className="uk-form-controls">
                  <input
                    id="event-video"
                    type="text"
                    className="uk-input"
                    placeholder="YouTube or Vimeo"
                    {...register("video")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1@s">
                <label className="uk-form-label" htmlFor="event-attach-label">
                  PDF Label
                </label>
                <div className="uk-form-controls">
                  <input
                    id="event-attach-label"
                    type="text"
                    className="uk-input"
                    {...register("attachment_label")}
                  />
                </div>
              </div>
              <div className="uk-width-1-3@s uk-first-column">
                <label className="uk-form-label" htmlFor="event-attachment">
                  PDF File
                </label>
                <div className="js-upload uk-placeholder hms-file-uploader uk-text-center">
                  <div className="uk-form-custom">
                    {!!attachPDF.name ? (
                      <>
                        <input
                          key="pdf_key"
                          type="file"
                          {...register("pdf_file")}
                          onChange={(evt) =>
                            setAttachPDF({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "file",
                              url: "",
                            })
                          }
                        />
                        <span className="grey-text">
                          {attachPDF.original_name}
                        </span>
                      </>
                    ) : (
                      <>
                        <div>
                          <Image
                            src="/images/upload-pdf.svg"
                            className="hms-navbar"
                          />
                        </div>
                        <input
                          key="pdf_key"
                          type="file"
                          {...register("pdf_file")}
                          onChange={(evt) =>
                            setAttachPDF({
                              name: evt.target.files[0]?.name,
                              original_name: evt.target.files[0]?.name,
                              type: "file",
                              url: "",
                            })
                          }
                        />
                        <span></span>
                      </>
                    )}
                  </div>
                </div>
                {!!attachPDF?.name && <MasterConfirm onDelete={deletePDF} />}
                <progress
                  id="js-progressbar"
                  className="uk-progress"
                  value="0"
                  max="100"
                  hidden
                ></progress>
              </div>
              <div className="uk-width-1-1 uk-margin-top">
                <button className="uk-button hms-btn hms-red-btn" type="submit">
                  Save
                </button>
                <button
                  className="uk-button hms-btn uk-offcanvas-close"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
