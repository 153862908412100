import { httpV2 } from "../http.js";

const get = () => {
  return httpV2.get("/rentals");
};

const RentalsService = {
  get,
  getRental: (id) => httpV2.get(`/rentals/${id}`),
  search: (criteria) => httpV2.post(`/rentals/search`, criteria),
  create: (objRental) => httpV2.post("/rentals", objRental),
  update: (id, objRental) =>
    httpV2.put(`/rentals/${id}`, { ...objRental, welcome_guide: [] }),
  delete: (id) => httpV2.delete(`/rentals/${id}`),

  create_section: (section) => httpV2.post("/rentals/section", section),
  update_section: (section) =>
    httpV2.put(
      `/rentals/section/${section.welcome_guide_question_id}`,
      section,
    ),
  delete_section: (id) => httpV2.delete(`/rentals/section/${id}`),
  // insertWelcomeGuide: (objGuide) => httpV2.post("/rentals/section", objGuide),
  // updateWelcomeGuide: (id, objGuide) =>
  //   httpV2.put(`/rentals/section/${id}`, objGuide),
  // createHomeGuide: (objGuide) =>
  //   httpV2.post("/rentals/section/property", objGuide),
  // deleteWelcomeGuide: (id) => httpV2.delete(`/rentals/section/${id}`),
};

export default RentalsService;
