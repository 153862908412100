import React from "react";
import { Link } from "../ui-kit/Link";

const TrialExpiredPage = () => {
  return (
    <div className="page-box sign-in">
      <h2 className="uk-heading-small">Trial Expired</h2>
      <div className="uk-margin">
        <div className="uk-form-stacked">
          <div className="uk-flex uk-flex-middle uk-flex-center">
            <img
              className="trial-expired"
              alt="info.icon"
              src="/images/red-logo.png"
            />
          </div>
          <div className="uk-margin">Your trial period has expired.</div>
          <div className="uk-margin">
            Please consider upgrading to a premium plan to continue using our
            services.
          </div>
          <div className="uk-margin">
            <Link to="/auth/login">Go to Login</Link>
          </div>
          <div className="uk-margin">
            If you have any questions or need assistance, contact{" "}
            <a href="mailto:sales@ruebarue.com">sales@ruebarue.com.</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialExpiredPage;
