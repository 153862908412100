// Import package components
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import UIkit from "uikit";
import { Link } from "../../../ui";

const recordsCountOptions = [20, 30, 40, 50];

const RecordsPerPage = () => {
  const [cookies, setCookie] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  useEffect(() => {
    handleRecordChange(20);
  }, []);

  useEffect(() => {
    if (
      recordsCountOptions.some((option) => option == recordsPerPage) === false
    ) {
      handleRecordChange(recordsCountOptions[0]);
    }
  }, [cookies, recordsPerPage]);

  const handleRecordChange = useCallback(
    (newRecordsCount) => {
      setCookie("records-per-page", newRecordsCount, { path: "/" });
      UIkit.dropdown("#uk-dropdown-nav").hide(0);
    },
    [setCookie],
  );

  const renderRecordsCountOption = useCallback(
    (option, index) => (
      <li
        className={classnames({ "uk-active": recordsPerPage == option })}
        key={`records_per_page_${index}`}
      >
        <Link href="#" onClick={() => handleRecordChange(option)}>
          {option} records per page
        </Link>
      </li>
    ),
    [recordsPerPage],
  );

  return (
    <>
      <div className="hms-records-per-page">
        <button className="uk-button" type="button">
          {recordsPerPage} records per page{" "}
          <span uk-icon="icon: chevron-down"></span>
        </button>
        <div id="uk-dropdown-nav" uk-dropdown="mode: click; offset: 10">
          <ul className="uk-nav uk-dropdown-nav">
            {recordsCountOptions.map(renderRecordsCountOption)}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RecordsPerPage;
