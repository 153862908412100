// Import package components
import { useContext, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";

import { AppContexts } from "../../../providers";
import AddTextLine from "../../common/AddTextLine";

const time1Options = [
  { label: "Pick One", value: "" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
];
const time2Options = [
  { label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
  { label: "(GMT-07:00) Arizona", value: "US/Arizona" },
  {
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    value: "America/Chihuahua",
  },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
  { label: "(GMT-06:00) Central America", value: "America/Managua" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
  {
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    value: "America/Mexico_City",
  },
  { label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
  {
    label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    value: "America/Bogota",
  },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
  { label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
  { label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
  { label: "(GMT-04:00) Manaus", value: "America/Manaus" },
  { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
  { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  {
    label: "(GMT-03:00) Buenos Aires, Georgetown",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  {
    label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    value: "Africa/Casablanca",
  },
  {
    label:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    value: "Etc/Greenwich",
  },
  {
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "Europe/Amsterdam",
  },
  {
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "Europe/Belgrade",
  },
  {
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "Europe/Brussels",
  },
  {
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "Europe/Sarajevo",
  },
  { label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
  { label: "(GMT+02:00) Amman", value: "Asia/Amman" },
  { label: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "Europe/Athens" },
  { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
  { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
  {
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: "Europe/Helsinki",
  },
  { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
  { label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
  { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
  {
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    value: "Europe/Moscow",
  },
  { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
  { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
  { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "Asia/Karachi" },
  {
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "Asia/Calcutta",
  },
  { label: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
  { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
  { label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
  { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
  { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  {
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    value: "Asia/Hong_Kong",
  },
  { label: "(GMT+08:00) Kuala Lumpur, Singapore", value: "Asia/Kuala_Lumpur" },
  { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
  { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
  { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
  { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
  { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  {
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "Australia/Canberra",
  },
  { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
  { label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
  { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  {
    label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    value: "Asia/Magadan",
  },
  { label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
  { label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
  { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];

const archiveText = {
  0: "Do not archive",
  1: "Day of Check-out",
  2: "1 Day after Check-out",
};

const MessagesTag = () => {
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const { user } = useContext(AppContexts.AuthContext);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm();
  const [dirty, setDirty] = useState(false);
  const [flags, setFlags] = useState([]);
  const [inbox, setInbox] = useState({
    forwarding_number: "",
    default_sms_response: "",
    unknown_response: "",
    timezone: "",
    auto_archive: 1,
    msg_window_start: "6:00",
    msg_window_end: "18:00",
    flags: [],
  });

  useEffect(() => {
    if (!!settings.inbox) {
      setInbox(settings.inbox);
      setFlags(settings.inbox.flags || []);
    }
  }, [settings]);

  // let defaultValues = {
  //   forwarding_number: "",
  //   default_sms_response: "",
  //   unknown_response: "",
  //   timezone: "",
  //   auto_archive: 1,
  //   msg_window_start: "6:00",
  //   msg_window_end: "18:00",
  //   flags: []
  // };

  // const inbox = useMemo(() => {
  //   if (!settings) {
  //     return {};
  //   } else {
  //     let result = {};
  //     if (settings.inbox) {
  //       Object.keys(settings.inbox).forEach((key) => {
  //         defaultValues[key] = settings.inbox[key];
  //       });
  //       result = defaultValues;
  //       return result;
  //     } else {
  //       return result;
  //     }
  //   }
  // }, [settings]);

  // useEffect(() => {
  //   if (!settings) {
  //     return;
  //   } else {
  //     if (!!settings.inbox) {
  //       setFlags(inbox.flags || []);
  //     }
  //   }
  // }, [settings]);

  const timeOptions = useMemo(
    () =>
      [...new Array(96)].map((_, idx) => {
        const minQuarter = idx % 4;
        const min = `0${minQuarter * 15}`.slice(-2);
        const hour = (idx - minQuarter) / 4;
        const meridiem = hour < 12 ? "AM" : "PM";
        const hour12 = hour % 12;
        return (
          <option key={idx} value={`${hour}:${min}`}>{`${
            hour12 === 0 ? 12 : hour12
          }:${min} ${meridiem}`}</option>
        );
      }),
    [],
  );

  // useEffect(() => {
  //   if (inbox && Object.keys(inbox).length > 0) {
  //     Object.keys(inbox).forEach((key) => setValue(key, inbox[key]));
  //   }
  // }, [inbox]);

  const msg_window_start = useMemo(() => {
    const hour = inbox?.msg_window_start.split(":")[0];
    const min = inbox?.msg_window_start.split(":")[1];
    const meridiem = hour < 12 ? "AM" : "PM";
    let converted_hour = hour % 12 === 0 ? 12 : hour % 12;
    const result = converted_hour + ":" + min + meridiem;
    return result;
  }, [inbox]);

  const msg_window_end = useMemo(() => {
    let hour = inbox?.msg_window_end.split(":")[0];
    const min = inbox?.msg_window_end.split(":")[1];
    const meridiem = hour < 12 ? "AM" : "PM";
    let converted_hour = hour % 12 === 0 ? 12 : hour % 12;
    const result = converted_hour + ":" + min + meridiem;
    return result;
  }, [inbox]);

  const handleRegister = (data) => {
    let updateData = { ...inbox, flags: flags };

    set("inbox", updateData).then((res) => {
      setDirty(false);
    });
  };

  const hasFlag = (flag) => {
    return !!flags.find((f) => f.name === flag);
  };

  const toggleFlag = (flag) => {
    if (hasFlag(flag)) {
      setFlags(flags.filter((f) => f.name !== flag));
    } else {
      setFlags([...flags, { name: flag }]);
    }

    setDirty(true);
  };

  const updateInbox = (field, e) => {
    setInbox({ ...inbox, [field]: e.target.value });
    setDirty(true);
  };

  return (
    <li
      className={`setting-tab-content ${
        active === "messages" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <AddTextLine feature="Texting Platform" />
        <h2>Texting Platform</h2>
        <form
          className="uk-grid-small"
          data-uk-grid
          onSubmit={handleSubmit(handleRegister)}
        >
          <div className="uk-width-1-2@s"></div>
          <div className="uk-width-1-1">
            <div className="uk-form-label">
              Out of Office Response{" "}
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="This auto response will be sent by text to after-hours text messages based on the team hours."
              />
            </div>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                rows="5"
                // {...register("default_sms_response")}
                value={inbox.default_sms_response}
                onInput={(e) => {
                  updateInbox("default_sms_response", e);
                }}
              ></textarea>
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <div className="uk-form-label">
              Call Forwarding Number{" "}
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="Calls to the assigned text number will be forwarded to this number."
              />
            </div>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                value={inbox.forwarding_number}
                onInput={(e) => {
                  updateInbox("forwarding_number", e);
                }}
              />
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-small-bottom uk-margin-large-right">
            <div className="uk-width-1-1 uk-margin-bottom">
              <div className="uk-form-label">
                Timezone{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Timezone used for text and email automation."
                />
              </div>
              <div className="uk-form-controls w-100">
                <div uk-form-custom="target: > * > span:first-child">
                  <select
                    // {...register("timezone")}
                    value={inbox.timezone}
                    onInput={(e) => {
                      updateInbox("timezone", e);
                    }}
                  >
                    <optgroup>
                      {time1Options.map((opt, index) => (
                        <option value={opt.value}>{opt.label}</option>
                      ))}
                    </optgroup>
                    <optgroup>
                      {time2Options.map((opt, index) => (
                        <option value={opt.value}>{opt.label}</option>
                      ))}
                    </optgroup>
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {
                        [...time1Options, ...time2Options].find(
                          (option) => option.value === inbox.timezone,
                        ).label
                      }
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 uk-margin-bottom">
              <div className="uk-form-label">
                Auto-Archive{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Automatically archive messages in the inbox."
                />
              </div>
              <div className="uk-form-controls w-100">
                <div uk-form-custom="target: > * > span:first-child">
                  <select
                    // {...register("auto_archive")}
                    value={inbox.auto_archive}
                    onInput={(e) => {
                      updateInbox("auto_archive", e);
                    }}
                  >
                    {[...new Array(11)].map((_, idx) => (
                      <option key={idx} value={idx - 1}>
                        {archiveText[idx] || `${idx - 1} Days after Check-out`}
                      </option>
                    ))}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {archiveText[inbox.auto_archive] ||
                        `${inbox.auto_archive} Days after Check-out`}
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 uk-margin-bottom">
              <div className="uk-form-label">
                Messaging Hours for Last-Minute Booking Automated Text{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Hours when last-minute booking automated text messages will be sent."
                />
              </div>
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-2">
                  <div className="uk-form-controls w-100">
                    <div uk-form-custom="target: > * > span:first-child">
                      <select
                        // {...register("msg_window_start")}
                        value={inbox.msg_window_start}
                        onInput={(e) => {
                          updateInbox("msg_window_start", e);
                        }}
                      >
                        <option value="">Select a Time</option>
                        {timeOptions}
                      </select>
                      <button
                        className="uk-button uk-button-default"
                        type="button"
                        tabIndex="-1"
                      >
                        <span>{msg_window_start}</span>
                        <span
                          uk-icon="icon: chevron-down"
                          className="hms-align-right"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="uk-form-controls w-100">
                    <div uk-form-custom="target: > * > span:first-child">
                      <select
                        // {...register("msg_window_end")}
                        value={inbox.msg_window_end}
                        onInput={(e) => {
                          updateInbox("msg_window_end", e);
                        }}
                      >
                        <option value="">Select a Time</option>
                        {timeOptions}
                      </select>
                      <button
                        className="uk-button uk-button-default"
                        type="button"
                        tabIndex="-1"
                      >
                        <span>{msg_window_end}</span>
                        <span
                          uk-icon="icon: chevron-down"
                          className="hms-align-right"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div className="uk-form-label">
                Unknown Number Auto Response{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Automatic response to text messages from unrecognized phone numbers."
                />
              </div>
              <div className="uk-form-controls">
                <textarea
                  className="uk-textarea"
                  rows="2"
                  // {...register("unknown_response")}
                  value={inbox.unknown_response}
                  onInput={(e) => {
                    updateInbox("unknown_response", e);
                  }}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-middle">
              <div className="autosend-checkbox">
                <label for="usePropSMSCode" className="setting-switch">
                  <input
                    type="checkbox"
                    id="usePropSMSCode"
                    checked={hasFlag("use_property_sms_code")}
                    onInput={() => {
                      toggleFlag("use_property_sms_code");
                    }}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <div className="uk-form-label uk-margin-small-left">
                Use Property SMS Code
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-small-top">
            <div className="uk-flex uk-flex-middle">
              <div className="autosend-checkbox">
                <label for="useGuestSMSCode" className="setting-switch">
                  <input
                    type="checkbox"
                    id="useGuestSMSCode"
                    checked={hasFlag("use_guest_sms_code")}
                    onInput={() => {
                      toggleFlag("use_guest_sms_code");
                    }}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <div className="uk-form-label uk-margin-small-left">
                Use Guest SMS Code
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-top">
            <button
              className="uk-button hms-btn hms-red-btn"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : dirty ? (
                "Save"
              ) : (
                "Saved"
              )}
            </button>
          </div>
        </form>
      </div>
    </li>
  );
};

export default MessagesTag;
