// Import package components
import { useContext, useMemo, useEffect, useState } from "react";
import { Image, InputContainer } from "uikit-react";
import { Form, FormInputWrapper } from "../../../ui";
import { useForm } from "react-hook-form";
import { AppContexts } from "../../../providers";
import ImageInput from "../../common/ImageInput";
import Preview from "./preview";

const guestbook_settings = {
  account_id: 0,
  tab_order: "arrival,about,departure,rules,safety",
  guestlink_expiration: 0,
  guestlink_available: 0,
  guestbook_headline: "",
  attachments: [],
};

const max_welcome_msg_length = 144;

const key_maps = {
  none: "Disabled",
  optional: "Enabled",
};

const GuestBookTags = () => {
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  let { user } = useContext(AppContexts.AuthContext);
  let { uploadFile } = useContext(AppContexts.UtilContext);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm();
  const [dirty, setDirty] = useState(false);
  const [upload, setUpload] = useState(null);
  const [capture, setCapture] = useState(false);

  const guestbook = useMemo(() => {
    if (!settings) {
      return {};
    } else {
      if (settings.guestbook) {
        return settings.guestbook;
      } else {
        return guestbook_settings;
      }
    }
  }, [settings]);

  let profileImage = useMemo(() => {
    return (
      (guestbook?.attachments || []).find(
        (a) => a.type === "guestbook_image",
      ) || null
    );
  }, [guestbook]);

  const handleGuestBookImage = (file) => {
    if (!!file) {
      setUpload(file);
    } else {
      setUpload(null);
    }

    setDirty(true);
  };

  useEffect(() => {
    document.title = "Settings | Guestbook - RueBaRue";
    if (guestbook) {
      setValue("guestlink_available", guestbook.guestlink_available);
      setValue("guestlink_expiration", guestbook.guestlink_expiration);
      setValue("guestbook_headline", guestbook.guestbook_headline);
      setValue("email_capture", guestbook.email_capture);
      setValue("email_capture_title", guestbook.email_capture_title);
      setValue("email_capture_intro", guestbook.email_capture_intro);
      setValue("tab_order", guestbook.tab_order);
    }
  }, [guestbook]);

  useEffect(() => {
    if (getValues("email_capture") === "none") {
      setCapture(false);
    } else if (
      getValues("email_capture") === "optional" ||
      getValues("email_capture") === "required"
    ) {
      setCapture(true);
    }
  }, [watch("email_capture")]);

  const guestlink_available = useMemo(() => getValues("guestlink_available"), [
    watch("guestlink_available"),
  ]);
  const guestlink_expiration = useMemo(
    () => getValues("guestlink_expiration"),
    [watch("guestlink_expiration")],
  );

  const handleUpdate = async (data) => {
    let attachment_buf = [];
    if (upload !== null) {
      let uploadUrl = await uploadFile(
        upload,
        `settings/guestbook/${user.user.account_id}`,
      );
      attachment_buf.push({
        type: "guestbook_image",
        name: "guestbook_default",
        original_name: "",
        url: uploadUrl,
      });
    }
    data.attachments = attachment_buf;
    let updateData = guestbook_settings;
    updateData = {
      ...updateData,
      ...{
        guestlink_available: parseInt(data.guestlink_available),
        guestlink_expiration: parseInt(data.guestlink_expiration),
        guestbook_headline: data.guestbook_headline,
        email_capture: data.email_capture,
        email_capture_title: data.email_capture_title,
        email_capture_intro: data.email_capture_intro,
        attachments: data.attachments,
        tab_order: data.tab_order || guestbook_settings.tab_order,
      },
    };
    set("guestbook", updateData).then((res) => {
      console.log(res);
    });

    reset({}, { keepValues: true });
  };

  const availableNames = {
    0: "Always Available",
  };

  const expirationNames = {
    0: "No Expiration",
  };

  return (
    <li
      className={`setting-tab-content ${
        active === "guestbook" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h2>Digital Guestbooks</h2>
        <Form
          className="uk-grid-small"
          data-uk-grid
          onSubmit={handleSubmit(handleUpdate)}
        >
          <div className="uk-width-1-1 uk-margin-bottom hms-headings">
            <div className="uk-form-label">Default Guestbook Profile Photo</div>
            <ImageInput
              label=""
              attachment={profileImage}
              handler={handleGuestBookImage}
            />
            <progress
              id="js-progressbar"
              className="uk-progress"
              value="0"
              max="100"
              hidden
            ></progress>
            <p className="hms-small-note">
              Min width: 800px. Aspect ratio: 16:9. Formats: .jpg, .jpeg, .png.
              Max file size: 5MB
            </p>
          </div>
          <div className="uk-width-1-1">
            <FormInputWrapper label="Guestbook Headline">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  onInput={() => {
                    setDirty(true);
                  }}
                  {...register("guestbook_headline")}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-1-1">
            <FormInputWrapper label="Guestbook Link Available From">
              <InputContainer>
                <div uk-form-custom="target: > * > span:first-child">
                  <select
                    {...register("guestlink_available")}
                    onInput={() => {
                      setDirty(true);
                    }}
                  >
                    {[...Array(30)].map((_, i) => {
                      return (
                        <option value={i} key={i}>
                          {availableNames[i] || `${i} Days Before Check-In`}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {availableNames[guestlink_available] ||
                        `${guestlink_available} Days Before Check-In`}
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-1-1">
            <FormInputWrapper label="Guestbook Link Expires At">
              <InputContainer>
                <div uk-form-custom="target: > * > span:first-child">
                  <select
                    {...register("guestlink_expiration")}
                    onInput={() => {
                      setDirty(true);
                    }}
                  >
                    {[...Array(11)].map((_, i) => {
                      return (
                        <option value={i} key={i}>
                          {expirationNames[i] || `${i} Days After Check-Out`}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {expirationNames[guestlink_expiration] ||
                        `${guestlink_expiration} Days After Check-Out`}
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </InputContainer>
            </FormInputWrapper>
          </div>

          <div className="uk-width-1-1">
            <hr />
          </div>

          <div className="uk-width-1-2">
            <div className="uk-form-label">
              Subscription Popup
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="Collect email addresses from anyone viewing the guestbooks"
              />
            </div>
            <div className="uk-form-controls">
              <div uk-form-custom="target: > * > span:first-child">
                <select
                  {...register("email_capture")}
                  onInput={() => {
                    setDirty(true);
                  }}
                >
                  <option value="none">Disabled</option>
                  <option value="optional">Enabled</option>
                </select>
                <button
                  className="uk-button uk-button-default"
                  type="button"
                  tabIndex="-1"
                >
                  <span>{key_maps[getValues("email_capture")]}</span>
                  <span
                    uk-icon="icon: chevron-down"
                    className="hms-align-right"
                  ></span>
                </button>
              </div>
            </div>
          </div>

          {capture && (
            <>
              <div className="uk-width-1-1">
                <FormInputWrapper label="Subscription Popup Title">
                  <InputContainer>
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Subscribe to our Newsletter"
                      onInput={() => {
                        setDirty(true);
                      }}
                      {...register("email_capture_title")}
                    />
                  </InputContainer>
                </FormInputWrapper>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-form-label">
                  Subscription Popup Intro Text
                </div>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    placeholder="Sign up to receive exclusive offers for your next stay."
                    rows="3"
                    onInput={() => {
                      setDirty(true);
                    }}
                    {...register("email_capture_intro")}
                  ></textarea>
                </div>
                <p className="secondary">
                  Characters Remaining: {max_welcome_msg_length - 0}
                </p>
              </div>
              <div className="uk-width-1-1">
                <button
                  type="button"
                  className="uk-button hms-btn hms-white-btn"
                  uk-toggle="target: #newsletter"
                >
                  Preview Popup
                </button>
              </div>
            </>
          )}

          <div className="uk-width-1-1 uk-margin-top">
            <div className="uk-flex uk-flex-middle">
              <button
                className="uk-button hms-btn hms-red-btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div data-uk-spinner="ratio: 0.5;"></div>
                ) : dirty ? (
                  "Save"
                ) : (
                  "Saved"
                )}
              </button>
            </div>
          </div>
        </Form>

        <Preview
          title={getValues("email_capture_title")}
          intro={getValues("email_capture_intro")}
        />
      </div>
    </li>
  );
};

export default GuestBookTags;
