import { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import classnames from "classnames";

import { AppContexts } from "../../providers";

import DestinationAdd from "./add";
import DestinationShare from "./share";
import TableLoading from "../common/Loading/table_loading";
import TableRow from "./common/row";
import DestinationGuideDelete from "./delete";
import Pagination from "../common/Pagination";
import { Link } from "../../ui";
import RecordsPerPage from "../common/RecordsPerPage";

const DestinationGuide = () => {
  const { listDestinations, get, loading } = useContext(
    AppContexts.DestinationContext,
  );
  const settingsContext = useContext(AppContexts.SettingsContext);
  const [cookies] = useCookies(["records-per-page"]);
  const history = useHistory();
  const { "records-per-page": recordsPerPage } = cookies;

  const [sorter, setSorter] = useState("updated_at");
  const [page, setPage] = useState(0);

  useEffect(() => {
    document.title = "Area Guides - RueBaRue";
    get();
    settingsContext.get(["tags"]);
  }, []);

  const destinations = useMemo(() => {
    if (!listDestinations) {
      return [];
    } else {
      return listDestinations
        .sort((prev, next) => {
          if (sorter === "updated_at") {
            return next[sorter].localeCompare(prev[sorter]);
          }
          return prev[sorter].localeCompare(next[sorter]);
        })
        .map((item) => {
          if (!item.tags) {
            return item;
          }
          let tags = item.tags.sort((prev, next) =>
            prev.name.localeCompare(next.name),
          );
          return { ...item, tags };
        });
    }
  }, [listDestinations, sorter]);

  const pageDestinations = useMemo(
    () =>
      destinations.slice(page * recordsPerPage, (page + 1) * recordsPerPage),
    [destinations, page, recordsPerPage],
  );

  const handlePageChange = useCallback((newPageIndex) => {
    setPage(newPageIndex);
  }, []);

  const allTags = useMemo(() => {
    if (!settingsContext.settings) {
      return [];
    } else {
      if (!settingsContext.settings.tags) {
        return [];
      } else {
        return settingsContext.settings.tags.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
    }
  }, [settingsContext.settings]);

  return (
    <>
      <section id="property-guide-template">
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Area Guides</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #addDestinationGuide-modal"
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> AREA GUIDE
              </button>
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={() => history.push("/settings/destination-guide")}
              >
                MANAGE SECTIONS
              </button>
            </div>
            <div>
              <div>
                <div className="hms-sortby-btn">
                  <button className="uk-button" type="button">
                    SORT BY<span uk-icon="icon: triangle-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li
                        className={classnames({
                          "uk-active": sorter === "updated_at",
                        })}
                      >
                        <Link href="#" onClick={() => setSorter("updated_at")}>
                          Last Updated
                        </Link>
                      </li>
                      <li
                        className={classnames({
                          "uk-active": sorter === "name",
                        })}
                      >
                        <Link href="#" onClick={() => setSorter("name")}>
                          Name
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-expand">Guide</th>
                    <th className="tags">Tags</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    TableLoading(4)
                  ) : pageDestinations.length > 0 ? (
                    pageDestinations.map((destination, index) => (
                      <TableRow
                        destination={destination}
                        allTags={allTags}
                        key={index}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} style={{ padding: "30px 25px" }}>
                        No area guides.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={destinations.length}
              index={0}
              onIndexChanged={handlePageChange}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <DestinationAdd />
      <DestinationShare />
      <DestinationGuideDelete />
    </>
  );
};

export default DestinationGuide;
