// Import package components
import { useMemo, useEffect, useContext, useRef } from "react";

// Import base components
import { Button } from "../../../ui";
import { AppContexts } from "../../../providers";

const Tags = ({ value, onChange, readOnly = false }) => {
  const settingsContext = useContext(AppContexts.SettingsContext);

  const dropdownRef = useRef(null);

  const tagValues = useMemo(() => {
    if (!settingsContext.settings) {
      return [];
    } else {
      if (!settingsContext.settings.tags) {
        return [];
      } else {
        return settingsContext.settings.tags.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
    }
  }, [settingsContext.settings]);

  const handleInputChange = (tagId, selected) => {
    if (selected) {
      onChange([...value, tagValues.find((tagValue) => tagValue.id === tagId)]);
    } else {
      onChange(value.filter((tag) => tag.id !== tagId));
    }
  };

  const openTags = (e) => {
    e.stopPropagation();

    if (!dropdownRef.current.classList.contains("uk-open")) {
      let handler = () => {
        window.removeEventListener("click", handler);
        dropdownRef.current.classList.remove("uk-open");
      };
      window.addEventListener("click", handler);
      dropdownRef.current.classList.add("uk-open");
    }
  };

  return (
    <div className="uk-flex uk-flex-top" onClick={openTags}>
      <div>
        <Button>
          <img src="/images/tag.svg" />
        </Button>
        <div className="uk-dropdown guest-tags-dropdown" ref={dropdownRef}>
          <ul className="uk-nav uk-dropdown-nav tags-selector">
            {tagValues.map((tagValue, index) => (
              <li key={`tagValues_list_${index}`}>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    checked={
                      value?.findIndex((tag) => tag.id === tagValue.id) >= 0
                    }
                    onChange={
                      readOnly
                        ? () => {}
                        : (event) =>
                            handleInputChange(tagValue.id, event.target.checked)
                    }
                  />
                  {tagValue.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="hms-guest-tags">
        <div className="guest-tags">
          {value &&
            value.map((tagValue, index) => (
              <div className="uk-inline" key={`tag_div_${index}`}>
                {tagValue.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tags;
