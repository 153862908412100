import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";

import queryString from "query-string";

import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

const EmailForm = (props) => {
  const { verify_email, confirm_email, cancel_email, loaded } = useForm();
  const { handleSubmit } = useForm();

  let authCtx = useContext(AppContexts.AuthContext);
  let [creds, setCreds] = useState({
    token: null,
    password: "",
    old: "",
    new: "",
  });

  let [status, setStatus] = useState("loading");
  let [inFlight, setInFlight] = useState(false);

  const updatePassword = (e) => {
    setCreds({ ...creds, password: e.target.value });
  };

  useEffect(() => {
    let authToken =
      queryString.parse(window.location.search)["reset-token"] || "";

    authCtx
      .verify_email(authToken)
      .then((resp, err) => {
        setStatus("pending");
        setCreds({
          token: authToken,
          password: "",
          old: resp.data.email,
          new: resp.data.email_update,
        });
      })
      .catch((err) => {
        console.warn(err);
        setStatus("fatal");
        // window.location.assign('/');
      });
  }, []);

  const confirm = () => {
    setInFlight(true);
    authCtx
      .update_email(creds.token, creds.password)
      .then((resp, err) => {
        setStatus("updated");
        console.log(resp);
      })
      .catch((err) => {
        setStatus("error");
        setInFlight(false);
        console.log(err);
      });
  };

  const cancel = () => {
    setInFlight(true);
    authCtx
      .cancel_email(creds.token)
      .then((resp, err) => {
        // window.location.assign("/")
        console.log(resp);
      })
      .catch((err) => {
        setStatus("error");
        setInFlight(false);
        console.log(err);
      });
  };

  const content = () => {
    switch (status) {
      case "loading":
        return <div>Loading</div>;
      case "pending":
        return (
          <div className="pending">
            <form className="uk-form-stacked" onSubmit={handleSubmit(confirm)}>
              <p className="error">
                There was an error updating your email, please try again or
                contact{" "}
                <a href="mailto:support@ruebarue.com">support@ruebarue.com</a>{" "}
                for assistance
              </p>
              <p>
                Please confirm your password to change your email from{" "}
                <b>{creds.old}</b> to <b>{creds.new}</b>
              </p>

              <div className="uk-margin">
                <label className="uk-form-label">Password Confirmation</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="password"
                    value={creds.password}
                    onInput={updatePassword}
                  />
                </div>
              </div>

              {!inFlight ? (
                <button className="uk-button uk-button-# hms-btn hms-red-btn">
                  Update Email
                </button>
              ) : (
                <button className="uk-button uk-button-# hms-btn hms-red-btn disabled">
                  Update Email
                </button>
              )}
            </form>
          </div>
        );
      case "updated":
        return (
          <div className="pending">
            <form className="uk-form-stacked" onSubmit={handleSubmit(confirm)}>
              <p>
                Your email has been updated sucessfully. Click{" "}
                <a href="/auth/login">here</a> to login
              </p>
            </form>
          </div>
        );
      case "cancelled":
        return <div>Cancelled</div>;
      case "error":
        return (
          <div className="pending">
            <form className="uk-form-stacked" onSubmit={handleSubmit(confirm)}>
              <p className="error">
                There was an error updating your email, please try again or
                contact{" "}
                <a href="mailto:support@ruebarue.com">support@ruebarue.com</a>{" "}
                for assistance
              </p>
              <p>
                Please confirm your password to change your email from{" "}
                <b>{creds.old}</b> to <b>{creds.new}</b>
              </p>

              <div className="uk-margin">
                <label className="uk-form-label">Password Confirmation</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="password"
                    value={creds.password}
                    onInput={updatePassword}
                  />
                </div>
              </div>

              {!inFlight ? (
                <button className="uk-button uk-button-# hms-btn hms-red-btn">
                  Update Email
                </button>
              ) : (
                <button className="uk-button uk-button-# hms-btn hms-red-btn disabled">
                  Update Email
                </button>
              )}
            </form>
          </div>
        );
      case "fatal":
        return (
          <div className="pending">
            <form className="uk-form-stacked" onSubmit={handleSubmit(confirm)}>
              <p className="error">
                There was an error updating your email, please try again or
                contact{" "}
                <a href="mailto:support@ruebarue.com">support@ruebarue.com</a>{" "}
                for assistance
              </p>
            </form>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="page-box sign-in auth email-update">
      <h2 className="uk-heading-small">Change Email Address</h2>
      <div className="uk-margin">{content()}</div>
    </div>
  );
};

export default EmailForm;
