import styled from "styled-components";

export const BreadcrumbLayout = styled.div`
  padding: 30px 15px 5px 20px;
  margin: 10px 0 5px 0;
`;

export const BreadcrumbTitle = styled.h1`
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  color: #272841;
  margin-bottom: 0px;
`;
