import React, { createContext, useState } from "react";

import guidesService from "../services/guides.service";

export const GuidesContext = createContext();
export const GuidesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [listEmails, setEmails] = useState([]);
  const [guestEmail, setGuestEmail] = useState({});
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [batchFlag, setBatchFlag] = useState(false);

  const getRentalProperty = async (rental_id) => {
    setLoading(true);
    let result = await guidesService.get_property(rental_id);
    setLoading(false);
  };

  const getCapturedEmails = async (criteria) => {
    setLoading(true);
    let res = await guidesService.get_guestbook_emails(criteria);
    setEmails(res.data);
    setLoading(false);
  };

  const deleteEmail = async (objEmail) => {
    guidesService
      .delete_guestbook_email(objEmail.id)
      .then((res, err) =>
        setEmails((prev) =>
          prev.filter((indexEmail) => indexEmail.id !== objEmail.id),
        ),
      )
      .catch((err) => console.log(err));
  };

  const guidesCtx = {
    loading,
    getRentalProperty,
    listEmails,
    getCapturedEmails,
    guestEmail,
    setGuestEmail,
    selectedEmails,
    setSelectedEmails,
    batchFlag,
    setBatchFlag,
    deleteEmail,
  };
  return (
    <GuidesContext.Provider value={guidesCtx}>
      {children}
    </GuidesContext.Provider>
  );
};
