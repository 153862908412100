import React from "react";

const MultipleChoice = ({ options, index, answer, disabled = true, other }) => {
  let otherOption = { ...other, answer: "Other" };
  let renderOptions = other ? [...options, otherOption] : options;

  return (
    <div className="uk-form-controls uk-margin-small-top">
      {renderOptions
        ?.filter((option) => !option.deleted)
        .map((option, i) => (
          <div key={i}>
            <label className="hms-choice">
              <input
                className="uk-radio"
                type="checkbox"
                id={`survey${index}-${i}`}
                disabled={disabled}
                defaultChecked={option.answer == answer}
              />
              <span className="hms-checkmark"></span>
              {option.answer}
            </label>
          </div>
        ))}
    </div>
  );
};

export default MultipleChoice;
