import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//import { useHistory } from "react-router-dom";

import { classes } from "../../../utils/classes";
import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

const LoginForm = (props) => {
  let authCtx = useContext(AppContexts.AuthContext);

  const { handleSubmit } = useForm();

  let [errors, setErrors] = useState([]);
  let [submitted, setSubmitted] = useState(false);
  let [completed, setCompleted] = useState(false);

  let [creds, setCreds] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    pword: "",
  });

  let [valid, setValid] = useState({
    minLength: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
    match: false,
  });

  useEffect(() => {
    document.title = "Sign Up - RueBaRue";
    let validation = Object.keys(valid).reduce((a, c) => {
      a[c] = false;
      return a;
    }, {});
    let { password, password_confirmation } = creds;

    if (password === "" && password_confirmation === "") {
      return setValid(validation);
    }

    if (password === password_confirmation) {
      validation.match = true;
    }

    if (password.length >= 8) {
      validation.minLength = true;
    }

    if (/[A-Z]+/g.test(password)) {
      validation.upper = true;
    }

    if (/[a-z]+/g.test(password)) {
      validation.lower = true;
    }

    if (/[0-9]+/g.test(password)) {
      validation.number = true;
    }

    if (/[!@#\$%\^&_]/g.test(password)) {
      validation.special = true;
    }

    setValid(validation);
  }, [creds]);

  const signup = () => {
    if (submitted) {
      return;
    }

    let errs = [];

    Object.keys(creds).map((k) => {
      if (k !== "pword") {
        if (!creds[k]) {
          errs.push(k);
        }
      }
    });

    if (errs.length !== 0) {
      setErrors({ msg: "Please fill out all fields", fields: errs });
      return;
    } else {
      setErrors(null);
    }

    // setSubmitted(true);
    authCtx
      .signup(creds)
      .then((resp, err) => {
        window.location.assign("/");
      })
      .catch((err) => {
        console.log(err);
        let { error, fields } = err;
        setErrors({ msg: error, fields: fields });
      });
  };

  const update = (field, e) => {
    setCreds({ ...creds, [field]: e.target.value });

    if ((errors?.fields || []).includes(field)) {
      setErrors((errors?.fields || []).filter((k) => k !== field));
    }
  };

  return (
    <div className="page-box sign-in auth">
      <h2 className="uk-heading-small uk-margin-small-bottom">
        Signup for RueBaRue
      </h2>
      <div>
        <form className="uk-form-stacked" onSubmit={handleSubmit(signup)}>
          <div>
            Sign up to to automate guest communication and drive more revenue.
            Get started for free. No credit card required.
            <br />
            <br />
            Does your company already have an account? Ask your RueBaRue admin
            to invite you from the team page.
          </div>
          {errors?.msg && (
            <div className="uk-text-danger">
              <br />
              {errors.msg}
            </div>
          )}

          <div className="uk-margin uk-flex uk-flex-middle">
            <div
              className={classes(
                { error: (errors?.fields || []).includes("first_name") },
                "uk-width-1-2",
              )}
            >
              <label className="uk-form-label">First Name</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={creds.first_name}
                  onInput={(e) => {
                    update("first_name", e);
                  }}
                />
              </div>
            </div>
            <div
              className={classes(
                { error: (errors?.fields || []).includes("last_name") },
                "uk-width-1-2",
                "uk-margin-left",
              )}
            >
              <label className="uk-form-label">Last Name</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={creds.last_name}
                  onInput={(e) => {
                    update("last_name", e);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={classes(
              { error: (errors?.fields || []).includes("email") },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                value={creds.email}
                onInput={(e) => {
                  update("email", e);
                }}
              />
            </div>
          </div>

          <div
            className={classes(
              { error: (errors?.fields || []).includes("password") },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="password"
                value={creds.password}
                onInput={(e) => {
                  update("password", e);
                }}
              />
            </div>
          </div>

          <div
            className={classes(
              {
                error: (errors?.fields || []).includes("password_confirmation"),
              },
              "uk-margin",
            )}
          >
            <label className="uk-form-label">Verify Password</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="password"
                value={creds.password_confirmation}
                onInput={(e) => {
                  update("password_confirmation", e);
                }}
              />
            </div>
          </div>

          <div className="uk-hidden">
            <input
              className="uk-input"
              value={creds.pword}
              onInput={(e) => {
                update("pword", e);
              }}
            />
          </div>

          <div className="uk-flex uk-inline-flex">
            <button
              className={classes(
                { disabled: submitted },
                "uk-button uk-button-# hms-btn hms-red-btn",
              )}
            >
              Sign Up
            </button>
            {!!errors && <p className="error">{errors.msg}</p>}
          </div>

          <div className="uk-margin">
            Password Rules:
            <ul className="password-validation">
              <li className={classes({ complete: valid.minLength })}>
                At least 8 characters
              </li>
              <li className={classes({ complete: valid.upper })}>
                At least one capital letter
              </li>
              <li className={classes({ complete: valid.lower })}>
                At least one lowercase letter
              </li>
              <li className={classes({ complete: valid.number })}>
                At least one number
              </li>
              <li className={classes({ complete: valid.special })}>
                At least one of the following !@#$%^&_
              </li>
              <li className={classes({ complete: valid.match })}>
                Passwords must match
              </li>
            </ul>
          </div>

          <div className="uk-margin">
            Already have an account? <Link to="login">Login</Link>
          </div>
          <div className="uk-margin">
            By signing up, you agree to our{" "}
            <a href="https://www.ruebarue.com/privacy" target="_blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://www.ruebarue.com/terms" target="_blank">
              Terms of Use
            </a>
            .
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
