import { useCallback, useMemo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { InputContainer, SelectOption, Select } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import moment from "moment";

import { AppContexts } from "../../../providers";
import { InputRadio, Form, FormRow, FormInputWrapper } from "../../../ui";

// Import common components
import SMSEmailInputbox from "../../common/sms-email-inputbox";
import ConvertUTCDateToLocalDate from "../../common/ConvertUTCDateToLocalDate";

const schema = yup.object().shape({
  name: yup.string().required(),
  folder: yup.string().required(),
  body: yup.string().required(),
});
const folderMap = [
  "Scheduler",
  "Alerts",
  "Schedule Ahead",
  "Extend Guest Stays",
];
const defaultValues = {
  name: "",
  subject: "",
  folder: "",
  body: "",
};

const SavedTemplateAdd = () => {
  const {
    templatesCtx: { msgTemplate, isNew, set },
  } = useContext(AppContexts.TemplatesContext);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrors();
    clearForms();
    Object.keys(defaultValues).forEach((formname) => {
      if (formname !== "folder") {
        setValue(
          formname,
          msgTemplate[formname]
            ? msgTemplate[formname]
            : defaultValues[formname],
        );
      } else {
        if (msgTemplate[formname]) {
          let template;
          switch (msgTemplate[formname]) {
            case "scheduledMsg":
              template = "Scheduler";
              break;
            case "alert":
              template = "Alerts";
              break;
            case "scheduleAhead":
              template = "Schedule Ahead";
              break;
            case "extendYourStay":
              template = "Extend Guest Stays";
              break;
            default:
              template = msgTemplate[formname];
              break;
          }
          setValue(formname, template);
        } else {
          setValue(formname, defaultValues[formname]);
        }
      }
    });

    if (Object.keys(msgTemplate).length === 0) {
      clearForms();
    }
  }, [msgTemplate]);

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  const handleRegister = async (formdata) => {
    let formMsgTemplate = formdata;
    try {
      const result = await (isNew
        ? set({ msg_templates: [formMsgTemplate] }, "msg_templates")
        : set(
            { msg_templates: [{ ...formMsgTemplate, id: msgTemplate.id }] },
            "msg_templates",
          ));

      UIkit.modal("#message-templates-modal").hide();
      UIkit.notification(
        isNew === true ? "Message Template Added" : "Message Template Updated",
        "success",
      );
    } catch (err) {
      UIkit.notification("Failed", "error");
    }
  };

  const responseBody = useMemo(() => getValues("body"), [watch("body")]);
  const handleError = (err) => console.error(err);

  return (
    <>
      <div
        id="message-templates-modal"
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {isNew === true ? "Add a" : "Update"} Message Template
              </h2>
            </div>
            <div className="uk-modal-body uk-flex">
              <Form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                <FormRow>
                  <FormInputWrapper label="Name*">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.name,
                        })}
                        id="txt-schedule-name"
                        type="text"
                        placeholder=""
                        {...register("name")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-2 uk-last-column">
                    <label className="uk-form-label">Folder</label>
                    <div className="uk-form-controls uk-width-1-1">
                      <div
                        uk-form-custom="target: > * > span:first-child"
                        className={classnames("uk-form-custom", {
                          select_error: errors.folder,
                        })}
                      >
                        <select {...register("folder")} className="uk-select">
                          <option value="" key={`folder_0`}>
                            Select a folder
                          </option>
                          {folderMap.map((folder, index) => (
                            <option value={folder} key={`folder_${index + 1}`}>
                              {folder}
                            </option>
                          ))}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span>
                            {getValues("folder")
                              ? getValues("folder")
                              : "Select a folder"}
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="Subject">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.subject,
                        })}
                        type="text"
                        placeholder=""
                        {...register("subject")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>

                <FormRow>
                  <div className="uk-width-1-1">
                    <SMSEmailInputbox
                      value={responseBody || ""}
                      error={errors.body}
                      macrosOrResponse={true}
                      onChange={(event) => setValue("body", event.target.value)}
                    />
                  </div>
                </FormRow>

                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="s-msg-last-updated">
                  {isNew === false ? (
                    <ConvertUTCDateToLocalDate
                      label="Last Updated: "
                      date={new Date(msgTemplate?.updated_at)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedTemplateAdd;
