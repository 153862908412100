// Import package components
import { useContext, useState, useEffect, useMemo, Fragment } from "react";
import { AppContexts } from "../../../providers";
import { useForm } from "react-hook-form";
import { Icon, Image } from "uikit-react";

import TagDetail from "./tags_detail";
import TagDelete from "./tags_delete";

import { AppIcon } from "../../icons";

const SettingTags = () => {
  const { set, settings, loading, setDeleted, active } = useContext(
    AppContexts.SettingsContext,
  );
  const { user } = useContext(AppContexts.AuthContext);
  const { register, setValue, getValues, unregister, handleSubmit } = useForm(
    {},
  );

  const [eTag, setEditTag] = useState(null);
  const [dTag, setDetailTag] = useState(null);

  const tags = useMemo(() => {
    if (!settings) {
      return [];
    } else {
      if (settings.tags) {
        return settings.tags;
      } else {
        return [];
      }
    }
  }, [settings]);

  const handleAddTag = () => {
    if (getValues("add_tag").trim().length > 0) {
      let tag = {
        account_id: user.account_id,
        name: getValues("add_tag").trim(),
      };
      set("tags", { type: "created", data: tag }).then((res) => {
        setValue("add_tag", "");
      });
    }
  };

  const handleEditTag = async () => {
    let idx = tags.findIndex((t) => t.id === eTag.id);
    if (tags[idx].name !== eTag.name)
      await set("tags", { type: "updated", data: eTag });
    setEditTag(null);
  };

  const handleDelete = (index) => {
    let tag = tags[index];
    if (!!tag) {
      setDeleted(tag);
    }
  };

  return (
    <li
      className={`setting-tab-content ${active === "tags" ? "uk-active" : ""}`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <div className="uk-flex uk-width-1-1 uk-flex-between">
          <div className="hms-headings">
            <h3>Tags</h3>
          </div>
          <div className="hms-headings">
            <div className="semi-bold-font">Used</div>
          </div>
        </div>

        <ul className="uk-list uk-list-striped">
          {tags.map((tag, index) => (
            <Fragment key={index}>
              <li className="uk-flex uk-flex-between uk-width-1-1">
                <div className="uk-flex-inline">
                  {eTag && tag.id === eTag.id ? (
                    <div>
                      <input
                        type="text"
                        value={eTag.name}
                        style={{ width: "400px" }}
                        className="uk-margin-small-right"
                        onChange={(e) =>
                          setEditTag((prev) => {
                            return { ...prev, name: e.target.value };
                          })
                        }
                      />
                      <span onClick={handleEditTag}>
                        <Icon options="check" button={true} />
                      </span>
                      <span onClick={() => setEditTag(null)}>
                        <Icon options="close" button={true} />
                      </span>
                    </div>
                  ) : (
                    <>
                      {parseInt(tag?.count) ? (
                        <a
                          href="#"
                          uk-toggle="target: #modal-tags-detail"
                          className="tag-item"
                          onClick={() => setDetailTag(tag)}
                        >
                          {tag?.name}
                        </a>
                      ) : (
                        <span className="tag-item">{tag?.name}</span>
                      )}

                      <button
                        className="uk-button hms-simple-icon-btn"
                        onClick={() => setEditTag(tag)}
                      >
                        <Image src="/images/edit-icon.svg" />
                      </button>

                      {parseInt(tag?.count) === 0 && (
                        <button
                          className="uk-button hms-simple-icon-btn"
                          uk-toggle="target: #tag-delete"
                          onClick={() => {
                            handleDelete(index);
                          }}
                        >
                          <Image src="/images/delete-icon.svg" />
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="uk-flex-inline">{tag?.count}</div>
              </li>
            </Fragment>
          ))}
        </ul>
        <div className="uk-width-1-1 uk-margin-top">
          <div className="add-dest-gd-tab-wrapper">
            <div className="uk-flex uk-flex-middle">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                {...register("add_tag")}
              />
              <div className="plus-w-2">
                <button
                  className="uk-button hms-btn hms-gray-btn"
                  type="button"
                  onClick={handleAddTag}
                >
                  {loading ? <div data-uk-spinner="ratio: 0.5;"></div> : ""}
                  {"  "}ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TagDetail tag={dTag} />
      <TagDelete />
    </li>
  );
};

export default SettingTags;
