// Import package components
import { createContext, useState } from "react";

import AnnouncementsService from "../services/announcements.service";

export const AnnouncementsContext = createContext();
export const AnnouncementsProvider = ({ children }) => {
  const contextAnnouncements = {
    dismissAnnouncement: async (user_id, announcement_id) => {
      try {
        let dismiss = await AnnouncementsService.dismissAnnouncement(
          user_id,
          announcement_id,
        );

        console.log(dismiss);
      } catch (err) {
        console.log(err);
      }
    },
  };

  return (
    <AnnouncementsContext.Provider value={contextAnnouncements}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
