// Import package components
import { useEffect, useContext, useMemo, useState } from "react";
import { InputContainer } from "uikit-react";
import { AppContexts } from "../../../providers";
import { Form, FormRow, FormInputWrapper } from "../../../ui";
import { set, useForm } from "react-hook-form";
import AddTextLine from "../../common/AddTextLine";

const FiveStarReviewTags = () => {
  const { settings, set, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const {
    listSurveys: surveys,
    loaded: surveysLoaded,
    getSurveys,
  } = useContext(AppContexts.SurveysContext);
  const [dirty, setDirty] = useState(false);
  const [fiveStar, setFiveStar] = useState({});

  const { user } = useContext(AppContexts.AuthContext);
  const { register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    document.title = "Settings | Five Star Review - RueBaRue";
    setFiveStar(settings.five_star || {});
  }, [settings]);

  useEffect(() => {
    if (!surveysLoaded) {
      getSurveys();
    }
  }, []);

  const handleUpdate = (field, e) => {
    setFiveStar({ ...fiveStar, [field]: e.target.value });
    setDirty(true);
  };

  const getSurveyName = (surveyId) => {
    return (
      surveys.find((s) => s.survey_id === surveyId)?.name || "Select a Survey"
    );
  };

  const handleSave = () => {
    set("five_star", fiveStar).then((res) => {
      setDirty(false);
    });
  };

  return (
    <li
      className={`setting-tab-content ${
        active === "five_star" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <Form
          className="uk-grid"
          data-uk-grid
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="uk-width-1-1 uk-margin-bottom hms-headings">
            <AddTextLine feature="Five Star Review" />
            <h2>Five Star Review</h2>
          </div>
          <div className="uk-width-4-5 mx-width-400">
            <FormInputWrapper label="Title" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  value={fiveStar.title}
                  onInput={(e) => {
                    handleUpdate("title", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5">
            <FormInputWrapper label="Introduction" size="two">
              <InputContainer>
                <textarea
                  rows="5"
                  className="uk-textarea"
                  value={fiveStar.intro}
                  onInput={(e) => {
                    handleUpdate("intro", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5 mx-width-400">
            <FormInputWrapper label="Google Review Link" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  value={fiveStar.google_link}
                  onInput={(e) => {
                    handleUpdate("google_link", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5 mx-width-400">
            <FormInputWrapper label="TripAdvidor Review Link" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  value={fiveStar.ta_link}
                  onInput={(e) => {
                    handleUpdate("ta_link", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5 mx-width-400">
            <FormInputWrapper label="Facebook Page Link" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  value={fiveStar.fb_link}
                  onInput={(e) => {
                    handleUpdate("fb_link", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5 mx-width-400">
            <FormInputWrapper label="Yelp Review Link" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  value={fiveStar.yelp_link}
                  onInput={(e) => {
                    handleUpdate("yelp_link", e);
                  }}
                />
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-4-5">
            <FormInputWrapper label="4 Star Or Below Survey" size="two">
              <InputContainer>
                <div uk-form-custom="target: > * > span:first-child">
                  <select
                    value={fiveStar.survey_id}
                    onInput={(e) => {
                      handleUpdate("survey_id", e);
                    }}
                  >
                    <option value="">Select Survey</option>
                    {surveys
                      .filter(survey => survey.deleted === false)
                      .map((survey, index) => {
                      return (
                        <option
                          value={survey.survey_id}
                          key={`survey_${index}`}
                        >
                          {survey.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>{getSurveyName(fiveStar.survey_id)}</span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-1-1 uk-margin-top">
            <div className="uk-flex uk-flex-middle">
              <button
                className="uk-button hms-btn hms-red-btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div data-uk-spinner="ratio: 0.5;"></div>
                ) : dirty ? (
                  "Save"
                ) : (
                  "Saved"
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </li>
  );
};

export default FiveStarReviewTags;
