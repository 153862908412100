const GuestRate = () => {
  return (
    <div
      id="modal-guest-add"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Add Guest</h2>
          </div>
          <div className="uk-modal-body">
            <div className="">
              <form className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="unique-property-id">
                    Property ID*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="unique-property-id"
                      type="text"
                      placeholder="Unique Property ID"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="unique-reservation-id"
                  >
                    Reservation Number*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="unique-reservation-id"
                      type="text"
                      placeholder="Unique Reservation Number"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-first-name">
                    First Name*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="guest-first-name"
                      type="text"
                      placeholder="Guest's First Name"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-last-name">
                    Last Name*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="guest-last-name"
                      type="text"
                      placeholder="Guest's Last Name"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-check-in">
                    Check-In*
                  </label>
                  <div className="uk-form-controls">
                    <div className="hms-date-picker">
                      <input
                        className="uk-input datePlaceholder"
                        type="date"
                        id="guest-check-in"
                        onClick={(e) => e.target.removeClass("datePlaceholder")}
                      />
                      <div className="hms-date-placeholder">Pick a Date</div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-check-out">
                    Check-Out*
                  </label>
                  <div className="uk-form-controls">
                    <div className="hms-date-picker">
                      <input
                        className="uk-input datePlaceholder"
                        type="date"
                        id="guest-check-out"
                        onClick={(e) => e.target.removeClass("datePlaceholder")}
                      />
                      <div className="hms-date-placeholder">Pick a Date</div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="guest-email-address"
                  >
                    Email
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="guest-email-address"
                      type="email"
                      placeholder="Guest's Email"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="guest-mobile-number"
                  >
                    Phone
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="guest-mobile-number"
                      type="text"
                      placeholder="Guest's Mobile Number"
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-door-code">
                    Door Code
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="guest-door-code"
                      type="text"
                      placeholder="Reservation's Door Code"
                    />
                  </div>
                </div>
                <div className="uk-width-1-1 hms-note">
                  <div>
                    *Updating the door code here will override the door code
                    sync from your PMS. Updating other fields will be
                    overwritten by PMS sync.
                  </div>
                </div>
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="button"
                  >
                    Save
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestRate;
