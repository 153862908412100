// Import package components
import { Flex, Image, Select, SelectOption } from "uikit-react";
import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { withRouter } from "react-router";
import moment from "moment";
import QueryString from "query-string";
import classnames from "classnames";
import { useCookies } from "react-cookie";

// Import base components
import { AppContexts } from "../../providers";
import { Link } from "../../ui";

// Import common components
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import AddTextLine from "../common/AddTextLine";

// Import alerts components
import AlertAdd from "./add";
import AlertShare from "./share";
import AlertDelete from "./delete";

const Alerts = ({ history, location }) => {
  const contextAlerts = useContext(AppContexts.contextAlerts);
  const settingsContext = useContext(AppContexts.SettingsContext);

  const [listParams, setListParams] = useState({
    ...QueryString.parse(location.search),
    type: "All",
    tag: null,
    search: "",
    sort: "updated_on",
    pastPage: 0,
    pendingPage: 0,
    upcoming: true,
  });

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { flagIsProcessing, setIsViewMode, setObjAlert } = contextAlerts;

  useEffect(() => {
    contextAlerts.getAlerts();
    settingsContext.get(["tags"]);
    settingsContext.get(["branding"]);
    document.title = "Alerts - RueBaRue";
  }, []);

  const tagValues = useMemo(
    () => settingsContext.settings.tags || [],
    [settingsContext.settings],
  );

  const handleClickEdit = (argAlert) => {
    showFormModal(argAlert);
    setIsViewMode(false);
  };

  const handleShare = (argAlert) => {
    setObjAlert(argAlert);
  };

  const handleClickView = (argAlert) => {
    showFormModal(argAlert);
    setIsViewMode(true);
  };

  const showFormModal = (argAlert) => {
    contextAlerts.getAlertGuests(argAlert);
    contextAlerts.setIsNew(false);
    setObjAlert(argAlert);
    // contextAlerts.setAlert(objAlert);
  };

  const handleClickClone = (argAlert) => {
    const { id, created_at, updated_on, ...objCloneAlert } = argAlert;
    objCloneAlert.name += " - Cloned";
    contextAlerts.setIsNew(true);
    setIsViewMode(false);
    setObjAlert(objCloneAlert);
  };

  const handleInputChange = (key, value) =>
    setListParams((prev) => ({...prev, [key]: value}));

  const handleClickDelete = (argAlert) => {
    setObjAlert(argAlert);
  };

  const handleClickAdd = () => {
    setObjAlert({});
    contextAlerts.setIsNew(true);
    setIsViewMode(false);
  };

  const handlePastPageIndexChanged = useCallback((newPageIndex) => {
    handleInputChange("pastPage", newPageIndex);
  }, []);

  const handlePendingPageIndexChanged = useCallback((newPageIndex) => {
    handleInputChange("pendingPage", newPageIndex);
  }, []);

  const listAlerts = useMemo(() => {
    if (!contextAlerts.listAlerts || contextAlerts.listAlerts == null) {
      return [];
    } else {
      return [...contextAlerts.listAlerts].filter(
        (indexAlert) => !indexAlert.cancelled,
      );
    }
  }, [contextAlerts.listAlerts]);

  const pendingAlerts = useMemo(
    () => listAlerts.filter((indexAlert) => !indexAlert.sent),
    [listAlerts],
  );
  const pastAlerts = useMemo(
    () => listAlerts.filter((indexAlert) => indexAlert.sent),
    [listAlerts],
  );

  const isPast = (indexAlert) => {
    let { scheduled, scheduled_date, scheduled_time } = indexAlert;

    let sendAt = `${scheduled_date} ${scheduled_time}`;

    return !scheduled || moment(sendAt, "YYYY-MM-DD h:mm A").isBefore(moment());
  };

  const getFilteredResult = useCallback(
    (array) =>
      array
        .filter((indexAlert) => {
          let {
            type: lType = "All",
            active: lActive = null,
            tag: lTag = null,
            search: lSearch,
            upcoming: lUpcoming,
          } = listParams;

          let {
            delivery: aType = "",
            active: aActive = null,
            tags: aTags = [],
            name: aName,
            scheduled: aScheduled,
            scheduled_date: aDate,
            scheduled_time: aTime,
          } = indexAlert;

          if (
            !!lUpcoming &&
            (!aScheduled ||
              moment(`${aDate} ${aTime}`, "YYYY-MM-DD h:mm a").isBefore(
                moment(),
              ))
          ) {
            return false;
          }

          if (
            !lUpcoming &&
            !!aScheduled &&
            moment(`${aDate} ${aTime}`, "YYYY-MM-DD h:mm a").isAfter(moment())
          ) {
            return false;
          }

          if (lType !== "All" && lType.toLowerCase() !== aType) {
            return false;
          }

          if (!!lActive && lActive !== aActive) {
            return false;
          }

          if (!!lTag && !(aTags || []).find((t) => t.id === +lTag)) {
            return false;
          }

          if (
            !!lSearch &&
            !aName.toLowerCase().includes(lSearch.toLowerCase())
          ) {
            return false;
          }

          return true;

          // if (listParams.type === "SMS" && indexAlert.delivery !== "sms")
          //   return false;
          // if (listParams.type === "Email" && indexAlert.delivery !== "email")
          //   return false;

          // if (
          //   listParams.active !== null &&
          //   indexAlert.active !== listParams.active
          // )
          //   return false;

          // if (
          //   listParams.tag &&
          //   (indexAlert.tags || []).findIndex(
          //     (tag) => tag.id.toString() === listParams.tag
          //   ) < 0
          // )
          //   return false;
          // if (
          //   listParams.search &&
          //   !indexAlert.name
          //     .toLowerCase()
          //     .includes(listParams.search.toLowerCase())
          // )
          //   return false;
          // return true;
        })
        .sort((objA, objB) => {
          if (listParams.sort === "updated_on")
            return objB.updated_on?.localeCompare(objA.updated_on);
          if (listParams.sort === "name")
            return objA.name.localeCompare(objB.name);
          return true;
        }),
    [listParams],
  );
  const filteredPendingAlerts = useMemo(
    () => getFilteredResult(pendingAlerts),
    [pendingAlerts, listParams],
  );
  const filteredPastAlerts = useMemo(
    () => getFilteredResult(pastAlerts),
    [pastAlerts, listParams],
  );

  const visiblePendingAlerts = useMemo(
    () =>
      filteredPendingAlerts.slice(
        listParams.pendingPage * recordsPerPage,
        (listParams.pendingPage + 1) * recordsPerPage,
      ),
    [filteredPendingAlerts, listParams, recordsPerPage],
  );
  const visiblePastAlerts = useMemo(
    () =>
      filteredPastAlerts.slice(
        listParams.pastPage * recordsPerPage,
        (listParams.pastPage + 1) * recordsPerPage,
      ),
    [filteredPastAlerts, listParams, recordsPerPage],
  );

  return (
    <>
      <section id="schedule-a-message">
        <AddTextLine feature="Alerts" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Alerts</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #alert-add"
                onClick={handleClickAdd}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> ADD AN ALERT
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto ">
              <Flex
                alignment="between top"
                className="workorder-form-top-filters"
              >
                <div>
                  <form className="uk-form uk-flex uk-flex-middle hms-form">
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={listParams.type}
                          onChange={(event) =>
                            handleInputChange("type", event.target.value)
                          }
                        >
                          <SelectOption value="SMS">SMS</SelectOption>
                          <SelectOption value="Email">Email</SelectOption>
                          <SelectOption value="All">SMS & Email</SelectOption>
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={
                            listParams.tag === null ? "All" : listParams.tag
                          }
                          onChange={(event) =>
                            handleInputChange(
                              "tag",
                              event.target.value === "All"
                                ? null
                                : event.target.value,
                            )
                          }
                        >
                          <SelectOption value="All">All Tags</SelectOption>
                          {tagValues?.map((tag, idx) => (
                            <SelectOption value={tag.id} key={`tag_${idx}`}>
                              {tag.name}
                            </SelectOption>
                          ))}
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {listParams.search && listParams.search !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() => handleInputChange("search", "")}
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name"
                          value={listParams.search}
                          onChange={(event) =>
                            handleInputChange("search", event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": listParams.sort === "updated_on",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleInputChange("sort", "updated_on")
                            }
                          >
                            Last Updated
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": listParams.sort === "name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() => handleInputChange("sort", "name")}
                          >
                            Alert Name
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              <ul
                data-uk-tab
                uk-tab="connect: #alerts_tab; animation: uk-animation-fade"
                className="uk-margin-small-bottom"
              >
                <li className="uk-active">
                  <Link
                    href="#"
                    onClick={() => {
                      handleInputChange("upcoming", true);
                    }}
                  >
                    SCHEDULED
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    onClick={() => {
                      handleInputChange("upcoming", false);
                    }}
                  >
                    PAST
                  </Link>
                </li>
              </ul>
              <div className="workorder-form-top-filters">
                <ul id="alerts_tab" className="uk-switcher">
                  <div className="hms-pending-alert">
                    <table className="uk-table uk-table-hover uk-table-divider hms-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th className="uk-table-expand">Name</th>
                          <th className="uk-table-expand">Scheduled For</th>
                          <th className="uk-table-expand">Tags</th>
                          <th className="actions">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flagIsProcessing == true ? (
                          TableLoading(5)
                        ) : visiblePendingAlerts.length > 0 ? (
                          visiblePendingAlerts.map((indexAlert, index) => (
                            <tr key={index}>
                              <td>
                                <div className="hms-sms-tag">
                                  {indexAlert.delivery.toUpperCase()}
                                </div>
                              </td>
                              <td>
                                <div className="semi-bold-font">
                                  {indexAlert.name}
                                </div>
                              </td>
                              <td>
                                {indexAlert.scheduled ? (
                                  <div>
                                    {moment
                                      .utc(indexAlert.scheduled_date)
                                      .format("MM/DD/YYYY")}{" "}
                                    at {indexAlert.scheduled_time}
                                  </div>
                                ) : (
                                  <div>Now</div>
                                )}
                              </td>
                              <td className="hms-add-tag">
                                <div className="hms-guest-tags">
                                  <div className="guest-tags">
                                    {indexAlert.tags?.map((tag, idx) => (
                                      <React.Fragment
                                        key={`alert_${indexAlert.name}_${idx}`}
                                      >
                                        <div className="uk-inline">
                                          {tag.name}
                                        </div>
                                        &nbsp;
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </div>
                              </td>
                              <td className="hms-action-btn">
                                <div className="uk-flex uk-flex-top">
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-add"
                                      disabled={isPast(indexAlert)}
                                      onClick={() =>
                                        handleClickEdit(indexAlert)
                                      }
                                      uk-tooltip="Edit"
                                    >
                                      <Image src="/images/edit.svg" />
                                    </button>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-share"
                                      onClick={() => handleShare(indexAlert)}
                                      uk-tooltip="Share"
                                    >
                                      <Image src="/images/share.svg" />
                                    </button>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-add"
                                      onClick={() =>
                                        handleClickClone(indexAlert)
                                      }
                                      uk-tooltip="Clone"
                                    >
                                      <Image src="/images/clone.svg" />
                                    </button>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-delete"
                                      disabled={isPast(indexAlert)}
                                      onClick={() =>
                                        handleClickDelete(indexAlert)
                                      }
                                      uk-tooltip="Delete"
                                    >
                                      <Image src="/images/delete.svg" />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ padding: "30px 25px" }}>
                              No alerts matching your current search or filters.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Flex
                      alignment="between top"
                      className="guest-form-top-filters"
                    >
                      <Pagination
                        count={filteredPendingAlerts.length}
                        index={listParams.pendingPage}
                        onIndexChanged={handlePendingPageIndexChanged}
                      />
                      <RecordsPerPage />
                    </Flex>
                  </div>
                  <div className="hms-past-alert">
                    <table className="uk-table uk-table-hover uk-table-divider hms-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th className="uk-table-expand">Name</th>
                          <th className="uk-table-expand">Sent At</th>
                          <th className="uk-table-expand">Tags</th>
                          <th className="actions">ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flagIsProcessing == true ? (
                          TableLoading(5)
                        ) : visiblePastAlerts.length > 0 ? (
                          visiblePastAlerts.map((indexAlert, index) => (
                            <tr key={index}>
                              <td>
                                <div className="hms-sms-tag">
                                  {indexAlert.delivery.toUpperCase()}
                                </div>
                              </td>
                              <td>
                                <div className="semi-bold-font">
                                  {indexAlert.name}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {moment
                                    .utc(indexAlert.send_on)
                                    .local()
                                    .format("MM/DD/YYYY hh:mm A")}
                                </div>
                              </td>
                              <td className="hms-add-tag">
                                <div className="hms-guest-tags">
                                  <div className="guest-tags">
                                    {indexAlert.tags?.map((tag) => (
                                      <>
                                        <div className="uk-inline">
                                          {tag.name}
                                        </div>
                                        &nbsp;
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </td>
                              <td className="hms-action-btn">
                                <div className="uk-flex uk-flex-top">
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-add"
                                      onClick={() =>
                                        handleClickView(indexAlert)
                                      }
                                      uk-tooltip="View"
                                    >
                                      <Image src="/images/view.svg" />
                                    </button>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-share"
                                      onClick={() => handleShare(indexAlert)}
                                      uk-tooltip="Share"
                                    >
                                      <Image src="/images/share.svg" />
                                    </button>
                                  </div>
                                  <div className="uk-inline">
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #alert-add"
                                      onClick={() =>
                                        handleClickClone(indexAlert)
                                      }
                                      uk-tooltip="Clone"
                                    >
                                      <Image src="/images/clone.svg" />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ padding: "30px 25px" }}>
                              No alerts matching your current search or filters.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Flex
                      alignment="between top"
                      className="guest-form-top-filters"
                    >
                      <Pagination
                        count={filteredPastAlerts.length}
                        index={listParams.pastPage}
                        onIndexChanged={handlePastPageIndexChanged}
                      />
                      <RecordsPerPage />
                    </Flex>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="hms-small-heading">
            <h3>Past Alerts</h3>
          </div> */}
        </div>
      </section>
      <AlertAdd />
      <AlertShare />
      <AlertDelete />
    </>
  );
};

export default withRouter(Alerts);
