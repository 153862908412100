import { createElement, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Branding from "./Branding";
import SettingsNavbar from "./Layout/navbar";
import Messages from "./Messages";
import WebChat from "./Chat";
import SettingTags from "./Tags";
import GuestBook from "./GuestBook";
import DestinationGuide from "./DestinationGuide";
import FiveStarReview from "./FiveStarReview";
import Embed from "./Embed";
import API from "./API";

const DefaultValue = {
  "branding": Branding,
  "messages": Messages,
  "chat": WebChat,
  "guestbook": GuestBook,
  "destination-guide": DestinationGuide,
  "five-star": FiveStarReview,
  "tags": SettingTags,
  "embed": Embed,
  "api": API,
};

const Settings = () => {
  const history = useHistory();

  let pathArray = history.location.pathname.split("/");
  let path = pathArray.pop().replace("#", "");
  let el = DefaultValue[path];

  useEffect(() => {
    if (path === "settings") history.push("/settings/branding");
  }, [path]);

  return (
    <section id="hms-settings-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Settings</h1>
      </div>
      <div id="hms-main-body">
        <div className="uk-width-1-1@s uk-grid" uk-grid>
          <SettingsNavbar />
          <div className="uk-width-expand hms-left-tabs-content">
            <ul id="settings-tab-left" className="uk-switcher">
              {!!el && createElement(el, {})}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
