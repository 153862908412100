// Import package components
import { useMemo, useContext, useEffect, useState } from "react";
import { AppContexts } from "../../../providers";
import { Image, Link } from "uikit-react";
import DestinationGuideDelete from "./guide_delete";
import {
  SortableElement,
  SortableContainer,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { host } from "../../../http";

const defaultTabs = [
  "faq",
  "restaurants",
  "attractions",
  "essentials",
  "directions",
  "tours",
];

const onDragStart = (e) => {
  return;

  let dragHandle = e.target.closest(".drag-handle");
  let dragTarget = e.target.closest(".drag-item");
  let dropTarget = e.target.closest(".drag-container");

  dragHandle.style.pointerEvents = "none";
  dragTarget.draggable = true;
  dragTarget.addEventListener("dragstart", function dragstart(e) {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", null);

    let isBefore = (el1, el2) => {
      if (el2.parentNode === el1.parentNode)
        for (
          var cur = el1.previousSibling;
          cur && cur.nodeType !== 9;
          cur = cur.previousSibling
        )
          if (cur === el2) return true;
      return false;
    };

    let dragOver = (e) => {
      console.log(dragTarget, e.target);
      if (isBefore(dragTarget, e.target))
        e.target.parentNode.insertBefore(dragTarget, e.target);
      else e.target.parentNode.insertBefore(dragTarget, e.target.nextSibling);
    };

    let targets = [...dropTarget.querySelectorAll(".drag-item")];
    targets.map((t) => t.addEventListener("dragover", dragOver));

    dragTarget.addEventListener("dragend", function dragend(e) {
      dragTarget.removeEventListener("dragstart", dragstart);
      dragTarget.removeEventListener("dragend", dragend);
      dragHandle.style.pointerEvents = "all";

      targets.map((t) => t.addEventListener("dragover", dragOver));
    });
  });
};

const DragHandle = SortableHandle(() => (
  <div className="uk-sortable-handle">
    <button
      className="uk-button hms-simple-icon-btn drag-handle"
      uk-tooltip="Move"
    >
      <Image src="../images/move.svg" />
    </button>
  </div>
));

const SortableItemDiv = ({ index, no, tab, val, onUpdate, onDelete }) => {
  return (
    <li className="drag-item" data-id={val.type}>
      <div className="uk-width-1-1">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <DragHandle />
          <div>
            {defaultTabs.indexOf(val.type) !== -1 ? (
              <button
                type="button"
                className="uk-button hms-simple-icon-btn lock-icon-grey"
              ></button>
            ) : (
              <button
                type="button"
                className="uk-button hms-simple-icon-btn"
                uk-toggle="target: #destination-delete"
                uk-tooltip="Delete"
                onClick={() => {
                  onDelete(val);
                }}
              >
                <Image src="/images/delete-icon.svg" />
              </button>
            )}
          </div>
          <div className="m-w-290">
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                value={val.label}
                onInput={(e) => {
                  onUpdate(val.type, "label", e);
                }}
              />
            </div>
          </div>
          <div>
            <div className="autosend-checkbox">
              <label for={`guideCheckbox${no}`} className="setting-switch">
                <input
                  type="checkbox"
                  id={`guideCheckbox${no}`}
                  checked={val.show_on_guide}
                  onInput={(e) => {
                    onUpdate(val.type, "show_on_guide", {
                      target: { value: !val.show_on_guide },
                    });
                  }}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          </div>
          <div>
            <div className="autosend-checkbox">
              <label for={`embedCheckbox${no}`} className="setting-switch">
                <input
                  type="checkbox"
                  id={`embedCheckbox${no}`}
                  checked={val.show_on_embed}
                  onInput={(e) => {
                    onUpdate(val.type, "show_on_embed", {
                      target: { value: !val.show_on_embed },
                    });
                  }}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 m-t-5"></div>
    </li>
  );
};

const SortableList = ({ tabs, onSortEnd, onUpdate, onDelete }) => {
  useEffect(() => {
    let container = document.querySelector(".drag-container");
    container.addEventListener("stop", () => {
      let ids = [...container.querySelectorAll(".drag-item")].map((el) => {
        return el.dataset["id"];
      });

      onSortEnd(ids);
    });
  }, []);

  return (
    <ul
      uk-sortable="handle: .uk-sortable-handle"
      className="drag-container"
      onStop={() => {
        console.log("stopped");
      }}
    >
      {tabs?.map((tab, index) => (
        <SortableItemDiv
          key={tab.type}
          index={index}
          no={index}
          val={tab}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}
    </ul>
  );
};

const DestinationGuideTags = () => {
  const { set, settings, loading, setDeleted, active } = useContext(
    AppContexts.SettingsContext,
  );
  const [addTab, setAddTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [destination, setDestination] = useState({});
  const [dirty, setDirty] = useState(false);
  const [updatedOrder, setUpdatedOrder] = useState(null);


  useEffect(() => {
    document.title = "Settings | Area Guide - RueBaRue";
    setTabs(settings.destination_tabs || []);
    setDestination(settings.destination || { tab_order: "" });
  }, [settings]);

  useEffect(() => {
    if (updatedOrder !== null) {
      setDirty(true);
      setDestination({ ...destination, tab_order: updatedOrder.join(",") });
      setUpdatedOrder(null)
    }
  }, [updatedOrder]);

  const handleAddTab = () => {
    let type = Math.floor(
      100000000 + Math.random() * (999999999 - 100000000 + 1),
    ).toString();
    let newOrder = [...destination.tab_order.split(","), type].join(",");

    if (addTab.trim().length > 0) {
      let temp = {
        label: addTab.trim(),
        show_on_embed: false,
        show_on_guide: false,
        isDefault: false,
        type: type,
      };

      setDirty(true);
      setAddTab("");
      setDestination({ ...destination, tab_order: newOrder });
      setTabs((prev) => [...prev, temp]);
    }
  };

  const handleSave = () => {
    set(["destination", "destination_tabs"], {
      destination_tabs: tabs,
      destination: destination,
    }).then((res) => {
      setAddTab("");
      setDirty(false);
    });
  };

  const handleUpdate = (type, field, evt) => {
    setDirty(true);
    setTabs(
      tabs.map((d) => {
        return type === d.type ? { ...d, [field]: evt.target.value } : d;
      }),
    );
  };

  const handleDelete = (tab) => {
    setDeleted(tab);
  };

  // const handleReorder = ({ oldIndex, newIndex }) => {
  //   let oldOrder = destination.tab_order.split(',')
  //   let newOrder = arrayMoveImmutable(oldOrder, oldIndex, newIndex);

  //   setDirty(true);
  //   setDestination({...destination, tab_order: newOrder.join(",")})
  // };

  const handleReorder = (tabIds) => {
    setUpdatedOrder(tabIds)
    // setDirty(true);
    // setDestination({ ...destination, tab_order: tabIds.join(",") });
  };

  let order = (destination.tab_order || "").split(",");
  let sorted = tabs
    .filter((t) => order.indexOf(t.type) !== -1)
    .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));

  return (
    <>
      <li
        className={`setting-destinationGuide-tab-content ${
          active === "destination" ? "uk-active" : ""
        }`}
      >
        <div className="uk-card hms-left-tabs-content-card mx-w-665">
          <h2>Area Guide</h2>
          <div className="uk-width-1-1">
            <div className="uk-width-1-4 guide-embed-label">
              <div className="uk-flex uk-flex-between uk-flex-middle">
                <div>Guide</div>
                <div>Embed</div>
              </div>
            </div>
          </div>
          <SortableList
            tabs={sorted}
            useDragHandle
            transitionDuration="0"
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onSortEnd={handleReorder}
          />
          <div className="uk-width-1-1 uk-margin">
            <div className="add-dest-gd-tab-wrapper">
              <div className="uk-flex uk-flex-middle">
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Tab Name"
                  value={addTab}
                  onChange={(e) => setAddTab(e.target.value)}
                />
                <div className="plus-w-2">
                  <button
                    className="uk-button hms-btn hms-gray-btn btn-no-margin"
                    type="button"
                    onClick={handleAddTab}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <button
              className="uk-button hms-btn hms-red-btn"
              disabled={loading}
              onClick={handleSave}
            >
              {loading ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : dirty ? (
                "Save"
              ) : (
                "Saved"
              )}
            </button>
          </div>
        </div>
      </li>
      <DestinationGuideDelete />
    </>
  );
};

export default DestinationGuideTags;
