// Import package components
import { useMemo, useEffect, useContext, useRef } from "react";

// Import base components
import { Button } from "../../../ui";
import { AppContexts } from "../../../providers";

const Properties = ({ value, onChange, readOnly = false }) => {
  const rentalContext = useContext(AppContexts.RentalsContext);

  const dropdownProRef = useRef(null);

  const tagValues = useMemo(() => {
    if (!rentalContext.rentals) {
      return [];
    } else {
      return rentalContext.rentals.sort((a, b) =>
        a.pms_id.localeCompare(b.pms_id),
      );
    }
  }, [rentalContext.rentals]);

  useEffect(() => {
    rentalContext.get();
  }, []);

  const handleInputChange = (tagId, selected) => {
    if (selected) {
      onChange([
        ...value,
        tagValues.find(
          (tagValue) => tagValue.rental_id.toString() === tagId.toString(),
        ),
      ]);
    } else {
      onChange(
        value.filter((tag) => tag.rental_id.toString() !== tagId.toString()),
      );
    }
  };

  const openTags = (e) => {
    e.stopPropagation();

    if (!dropdownProRef.current.classList.contains("uk-open")) {
      let handler = () => {
        window.removeEventListener("click", handler);
        dropdownProRef.current.classList.remove("uk-open");
      };
      window.addEventListener("click", handler);
      dropdownProRef.current.classList.add("uk-open");
    }
  };

  return (
    <div className="uk-flex uk-flex-top" onClick={openTags}>
      <div>
        <Button>
          <img src="/images/rental.svg" />
        </Button>
        <div className="uk-dropdown guest-tags-dropdown" ref={dropdownProRef}>
          <ul className="uk-nav uk-dropdown-nav tags-selector">
            {tagValues.map(
              (tagValue, index) =>
                tagValue.pms_id != "" && (
                  <li key={index}>
                    <label>
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        checked={
                          value?.findIndex(
                            (tag) =>
                              tag.rental_id.toString() ===
                              tagValue.rental_id.toString(),
                          ) >= 0
                        }
                        onChange={
                          readOnly
                            ? () => {}
                            : (event) =>
                                handleInputChange(
                                  tagValue.rental_id,
                                  event.target.checked,
                                )
                        }
                      />
                      {tagValue.pms_id}
                    </label>
                  </li>
                ),
            )}
          </ul>
        </div>
      </div>
      <div className="hms-guest-tags">
        <div className="guest-tags">
          {value &&
            value.map((tagValue, index) => (
              <div className="uk-inline" key={index}>
                {tagValue.pms_id}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Properties;
