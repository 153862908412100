import { Image } from "uikit-react";

const MasterConfirm = ({ onDelete }) => {
  return (
    <div className="uk-inline">
      <span>
        <Image src="/images/delete-icon.svg" />
      </span>
      <div uk-drop="mode: click">
        <div className="uk-card uk-card-body uk-card-default hms-confirm-drop">
          <h2 className="uk-modal-title uk-flex uk-flex-left">Confirm</h2>
          <p>Are you sure you want to delete?</p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn hms-white-btn uk-drop-close"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={onDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MasterConfirm;
