// Import package components
import { createContext, useState } from "react";
import rentalsService from "../services/rentals.service";
import settingService from "../services/settings.service";

export const MasterGuideContext = createContext();
export const MasterGuideProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [guide, setGuide] = useState({});
  const [order, setOrder] = useState([]);
  const [indexOrder, setIndexOrder] = useState(0);
  const [success, setSuccess] = useState(false);
  const [success1, setSuccess1] = useState(false);
  const [tab, setTab] = useState("");
  const [tabIndex, setTabIndex] = useState(-1);
  const [selectedIcon, setSelectedIcon] = useState("");

  const [tabPreference, setTabPreference] = useState(null);
  const [deletedTab, setDeletedTab] = useState(null);

  const [loading, setLoading] = useState(false);

  const contextMasterGuide = {
    isEditing,
    setIsEditing,
    isNew,
    setIsNew,
    guide,
    setGuide,
    loading,
    order,
    setOrder,
    indexOrder,
    setIndexOrder,
    success,
    setSuccess,
    success1,
    setSuccess1,
    tab,
    setTab,
    tabPreference,
    setTabPreference,
    deletedTab,
    setDeletedTab,
    tabIndex,
    setTabIndex,
    selectedIcon,
    setSelectedIcon,
    insertGuide: (objGuide) => {
      setLoading(true);
      rentalsService
        .insertWelcomeGuide(objGuide)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            let temp = order;
            temp[indexOrder].ordering = [
              res.data.welcome_guide_question_id,
              ...temp[indexOrder].ordering,
            ];
            settingService
              .set({
                welcome_order: temp,
              })
              .then((r) => {
                if (r.data) {
                  setSuccess(true);
                } else {
                  setSuccess(false);
                }
              });
          } else {
            setSuccess(false);
          }
        })
        .catch((err) => console.log(err));
    },
    updateGuide: async (id, objGuide) => {
      setLoading(true);
      let res = await rentalsService.updateWelcomeGuide(id, objGuide);
      setLoading(false);
      if (res.data) {
        setGuide({ ...guide, ...res.data });
        setSuccess(true);
        return res.data;
      }
    },
    createHomeGuide: async (objGuide) => {
      setLoading(true);
      let res = await rentalsService.createHomeGuide(objGuide);
      setLoading(false);
      if (res.data) {
        setGuide({ ...guide, ...res.data });
        setSuccess(true);
        return res.data;
      }
    },
    deleteGuide: async (id) => {
      let res = await rentalsService.deleteWelcomeGuide(id);
      if (res.data) {
        let temp = order;
        let ordering = temp[indexOrder].ordering;
        ordering.splice(
          ordering.findIndex(
            (item) => item == res.data.welcome_guide_question_id,
          ),
          1,
        );
        temp[indexOrder].ordering = ordering;
        let result = await settingService.set({ welcome_order: temp });
        if (result.data) {
          return Promise.resolve(result.data);
        }
      } else {
        return null;
      }
    },
    updateOrder: async (ordering) => {
      let result = await settingService.set({ welcome_order: ordering });
      return result;
    },
    insertTab: async (tab) => {
      let temp = order;
      temp.push({ account_id: temp[0].account_id, ordering: [], type: tab });
      setLoading(true);
      let result = await settingService.set({ welcome_order: temp });
      setLoading(false);
      if (result.data) {
        setSuccess1(true);
      }
    },
    updateTab: async (tab) => {
      let temp = order;
      temp[indexOrder].type = tab;
      setLoading(true);
      let result = await settingService.set({ welcome_order: temp });
      setLoading(false);
      if (result.data) {
        setSuccess1(true);
      }
    },
    deleteTab: async (index) => {
      let temp = order;
      temp.splice(index, 1);
      let result = await settingService.set({ welcome_order: temp });
      if (result.data) {
        return Promise.resolve(result.data);
      }
    },
  };

  return (
    <MasterGuideContext.Provider value={contextMasterGuide}>
      {children}
    </MasterGuideContext.Provider>
  );
};
