// Import package components
import { useEffect, useState, useMemo, useContext } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { AppContexts } from "../../providers";
import Card from "./card";
import AddTextLine from "../common/AddTextLine";

const ExtendGuestStay = () => {
  const { searchReservation, listReservations, flagIsProcessing } = useContext(
    AppContexts.ReservationsContext,
  );

  const { rentals, get: getRentals } = useContext(AppContexts.RentalsContext);
  const settingsCtx = useContext(AppContexts.SettingsContext);

  const [filters, setFilters] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    min_gap: -1,
    max_gap: 99999,
    extend_stay: "check_out",
    date_range: 7,
    property_id: "",
    tag: "",
  });

  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState([]);

  // To leave the legacy variables in place
  let {
    start_date: startDate,
    min_gap: minGap,
    max_gap: maxGap,
    extend_stay: extendStay,
    date_range: dateRange,
    property_id: propertyId,
    tag: tag,
  } = filters;

  useEffect(() => {
    let criteria = {
      search: "",
      filters: "owners,blocks,standard",
      preset: "staying-between",
      start_date: moment(startDate, "YYYY-MM-DD").add(-1, "days"),
      end_date: moment(startDate, "YYYY-MM-DD").add(14, "days"),
      end_type: "check_in",
      categories: ["OWN", "BLK", "STA", ""],
      tag: "",
      page: 0,
      per_page: -1,
      checked_in: false,
      check_out: false,
    };

    Promise.all([
      getRentals(),
      searchReservation(criteria),
      settingsCtx.get(["tags"]),
    ]).then(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(async () => {
    if (startDate) {
      let criteria = {
        search: "",
        filters: "owners,blocks,standard",
        preset: "staying-between",
        start_date: moment(startDate, "YYYY-MM-DD").add(-1, "days"),
        end_date: moment(startDate, "YYYY-MM-DD").add(14, "days"),
        end_type: "check_in",
        categories: ["OWN", "BLK", "STA", ""],
        page: 0,
        per_page: -1,
        checked_in: false,
        check_out: false,
        tag: "",
      };
      await searchReservation(criteria);
    }
  }, [startDate]);

  const getClassName = (reservation) => {
    const classes = {
      OWN: "owner",
      BLK: "block",
      STA: "guest",
    };

    let classList = [];
    if (selected.includes(reservation.guest_reservation_id)) {
      classList.push("selected");
    }

    classList.push(classes[reservation.category] || "guest");

    return classList.join(" ");
  };

  const hasFlag = (reservation, flag) => {
    return (reservation.flags || []).find((f) => f.name === flag);
  };

  const hasRes = (rental) => {
    if (dateRange === "") {
      return true;
    }

    var start = moment(startDate).startOf("day"),
      targetDate = start.clone().add(dateRange, "days");

    var matching = listReservations
      .filter((res) => {
        return res.pms_id === rental.pms_id;
      })
      .filter((res) => {
        if (extendStay === "check_in") {
          return moment.utc(res.check_in).isSame(targetDate, "day");
        } else {
          return moment.utc(res.check_out).isSame(targetDate, "day");
        }
      });

    return matching.length > 0;
  };

  const hasTargetGap = (rental) => {
    let start = moment(startDate).startOf("day");
    let end = moment(startDate).add(14, "days").endOf("day");
    let targetDate = start.add(dateRange, "days");

    let sorted = listReservations
      .filter((res) => {
        return res.pms_id === rental.pms_id;
      })
      .sort((a, b) => {
        return moment.utc(a.check_in).diff(moment.utc(b.check_in));
      });

    // Get the reservation whose check in/out lands on the selected day
    let targetRes = sorted.filter((res) => {
      return extendStay === "check_out"
        ? moment.utc(res.check_out).isSame(targetDate, "day")
        : moment.utc(res.check_in).isSame(targetDate, "day");
    })[0];

    if (!targetRes) {
      return false;
    }

    // Get the previous or next reservation depending on the direction
    let targetIdx = sorted.indexOf(targetRes);
    let neighbor =
      extendStay === "check_out"
        ? sorted[targetIdx + 1] || null
        : sorted[targetIdx - 1] || null;

    // Get the check in/out to compare
    let checkOut = moment.utc(targetRes.check_out).startOf("day");
    let checkIn = moment.utc(targetRes.check_in).startOf("day");

    // Based on direction, check if either the neighbor (if it exists)
    // or the respective start or end dates are bigger than the gap
    return extendStay === "check_out"
      ? neighbor === null
        ? end.diff(checkOut, "days") > minGap &&
          end.diff(checkOut, "days") <= maxGap
        : moment.utc(neighbor.check_in).startOf("day").diff(checkOut, "days") >
            minGap &&
          moment.utc(neighbor.check_in).startOf("day").diff(checkOut, "days") <=
            maxGap
      : neighbor === null
      ? checkIn.diff(start, "days") > minGap &&
        checkIn.diff(start, "days") <= maxGap
      : checkIn.diff(moment.utc(neighbor.check_out).startOf("day"), "days") >
          minGap &&
        checkIn.diff(moment.utc(neighbor.check_out).startOf("day"), "days") <=
          maxGap;
  };

  const hasGap = (rental) => {
    if (dateRange !== "") {
      return hasTargetGap(rental);
    }

    let start = moment(startDate).startOf("day");
    let end = moment(startDate).add(14, "days").endOf("day");

    var sorted = listReservations
      .filter((res) => {
        return res.pms_id === rental.pms_id;
      })
      .sort((a, b) => {
        return moment(a.check_in).diff(moment(b.check_in));
      });

    if (sorted.length === 0) {
      return false;
    }

    var gapped = false,
      diff = -1;

    for (var i = 1; i < sorted.length; i++) {
      var checkOut = moment.utc(sorted[i].check_out).startOf("day"),
        checkIn = moment.utc(sorted[i].check_in).startOf("day");

      diff = checkIn.diff(checkOut, "days");
      if (diff > minGap && diff <= maxGap) {
        gapped = true;
        break;
      }
    }

    var hasGap =
      gapped ||
      (moment.utc(sorted[0].check_in).startOf("day").diff(start, "days") >
        minGap &&
        moment.utc(sorted[0].check_in).startOf("day").diff(start, "days") <=
          maxGap) ||
      (end.diff(
        moment.utc(sorted[sorted.length - 1].check_out).startOf("day"),
        "days",
      ) > minGap &&
        end.diff(
          moment.utc(sorted[sorted.length - 1].check_out).startOf("day"),
          "days",
        ) <= maxGap);

    return hasGap;
  };

  const filteredRentals = useMemo(() => {
    if (!loaded) {
      return [];
    } else {
      return rentals
        .sort((prev, next) => prev.pms_id.localeCompare(next.pms_id))
        .filter((r) => {
          if (propertyId !== "") {
            return r.pms_id.toLowerCase().startsWith(propertyId);
          }

          if (tag !== "") {
            return (r.tags || []).findIndex((t) => t.id == tag) > -1;
          }

          if (!hasGap(r)) {
            return false;
          }

          if (!hasRes(r)) {
            return false;
          }

          return true;
        });
    }
  }, [filters, loaded, rentals]);

  const tags = useMemo(() => settingsCtx.settings.tags || [], [
    settingsCtx.settings,
  ]);

  const handleFilter = (key, e) => {
    let { max_gap, min_gap } = filters;
    let { value } = e.target;

    if (key === "min_gap" && value > max_gap) {
      return;
    }

    if (key === "max_gap" && value < min_gap) {
      return;
    }

    setFilters({ ...filters, [key]: e.target.value });
  };

  const handleTarget = (id) => {
    setSelected((prev) => {
      let temp = prev.findIndex((item) => item == id);
      if (temp >= 0) {
        return [...prev.slice(0, temp), ...prev.slice(temp + 1)];
      } else {
        return [...prev, id];
      }
    });
  };

  const renderer = (rental, index) => {
    let reservations = listReservations.filter(
      (r) => r.pms_id === rental.pms_id,
    );

    return (
      <ul
        key={`extend-stay-list-${rental.rental_id}`}
        className="extend-stays-outer-grid uk-width-1-1"
      >
        <li className="extend-stays-property-id">{rental.pms_id}</li>
        <li>
          <ul className="extend-stays-inner-grid">
            {[...new Array(15)].map((_, index) => (
              <li
                key={`${rental.rental_id}-${index}`}
                className={index === dateRange + 1 ? "extend-stay-target" : ""}
              ></li>
            ))}

            {reservations.map((reservation) => {
              let width = (1 / 15) * 100;
              let left = moment
                .utc(reservation.check_in)
                .startOf("day")
                .diff(
                  moment.utc(startDate).add(-1, "days").startOf("day"),
                  "days",
                );
              let right = moment
                .utc(reservation.check_out)
                .startOf("day")
                .diff(
                  moment.utc(startDate).add(-1, "days").startOf("day"),
                  "days",
                );

              return (
                <li
                  key={`${rental.rental_id}-${reservation.guest_reservation_id}`}
                  className={getClassName(reservation)}
                  style={{
                    left: `${(left + 0.6) * width}%`,
                    right: `${100 - (right + 0.4) * width}%`,
                  }}
                  onClick={() => handleTarget(reservation.guest_reservation_id)}
                  uk-tooltip={`
                      title: <div class='hms-extend-card'><div class='uk-grid uk-padding-small'><div class='uk-width-1-1@s uk-margin-tiny-bottom'><label class='uk-form-label'>NAME</span><div>${
                        reservation.name
                      }</div></div><div class='uk-width-1-1@s uk-margin-top uk-margin-tiny-bottom'><label class='uk-form-label'>CHECK-IN/CHECK-OUT</label><div>${moment
                    .utc(reservation.check_in)
                    .format("MM/DD/YYYY")} - ${moment
                    .utc(reservation.check_out)
                    .format(
                      "MM/DD/YYYY",
                    )}</div></div><div class='uk-width-1-1@s uk-margin-top'><label class='uk-form-label'>PHONE</label><div>${
                    reservation.phone
                  } </div></div><div class='uk-width-1-1@s uk-margin-top'><label class='uk-form-label'>EMAIL</label><div> ${
                    reservation.email
                  }</div></div></div></div>;
                      container: #hms-extendStays;
                      delay: 300;`}
                >
                  {hasFlag(reservation, "check_in_extension_sent") && (
                    <div className="check_in_extension_sent"></div>
                  )}
                  {hasFlag(reservation, "check_out_extension_sent") && (
                    <div className="check_out_extension_sent"></div>
                  )}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    );
  };

  return (
    <>
      <section id="hms-extendStays">
        <AddTextLine feature="Extend Guest Stay" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Extend Guest Stay</h1>
        </div>

        <div id="hms-main-body">
          <div className="uk-flex">
            <div className="uk-width-2-3">
              <div className="uk-card uk-card-small uk-card-default hms-form-card">
                <div className="uk-overflow-auto">
                  <form className="uk-form uk-flex uk-flex-middle extend-stay-filters hms-form">
                    <div className="uk-inline">
                      <label className="uk-form-label" htmlFor="stayStartDate">
                        Start Date
                      </label>
                      <div className="uk-form-controls hms-date-picker">
                        <input
                          type="date"
                          id="stayStartDate"
                          value={filters.start_date}
                          onInput={(e) => handleFilter("start_date", e)}
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="uk-inline rbr-min-gap">
                      <label className="uk-form-label">Min Gap</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={filters.min_gap}
                            onInput={(e) => handleFilter("min_gap", e)}
                          >
                            <option value={-1}>Show All</option>
                            {[...new Array(6)].map((_, index) => (
                              <option value={`${index}`} key={`gap_${index}`}>
                                {index + 1}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline rbr-max-gap">
                      <label className="uk-form-label">Max Gap</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={filters.max_gap}
                            onInput={(e) => handleFilter("max_gap", e)}
                          >
                            <option value={99999}>Show All</option>
                            {[...new Array(6)].map((_, index) => (
                              <option value={`${index}`} key={`gap_${index}`}>
                                {index + 1}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline extend-stay-dropdown">
                      <label className="uk-form-label">Extend Stay</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={filters.extend_stay}
                            onInput={(e) => handleFilter("extend_stay", e)}
                          >
                            <option value="check_in">Early Check-In</option>
                            <option value="check_out">Late Check-Out</option>
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span>
                              {extendStay === "check_in"
                                ? "Early Check-In"
                                : extendStay === "check_out"
                                ? "Late Check-Out"
                                : ""}
                            </span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <label className="uk-form-label">
                        {extendStay == "check_in" ? "Arriving" : "Departing"} In
                      </label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={filters.date_range}
                            onInput={(e) => handleFilter("date_range", e)}
                          >
                            <option value="">Any Day</option>
                            {[...new Array(8)].map((_, index) => (
                              <option value={index} key={`date_${index}`}>
                                {`${index} Days - ${moment(startDate)
                                  .add(index, "days")
                                  .format("ddd")}, ${moment(startDate)
                                  .add(index, "days")
                                  .format("MM/DD")}`}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span>
                              {dateRange == ""
                                ? "Any Day"
                                : `${dateRange} Days - ${moment(startDate)
                                    .add(dateRange, "days")
                                    .format("ddd")}, ${moment(startDate)
                                    .add(dateRange, "days")
                                    .format("MM/DD")}`}
                            </span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <label className="uk-form-label">Tag</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={filters.tag}
                            onInput={(e) => handleFilter("tag", e)}
                          >
                            <option value="">All Tags</option>
                            {tags.map((t, idx) => (
                              <option value={t.id} key={`tag_${idx}`}>
                                {t.name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span>{tag == "" ? "All Tags" : tag}</span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <label className="uk-form-label">Property ID</label>
                      <div className="uk-form-controls">
                        <div className="uk-search uk-search-default uk-form-controls">
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          ></span>
                          <input
                            className="uk-search-input"
                            type="search"
                            value={filters.property_id}
                            onInput={(e) => handleFilter("property_id", e)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div>Select guests by clicking on the blue lines.</div>
                  <div className="extend-stays-grid">
                    <ul className="extend-stays-outer-grid extend-stays-grid-header uk-width-1-1">
                      <li className="extend-stays-property-id">Property ID</li>
                      <li>
                        <ul className="extend-stays-inner-grid">
                          {[...new Array(15)].map((_, index) => (
                            <li
                              key={`header-${index}`}
                              className={
                                index === dateRange + 1
                                  ? "extend-stay-target"
                                  : ""
                              }
                            >
                              <div>
                                {moment(startDate)
                                  .add(index - 1, "days")
                                  .format("MM/DD")}
                              </div>
                              <div>
                                {moment(startDate)
                                  .add(index - 1, "days")
                                  .format("ddd")}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>

                    {!loaded ? (
                      <div className="extend-stays-grid-empty uk-padding uk-width-1-1 uk-text-center">
                        <div data-uk-spinner="ratio: 2;"></div>
                      </div>
                    ) : filteredRentals.length === 0 ? (
                      <div className="extend-stays-grid-empty uk-padding uk-width-1-1 uk-text-center">
                        <div>
                          No bookings found. Please change the filters and try
                          again.
                        </div>
                      </div>
                    ) : (
                      filteredRentals.map(renderer)
                    )}
                  </div>
                </div>
              </div>

              <div className="uk-margin-top uk-flex uk-flex-middle hms-extendStay-user-colors">
                <div>Guest</div>
                <div>Owner</div>
                <div>Block</div>
              </div>
              <div className="uk-width-1-1 uk-margin-top footnote">
                Please cross-check the accuracy of owner reservations and
                maintenance blocks on the calendar above in your rental
                software.
              </div>
            </div>

            <div className="uk-width-1-3">
              <Card selectedIds={selected} setSelected={setSelected} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExtendGuestStay;
