// Import package components
import { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Image, InputContainer, Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";
import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import { AppContexts } from "../../../providers";
import RecordsPerPage from "../../common/RecordsPerPage";

const GuestBookView = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { guestbook } = ReportsContext;

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  useEffect(() => {
    if (!ReportsContext.startDate || !ReportsContext.endDate) {
      return;
    } else {
      ReportsContext.getGuestbook(
        ReportsContext.startDate,
        ReportsContext.endDate,
        page,
        filter ? filter.trim() : filter,
      );
    }
  }, [ReportsContext.startDate, ReportsContext.endDate, filter, page]);

  const pagedGuestbook = useMemo(
    () =>
      guestbook
        ?.sort((prev, next) => next.viewed_at.localeCompare(prev.viewed_at))
        .slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [guestbook, page, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Guestbook Views</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Guest Name Or Property ID"
          onFilterChange={handleFilterChange}
        />
        <div className="secondary">{guestbook?.length} GuestBook Views</div>
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>FIRST VIEWED AT</th>
              <th>GUEST NAME</th>
              <th>CHECK-IN DATE</th>
              <th>PROPERTY ID</th>
              <th>HOME GUIDE</th>
            </tr>
          </thead>
          <tbody>
            {pagedGuestbook?.length ? (
              pagedGuestbook?.map((item) => (
                <tr>
                  <td>
                    {moment(item.viewed_at).format("MM/DD/YYYY hh:mm A")}
                  </td>
                  <td>
                    {item.guest_first_name} {item.guest_last_name}
                  </td>
                  <td>
                    {moment(item.guest_check_in).utc().format("MM/DD/YYYY")}
                  </td>
                  <td className="reports-table-wrap-overflow">
                    {item.property_pms_id}
                  </td>
                  <td>
                    <a href={`rental/${item.property_id}`}>
                      {item.property_name}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={guestbook?.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default GuestBookView;
