import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { Flex } from "uikit-react";
import moment from "moment";
import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import { AppContexts } from "../../../providers";

const FiveStarRating = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { fivestar, startDate, endDate, getFiveStar } = useContext(
    AppContexts.ReportsContext,
  );

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    } else {
      getFiveStar(startDate, endDate, page, filter ? filter.trim() : filter);
    }
  }, [startDate, endDate, filter, page]);

  // const filteredFivestar = useMemo(() => {
  //   let items = fivestar?.reduce((acc, item) => {
  //     const key = `${item.guest_reservation_id}-${item.guest_name}-${item.pms_id}`;
  //     if (!acc[key]) {
  //       acc[key] = [];
  //     }
  //     acc[key].push(item);
  //     return acc;
  //   }, {});
  //   return Object.keys(items)
  //     .map((key) => items[key][0])
  //     ?.sort((prev, next) => next.rated_on.localeCompare(prev.rated_on));
  // }, [fivestar]);

  const pagedFivestar = useMemo(
    () =>
      fivestar
        ?.sort((prev, next) => next.rated_on.localeCompare(prev.rated_on))
        .slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [fivestar, page, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Five Star Ratings</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Guest Name Or Property ID"
          onFilterChange={handleFilterChange}
        />
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>RATED ON</th>
              <th>GUEST NAME</th>
              <th>CHECK-IN DATE</th>
              <th>PROPERTY ID</th>
              <th>RATING</th>
            </tr>
          </thead>
          <tbody>
            {pagedFivestar?.length ? (
              pagedFivestar?.map((item) => (
                <tr>
                  <td>
                    {moment.utc(item.rated_on).format("MM/DD/YYYY hh:mm A")}
                  </td>
                  <td>{item.guest_name}</td>
                  <td>{moment.utc(item.check_in).format("MM/DD/YYYY")}</td>
                  <td className="reports-table-wrap-overflow">{item.pms_id}</td>
                  <td>
                    <div className="uk-flex uk-flex-between uk-flex-top">
                      <div className="hms-guest-review">
                        {[...Array(Math.round(item.response))].map(
                          (value, idx) => (
                            <span
                              key={`rating_${idx}`}
                              uk-icon="icon: star; ratio: 0.8"
                              className="hms-rated-star"
                            ></span>
                          ),
                        )}
                        {[...Array(5 - Math.round(item.response))].map(
                          (value, idx) => (
                            <span
                              key={`non-rating_${idx}`}
                              uk-icon="icon: star; ratio: 0.8"
                            ></span>
                          ),
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={fivestar?.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default FiveStarRating;
