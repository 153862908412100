import QRCode from "qrcode.react";

const QRPart = ({ value, guide }) => {
  const downloadQR = () => {
    const canvas = document.getElementById(value);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${value}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <button
        className="uk-button"
        type="button"
        uk-tooltip="Download QR Code"
        onClick={downloadQR}
      >
        <img src="/images/qr-code.svg" />
        <QRCode
          hidden={true}
          id={value}
          bgColor="white"
          value={`https://guide.ruebarue.com/${guide}/${value}`}
          title="QRcodeForHome"
          style={{ border: "6px solid white" }}
          level={"H"}
          includeMargin={true}
        />
      </button>
    </>
  );
};

export default QRPart;
