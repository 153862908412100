/* eslint-disable react-hooks/exhaustive-deps */

// Import package components
import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

const Dialog = (props) => {
  const elementDialog = useMemo(() => {
    const element = document.createElement("div");
    element.setAttribute("id", props.id);
    element.setAttribute("data-uk-modal", "");
    return element;
  }, []);

  useEffect(() => {
    const listClassNames = ["portal-container"];
    if (props.className)
      props.className
        .split(" ")
        .forEach((indexClassName) => listClassNames.push(indexClassName));
    listClassNames.forEach((indexClassName) =>
      elementDialog.classList.add(indexClassName),
    );

    const target =
      props.parent && props.parent.appendChild ? props.parent : document.body;
    target.appendChild(elementDialog);
    return () => target.removeChild(elementDialog);
  }, [elementDialog, props.parent, props.className]);

  return ReactDOM.createPortal(props.children, elementDialog);
};

export default Dialog;
