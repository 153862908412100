import { useContext, useState, useEffect } from "react";
import UIkit from "uikit";
import { useForm } from "react-hook-form";

const ImageContent = (props) => {
  let [image, setImage] = useState(null);
  useEffect(() => {
    console.log(typeof props.file);
    if (props.file) {
      if (props.file?.url) {
        setImage(props.file.url);
      } else {
        var oFReader = new FileReader();
        oFReader.readAsDataURL(props.file);
        oFReader.onload = function (oFREvent) {
          setImage(oFREvent.target.result);
        }.bind(this);
      }
    }
  }, [props.file]);

  const removeImage = (idx) => {
    const handler = props.handler || (() => {});
    handler(idx);
  };

  return (
    <div key={props.index}>
      {!!image && (
        <div className="uk-width-1-1">
          <div className="image-upload">
            <div
              className="remove-img"
              onClick={() => removeImage(props.index)}
            >
              <div uk-tooltip="Delete">
                <img src="/images/delete.svg" />
              </div>
            </div>
          </div>
          <div className="uk-placeholder hms-file-uploader">
            <img className="bg-img" src={image} />
          </div>
        </div>
      )}
    </div>
  );
};

const ImagePreview = (props) => {
  const { attachments, handler } = props;

  return (
    <>
      {!!attachments &&
        attachments.map((f, idx) => (
          <ImageContent key={idx} index={idx} file={f} handler={handler} />
        ))}
    </>
  );
};

export default ImagePreview;
