// Import package components
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Image,
  Navbar,
  NavbarContainer,
  NavbarDropdown,
  NavbarSticky,
  NavItem,
  Badge,
} from "uikit-react";
import UIkit from "uikit";
import { Link as RawLink } from "../ui-kit/Link";
import { AppContexts } from "../../providers";

import Notification from "./notification";
import TrialAccount from "../common/TrialAccount";

const Link = ({ onClick, ...props }) => (
  <RawLink
    onClick={() => {
      onClick && onClick();
    }}
    {...props}
  />
);

const Header = (props) => {
  const { user: account, inbox, logout } = useContext(AppContexts.AuthContext);
  const location = useLocation();

  //const history = useHistory();

  const handleLogout = async () => {
    console.log("logging out");

    logout()
      .then(() => {
        console.log("Goodbye");
      })
      .catch((err) => {
        console.warn("Error logging out:", err);
      });
  };

  useEffect(() => {
    window.innerWidth <= 1024 && handleNavbarCollapse();
    window.onresize = () => {
      window.innerWidth <= 1024
        ? handleNavbarCollapse()
        : (document.getElementById("navbar_collapse").hidden = false);
    };
  }, []);

  const handleNavbarCollapse = () => {
    document.getElementById("navbar_collapse").hidden = true;
  };

  const isActive = (paths) => {
    if (!Array.isArray(paths)) {
      paths = [paths];
    }
    return paths.some(path => {
      const regex = new RegExp(`^${path}`);
      return regex.test(location.pathname);
    }) ? 'uk-active' : '';
  };

  return (
    <>
      <NavbarSticky id="ruebarue-navbar">
        <NavbarContainer className="hms-navbar">

          <Link to="/" className={`hms-logo ${isActive('/')}`} id="nav_list">
            <Image src="/images/logo.svg" alt="logo" />
          </Link>
          <a
            id="navbar-collapse-toggler"
            className="uk-hidden@l"
            data-uk-navbar-toggle-icon
            href="#"
            uk-toggle="target: #mobile-menu; animation: uk-animation-fade"
            aria-expanded="false"
          ></a>
          <div
            className="navbar-collapse uk-offcanvas-bar@l"
            id="navbar_collapse"
          >
            {account && (
              <Navbar left>
                <NavItem className={isActive('/messages')}>
                  <Link to="/messages">
                    Messages {/*<span className="message-count">98</span>*/}
                  </Link>
                </NavItem>
                <NavItem className={isActive(['/guests'])}>
                  <Link to="/guests">Guests</Link>
                </NavItem>
                <NavItem className={isActive(['/contacts'])}>
                  <Link to="/contacts">Contacts</Link>
                </NavItem>
                <NavItem className={isActive(['/work-orders'])}>
                  <Link to="/work-orders">Work Orders</Link>
                </NavItem>
                <NavItem className={isActive(['/extend-guest-stay'])}>
                  <Link to="/extend-guest-stay">Extend Stays</Link>
                </NavItem>
                <NavItem className={isActive(['/properties', '/areas', '/master-home-guide', '/subscriptions'])}>
                  <Link to="#">
                    Guestbooks <Image src="/images/nav-dropdown-arrow.svg" />
                  </Link>
                  <NavbarDropdown id="guide-dropdown">
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#guide-dropdown").hide(0);
                        }}
                        to="/properties"
                      >
                        Home Guides
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#guide-dropdown").hide(0);
                        }}
                        to="/areas"
                      >
                        Area Guides
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#guide-dropdown").hide(0);
                        }}
                        to="/master-home-guide"
                      >
                        Master Home Guide
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#guide-dropdown").hide(0);
                        }}
                        to="/subscriptions"
                      >
                        Subscriptions
                      </Link>
                    </NavItem>
                  </NavbarDropdown>
                </NavItem>
                <NavItem className={isActive(['/scheduler', '/alerts', '/surveys', '/saved-responses', '/message-templates'])}>
                  <Link to="#">
                    Automate <Image src="/images/nav-dropdown-arrow.svg" />
                  </Link>
                  <NavbarDropdown id="automate-dropdown">
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#automate-dropdown").hide(0);
                        }}
                        to="/scheduler"
                      >
                        Scheduler
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#automate-dropdown").hide(0);
                        }}
                        to="/alerts"
                      >
                        Alerts
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#automate-dropdown").hide(0);
                        }}
                        to="/surveys"
                      >
                        Surveys
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#automate-dropdown").hide(0);
                        }}
                        to="/saved-responses"
                      >
                        Saved Responses
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#automate-dropdown").hide(0);
                        }}
                        to="/message-templates"
                      >
                        Message Templates
                      </Link>
                    </NavItem>
                  </NavbarDropdown>
                </NavItem>
              </Navbar>
            )}
            {account && (
              <Navbar right>
                <NavItem>
                  <Link
                    to="#"
                    uk-toggle="target: #modal-notifications"
                    uk-tooltip="Notifications"
                    className="hms-notification-badge"
                  >
                    <Image
                      src="/images/announcement.svg"
                      className="margin-zero"
                    />
                    {account.announcements?.length > 0 && (
                      <Badge
                        className="hms-notification-count"
                        count={account.announcements.length}
                      />
                    )}
                  </Link>
                </NavItem>
                <NavItem className={isActive(['/reports/'])}>
                  <Link to="/reports" uk-tooltip="Reports">
                    <Image src="/images/reports.svg" className="margin-zero" />
                  </Link>
                </NavItem>
                <NavItem className={isActive(['/team', '/settings', '/billing'])}>
                  <Link to="#">
                    <Image src="/images/settings.svg" className="margin-zero" />
                  </Link>
                  <NavbarDropdown id="more-dropdown">
                    {!+account?.user?.owner_id && (
                      <NavItem>
                        <Link to="/team">Team</Link>
                      </NavItem>
                    )}
                    <NavItem>
                      <Link to="/settings">Settings</Link>
                    </NavItem>
                    {!+account?.user?.owner_id && (
                      <NavItem>
                        <Link
                          onClick={() => {
                            UIkit.dropdown("#profile-dropdown").hide(0);
                          }}
                          to="/billing"
                        >
                          Billing
                        </Link>
                      </NavItem>
                    )}
                  </NavbarDropdown>
                </NavItem>
                <NavItem className={isActive(['/profile'])}>
                  <Link to="#">
                    <Image src="/images/profile.svg" className="margin-zero" />
                  </Link>
                  <NavbarDropdown id="profile-dropdown">
                    <NavItem>
                      <Link
                        onClick={() => {
                          UIkit.dropdown("#profile-dropdown").hide(0);
                        }}
                        to="/profile"
                      >
                        Profile
                      </Link>
                    </NavItem>
                    <NavItem>
                      <a
                        href="https://knowledge.ruebarue.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Knowledge Base
                      </a>
                    </NavItem>
                    <NavItem>
                      <Link to="#" onClick={handleLogout}>
                        Sign Out
                      </Link>
                    </NavItem>
                  </NavbarDropdown>
                </NavItem>
              </Navbar>
            )}
          </div>
          <div id="mobile-menu" uk-offcanvas="overlay: true">
            <div className="uk-offcanvas-bar">
              <button
                className="uk-offcanvas-close responsive-menu-close"
                type="button"
                uk-close
              >
                <Image src="/images/close-small.svg" alt="logo" />
              </button>
              <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical responsive-outer-menu">
                <li>
                  <a href="/messages">Messages</a>
                </li>
                <li>
                  <a href="/guests">Guests</a>
                </li>
                <li>
                  <a href="/contacts">Contacts</a>
                </li>
                <li>
                  <a href="/work-orders">Work Orders</a>
                </li>
                <li>
                  <a href="/extend-guest-stay">Extend Stays</a>
                </li>
                <li className="uk-nav-header">Guestbooks</li>
                <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical responsive-inner-menu">
                  <li>
                    <a href="/properties">Home Guides</a>
                  </li>
                  <li>
                    <a href="/areas">Area Guides</a>
                  </li>
                  <li>
                    <a href="/master-home-guide">Master Home Guides</a>
                  </li>
                  <li>
                    <a href="/subscriptions">Subscriptions</a>
                  </li>
                </ul>
                <li className="uk-nav-header">Automate</li>
                <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical responsive-inner-menu">
                  <li>
                    <a href="/scheduler">Scheduler</a>
                  </li>
                  <li>
                    <a href="/alerts">Alerts</a>
                  </li>
                  <li>
                    <a href="/surveys">Surveys</a>
                  </li>
                  <li>
                    <a href="/saved-responses">Saved Responses</a>
                  </li>
                  <li>
                    <a href="/message-templates">Message Templates</a>
                  </li>
                </ul>
                <li>
                  <a href="/reports">Reports</a>
                </li>
                <li>
                  <a href="/team">Team</a>
                </li>
                <li>
                  <a href="/settings">Settings</a>
                </li>
                <li>
                  <a href="/billing">Billing</a>
                </li>
                <li>
                  <a href="/profile">Profile</a>
                </li>
                <li>
                  <a href="https://knowledge.ruebarue.com/" target="_blank">
                    Knowledge Base
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleLogout}>
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </NavbarContainer>
      </NavbarSticky>
      <TrialAccount />
      <Notification />
    </>
  );
};

export default Header;
