import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import queryString from "query-string";

//import { useHistory } from "react-router-dom";

import { classes } from "../../../utils/classes";
import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

import Loading from "../../common/Loading";

const LoginForm = (props) => {
  let { join, get_invitation, invitation, loaded } = useContext(
    AppContexts.AuthContext,
  );

  const { handleSubmit } = useForm();

  let [errors, setErrors] = useState([]);
  let [submitted, setSubmitted] = useState(false);
  let [completed, setCompleted] = useState(false);

  let [creds, setCreds] = useState({
    token: queryString.parse(window.location.search)?.token || "",
    password: "",
    password_confirmation: "",
    pword: "",
  });

  let [valid, setValid] = useState({
    minLength: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
    match: false,
  });

  useEffect(() => {
    console.log("Loading?", loaded);
    if (!loaded) {
      get_invitation(queryString.parse(window.location.search)?.token || "");
    }
  }, []);

  useEffect(() => {
    let validation = Object.keys(valid).reduce((a, c) => {
      a[c] = false;
      return a;
    }, {});
    let { password, password_confirmation } = creds;

    if (password === "" && password_confirmation === "") {
      return setValid(validation);
    }

    if (password === password_confirmation) {
      validation.match = true;
    }

    if (password.length >= 8) {
      validation.minLength = true;
    }

    if (/[A-Z]+/g.test(password)) {
      validation.upper = true;
    }

    if (/[a-z]+/g.test(password)) {
      validation.lower = true;
    }

    if (/[0-9]+/g.test(password)) {
      validation.number = true;
    }

    if (/[!@#\$%\^&_]/g.test(password)) {
      validation.special = true;
    }

    setValid(validation);
  }, [creds]);

  const handleJoin = () => {
    if (submitted) {
      return;
    }

    let errs = [];

    Object.keys(creds).map((k) => {
      if (k !== "pword") {
        if (!creds[k]) {
          errs.push(k);
        }
      }
    });

    if (errs.length !== 0) {
      setErrors({ msg: "Please fill out all fields", fields: errs });
      return;
    }

    join(creds)
      .then((resp, err) => {
        window.location.assign("/");
      })
      .catch((err) => {
        let { error, fields } = err;
        setErrors({ msg: error, fields: fields });
      });
  };

  const update = (field, e) => {
    setCreds({ ...creds, [field]: e.target.value });

    if ((errors?.fields || []).includes(field)) {
      setErrors((errors?.fields || []).filter((k) => k !== field));
    }
  };

  return (
    <div className="page-box sign-in auth">
      <h2 className="uk-heading-small uk-margin-small-bottom">Signup for RueBaRue</h2>
      <div>
        {!loaded ? (
          Loading()
        ) : (
          <form className="uk-form-stacked" onSubmit={handleSubmit(handleJoin)}>
            <div>
              Sign up to to automate guest communication and drive more revenue. Get started for free. No credit card required.

              <br/><br/>
              Does your company already have an account? Ask your RueBaRue admin to invite you from the team page. 
            </div>
            <div className="uk-margin uk-flex uk-flex-middle">
              <div
                className={classes(
                  { error: (errors?.fields || []).includes("first_name") },
                  "uk-width-1-2",
                )}
              >
                <label className="uk-form-label">First Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    disabled={true}
                    value={invitation?.recipient?.first_name}
                  />
                </div>
              </div>
              <div
                className={classes(
                  { error: (errors?.fields || []).includes("last_name") },
                  "uk-width-1-2",
                  "uk-margin-left",
                )}
              >
                <label className="uk-form-label">Last Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    disabled={true}
                    value={invitation?.recipient?.last_name}
                  />
                </div>
              </div>
            </div>

            {/*
            <div
              className={classes(
                { error: (errors?.fields || []).includes("email") },
                "uk-margin",
              )}
            >
              <label className="uk-form-label">Email</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  disabled={true}
                  value={invitation?.recipient?.email}
                />
              </div>
            </div>
            */}

            <div
              className={classes(
                { error: (errors?.fields || []).includes("password") },
                "uk-margin",
              )}
            >
              <label className="uk-form-label">Password</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="password"
                  value={creds.password}
                  onInput={(e) => {
                    update("password", e);
                  }}
                />
              </div>
            </div>

            <div
              className={classes(
                {
                  error: (errors?.fields || []).includes(
                    "password_confirmation",
                  ),
                },
                "uk-margin",
              )}
            >
              <label className="uk-form-label">Verify Password</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  type="password"
                  value={creds.password_confirmation}
                  onInput={(e) => {
                    update("password_confirmation", e);
                  }}
                />
              </div>
            </div>

            <div className="uk-hidden">
              <input
                className="uk-input"
                value={creds.pword}
                onInput={(e) => {
                  update("pword", e);
                }}
              />
            </div>

            <button
              className={classes(
                { disabled: submitted },
                "uk-button uk-button-# hms-btn hms-red-btn",
              )}
            >
              Sign Up
            </button>

            <div className="uk-margin">
              Password Rules:
              <ul className="password-validation">
                <li className={classes({ complete: valid.minLength })}>
                  At least 8 characters
                </li>
                <li className={classes({ complete: valid.upper })}>
                  At least one capital letter
                </li>
                <li className={classes({ complete: valid.lower })}>
                  At least one lowercase letter
                </li>
                <li className={classes({ complete: valid.number })}>
                  At least one number
                </li>
                <li className={classes({ complete: valid.special })}>
                  At least one of the following !@#$%^&_
                </li>
                <li className={classes({ complete: valid.match })}>
                  Passwords must match
                </li>
              </ul>
            </div>

            <div className="uk-margin">
              Already have an account? <Link to="login">Login</Link>
            </div>
            <div className="uk-margin">
              By signing up, you agree to our{" "}
              <a href="https://www.ruebarue.com/privacy" target="_blank">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://www.ruebarue.com/terms" target="_blank">
                Terms of Use
              </a>
              .
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
