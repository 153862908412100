import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";

//import { useHistory } from "react-router-dom";

import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

const ResetForm = (props) => {
  const { register, handleSubmit } = useForm();

  let authCtx = useContext(AppContexts.AuthContext);

  let [hasReset, setHasReset] = useState(false);
  let [errors, setErrors] = useState(false);

  useEffect(() => {
    document.title = "Reset Password - RueBaRue";
  });

  const reset = (data) => {
    authCtx
      .reset_password(data.email)
      .then((resp, err) => {
        console.log("RESET:", resp);
        setHasReset(true);
        UIkit.notification(
          "Email has been sent to with a link to change your password",
          "success",
        );
      })
      .catch((err) => {
        UIkit.notification(`Failed: ${err}`, "error");
        console.warn(err);
      });
  };

  return (
    <div className="page-box sign-in">
      <h2 className="uk-heading-small uk-margin-small-bottom">Reset Password</h2>
      <div>
        <form className="uk-form-stacked" onSubmit={handleSubmit(reset)}>
          <div className="uk-margin">
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                {...register("email", { required: true })}
              />
            </div>
          </div>

          {!hasReset ? (
            <button className="uk-button uk-button-# hms-btn hms-red-btn">
              Reset Password
            </button>
          ) : (
            <p>
              Thank you, and email has been sent to with a link to change your
              password
            </p>
          )}

          <div className="uk-margin">
            Need an account? <Link to="signup">Sign Up</Link>
          </div>
          <div className="uk-margin">
            Return to <Link to="login">Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetForm;
