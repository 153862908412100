// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
// import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../../common/Dialog";

const PreviewPopup = (props) => {
  const { title, intro } = props;

  return (
    <>
      <Dialog id="newsletter" className="uk-flex-top">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical email-capture">
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
          <h2 className="uk-modal-title uk-text-center">{title}</h2>
          <p className="uk-text-center">{intro}</p>
          <div className="uk-margin uk-text-center">
            <input className="uk-input preview-input" type="email" placeholder="Email" />
          </div>
          <div className="uk-text-center">
            <button className="uk-button uk-button-primary preview-btn" type="button">SIGN UP</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PreviewPopup;