const MainBody = (props) => {
  return (
    <>
      <section id={props.id}>
        <div id="hms-page-title">
          <h1 className="uk-heading-small">{props.title}</h1>
        </div>
        <div id="hms-main-body">{props.children}</div>
      </section>
    </>
  );
};

export default MainBody;
