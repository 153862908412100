import React, { createContext, useState } from "react";

import transactionalService from "../services/transactional.service";

export const TransactionalContext = createContext();
export const TransactionalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(null);

  const extensions = async (objExtend) => {
    setLoading(true);
    let result = await transactionalService.extensions(objExtend);
    setLoading(false);
    if (result.data) {
      setIsSuccessful(true);
    } else {
      setIsSuccessful(false);
    }
    return result.data;
  };
  const transactionalCtx = {
    isSuccessful,
    setIsSuccessful,
    loading,
    extensions,
  };
  return (
    <TransactionalContext.Provider
      value={{ transactionalCtx: transactionalCtx }}
    >
      {children}
    </TransactionalContext.Provider>
  );
};
