import { useState, useCallback, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import UIkit from "uikit";
import GoogleAutoComplete from "../../common/GoogleAutoComplete";
import { AppContexts } from "../../../providers";
import classnames from "classnames";

const DestinationAdd = () => {
  const UtilContext = useContext(AppContexts.UtilContext);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const [placeId, setPlaceId] = useState(null);
  const [isValidate, setValidate] = useState(true);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const history = useHistory();

  const handleCreateDestination = async (e) => {
    e.preventDefault();
    placeId ? setValidate(true) : setValidate(false);
    try {
      let place = await UtilContext.place(placeId);
      let newDestination = {
        google_place_id: place.place_id,
        address: place.formatted_address,
        name: place.name + " Area Guide",
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      };
      let createdDestination =
        await DestinationContext.createDestination(newDestination);
      UIkit.modal("#addDestinationGuide-modal").hide();
      history.push(`areas_detail/${createdDestination.destination_id}`);
      DestinationContext.get();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      id="addDestinationGuide-modal"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="overlay: true; flip: true;"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Add an Area Guide</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleCreateDestination}
              >
                <div className="uk-width-1-1">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      City, Town or Address*
                    </label>
                    <div className="uk-search uk-search-default w-100">
                      <GoogleAutoComplete
                        className={classnames({ error: !isValidate })}
                        width="100%"
                        onSelect={(_, value) => setPlaceId(value.text)}
                        onChange={(e) => setAutoCompleteValue(e)}
                        address={autoCompleteValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="hms-small-itelic">
                  Begin typing and select a suggested address.
                </div>
                <div className="uk-width-1-1">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    Continue
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationAdd;
