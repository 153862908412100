// Import package components
import { useContext, useMemo } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const GuestDelete = ({ targetReservation, criteria }) => {
  // const contextAlert = useContext(AppContexts.contextAlerts);
  const reservationContext = useContext(AppContexts.ReservationsContext);

  const isMultiple = useMemo(() => {
    return Array.isArray(targetReservation);
  }, [targetReservation]);

  const handleDelete = async () => {
    isMultiple
      ? await reservationContext.deleteReservations(targetReservation)
      : await reservationContext.deleteReservation(targetReservation);

    await reservationContext.searchReservation(criteria);
    UIkit.modal("#guest-delete").hide();
  };

  return (
    <>
      <Dialog id="guest-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">
            Delete {!isMultiple && "a "}Guest{isMultiple && "s"}
          </h2>

          <p>
            Are you sure you want to delete{" "}
            <b>
              {!isMultiple &&
                `${targetReservation.first_name} ${targetReservation.last_name}'s `}
            </b>
            ?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              {reservationContext.flagIsProcessing == true ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : (
                "Delete"
              )}
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default GuestDelete;
