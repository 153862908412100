// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const AlertDelete = () => {
  const contextAlert = useContext(AppContexts.contextAlerts);
  const { objAlert, deleteAlert } = contextAlert;

  const handleDelete = async () => {
    await deleteAlert(objAlert);
    UIkit.modal("#alert-delete").hide();
  };

  return (
    <>
      <Dialog id="alert-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Alert</h2>
          <p>
            Are you sure you want to delete <b>{objAlert.name}</b>?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              {contextAlert.flagIsProcessing === true ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : (
                "Delete"
              )}
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default AlertDelete;
