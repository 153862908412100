import { useContext, useEffect } from "react";
import { AppContexts } from "../../providers";

const Footer = (props) => {
  const { user: account, inbox, logout } = useContext(AppContexts.AuthContext);

  return (
    <>
      <section id="hms-footer">
        {account && <span>Signed in as: {account?.user?.email || ""}</span>}
        {inbox && (
          <span>
            SMS Number:{" "}
            {inbox?.phone || (
              <span>
                Contact{" "}
                <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a> to
                add a text line
              </span>
            )}
          </span>
        )}
      </section>
    </>
  );
};

export default Footer;
