import { useEffect } from "react";
import { Image } from "uikit-react";
import moment from "moment";

const WorkOrderComment = (props) => {
  const { order } = props;

  return (
    <div className="wo-model-comment-section">
      <div className="wo-model-comment-inner">
        <h4 style={{ textTransform: "capitalize" }}>Comments</h4>
        {/* <div className="uk-margin">
          <button className="uk-button wo-add-comment-btn">
            <span uk-icon="icon: plus-circle; ratio: 1.2"></span> Add a comment
          </button>
        </div> */}
        <div className="wo-model-comment-wrapper">
          {order?.work_orders_updates &&
            order?.work_orders_updates.map((update) => (
              <div
                className="wo-model-comment-card wo-model-replywith-comment"
                key={`work_order_comment_${update.id}`}
              >
                <div className="wo-model-comment-content">
                  <div className="uk-form-label">
                    {`${update.updater?.first_name} ${update.updater?.last_name}`}
                  </div>
                  <div className="wo-comment-date">{`${moment
                    .utc(update.created_on)
                    .local()
                    .format("MM/DD/YYYY, hh:mm A")}`}</div>
                  <div>{update.description}</div>
                  {update.attachments && Array.isArray(update.attachments) && (
                    <div className="wo-comment-attachment">
                      {update.attachments.map((attachment) => (
                        <Image src={attachment.url} alt={attachment.name} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          {/* <div className="wo-model-comment-card">
            <div className="wo-model-comment-content">
              <div className="wo-comment-name">Jack O’Malley</div>
              <div className="wo-comment-date">2 days ago</div>
              <div className="wo-comment-desc">
                It may be fixable, but requires a replacement piece that would
                take at least a week to arrive.
              </div>
            </div>
          </div>
          <div className="wo-model-comment-card wo-model-replywith-comment">
            <div className="wo-model-comment-content">
              <div className="wo-comment-name">Lisa Swanson</div>
              <div className="wo-comment-date">2 days ago</div>
              <div className="wo-comment-desc">
                Lis and I agree- go ahead and buy a new one and drop it off
                today.
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WorkOrderComment;
