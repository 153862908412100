// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../../providers";

// Import common components
import Dialog from "../../common/Dialog";

const MasterTabDelete = ({ onDelete }) => {
  const { tabIndex } = useContext(AppContexts.MasterGuideContext);

  return (
    <>
      <Dialog id="master-tab-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Tab</h2>
          <p>
            Are you sure you want to delete?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={() => onDelete(tabIndex)}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default MasterTabDelete;
