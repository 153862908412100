import React, { useContext, useEffect, useState } from "react";
import { Image } from "uikit-react";

import moment from "moment";

import { host } from "../../../http";
import { AppContexts } from "../../../providers";

import DragHandle from "../common/handle";
import RecommendationDelete from "../common/delete";
import Markdown from "../../common/Markdown";
import { Link } from "../../../ui";

const Recommendation = ({ rec, orderNo, tabId }) => {
  const [isExtendableTip, setIsExtendalbleTip] = useState(false);
  const [tip, setTip] = useState(rec?.tip || "");

  const {
    setDeletedRecommendation,
    getSelectedDestination,
    updateRecommendation,
    setIsNew,
    setDestinationId,
    setTabId,
    setSection,
  } = useContext(AppContexts.DestinationContext);

  useEffect(() => {
    setTip(rec?.tip);
  }, [rec]);

  const handleDelete = () => {
    console.log(rec)
    setDeletedRecommendation(rec);
  };

  const handleSaveTip = () => {
    updateRecommendation({ ...rec, tip: tip });
    setIsExtendalbleTip(false);
  };

  const handleEdit = () => {
    setIsNew(false);
    setDestinationId(rec.destination_id);
    setTabId(tabId);
    setSection(rec);
  };

  const getAttachment = (type) => {
    return (rec?.attachments || []).filter((a) => a.type === type)[0] || null;
  };

  const hasLoc = () => {
    return !!rec.lat && !!rec.lng;
  };

  const isPlace = () => {
    return rec?.display_as === "place";
  };

  const buildVideo = () => {
    var youtubeRegex =
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      vimeoRegex =
        /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    var youtubeMatch = (video.url || "").match(youtubeRegex),
      vimeoMatch = (video.url || "").match(vimeoRegex);

    if (!!youtubeMatch && youtubeMatch[5].length == 11) {
      var videoId = youtubeMatch[5];
      return (
        <div className="video-wrapper">
          <iframe
            className="display recommendation-video"
            title={`video_${videoId}`}
            width="585"
            height="330"
            src={
              "//www.youtube.com/embed/" + videoId + "?rel=0&modestbranding=1"
            }
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
      );
    } else if (!!vimeoMatch) {
      var videoId = vimeoMatch[4];

      return (
        <iframe
          src={`https://player.vimeo.com/video/${videoId}`}
          title={`video_${videoId}`}
          width="585"
          height="330"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      );
    } else {
      return null;
    }
  };

  const getImage = () => {
    let url = isPlace()
      ? `${host}/v2/google/image/${rec.google?.google_place_id}`
      : alterAssetUrl(getAttachment("image")?.url);

    // console.log("ATTACHMENT:", url, rec)
    if (!url) {
      return null;
    }

    return (
      <div className={"property-img-box"}>
        <img className="destination-img" src={url} alt="destination-img" />
        {hasLoc() && <div className="hms-property-number">{orderNo + 1}</div>}
      </div>
    );
  };

  const buildEventTimes = () => {
    let { display_as, start_date, start_time, end_date, end_time } = rec;

    if (display_as !== "event") {
      return null;
    }

    let startDate = !!start_date
      ? moment(start_date, "YYYY-MM-DD").format("MMM D, YYYY")
      : "";
    let startTime = !!start_time
      ? moment(start_time, "HH:mm").format("h:mm a")
      : "";
    let endDate = !!end_date
      ? moment(end_date, "YYYY-MM-DD").format("MMM D, YYYY")
      : "";
    let endTime = !!end_time ? moment(end_time, "HH:mm").format("h:mm a") : "";

    return `${[
      [startDate, startTime].join(", "),
      [endDate, endTime].join(", "),
    ].join(" - ")}`;
  };

  let video = getAttachment("you_tube");
  let file = getAttachment("file");

  const handleCancel = () => {
    setIsExtendalbleTip(false);
    setTip(rec?.tip || "");
  };

  const alterAssetUrl = (url) => {
    return url
      ?.replace("http://uploads.ruebarue.com", "https://public.ruebarue.com")
      ?.replace(
        "s3.us-east-2.amazonaws.com/uploads.ruebarue.com",
        "public.ruebarue.com",
      );
  };

  return (
    <li
      className="drag-item"
      data-id={rec.recommendation_id}
      key={rec.destination_id}
    >
      <div
        className="uk-card uk-card-small uk-card-default hms-property-card"
        style={{ marginTop: "5px" }}
      >
        <div className="uk-flex uk-flex-between">
          <div className="hms-property-card-body">
            <div className="uk-flex">
              {getImage()}
              <div className="hms-property-card-content">
                {buildEventTimes()}
                <h3>{rec?.name || ""}</h3>
                <p>{rec?.address || ""}</p>
              </div>
            </div>
            {isExtendableTip ? (
              <div className="uk-margin-small-top">
                <textarea
                  rows={5}
                  onChange={(e) => setTip(e.target.value)}
                  value={tip}
                  style={{ width: "100%" }}
                />
                <div className="uk-width-1-1 uk-margin-small-top">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    onClick={handleSaveTip}
                  >
                    Save
                  </button>
                  <input
                    type="button"
                    className="uk-button hms-btn uk-modal-close"
                    value="Cancel"
                    onClick={handleCancel}
                  />
                </div>
              </div>
            ) : (
              <div>
                {tip === "" ? (
                  <div style={{ fontStyle: "italic" }}>
                    Add a tip for your guests
                  </div>
                ) : (
                  <Markdown input={tip} />
                )}
                {rec.display_as === "place" && (
                  <div className="uk-width-1-1 uk-margin-small-top">
                    <button
                      className="uk-button hms-btn hms-red-btn"
                      type="submit"
                      onClick={() => setIsExtendalbleTip(true)}
                    >
                      {tip === "" ? "Add Tip" : "Edit tip"}
                    </button>
                  </div>
                )}
              </div>
            )}

            {!!rec.external_link && (
              <div className="hsm-external-link uk-margin-top">
                <a href={rec.external_link} target="_blank" rel="noreferrer">
                  {rec.external_link}
                </a>
              </div>
            )}

            {!!file && (
              <div className="hsm-pdf uk-margin-top">
                <Image
                  src="/images/pdf-icon.svg"
                  className="uk-margin-small-right"
                />
                <Link href={alterAssetUrl(file.url)} target="_blank">
                  {file.name || file.original_name}
                </Link>
              </div>
            )}

            {!!video && buildVideo()}
          </div>
          <div className="hms-property-card-btn">
            {rec.display_as !== "place" && (
              <>
                <div>
                  <button
                    className="uk-button"
                    uk-toggle={`target: #destination-${
                      rec.display_as === "overview" ? "text" : rec.display_as
                    }-modal`}
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    <Image src="/images/edit-icon.svg" />
                  </button>
                </div>
                <hr />
              </>
            )}
            <DragHandle />
            <hr />
            <div>
              <button
                className="uk-button"
                uk-toggle="target: #recommendation-delete"
                onClick={() => {
                  handleDelete();
                }}
              >
                <Image src="/images/remove.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <RecommendationDelete />
    </li>
  );
};

export default Recommendation;
