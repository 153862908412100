// Import package components
import moment from "moment";

import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { CSVLink } from "react-csv";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import PageTitle from "../common/PageTitle";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import AddTextLine from "../common/AddTextLine";

// Import email components
import EmailDelete from "./delete";

const GuestEmails = ({ history }) => {
  const { register, handleSubmit, setValue } = useForm();

  const [criteria, setCriteria] = useState({
    sort: "created_at",
    page: 0,
    start: moment().subtract(7, "day").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const [selectedFlags, setSelectedFlags] = useState([]);
  const guidesContext = useContext(AppContexts.GuidesContext);

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { listEmails, loading } = guidesContext;

  const headers = [
    { label: "Subscribed On", key: "created_at" },
    { label: "First Name", key: "guest_reservation.first_name" },
    { label: "Last Name", key: "guest_reservation.last_name" },
    { label: "Email", key: "email" },
    { label: "URL", key: "url" },
  ];

  useEffect(() => {
    guidesContext.getCapturedEmails(criteria);
  }, []);

  useEffect(() => {
    setSelectedFlags(Array(listEmails.length).fill(false));
    document.title = "Subscriptions - RueBaRue";
  }, [listEmails.length]);

  useEffect(() => {
    guidesContext.setSelectedEmails(
      selectedFlags
        .map((selected, index) => (selected ? listEmails[index] : null))
        .filter((contact) => contact),
    );
  }, [selectedFlags, listEmails]);

  const selectedAll = useMemo(() => selectedFlags.every((checked) => checked), [
    selectedFlags,
  ]);

  const sortedEmails = useMemo(
    () => listEmails.sort((a, b) => (a.created_at > b.created_at ? -1 : 1)),
    [listEmails, criteria],
  );

  const visibleEmails = useMemo(
    () =>
      listEmails.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [sortedEmails, criteria, recordsPerPage],
  );

  const handleSelect = useCallback((checked, index) => {
    setSelectedFlags((flags) => [
      ...flags.slice(0, index),
      checked,
      ...flags.slice(index + 1),
    ]);
  }, []);

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const handleDeleteEmail = useCallback((email) => {
    guidesContext.setBatchFlag(false);
    guidesContext.setGuestEmail(email);
  });

  const emailRenderer = useCallback(
    (data, index) => (
      <tr key={data?.id}>
        <td>
          <div className="autosend-checkbox">
            <label htmlFor={`autoSendCheckbox${index}`}>
              <input
                type="checkbox"
                id={`autoSendCheckbox${index}`}
                checked={selectedFlags[index]}
                onChange={(event) => handleSelect(event.target.checked, index)}
              />
            </label>
          </div>
        </td>
        <td>{moment.utc(data?.created_at).utc().format("MM/DD/YYYY")}</td>
        <td>{data?.email}</td>
        <td>
          <div>
            {data?.guest_reservation?.first_name}{" "}
            {data?.guest_reservation?.last_name}
            <br />
          </div>
          {/* <div className="guests-secondary">
            {moment.utc(data?.check_in).utc().format("MM/DD/YYYY")} -{" "}
            {moment.utc(data?.check_out).utc().format("MM/DD/YYYY")}
          </div> */}
        </td>
        <td className="secondary subscriptions-guestbook-link">{data?.url}</td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-delete"
                onClick={() => handleDeleteEmail(data)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [selectedFlags],
  );

  const handleSelectAll = useCallback(
    (event) =>
      setSelectedFlags((flags) => flags.map(() => event.target.checked)),
    [selectedFlags],
  );

  const handleFilter = useCallback(
    (range) => {
      let newCriteria = {
        ...criteria,
        start: range.start,
        end: range.end,
      };
      guidesContext.getCapturedEmails(newCriteria);
      setCriteria(newCriteria);
    },
    [criteria],
  );

  return (
    <>
      <section id="view-add-contacts">
        <AddTextLine feature="Subscriptions" />
        <PageTitle title="Subscriptions" />

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between">
            <form action="#" onSubmit={handleSubmit(handleFilter)}>
              <div className="uk-flex">
                <div className="uk-form-controls">
                  <div className="hms-date-picker">
                    <input
                      className="uk-input"
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      defaultValue={criteria.start}
                      {...register("start")}
                    />
                  </div>
                </div>
                <div className="uk-form-controls uk-margin-small-left">
                  <div className="hms-date-picker">
                    <input
                      className="uk-input"
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      defaultValue={criteria.end}
                      {...register("end")}
                    />
                  </div>
                </div>
                <div className="uk-margin-small-left">
                  <button
                    className="uk-button hms-btn hms-red-btn hms-go-btn"
                    type="submit"
                  >
                    Go
                  </button>
                </div>
              </div>
            </form>

            <div className="uk-flex">
              <CSVLink
                data={visibleEmails}
                filename={`${moment().unix()}_subscribed_emails.csv`}
                headers={headers}
              >
                <button class="download">
                  <img data-src="/images/download.svg" data-uk-img="" loading="lazy" src="/images/download.svg" uk-tooltip="Download CSV" />
                </button>
              </CSVLink>
            </div>
          </div>

          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-shrink">
                      <label
                        htmlFor="checkboxEmailAll"
                        className="checkboxContact"
                      >
                        <input
                          type="checkbox"
                          id="checkboxEmailAll"
                          checked={selectedAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </th>
                    <th className="uk-table-expand name-field">
                      Subscribed On
                    </th>
                    <th className="uk-table-expand name-field">Email</th>
                    <th className="uk-table-expand name-field">Guest Name</th>
                    {/* <th className="uk-table-expand name-field">Guest Name / CHECK-IN & OUT</th> */}
                    <th className="uk-table-expand name-field">Guide</th>
                    <th className="uk-width-205 actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {loading === true ? (
                    TableLoading(6)
                  ) : visibleEmails.length > 0 ? (
                    visibleEmails.map(emailRenderer)
                  ) : (
                    <tr>
                      <td colSpan={9} style={{ padding: "30px 25px" }}>
                        No subscriptions yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={listEmails.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <EmailDelete />
    </>
  );
};

export default GuestEmails;
