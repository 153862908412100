import http, { httpV2 } from "../http.js";

const GuidesService = {
  get_property: (rental_id) => httpV2.get(`/guides/rental/${rental_id}`),
  get_guestbook_emails: (criteria) =>
    httpV2.get(
      `/guides/guestbook-emails?start=${criteria.start}&end=${criteria.end}`,
    ),
  delete_guestbook_email: (email_id) =>
    httpV2.delete(`/guides/guestbook-emails/${email_id}`),
};

export default GuidesService;
