// Import package components
import { useContext, useEffect, useMemo, useState } from "react";
import { InputContainer, Flex } from "uikit-react";
import { AppContexts } from "../../../providers";
import { Form, FormInputWrapper } from "../../../ui";
import { useForm } from "react-hook-form";

const EmbedTag = () => {
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const [destination, setDestination] = useState({});
  const [dirty, setDirty] = useState(false);

  const { handleSubmit } = useForm();

  useEffect(() => {
    console.log("settings:", settings);
    document.title = "Settings | Embed - RueBaRue";
    setDestination(settings.destination || { tab_order: "" });
  }, [settings]);

  const handleSave = () => {
    console.log("destination:", destination);
    set(["destination"], {
      destination: destination,
    }).then((res) => {
      setDirty(false);
    });
  };

  const handleUpdate = (field, evt) => {
    setDirty(true);
    setDestination({ ...destination, [field]: evt.target.value });
  };

  const handleToggle = (field, evt) => {
    setDirty(true);
    setDestination({ ...destination, [field]: !destination[field] });
  };

  const labels = {
    show_embed_distance: "Distance To Places",
    show_guide_title: "Guide Title",
    show_embed_hours: "Hours",
    show_embed_reviews: "Reviews",
    show_embed_website: "Website",
    show_embed_phone: "Phone",
    show_embed_directions: "Directions",
  };

  const modules = [
    "show_embed_distance",
    "show_guide_title",
    "show_embed_hours",
    "show_embed_reviews",
  ];

  const links = [
    "show_embed_website",
    "show_embed_phone",
    "show_embed_directions",
  ];

  return (
    <li
      className={`setting-embed-tab-content ${
        active === "embed" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h2>Embed</h2>
        <Form
          className="uk-grid-small"
          data-uk-grid
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="uk-width-1-2 uk-margin-bottom hms-headings">
            <div className="uk-form-label">Number of Items to Display</div>
            <FormInputWrapper size="two">
              <InputContainer>
                <div
                  uk-form-custom="target: > * > span:first-child"
                  className="uk-form-custom uk-width-1"
                >
                  <select
                    onChange={(event) =>
                      setDestination({
                        ...destination,
                        embed_limit: event.target.value,
                      })
                    }
                    value={destination["embed_limit"]}
                  >
                    {[...Array(10)].map((_, i) => {
                      if (i === 0) {
                        return (
                          <option value="all" key={i}>
                            All
                          </option>
                        );
                      } else {
                        return (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default uk-width-1-1"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {!destination.embed_limit
                        ? "All"
                        : destination.embed_limit}
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </InputContainer>
            </FormInputWrapper>
          </div>
          <div className="uk-width-1-1 uk-margin-large-top hms-headings">
            <div className="uk-form-label">Modules</div>
          </div>
          <ul className="uk-list uk-list-divider uk-width-1-2">
            {modules.map((key, index) => {
              return (
                <li className="uk-width-1">
                  <FormInputWrapper size="two">
                    <Flex wrap="between">
                      <label>{labels[key]}</label>
                      <InputContainer className="autosend-checkbox">
                        <label htmlFor={key} className="setting-switch">
                          <input
                            type="checkbox"
                            className="uikit-checkbox"
                            id={key}
                            checked={destination[key]}
                            onInput={() => {
                              handleToggle(key);
                            }}
                          />
                          <span className="setting-slider setting-round"></span>
                        </label>
                      </InputContainer>
                    </Flex>
                  </FormInputWrapper>
                </li>
              );
            })}
          </ul>
          <div className="uk-width-1-1 uk-margin-large-top hms-headings">
            <div className="uk-form-label">External Links</div>
          </div>
          <ul className="uk-list uk-list-divider uk-width-1-2">
            {links.map((key, index) => {
              return (
                <li className="uk-width-1">
                  <FormInputWrapper size="two">
                    <Flex wrap="between">
                      <label>{labels[key]}</label>
                      <InputContainer className="autosend-checkbox">
                        <label
                          htmlFor={`scheduleWebsiteActive${index}`}
                          className="setting-switch"
                        >
                          <input
                            type="checkbox"
                            className="uikit-checkbox"
                            id={`scheduleWebsiteActive${index}`}
                            checked={destination[key]}
                            onInput={() => {
                              handleToggle(key);
                            }}
                          />
                          <span className="setting-slider setting-round"></span>
                        </label>
                      </InputContainer>
                    </Flex>
                  </FormInputWrapper>
                </li>
              );
            })}
          </ul>
          <div className="uk-width-1-1 uk-margin-top">
            <div className="uk-flex uk-flex-middle">
              <button
                className="uk-button hms-btn hms-red-btn"
                type="submit"
                disabled={loading}
              >
                {loading ? <div data-uk-spinner="ratio: 0.5;"></div> : "Saved"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </li>
  );
};

export default EmbedTag;
