import React from "react";
import IcoMoon from "react-icomoon";
import standardSet from "../../../assets/icons/ruebarue/selection.json";
import guideSet from "../../../assets/icons/guide-icons/selection.json";
const BaseIcon = (props) => {
  let [prefix, icon] = props.icon.split("icon-");
  let iconSet = standardSet;

  if (prefix === "guide-") {
    iconSet = guideSet;
  }

  if (!icon) {
    iconSet = guideSet;
    icon = "info";
  }

  let properties = { ...props, icon: icon };

  return <IcoMoon iconSet={iconSet} {...properties} />;
};

export default BaseIcon;
