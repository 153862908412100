// Import package components
import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AppContexts } from "../../../providers";
import SMSEmailInputBox from "../../common/sms-email-inputbox";

const schema = yup.object().shape({
  body: yup.string().required(),
});

const defaultValues = {
  method: "sms",
  body: "",
  subject: "",
};

const GuestMessages = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const reservationContext = useContext(AppContexts.ReservationsContext);
  const { get, settings } = useContext(AppContexts.SettingsContext);
  const history = useHistory();

  useEffect(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
    get(["branding"]);
  }, [reservationContext.reservation]);

  useEffect(() => {
    if (settings && settings.branding) {
      setValue("from_name", settings.branding?.from_name);
      setValue("from_email", settings.branding?.from_email);
    }
  }, [settings]);

  const handleSendMsg = async (data) => {
    setLoading(true);
    console.log(reservationContext.reservation);
    if (!reservationContext.reservation.formatted_phone) {
      setLoading(false);
      UIkit.notification("Phone number is missing", "warning");
      return;
    }
    let sms = {
      guest_reservation_id: reservationContext.reservation.guest_reservation_id,
      message: data.body,
      phone: reservationContext.reservation.phone,
    };
    let result = await reservationContext.createSMS(sms);
    if (result) {
      setLoading(false);
      UIkit.modal("#modal-guest-messages").hide();
      UIkit.notification("Text message sent successfully.", "success");
      setValue("body", "");
    }
  };

  const handleSelectedResponse = (response) => {
    setValue("body", response);
    UIkit.dropdown("#messages-response").hide(0);
  };

  const method = useMemo(() => getValues("method"), [watch("method")]);
  const body = useMemo(() => getValues("body"), [watch("body")]);

  const handleClose = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  return (
    <div
      id="modal-guest-messages"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Send Message</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleSubmit(handleSendMsg)}
              >
                {method === "email" && (
                  <>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-subject">
                          Subject
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-subject"
                            type="text"
                            {...register("subject")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-name">
                          From Name
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-name"
                            type="text"
                            {...register("from_name")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-email">
                          From Email Address
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-email"
                            type="text"
                            {...register("from_email")}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="uk-width-1-1">
                  <SMSEmailInputBox
                    value={body || ""}
                    limit={method === "sms"}
                    macrosOrResponse={false}
                    onChange={(event) => setValue("body", event.target.value)}
                    onSavedReply={handleSelectedResponse}
                  />
                </div>
                <div className="uk-width-1-1 uk-margin-top uk-flex">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>

                  {!!reservationContext.reservation.thread_id &&
                    !!reservationContext.reservation.formatted_phone && (
                      <button
                        className="uk-button hms-btn hms-orange-btn uk-margin-small-bottom"
                        onClick={() =>
                          history.push(
                            `/messages/${reservationContext.reservation.thread_id}`,
                          )
                        }
                        type="button"
                      >
                        View Conversation
                      </button>
                    )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestMessages;
