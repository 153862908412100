// Import package components
import moment from "moment";

import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import UIkit from "uikit";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import Papa from "papaparse";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import PageTitle from "../common/PageTitle";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import { Link } from "../../ui";
import AddTextLine from "../common/AddTextLine";

// Import contact components
import ContactAdd from "./add";
import ContactDelete from "./delete";
import Message from "./message";
import Tags from "../common/Tags";
import Properties from "../common/Properties";
import TagsList from "./tag_list";

const sortOptions = [
  { key: "updated_at", title: "Last Updated" },
  { key: "first_name", title: "First Name" },
  { key: "last_name", title: "Last Name" },
];

const Contacts = ({ history }) => {
  const [criteria, setCriteria] = useState({
    search_term: "",
    sort: "updated_at",
    page: 0,
    tag: "all",
    type: "all",
    property: "all",
  });
  const inputRef = useRef(null);
  const [selectedFlags, setSelectedFlags] = useState([]);
  const contactContext = useContext(AppContexts.ContactsContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalContext = useContext(AppContexts.RentalsContext);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { listContacts, flagIsProcessing, updateContact } = contactContext;
  const propertyValues = useMemo(
    () =>
      (rentalContext.rentals || [])
        .filter((r) => r.pms_id)
        .sort((a, b) => a.pms_id.localeCompare(b.pms_id)) || [],
    [rentalContext.rentals],
  );
  const tagValues = useMemo(
    () =>
      settingsContext.settings.tags?.sort((a, b) =>
        a.name.localeCompare(b.name),
      ) || [],
    [settingsContext.settings],
  );
  const contacts = useMemo(
    () =>
      listContacts?.map((item) => {
        let tags = (item.tags || []).sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        let rentals = (item.rentals || []).sort((a, b) =>
          a.pms_id.localeCompare(b.pms_id),
        );
        return { ...item, tags, rentals };
      }),
    [listContacts],
  );
  const filteredContacts = useMemo(
    () =>
      contacts
        .filter((contact) => {
          if (criteria.tag && criteria.tag !== "all") {
            if (
              contact.tags.reduce(
                (passed, tag) => passed || tag.name === criteria.tag,
                false,
              ) === false
            ) {
              return false;
            }
          }
          if (criteria.property && criteria.property !== "all") {
            if (
              contact.rentals.reduce(
                (passed, pty) => passed || pty.pms_id === criteria.property,
                false,
              ) === false
            ) {
              return false;
            }
          }
          if (
            criteria.search_term &&
            contact.first_name
              .toLowerCase()
              .search(criteria.search_term.trim().toLowerCase()) === -1 &&
            contact.last_name
              .toLowerCase()
              .search(criteria.search_term.trim().toLowerCase()) === -1 &&
            (contact.rentals || []).findIndex(
              (rental) =>
                rental.pms_id
                  .toLowerCase()
                  .search(criteria.search_term.trim().toLocaleLowerCase()) !==
                -1,
            ) === -1
          )
            return false;
          if (criteria.type && criteria.type !== "all") {
            if (criteria.type === "lead") {
              if (
                contact.category.toLowerCase() !== "lead" &&
                contact.category.toLowerCase() !== "leads"
              ) {
                return false;
              }
            } else if (
              contact.category.toLowerCase() !== criteria.type.toLowerCase()
            ) {
              return false;
            }
          }
          return true;
        })
        .sort((prev, next) => {
          if (criteria.sort === "first_name") {
            return prev.first_name.trim().localeCompare(next.first_name.trim());
          } else if (criteria.sort === "last_name") {
            return prev.last_name.trim().localeCompare(next.last_name.trim());
          } else if (criteria.sort === "updated_at") {
            return moment(prev.updated_at) < moment(next.updated_at) ? 1 : -1;
          }
        }),
    [contacts, criteria],
  );
  const visibleContacts = useMemo(
    () =>
      filteredContacts.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [filteredContacts, criteria, recordsPerPage],
  );

  const searchTerm = useMemo(() => criteria.search_term, [
    criteria.search_term,
  ]);
  const selectedAll = useMemo(() => selectedFlags.every((checked) => checked), [
    selectedFlags,
  ]);
  const selectedAny = useMemo(() => selectedFlags.some((checked) => checked), [
    selectedFlags,
  ]);

  useEffect(() => {
    setSelectedFlags(Array(visibleContacts.length).fill(false));
    document.title = "Contacts - RueBaRue";
  }, [visibleContacts.length]);

  useEffect(() => {
    contactContext.setSelectedContacts(
      selectedFlags
        .map((selected, index) => (selected ? visibleContacts[index] : null))
        .filter((contact) => contact),
    );
  }, [selectedFlags, visibleContacts]);

  // useEffect(() => {
  // 	return history.block(() => {
  // 		if (flagIsEditing) {
  // 			if (!window.confirm("Do you want to go to another page without saving?")) {
  // 				UIkit.modal("#modal-contact-add").show();
  // 				return false;
  // 			}
  // 			return true;
  // 		}
  // 		return true;
  // 	});
  // }, [flagIsEditing]);

  useEffect(() => {
    contactContext.getContacts();
  }, []);

  const handleAddContact = useCallback(() => {
    contactContext.setIsNew(true);
    contactContext.initContact();
  }, []);

  const handleEditContact = useCallback((contact) => {
    contactContext.setIsNew(false);
    contactContext.setContact(contact);
  });

  const handleSelected = useCallback(() => {
    contactContext.setBatchFlag(true);
  }, []);

  const handleSelectedTags = useCallback((tags, contact) => {
    let temp = { ...contact, tags };
    updateContact(temp);
  }, []);

  const handleSelectedRentals = useCallback((rentals, contact) => {
    let temp = { ...contact, rentals };
    updateContact(temp);
  }, []);

  const handleMessage = useCallback((contact) => {
    contactContext.setBatchFlag(false);
    contactContext.setContact(contact);
  }, []);

  const handleDeleteContact = useCallback((contact) => {
    contactContext.setBatchFlag(false);
    contactContext.setContact(contact);
  });

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const handleSelect = useCallback((checked, index) => {
    setSelectedFlags((flags) => [
      ...flags.slice(0, index),
      checked,
      ...flags.slice(index + 1),
    ]);
  }, []);

  const contactRenderer = useCallback(
    (contact, index) => (
      <tr key={contact?.id}>
        <td>
          <div className="autosend-checkbox">
            <label htmlFor={`autoSendCheckbox${index}`}>
              <input
                type="checkbox"
                id={`autoSendCheckbox${index}`}
                checked={selectedFlags[index]}
                onChange={(event) => handleSelect(event.target.checked, index)}
              />
            </label>
          </div>
        </td>
        <td>
          <div className="contact-td">
            <span
              uk-tooltip={`title: ${contact?.category
                .toLowerCase()
                .replace(/\b\w/g, (s) =>
                  s.toUpperCase(),
                )}; pos: bottom-top; cls: uk-active contact-tooltip`}
              style={{ width: "32px", height: "32px" }}
            >
              <img
                src={`images/${contact?.category.toLowerCase()}-active.svg`}
                alt="active.svg"
                width="30px"
                height="30px"
              />
            </span>
            <div>
              <div className="semi-bold-font">
                {contact?.first_name} {contact?.last_name}
              </div>
              <div className="secondary">{contact?.formatted_phone}</div>
              <div className="secondary">{contact?.email}</div>
            </div>
          </div>
        </td>
        <td>
          {contact?.category
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase())}
        </td>
        <td className="hms-add-tag">
          <Properties
            value={contact?.rentals}
            onChange={(editedTags) =>
              handleSelectedRentals(editedTags, contact)
            }
          />
        </td>
        <td className="hms-add-tag">
          <Tags
            all={tagValues}
            value={contact?.tags}
            onChange={(event) => handleSelectedTags(event, contact)}
          />
        </td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-message"
                onClick={() => handleMessage(contact)}
                uk-tooltip="Send a Message"
              >
                <Image src="/images/sms.svg" />
              </button>
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-add"
                onClick={() => handleEditContact(contact)}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </button>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-delete"
                onClick={() => handleDeleteContact(contact)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [selectedFlags],
  );

  const sortOptionRenderer = useCallback(
    (option, index) => (
      <li
        className={classnames({ "uk-active": criteria.sort === option.key })}
        key={index}
      >
        <Link href="#" onClick={() => handleCriteriaChange("sort", option.key)}>
          {option.title}
        </Link>
      </li>
    ),
    [criteria.sort],
  );

  const handleSelectAll = useCallback(
    (event) =>
      setSelectedFlags((flags) => flags.map(() => event.target.checked)),
    [selectedFlags],
  );

  const handleImport = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // Store the current input value
    const inputValue = event.target.value;
    await Papa.parse(fileObj, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        let temp = results.data.map((data) => {
          if (data.category?.toLowerCase() == "leads") {
            return { ...data, category: "lead" };
          } else {
            return data;
          }
        });
        if (!temp.length) {
          UIkit.notification("No content", "error");
          return;
        }
        const columns = Object.keys(temp[0]);
        let errors = [
          "category",
          "email",
          "first_name",
          "last_name",
          "phone",
        ].filter((item) => !columns.find((column) => column === item));
        if (!!errors.length) {
          UIkit.notification(`No Columns ${errors.join(", ")}`, "error");
          return;
        }
        const result = await contactContext.importContacts(temp);
        await contactContext.getContacts();
        if (!!result) {
          if (!!(result?.exists || []).length) {
            UIkit.notification(
              `${result?.exists?.length} records already exist.`,
              "error",
            );
          } else if (!!(result?.errors || []).length) {
            UIkit.notification(`Failed: ${result?.errors[0]}`, "error");
          } else {
            UIkit.notification("Contacts Added Successfully", "success");
          }
        } else {
          UIkit.notification("Failed: Please check your csv format", "error");
        }

        event.target.value = "";

        // Trigger the onInput event manually by dispatching a new Event
        const newEvent = new Event("input", { bubbles: true });
        event.target.dispatchEvent(newEvent);
      },
    });
  };

  const handleClickImport = () => {
    inputRef.current.click();
  };

  return (
    <>
      <section id="view-add-contacts">
        <AddTextLine feature="Contacts" />
        <PageTitle title="Contacts" />

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #modal-contact-add"
                onClick={handleAddContact}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Contact
              </button>
              <button
                className="uk-button hms-btn hms-orange-btn"
                uk-toggle="target: #modal-contact-message"
                disabled={!selectedAny}
                onClick={handleSelected}
              >
                <span uk-icon="icon: mail; ratio: 1"></span> Message
              </button>
              <TagsList all={tagValues} disabled={!selectedAny} />
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onChange={handleImport}
                accept=".csv"
              />
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={handleClickImport}
              >
                <span uk-icon="icon: arrow-up; ratio: 1"></span> Import
              </button>
              <a
                className="uk-margin-small-right"
                href="/samples/contact-import-sample.csv"
                style={{ color: "black" }}
                target="_blank"
              >
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  uk-tooltip="Click to download sample comma separated file (CSV) to bulk import contacts"
                />
              </a>
              <button
                className="uk-button hms-btn hms-white-btn"
                uk-toggle="target: #modal-contact-delete"
                disabled={!selectedAny}
                onClick={handleSelected}
              >
                <span uk-icon="icon: trash; ratio: 0.7"></span> Delete
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <Flex alignment="between top" className="guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={criteria.type === null ? "All" : criteria.type}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "type",
                              event.target.value === "All"
                                ? null
                                : event.target.value,
                            )
                          }
                        >
                          <option value="all">All Types</option>
                          <option value="owner">Owner</option>
                          <option value="housekeeper">Housekeeper</option>
                          <option value="maintenance">Maintenance</option>
                          <option value="vendor">Vendor</option>
                          <option value="lead">Lead</option>
                          <option value="other">Other</option>
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={
                            criteria.property === null
                              ? "All"
                              : criteria.property
                          }
                          onChange={(event) =>
                            handleCriteriaChange(
                              "property",
                              event.target.value === "All"
                                ? null
                                : event.target.value,
                            )
                          }
                        >
                          <SelectOption value="all">
                            All Properties
                          </SelectOption>
                          {propertyValues.map((property, index) => (
                            <SelectOption value={property.pms_id} key={index}>
                              {property.pms_id}
                            </SelectOption>
                          ))}
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={criteria.tag === null ? "All" : criteria.tag}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "tag",
                              event.target.value === "All"
                                ? null
                                : event.target.value,
                            )
                          }
                        >
                          <SelectOption value="all">All Tags</SelectOption>
                          {tagValues.map((tag, index) => (
                            <SelectOption value={tag.name} key={index}>
                              {tag.name}
                            </SelectOption>
                          ))}
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {searchTerm && searchTerm !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_term", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name or Property ID"
                          value={searchTerm}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "search_term",
                              event.target.value,
                            )
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        {sortOptions.map(sortOptionRenderer)}
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-shrink">
                      <label
                        htmlFor="checkboxContactAll"
                        className="checkboxContact"
                      >
                        <input
                          type="checkbox"
                          id="checkboxContactAll"
                          checked={selectedAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </th>
                    <th className="uk-table-expand name-field">
                      Name/Phone/Email
                    </th>
                    <th className="uk-table-expand">Type</th>
                    <th className="uk-table-expand">Properties</th>
                    <th className="uk-table-expand">Tags</th>
                    <th className="uk-width-205 actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {flagIsProcessing == true ? (
                    TableLoading(6)
                  ) : visibleContacts.length > 0 ? (
                    visibleContacts.map(contactRenderer)
                  ) : (
                    <tr>
                      <td colSpan={9} style={{ padding: "30px 25px" }}>
                        No contacts matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={filteredContacts.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <Message />
      <ContactAdd />
      <ContactDelete />
    </>
  );
};

export default Contacts;
