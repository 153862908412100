import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

const Marker = (props) => {
  return (
    <div className={props.classes}>
      <div className="label">{props.idx}</div>
      <div className="tip">{props.text}</div>
    </div>
  );
};

const Map = (props) => {
  let [map, setMap] = useState(null);
  let [maps, setMaps] = useState(null);
  let [markers, setMarkers] = useState([]);

  const onLoaded = async (m, ms) => {
    await Promise.all([setMap(m), setMaps(ms)]);

    renderMarkers();
  };

  const renderMarkers = async () => {
    let bounds = null;
    if (!!map && !!maps) {
      bounds = new maps.LatLngBounds();
    }

    let newMarkers = [];
    if (props.showHome) {
      newMarkers.push(
        <Marker
          key="home-pin"
          text=""
          lat={props.center.lat}
          lng={props.center.lng}
          classes="marker home-marker"
        />,
      );

      if (!!map && !!maps) {
        bounds.extend({ lat: props.center.lat, lng: props.center.lng });
      }
    }

    (props.markers || []).map((marker, idx) => {
      newMarkers.push(
        <Marker
          key={"pin-" + idx}
          text={marker.text}
          lat={marker.lat}
          lng={marker.lng}
          idx={idx + 1}
          classes="marker"
        />,
      );
      if (!!map && !!maps) {
        bounds.extend({ lat: marker.lat, lng: marker.lng });
      }
    });

    if (!!map && !!maps && !!bounds) {
      map.fitBounds(bounds);
    }

    setMarkers(newMarkers);
  };

  useEffect(() => {
    renderMarkers();
  }, [props.markers]);

  return (
    <div className="google-map uk-width-2-5@s">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onLoaded(map, maps)}
      >
        {markers}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
