// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { Icon, Image } from "uikit-react";
import UIkit from "uikit";

import { AppContexts } from "../../../providers";
import MasterPropertyGuideAdd, {
  MODAL_NAME as MASTER_PROPERTY_GUIDE_ADD_MODAL_NAME,
} from "./add";
import MasterPropertyGuideEdit, {
  MODAL_NAME as MASTER_PROPERTY_GUIDE_EDIT_MODAL_NAME,
} from "./edit";
import TabAdd from "./add_tab";
import TabEdit from "./edit_tab";
import IconList from "./icon_list";
import classnames from "classnames";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import GuideIcon from "../../common/GuideIcon";
import Loading from "../../common/Loading";
import Markdown from "../../common/Markdown";
import MasterDelete from "./delete";
import ResetToMaster from "./reset";
import MasterTabDelete from "./delete_tab";
import HomeSection from "./home-section";
import { Link } from "../../../ui";
import { useHistory } from "react-router-dom";
import Share from "../share";

const PropertyGuide = () => {
  const { user, refresh } = useContext(AppContexts.AuthContext);
  const {
    rental,
    setRental,
    getRental,
    updateRental,
    updateSection,
    createSection,
    selectedTab,
    setSelectedTab,
    selectedSection,
    setSelectedSection,
  } = useContext(AppContexts.RentalsContext);

  const rental_id = window.location.pathname.split("/")[2];
  const history = useHistory();

  // const MasterGuideContext = useContext(AppContexts.MasterGuideContext);
  const { get, set, settings, setSettings } = useContext(
    AppContexts.SettingsContext,
  );

  const { getSurveyList } = useContext(AppContexts.SurveysContext);

  const [loading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [isEditTitle, setisEditTitle] = useState(false);
  const [title, setTitle] = useState("");

  let tabOrder = useMemo(() => {
    let order = [];
    if (!!settings?.guestbook) {
      order = (settings.guestbook.tab_order || "")
        .split(",")
        .filter((o) => !!o);
    }

    return order;
  }, [settings]);

  let sectionOrder = useMemo(() => {
    if (!selectedTab || !settings.welcome_order) {
      return [];
    }

    return (
      settings.welcome_order.filter((wo) => wo.type === selectedTab.type)[0]
        ?.ordering || []
    );
  }, [selectedTab, settings]);

  let { guestbook, welcome_tabs, welcome_order } = useMemo(() => {
    return settings;
  }, [settings]);

  useEffect(() => {
    setTitle(rental.name);
  }, [rental]);

  let mergedSections = useMemo(() => {
    if (!rental) {
      return user?.welcome_guide || [];
    }

    return (user?.welcome_guide || []).map((item) => {
      let rentalItem = (rental.welcome_guide || []).find(
        (s) => +s.parent_id === +item.welcome_guide_question_id,
      );

      return { ...(rentalItem || item), icon: item.icon };
    });
  }, [user, rental]);

  let isMaster = useMemo(() => {
    return !rental || !rental.rental_id;
  }, [rental]);

  useEffect(async () => {
    document.body.style.overflow = "unset";
    setSelectedTab(null);

    await Promise.all([
      get(["guestbook", "welcome_order", "welcome_tabs"]),
      getSurveyList(),
    ]);

    let property_id = window.location.pathname.split("/")[2];
    if (!!property_id && /[0-9]{16}/.test(property_id)) {
      document.title = "Home Guides";
      await getRental(property_id);
    } else {
      document.title = "Master Home Guide - RueBaRue";
      setRental({});
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      selectedTab === null &&
      !!settings?.guestbook &&
      !!settings?.welcome_tabs
    ) {
      let firstTab = settings?.guestbook.tab_order.split(",")[0];
      let tab = (settings?.welcome_tabs || []).find((t) => t.type === firstTab);

      setSelectedTab(tab);
    }
  }, [settings]);

  const getImage = useCallback(
    (guide) =>
      guide.attachments?.find((attachment) => attachment.type === "image"),
    [],
  );

  const getPdf = useCallback(
    (guide) =>
      guide.attachments?.find((attachment) => attachment.type === "file"),
    [],
  );

  const getVideo = useCallback((item) => {
    let video = item.attachments?.find(
      (attachment) => attachment.type === "you_tube",
    );

    if (!video) {
      return null;
    }

    let youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    let youtubeMatch = (video.url || "").match(youtubeRegex),
      vimeoMatch = (video.url || "").match(vimeoRegex);

    if (!!youtubeMatch && youtubeMatch[5].length == 11) {
      let videoId = youtubeMatch[5];

      return `//www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`;
    } else if (!!vimeoMatch) {
      var videoId = vimeoMatch[4];

      return `https://player.vimeo.com/video/${videoId}`;
    } else {
      return null;
    }
  }, []);

  const alterAssetUrl = (url) => {
    return url
      .replace("http://uploads.ruebarue.com", "https://public.ruebarue.com")
      .replace(
        "s3.us-east-2.amazonaws.com/uploads.ruebarue.com",
        "public.ruebarue.com",
      );
  };

  const handleEdit = useCallback((section) => {
    setSelectedSection(section);
  }, []);

  const handleTitleEdit = () => {
    setisEditTitle(true);
  };

  const handleBack = () => {
    history.push("/properties");
    setSelectedTab(null);
  };

  const handleSaveTitle = async () => {
    let result = await updateRental(rental.rental_id, {
      ...rental,
      name: title,
    });
    if (result) {
      getRental(rental.rental_id);
    }
    setisEditTitle(false);
  };

  const handleDelete = useCallback((section) => {
    setSelectedSection(section);
  }, []);

  const handleExpand = useCallback(
    async (section) => {
      section.expanded = !section.expanded;
      await updateSection(section);

      if (!!rental && !!rental.rental_id) {
        await Promise.all([getRental(rental.rental_id), refresh()]);
      } else {
        await Promise.all([refresh()]);
      }
    },
    [rental],
  );

  const handleVisible = useCallback(
    async (section, event) => {
      setProcessing(true);
      setSelectedSection(section);
      let saveFn = updateSection;
      section.suspended = !event.target.checked;
      if (+section.parent_id === 0 || +section.rental_id === 0) {
        section.parent_id = +section.welcome_guide_question_id;
        section.rental_id = +rental.rental_id;

        saveFn = createSection;
      }
      await saveFn({ ...section });

      if (!!rental && !!rental.rental_id) {
        await Promise.all([getRental(rental.rental_id), refresh()]);
      } else {
        await Promise.all([refresh()]);
      }
      setProcessing(false);
    },
    [rental],
  );

  const handleReorder = ({ oldIndex, newIndex }) => {
    if (!!rental && !!rental.rental_id) {
      return;
    }

    let ordering = arrayMoveImmutable(sectionOrder, oldIndex, newIndex);

    let order = (settings?.welcome_order || []).filter(
      (wo) => wo.type === selectedTab.type,
    )[0];

    if (!!order) {
      order.ordering = ordering;
      setSettings((pre) => ({
        ...pre,
        welcome_order: settings.welcome_order,
      }));
      set("welcome_order", settings.welcome_order);
    }
  };

  const handleIcon = useCallback((section) => {
    setSelectedSection(section);
  }, []);

  const DragHandle = SortableHandle(() => (
    <div>
      <button className="uk-button" uk-tooltip="Move">
        <Image src="/images/move.svg" />
      </button>
    </div>
  ));

  const SortableItemDiv = SortableElement(({ section }) => (
    <div
      className="uk-card uk-card-small uk-card-default hms-property-card"
      style={{
        backgroundColor:
          !isMaster && +section.rental_id !== 0 ? "var(--light-red)" : "",
      }}
    >
      <div className="uk-flex uk-flex-between">
        <div className="hms-property-card-body">
          <div className="uk-flex">
            <div className="m-w-38">
              <GuideIcon
                icon={section?.icon == null ? "info" : section.icon}
                uk-toggle={isMaster ? "target: #iconList-modal" : ""}
                onClick={() => handleIcon(section)}
              ></GuideIcon>
            </div>
            <div className="hms-property-card-content">
              <h3>{section.title}</h3>
              {isMaster && (
                <div className="hms-guest-tags">
                  <div className="guest-tags">
                    {section.tags
                      ?.filter((t) => !!t)
                      ?.sort((prev, next) => prev.name.localeCompare(next.name))
                      ?.map((tag, index) => (
                        <div className="uk-inline" key={index}>
                          {tag.name}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div>
                <Markdown input={section.body} />
              </div>
              {!!getPdf(section) && (
                <div>
                  <Image
                    src="/images/pdf-icon.svg"
                    className="uk-margin-small-right"
                  />
                  <Link
                    target="_blank"
                    href={alterAssetUrl(getPdf(section).url)}
                  >
                    {getPdf(section).name || getPdf(section).original_name}
                  </Link>
                </div>
              )}
              {!!getImage(section) && (
                <div className="uk-margin-top">
                  <Image src={alterAssetUrl(getImage(section).url)} />
                </div>
              )}
              {!!getVideo(section) && (
                <div className="video-responsive">
                  <iframe
                    width="585"
                    height="330"
                    src={getVideo(section)}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="Embedded youtube"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hms-property-card-btn guide-actions">
          <div>
            <button
              className="uk-button"
              uk-toggle={`target: #${MASTER_PROPERTY_GUIDE_EDIT_MODAL_NAME}`}
              uk-tooltip="Edit Block"
              onClick={() => handleEdit(section)}
            >
              <Image src="/images/edit-icon.svg" />
            </button>
          </div>{" "}
          {isMaster && (
            <>
              <hr />
              <DragHandle section={section} />
              <hr />
              <div className={section.expanded ? "active" : ""}>
                <button
                  className={`uk-button`}
                  uk-tooltip="Expand Block"
                  onClick={() => handleExpand(section)}
                >
                  <Image src="/images/expand-block.svg" />
                </button>
              </div>{" "}
              <hr />
              <div>
                <button
                  className="uk-button"
                  onClick={() => handleDelete(section)}
                  uk-toggle="target: #master-property-delete"
                  uk-tooltip="Delete Block"
                >
                  <Image src="/images/delete-icon.svg" />
                </button>
              </div>
            </>
          )}
          {!isMaster && +section.rental_id !== 0 && (
            <div>
              <hr />
              <div>
                <button
                  className="uk-button"
                  onClick={() => handleDelete(section)}
                  uk-toggle="target: #reset-property"
                  uk-tooltip="Reset to Master"
                >
                  <Image src="/images/reset-icon.svg" />
                </button>
              </div>
            </div>
          )}
          {!isMaster && (
            <div>
              <hr />
              <div
                className="autosend-checkbox"
                uk-tooltip={section.suspended ? "Show Block" : "Hide Block"}
              >
                {isProcessing && selectedSection === section ? (
                  <div data-uk-spinner="ratio: 0.5;"></div>
                ) : (
                  <label className="setting-switch">
                    <input
                      type="checkbox"
                      onChange={(event) => handleVisible(section, event)}
                      checked={!section.suspended}
                    />
                    <span className="setting-slider setting-round"></span>
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ sections }) => {
    return (
      <div className="uk-width-2-3@s">
        {sections?.map((section, index) => {
          if (!isMaster && !!section.tags) {
            return section.tags?.some((tag) => {
              return rental?.tags?.find((item) => item.name === tag.name);
            }) ? (
              <SortableItemDiv
                key={section.welcome_gudie_question_id}
                index={index}
                section={section}
              />
            ) : (
              <></>
            );
          }
          return (
            <SortableItemDiv
              key={section.welcome_gudie_question_id}
              index={index}
              section={section}
            />
          );
        })}
      </div>
    );
  });

  const handleDeleteTab = async () => {
    if (!isMaster || !selectedTab || !guestbook) {
      return;
    }

    let tempOrder = [...guestbook.tab_order.split(",")]
      .filter((t) => t !== selectedTab.type)
      .join(",");

    let temp = { ...guestbook, tab_order: tempOrder };

    set("guestbook", temp)
      .then(() => {
        UIkit.notification("Deleting Tab Complete", "success");
      })
      .catch((err) => {
        UIkit.notification("Failed", "error");
      })
      .finally(() => {
        UIkit.modal("#master-tab-delete").hide();
        setSelectedTab((welcome_tabs || [])[0] || null);
      });
  };

  const handleEditTab = (tab) => {
    setSelectedTab(welcome_tabs.find((t) => t.type === tab.type));
  };

  const handleOrderTab = ({ oldIndex, newIndex }, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (newIndex === 0) {
      return;
    }

    let old_order = (guestbook?.tab_order || "").split(",");
    let temp = arrayMoveImmutable(old_order, oldIndex, newIndex);
    let tab_order = temp.join(",");
    let result = { ...guestbook, tab_order };
    setSettings((pre) => ({ ...pre, guestbook: result }));
    set("guestbook", result);
  };

  const DragHandleTab = SortableHandle(({ tab }) => (
    <span
      tabIndex="0"
      style={{
        cursor: "move",
        display: "inline-block",
        backgroundColor: "inherit",
      }}
    >
      {tab?.label}
    </span>
  ));

  const SortableItemTab = SortableElement(({ tab, no }) => (
    <div className="master-guide-list">
      <div
        key={no}
        className={classnames("", {
          "master-active": tab?.type === selectedTab?.type,
        })}
        style={{ textAlign: "center" }}
        onClick={() => {
          setSelectedTab(tab);
          // MasterGuideContext.setIndexOrder(no);
        }}
      >
        {isMaster ? (
          <DragHandleTab tab={tab} />
        ) : (
          <span
            tabIndex="0"
            style={{
              cursor: "initial",
              display: "inline-block",
              backgroundColor: "inherit",
            }}
          >
            {tab?.label}
          </span>
        )}
        {no === 0 || !isMaster ? (
          <span style={{ marginRight: "23px" }}></span>
        ) : (
          <>
            <span
              onClick={() => handleEditTab(tab)}
              uk-toggle="target: #editTab-modal"
              style={{ marginInline: "10px", cursor: "pointer" }}
              uk-tooltip="Edit Tab"
            >
              <Image src="/images/edit-icon.svg" />
            </span>
            <span
              onClick={() => handleEditTab(tab)}
              uk-toggle="target: #master-tab-delete"
              uk-tooltip="Delete Tab"
              style={{ cursor: "pointer" }}
            >
              <Image src="/images/delete-icon.svg" />
            </span>
          </>
        )}
      </div>
    </div>
  ));

  const SortableListTab = SortableContainer(({ tabs }) => (
    <div className="master-guide-tab">
      {tabs.map((t, i) => (
        <SortableItemTab
          tab={t}
          index={i}
          no={i}
          key={t?.type}
          disabled={i ? false : true}
        />
      ))}
    </div>
  ));

  return (
    <>
      {loading == true ? (
        <Loading />
      ) : (
        <section id="master-property-template">
          <div id="hms-page-title">
            <div className="uk-flex uk-flex-between uk-flex-middle">
              {isMaster ? (
                <h1 className="uk-heading-small">Master Home Guide</h1>
              ) : (
                <>
                  <div className="uk-flex uk-flex-middle">
                    <button
                      className="uk-button hms-img-btn go-back-arrow-btn"
                      onClick={handleBack}
                    >
                      <span uk-icon="icon: arrow-left; ratio: 1"></span>
                    </button>
                    {isEditTitle ? (
                      <div>
                        <input
                          type="text"
                          value={title}
                          style={{ width: "400px" }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <span onClick={handleSaveTitle}>
                          <Icon options="check" button={true} />
                        </span>
                        <span onClick={() => setisEditTitle(false)}>
                          <Icon options="close" button={true} />
                        </span>
                      </div>
                    ) : (
                      <>
                        <h1 className="uk-heading-small">{title}</h1>
                        <button
                          className="uk-button hms-simple-icon-btn"
                          onClick={handleTitleEdit}
                        >
                          <Image src="/images/edit-icon.svg" />
                        </button>
                      </>
                    )}
                  </div>
                  <div className="hms-page-title-btn uk-flex uk-flex-middle uk-flex-between">
                    <button
                      className="uk-button hms-btn hms-green-btn uk-margin-small-right"
                      onClick={() => setRental(rental)}
                      uk-toggle="target: #share-modal"
                      uk-tooltip="Share"
                    >
                      Share
                    </button>
                    <button className="uk-button hms-btn hms-blue-btn">
                      <a
                        href={`https://guide.ruebarue.com/rental/${rental.rental_id}`}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        Guest View
                      </a>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div id="hms-main-body">
            <div className="hms-property-tabs uk-flex uk-flex-between uk-flex-middle">
              <SortableListTab
                tabs={tabOrder.map((o) =>
                  welcome_tabs.find((t) => o === t.type),
                )}
                axis="x"
                useDragHandle
                onSortEnd={handleOrderTab}
                pressDelay={100}
              />
              {isMaster && (
                <div className="hms-tabs-plus-btn">
                  <button
                    className="uk-button hms-img-btn"
                    uk-toggle="target: #addTab-modal"
                  >
                    <span
                      uk-icon="icon: plus; ratio: 0.8"
                      className="plus-w-2"
                    ></span>
                  </button>
                </div>
              )}
            </div>
            {isMaster && (
              <div className="uk-flex uk-flex-between uk-flex-top">
                <div className="hms-guest-btn">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    uk-toggle={`target: #${MASTER_PROPERTY_GUIDE_ADD_MODAL_NAME}`}
                  >
                    <span uk-icon="icon: plus; ratio: 0.7"></span> Block
                  </button>
                </div>
              </div>
            )}
            <div className="uk-flex uk-flex-top">
              <SortableList
                sections={
                  Array.isArray(sectionOrder)
                    ? sectionOrder
                        .map((id) =>
                          mergedSections.find((s) => {
                            return (
                              +id === +s.welcome_guide_question_id ||
                              +id === +s.parent_id
                            );
                          }),
                        )
                        .filter((s) => !!s)
                    : []
                }
                useDragHandle
                onSortEnd={handleReorder}
              />
              {!isMaster && <HomeSection />}
            </div>
          </div>
        </section>
      )}

      <Share />
      <MasterPropertyGuideAdd />
      <MasterPropertyGuideEdit />
      <TabAdd />
      <TabEdit />
      <IconList />
      <MasterDelete />
      <ResetToMaster />
      <MasterTabDelete onDelete={handleDeleteTab} />
    </>
  );
};

export default PropertyGuide;
