import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { AppDefs, GuideDefs } from "./components/icons/defs";

import AppLoader from "./components/layouts/app-loader";

import { AppProviders } from "./providers";

function App() {
  return (
    <AppProviders>
      <CookiesProvider>
        <BrowserRouter>
          <AppDefs />
          <GuideDefs />

          <Switch>
            <Route path="/" render={(props) => <AppLoader {...props} />} />
          </Switch>
        </BrowserRouter>
      </CookiesProvider>
    </AppProviders>
  );
}

export default App;
