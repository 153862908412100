import React, { useRef } from "react";
import { Button } from "../../ui";
import clsx from "clsx";

const Tags = ({ value, all, onChange, readOnly = false }) => {
  const dropdownRef = useRef(null);
  const handleInputChange = async (tagId, selected) => {
    if (selected) {
      value
        ? await onChange([
            ...value,
            all.find((tagValue) => tagValue.id.toString() === tagId.toString()),
          ])
        : await onChange([
            all.find((tagValue) => tagValue.id.toString() === tagId.toString()),
          ]);
    } else {
      await onChange(
        value.filter((tag) => tag.id.toString() !== tagId.toString()),
      );
    }
  };

  return (
    <div className="uk-flex uk-flex-top">
      <div>
        <Button>
          <img src="/images/tag.svg" />
        </Button>
        <div
          data-uk-dropdown="mode: click"
          className="guest-tags-dropdown"
          ref={dropdownRef}
        >
          <ul className="uk-nav uk-dropdown-nav tags-selector">
            {all?.map((tagValue, index) => (
              <li key={index}>
                <label
                  className={clsx({
                    disabled:
                      value?.findIndex(
                        (item) =>
                          item.id === tagValue.id &&
                          item.type === "destination",
                      ) >= 0,
                  })}
                >
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    checked={
                      value?.findIndex(
                        (tag) => tag.id.toString() === tagValue.id.toString(),
                      ) >= 0
                    }
                    onChange={
                      readOnly
                        ? () => {}
                        : (event) =>
                            handleInputChange(tagValue.id, event.target.checked)
                    }
                    disabled={
                      value?.findIndex(
                        (item) =>
                          item.id === tagValue.id &&
                          item.type === "destination",
                      ) >= 0
                    }
                  />
                  {tagValue.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="hms-guest-tags">
        <div className="guest-tags">
          {value &&
            value.map((tag, index) => (
              <div
                className={clsx("uk-inline", {
                  destination: tag.type === "destination",
                })}
                key={index}
              >
                {tag.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tags;
