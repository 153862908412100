const LegacyNote = () => {
  return (
    <div className="uk-text-small uk-text-italic uk-margin-small-top">
      *Detailed reports for guestbooks, properties and destinations are only
      available from April 2020 onward. <a href="#"> Legacy Reports</a>
    </div>
  );
};

export default LegacyNote;
