import { useContext, React } from "react";
import UIkit from "uikit";
import Dialog from "../../../common/Dialog";
import { AppContexts } from "../../../../providers";

const GuideDelete = () => {
  const { set, settings, deleted, setDeleted } = useContext(
    AppContexts.SettingsContext,
  );

  const cancelDelete = () => {
    setDeleted(null);

    UIkit.modal("#destination-delete").hide();
  };

  const handleDelete = () => {
    if (deleted === null || !deleted.type) {
      return;
    }

    console.log("Deleting", deleted);

    let tabs = settings.destination_tabs || [];
    let destination = settings.destination || { tab_order: "" };
    let tab_order = destination.tab_order
      .split(",")
      .filter((t) => deleted.type !== t)
      .join(",");

    set(["destination", "destination_tabs"], {
      destination_tabs: tabs.filter((t) => t.type !== deleted.type),
      destination: { ...destination, tab_order: tab_order },
    }).then(() => {
      console.log("clearing deleted");

      setDeleted(null);
    });

    UIkit.modal("#destination-delete").hide();
  };

  return (
    <>
      <Dialog id="destination-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Area Guide Tab</h2>
          <p>
            Are you sure you want to delete?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default GuideDelete;
