// Import package components
import { useContext, useEffect, useMemo } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const ContactDelete = () => {
  const {
    templatesCtx: { response, deleteTemplate },
  } = useContext(AppContexts.TemplatesContext);

  const handleDelete = () => {
    deleteTemplate(response.saved_response_id, "saved_responses");
    UIkit.modal("#modal-template-delete").hide();
  };

  return (
    <>
      <Dialog id="modal-template-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Saved Response</h2>
          <p>
            Are you sure you want to delete <b>{response.name}</b>?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default ContactDelete;
