import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContexts } from "../../../providers";
import classnames from "classnames";
import UIkit from "uikit";

const EditAdd = () => {
  const { set, get, settings } = useContext(AppContexts.SettingsContext);
  const { refresh } = useContext(AppContexts.AuthContext);
  const { selectedTab, setSelectedTab } = useContext(
    AppContexts.RentalsContext,
  );

  let [label, setLabel] = useState(selectedTab?.label || "");
  let [errors, setErrors] = useState([]);
  let [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!settings["welcome_tabs"]) {
      setRefreshing(true);
      get([...Object.keys(settings), "welcome_tabs"]).then(() => {
        setRefreshing(false);
      });
    }
  }, []);

  useEffect(() => {
    setLabel(selectedTab?.label || "");
  }, [selectedTab]);

  const { handleSubmit } = useForm();

  const submit = () => {
    if (label.trim() === "") {
      setErrors(["label"]);
    }

    let temp_tabs = JSON.parse(JSON.stringify(settings.welcome_tabs));
    temp_tabs.map((t) => {
      if (t.type === selectedTab.type) {
        t.label = label;
      }
    });

    set(["welcome_tabs"], { welcome_tabs: temp_tabs }).then(async () => {
      UIkit.modal("#editTab-modal").hide();
      UIkit.notification("Updated Successfully", "success");
      setLabel("");

      await refresh();
    });
  };

  const edit = (e) => {
    setLabel(e.target.value);
    setErrors([]);
  };

  const handleCancel = () => {
    setLabel(selectedTab?.label || "");
  };

  return (
    <div
      id="editTab-modal"
      className="uk-modal-full hms-modals uk-modal"
      uk-offcanvas="overlay: true; flip: true;"
    >
      <div className="uk-offcanvas-bar">
        <div>
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Edit Tab</h2>
          </div>
          <div className="uk-modal-body">
            <div>
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleSubmit(submit)}
              >
                <div className="uk-width-1-1">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">Name*</label>
                    <input
                      className={classnames("uk-input", { error: errors.type })}
                      type="text"
                      value={label}
                      onInput={edit}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    {refreshing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdd;
