import { useContext, useState, useEffect } from "react";

import { AppContexts } from "../../providers";

import MainBody from "../common/MainBody";
import UpdatePasswordForm from "./forms/update-password";
import UpdateEmailForm from "./forms/update-email";
import UpdateImageForm from "./forms/update-profile-image";

const Profile = () => {
  useEffect(() => {
    document.title = "Profile - RueBaRue";
  }, []);

  return (
    <>
      <MainBody id="hms-profile-settings-template" title="Profile Settings">
        <div className="uk-card uk-card-small uk-card-default hms-form-card">
          <div className="uk-flex">
            <div className="uk-width-3-5 profile-setting-left">
              <div className="profile-form">
                <div className="uk-width-1-1 uk-margin-bottom hms-headings">
                  <h3>Profile Picture</h3>
                </div>

                <UpdateImageForm />
              </div>

              <div className="uk-width-1-1 uk-margin-top uk-margin-bottom">
                <hr />
              </div>

              <div className="profile-form">
                <div className="uk-width-1-1 uk-margin-small-top uk-margin-bottom hms-headings">
                  <h3>Update Email</h3>
                </div>

                <UpdateEmailForm />
              </div>

              <div className="uk-width-1-1 uk-margin-top uk-margin-small-bottom">
                <hr />
              </div>

              <div className="profile-form">
                <div className="uk-width-1-1 uk-margin-bottom hms-headings">
                  <h3>Update Password</h3>
                </div>

                <UpdatePasswordForm />
              </div>
            </div>
          </div>
        </div>
      </MainBody>
    </>
  );
};

export default Profile;
