// Import package components
import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import moment from "moment";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import AddTextLine from "../common/AddTextLine";

// Import surveys components
import SurveyAdd from "./survey_add";
import SurveyDelete from "./delete";

const Surveys = () => {
  const [criteria, setCriteria] = useState({
    search_term: "",
    sort: "updated_on",
    page: 0,
  });

  const surveysContext = useContext(AppContexts.SurveysContext);
  const {
    listSurveys: surveys,
    setRecordsPerPage,
    setPage,
    getSurveys,
    setIsNew,
    initSurvey,
    insertSurvey,
    cloneSurvey,
    updateSurvey,
    setSurvey,
    flagIsProcessing,
  } = surveysContext;

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const filteredSurveys = useMemo(
    () =>
      surveys
        .filter((survey) => survey.deleted == false)
        .sort((prev, next) => {
          if (criteria.sort === "updated_on") {
            return next.updated_at?.localeCompare(prev.updated_at);
          } else if (criteria.sort === "name") {
            return prev.name?.localeCompare(next.name);
          }
        }),
    [surveys, criteria],
  );

  const visibleSurveys = useMemo(
    () =>
      filteredSurveys.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [filteredSurveys, criteria, recordsPerPage],
  );

  useEffect(() => {
    document.title = "Surveys - RueBaRue";
    setRecordsPerPage(recordsPerPage);
    setPage(criteria.page);
    getSurveys();
  }, [recordsPerPage, criteria.page]);

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });
  const handleAddSurvey = useCallback(() => {
    setIsNew(true);
    initSurvey();
  }, []);

  const handleCloneSurvey = useCallback(async (survey) => {
    let result = await cloneSurvey(survey.survey_id);
  }, []);

  const handleDeleteSurvey = useCallback((survey) => {
    survey.deleted = true;
    setSurvey(survey);
  }, []);

  const surveyRenderer = useCallback(
    (survey, index) => (
      <tr key={index}>
        <td>
          <div className="semi-bold-font">{survey.name}</div>
        </td>
        <td>{survey.survey_results_count || 0}</td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <Link
                className="uk-button"
                to={`/surveys/${survey.survey_id}/result`}
                uk-tooltip="Responses"
              >
                <Image src="/images/responses.svg" />
              </Link>
            </div>
            <div className="uk-inline">
              <Link
                className="uk-button"
                to={`/surveys/${survey.survey_id}/preview`}
                target="_blank"
                uk-tooltip="Preview"
              >
                <Image src="/images/preview.svg" />
              </Link>
            </div>
            <div className="uk-inline">
              <Link
                className="uk-button"
                to={`/surveys/${survey.survey_id}/edit`}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </Link>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                onClick={() => handleCloneSurvey(survey)}
                uk-tooltip="Clone"
              >
                <Image src="/images/clone.svg" />
              </button>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #survey-delete-modal"
                onClick={() => handleDeleteSurvey(survey)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [],
  );

  return (
    <>
      <section id="schedule-a-message">
        <AddTextLine feature="Surveys" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Surveys</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #survey-add-modal"
                onClick={handleAddSurvey}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Survey
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-expand">Name</th>
                    <th className="uk-table-shrink">RESPONSES</th>
                    <th className="actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {flagIsProcessing == true ? (
                    TableLoading(3)
                  ) : visibleSurveys.length > 0 ? (
                    visibleSurveys.map(surveyRenderer)
                  ) : (
                    <tr>
                      <td colSpan={3} style={{ padding: "30px 25px" }}>
                        No surveys.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={filteredSurveys.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <SurveyAdd />
      <SurveyDelete />
    </>
  );
};

export default Surveys;
