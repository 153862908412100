// Import package components
import { createContext, useState } from "react";
import { useCookies } from "react-cookie";

import WorkOrderService from "../services/workorder.service";

export const WorkOrderContext = createContext();
export const WorkOrderProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [workOrder, setWorkOrder] = useState(undefined);
  const [workOrders, setWorkOrders] = useState([]);
  const [assigners, setAssigners] = useState({
    recipients: [],
    contacts: [],
    assignees: [],
  });
  const [tags, setTags] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const contextWorkOrder = {
    isNew,
    setIsNew,
    workOrder,
    setWorkOrder,
    total,
    workOrders,
    assigners,
    tags,
    loading,
    isProcessing,
    setProcessing,

    initWorkOrder: () => {
      setWorkOrder({
        account_id: 0,
        guest_reservation_id: 0,
        rental_id: 0,
        message_id: 0,
        created_by: 0,
        ticket_number: 0,
        status: "new",
        priority: "medium",
        summary: "",
        description: "",
      });
    },

    getWorkOrder: (id, slug = undefined) => {
      WorkOrderService.getWorkOrder(id, slug)
        .then((res, err) => {
          setWorkOrder(res.data);
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    insertWorkOrder: async (obj) => {
      setProcessing(true);
      try {
        const res = await WorkOrderService.insertWorkOrder(obj);
        setWorkOrders((workOrders) => [...workOrders, res.data]);
        setProcessing(false);
        return res.data;
      } catch (err) {
        console.log(err);
        setProcessing(false);
      }
    },

    updateWorkOrder: async (id, obj) => {
      try {
        setProcessing(true);
        const res = await WorkOrderService.updateWorkOrder(id, obj);
        const newOrder = res.data;
        setWorkOrder({ ...workOrder, ...newOrder });
        // const idx = workOrders.findIndex(
        //   (order) => order.id.toString() === newOrder.id.toString()
        // );
        // console.log("idx:", idx);
        // if (idx > -1) {
        //   let updated = workOrders;
        //   updated[idx] = res.data;
        //   console.log("updated:", updated);
        //   setWorkOrders(updated);
        // }
        if (newOrder) {
          setWorkOrders((prev) =>
            prev.map((item) =>
              +item.id === +newOrder.id
                ? { ...newOrder, work_order_number: item.work_order_number }
                : item,
            ),
          );
        }
        setProcessing(false);
        return res.data;
      } catch (err) {
        setProcessing(false);
        console.log(err);
      }
    },

    deleteWorkOrder: async (id) => {
      setLoading(true);
      try {
        const res = await WorkOrderService.deleteWorkOrder(id);
        setLoading(false);
        setWorkOrders((orders) => orders.filter((order) => order.id !== id));
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },

    getWorkOrders: (slug = undefined, criteria, recordsPerPage) => {
      setLoading(true);
      WorkOrderService.getWorkOrders(slug, criteria, recordsPerPage || 20)
        .then((res, err) => {
          let orders = res.data;
          if (orders.length > 0) {
            setTotal(orders[0].total);
          } else {
            setTotal(0);
          }
          setWorkOrders(orders);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    },

    getAssigners: (slug) =>
      WorkOrderService.getAssigners(slug)
        .then((res, err) => {
          setAssigners(res.data);
        })
        .catch((err) => {
          console.log(err);
        }),

    getTags: (slug) =>
      WorkOrderService.getTags(slug)
        .then((res, err) => {
          setTags(res.data);
        })
        .catch((err) => {
          console.log(err);
        }),

    commentWorkOrder: (id, data, slug = undefined) => {
      setLoading(true);
      WorkOrderService.commentWorkOrder(id, data, slug)
        .then((res, err) => {
          setLoading(false);
          setWorkOrder(res.data);
          return res.data;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  };

  return (
    <WorkOrderContext.Provider value={contextWorkOrder}>
      {children}
    </WorkOrderContext.Provider>
  );
};
