import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { classes } from "../../../utils/classes";
import { AppContexts } from "../../../providers";
import { Link } from "../../ui-kit/Link";
import UIkit from "uikit";

const ChangePasswordForm = (props) => {
  const { handleSubmit } = useForm();

  let authCtx = useContext(AppContexts.AuthContext);

  let [creds, setCreds] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  let [status, setStatus] = useState("initial");
  let [errors, setErrors] = useState([]);
  let [submitted, setSubmitted] = useState(false);
  let [completed, setCompleted] = useState(false);

  let [valid, setValid] = useState({
    minLength: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
    match: false,
  });

  useEffect(() => {
    let validation = Object.keys(valid).reduce((a, c) => {
      a[c] = false;
      return a;
    }, {});
    let { password, password_confirmation } = creds;

    if (password === "" && password_confirmation === "") {
      return setValid(validation);
    }

    if (password === password_confirmation) {
      validation.match = true;
    }

    if (password.length >= 8) {
      validation.minLength = true;
    }

    if (/[A-Z]+/g.test(password)) {
      validation.upper = true;
    }

    if (/[a-z]+/g.test(password)) {
      validation.lower = true;
    }

    if (/[0-9]+/g.test(password)) {
      validation.number = true;
    }

    if (/[!@#\$%\^&_]/g.test(password)) {
      validation.special = true;
    }

    setValid(validation);
  }, [creds]);

  const change = () => {
    let errs = [];

    Object.keys(creds).map((k) => {
      if (!creds[k]) {
        errs.push(k);
      }
    });

    setErrors(errs);

    if (errs.length !== 0) {
      return;
    }

    setStatus("inflight");
    authCtx
      .change_password(creds)
      .then((resp, err) => {
        setStatus("complete");
        setCreds({
          old_password: "",
          password: "",
          password_confirmation: "",
        });
        UIkit.notification("Password has been changed", "success");
      })
      .catch((err) => {
        console.warn(err);
        setStatus("error");
        UIkit.notification(`Failed: ${err}`, "error");
      });
  };

  const update = (field, e) => {
    setCreds({ ...creds, [field]: e.target.value });

    if (errors.includes(field)) {
      setErrors(errors.filter((k) => k !== field));
    }
  };

  return (
    <form className="auth uk-form-stacked" onSubmit={handleSubmit(change)}>
      {status === "complete" && <p>Your password has been updated</p>}

      {status === "error" && <p>There was an error updating your password</p>}

      <div
        className={classes(
          { error: errors.includes("old_password") },
          "uk-margin",
        )}
      >
        <label className="uk-form-label">Current Password</label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="password"
            value={creds.old_password}
            onInput={(e) => {
              update("old_password", e);
            }}
          />
        </div>
      </div>

      <div
        className={classes({ error: errors.includes("password") }, "uk-margin")}
      >
        <label className="uk-form-label">Password</label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="password"
            value={creds.password}
            onInput={(e) => {
              update("password", e);
            }}
          />
        </div>
      </div>

      <div
        className={classes(
          { error: errors.includes("password_confirmation") },
          "uk-margin",
        )}
      >
        <label className="uk-form-label">Password Confirmation</label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="password"
            value={creds.password_confirmation}
            onInput={(e) => {
              update("password_confirmation", e);
            }}
          />
        </div>
      </div>

      <div className="uk-margin">
        Password Rules:
        <ul className="password-validation">
          <li className={classes({ complete: valid.minLength })}>
            At least 8 characters
          </li>
          <li className={classes({ complete: valid.upper })}>
            At least one capital letter
          </li>
          <li className={classes({ complete: valid.lower })}>
            At least one lowercase letter
          </li>
          <li className={classes({ complete: valid.number })}>
            At least one number
          </li>
          <li className={classes({ complete: valid.special })}>
            At least one of the following !@#$%^&_
          </li>
          <li className={classes({ complete: valid.match })}>
            Passwords must match
          </li>
        </ul>
      </div>

      <button
        className={`uk-button uk-button-# hms-btn hms-red-btn ${
          status === "inflight" ? "disabled" : ""
        }`}
      >
        Change Password
      </button>
    </form>
  );
};

export default ChangePasswordForm;
