// Import package components
import { createContext, useState } from "react";

import SavedResponseService from "../services/responses.service";

export const SavedResponseContext = createContext();
export const SavedResponseProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [listResponses, setListResponses] = useState([]);
  const [response, setResponse] = useState({});

  const [loading, setLoading] = useState(true);

  const contextResponse = {
    isEditing,
    setIsEditing,
    isNew,
    setIsNew,
    listResponses,
    response,
    setResponse,

    initResponse: () => {
      setResponse({
        name: "",
        response: "",
      });
    },

    selectResponseList: () => {
      setLoading(true);
      SavedResponseService.selectSavedResponseList()
        .then((res, err) => setListResponses(res.data))
        .catch((err) => console.log(err));
      setLoading(false);
    },

    insertResponse: (objResponse) =>
      SavedResponseService.insertSavedResponse(objResponse)
        .then((res, err) => {
          setListResponses([...listResponses, res.data]);
          return res.data;
        })
        .catch((err) => console.log(err)),
    updateResponse: (objResponse) => {
      delete objResponse.created_at;
      delete objResponse.updated_at;
      SavedResponseService.updateSavedResponse(objResponse)
        .then((res) =>
          setListResponses(
            listResponses.map((indexResponse) =>
              indexResponse.id === res.data.id ? res.data : indexResponse,
            ),
          ),
        )
        .catch((err) => console.log(err));
    },

    deleteResponse: () => {
      SavedResponseService.deleteSavedResponse(response)
        .then((res, err) =>
          setListResponses(
            listResponses.filter(
              (indexResponse) => indexResponse.id !== response.id,
            ),
          ),
        )
        .catch((err) => console.log(err));
    },

    loading,
  };

  return (
    <SavedResponseContext.Provider value={contextResponse}>
      {children}
    </SavedResponseContext.Provider>
  );
};
