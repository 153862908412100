import React, { useEffect, useMemo, useContext, useState } from "react";
import { AppContexts } from "../../../providers";

const SelectTemplate = ({ disabled = false, onChange, template }) => {
  const {
    templatesCtx: { get, templates },
  } = useContext(AppContexts.TemplatesContext);

  useEffect(() => {
    get(["msg_templates"]);
  }, []);

  const msgTemplates = useMemo(() => {
    if (!templates) {
      return [];
    } else {
      if (!templates.msg_templates) {
        return [];
      } else {
        return templates.msg_templates.sort((a, b) =>
          a.subject.localeCompare(b.subject),
        );
      }
    }
  }, [templates]);

  const converted_msgTemplates = useMemo(
    () =>
      msgTemplates.map((template) => {
        switch (template.folder) {
          case "scheduledMsg":
            return { ...template, folder: "Scheduler" };
          case "alert":
            return { ...template, folder: "Alerts" };
          case "scheduleAhead":
            return { ...template, folder: "Schedule Ahead" };
          case "extendYourStay":
            return { ...template, folder: "Extend Guest Stays" };
          default:
            return template;
        }
      }),
    [msgTemplates],
  );

  const templates_folder = useMemo(() => {
    let folders = [];
    converted_msgTemplates.forEach((template) => {
      if (!folders.find((folder) => folder === template.folder)) {
        folders.push(template.folder);
      }
    });
    return folders;
  }, [converted_msgTemplates]);

  const handleTemplateChange = (e) => {
    let temp = msgTemplates.find((v) => v.id == e.target.value);
    onChange(temp);
  };

  return (
    <>
      <div className="uk-width-1-1">
        <label className="uk-form-label">Select Text From a Template</label>
        <div className="uk-form-controls uk-width-1-2">
          <div uk-form-custom="target: > * > span:first-child">
            <select
              disabled={disabled}
              onChange={handleTemplateChange}
              value={template ? template.id : ""}
            >
              <option value="">Select One</option>
              {templates_folder.map((folder, idx) => {
                return (
                  <React.Fragment key={`template_${idx}`}>
                    <optgroup label={folder}></optgroup>
                    {msgTemplates
                      .filter((msgtem) => msgtem.folder === folder)
                      .map((msg, index) => (
                        <option value={msg.id} key={`template_option_${index}`}>
                          {msg.name}
                        </option>
                      ))}
                  </React.Fragment>
                );
              })}
            </select>
            <button
              className="uk-button uk-button-default"
              type="button"
              tabIndex="-1"
            >
              <span>{template ? template.name : "Select One"}</span>
              <span
                uk-icon="icon: chevron-down"
                className="hms-align-right"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTemplate;
