import React, { useContext } from "react";
import moment from "moment";
import _classNames from "classnames";
// import { setClassNames } from "../../utils/set-class-names";
import { AppContexts } from "../../../providers";

const TrialAccount = () => {
  const { user } = useContext(AppContexts.AuthContext);
  return (
    <>
      {(user?.details.type === "trial" ||
        user?.details.type === "trial-full") &&
        moment(user.details.trial_end) > moment() && (
          <div className="uk-card uk-card-small uk-card-default hms-card trial-account-banner">
            <div className="uk-flex uk-flex-center">
              <div>
                <u>
                  {moment(user?.details.trial_end).diff(moment(), "days")} days
                </u>{" "}
                left in your free trial. Please contact{" "}
                <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a> to
                start your subscription.
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default TrialAccount;
