import {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
  forwardRef,
} from "react";
import { useHistory } from "react-router-dom";
import { AppContexts } from "../../../providers";
import { useForm } from "react-hook-form";
import { Icon } from "uikit-react";
import AddQuestion from "../question_add";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import MultipleChoice from "../question_types/multiple-choice";
import ShortAnswer from "../question_types/short-answer";
import Checkboxes from "../question_types/checkboxes";
import Range from "../question_types/range";
import QuestionDelete from "../question_delete";
import moment from "moment";

const SurveyEdit = () => {
  const {
    getSurvey,
    updateSurvey,
    setSurvey,
    setIsNew,
    setQuestion,
    initQuestion,
    setQuestionNo,
  } = useContext(AppContexts.SurveysContext);
  const surveysContext = useContext(AppContexts.SurveysContext);
  const history = useHistory();
  const [editName, setEditName] = useState(false);
  const [editIntro, setEditIntro] = useState(false);
  const { register, setValue, getValues } = useForm();

  useEffect(async () => {
    document.title = "Edit Survey - RueBaRue";
    let survey_id = history.location.pathname.split("/")[2];
    await getSurvey(survey_id);
  }, []);

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);

  const survey = useMemo(() => {
    if (!surveysContext.survey) {
      return {};
    } else {
      let temp = surveysContext.survey;
      let realQuestions = [];
      if (surveysContext.survey.question_order) {
        let orders = surveysContext.survey.question_order.split(",");
        realQuestions = orders
          .map((order_id) => {
            return surveysContext.survey.questions?.find(
              ({ survey_question_id, deleted }) =>
                survey_question_id.toString() === order_id.toString() &&
                deleted === false
            );
          })
          .filter((item) => !!item);
      }
      temp.questions = realQuestions;
      temp.questions.forEach((question) => {
        let realOptions = [];
        if (question.option_order) {
          let orders = question.option_order.split(",");
          realOptions = orders
            .map((order_id) => {
              return question.question_options?.find(
                ({ survey_question_option_id, deleted, type }) => {
                  return (
                    (+survey_question_option_id == +order_id ||
                      +type == +order_id) &&
                    deleted == false
                  );
                }
              );
            })
            .filter((item) => !!item);
          question.question_options = realOptions;
        }
      });
      return temp;
    }
  }, [surveysContext.survey]);

  useEffect(() => {
    console.log(survey);
  }, [survey]);

  const handleEditName = async () => {
    let name = getValues("name");
    let sv = { ...survey };
    delete sv.questions;
    let res = await updateSurvey({
      ...sv,
      name: name,
    });
    setSurvey((prev) => {
      return { ...prev, ...res };
    });
    setValue("name", name);
    setEditName(false);
  };

  const handleEditIntro = async () => {
    let intro = getValues("intro");
    let sv = { ...survey };
    delete sv.questions;
    let res = await updateSurvey({
      ...sv,
      intro: intro,
    });
    setSurvey((prev) => {
      return { ...prev, ...res };
    });
    setValue("intro", intro);
    setEditIntro(false);
  };

  const handleEditQuestion = (index) => {
    setIsNew(false);
    setQuestion(survey?.questions[index]);
  };

  const handleAddQuestion = () => {
    setIsNew(true);
    initQuestion();
  };

  const orderOptions = (options, order) => {
    return order
      .map((id) =>
        (options || []).find((o) => {
          return +o.type === +id || +o.survey_question_option_id === +id;
        })
      )
      .filter((o) => {
        return !!o;
      });
  };

  const DragHandle = SortableHandle(() => (
    <div>
      <button type="button" className="uk-button" uk-tooltip="Move">
        <img src="/images/move.svg" />
      </button>
    </div>
  ));

  const SortableItem = SortableElement(
    forwardRef(({ question, no }, ref) => {
      return (
        <div
          className="uk-card uk-card-small uk-card-default hms-property-card"
          key={no}
          ref={ref}
        >
          <div className="uk-flex uk-flex-between">
            <div className="hms-property-card-body">
              <div className="hms-property-card-content">
                <h4 className="card-heading-small">{question?.question}</h4>
                <div className="hms-small-note">{question?.help_text}</div>
                <div>
                  {question?.question_type === "multiple-choice" && (
                    <MultipleChoice
                      options={orderOptions(
                        question?.question_options,
                        (question?.option_order || "").split(",")
                      )}
                      index={no}
                      other={question?.include_other}
                    />
                  )}
                  {question?.question_type === "short-answer" && (
                    <ShortAnswer />
                  )}
                  {question?.question_type === "checkboxes" && (
                    <Checkboxes
                      options={orderOptions(
                        question?.question_options,
                        (question?.option_order || "").split(",")
                      )}
                      index={no}
                      other={question?.include_other}
                    />
                  )}
                  {question?.question_type === "range-5" && (
                    <Range question={question} index={no} type={5} />
                  )}
                  {question?.question_type === "range-10" && (
                    <Range question={question} index={no} type={10} />
                  )}
                </div>
              </div>
            </div>
            <div className="hms-property-card-btn">
              <div>
                <button
                  type="button"
                  className="uk-button"
                  uk-toggle="target: #modal-question-add"
                  onClick={() => handleEditQuestion(no)}
                  uk-tooltip="Edit"
                >
                  <img src="/images/edit-icon.svg" />
                </button>
              </div>
              <hr />
              <DragHandle />
              <hr />
              <div>
                <button
                  type="button"
                  className="uk-button"
                  uk-toggle="target: #question-delete-modal"
                  onClick={() => setQuestionNo(no)}
                  uk-tooltip="Delete"
                >
                  <img src="/images/delete-icon.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    })
  );

  const SortableList = SortableContainer(({ questions, order }) => {
    return (
      <div>
        <div className="uk-width-1-1">
          {order.split(",")?.map((id, i) => {
            let qs = (questions || []).find(
              (q) => +q.survey_question_id === +id
            );
            if (qs) {
              return (
                <SortableItem
                  question={qs}
                  no={i}
                  index={i}
                  key={`item-${i}`}
                />
              );
            }
          })}
        </div>
      </div>
    );
  });

  const handleReorder = ({ oldIndex, newIndex }) => {
    let temp = arrayMoveImmutable(survey.questions, oldIndex, newIndex);
    let order = temp.map((item) => item.survey_question_id).join();
    let sv = { ...survey, question_order: order };

    setSurvey((prev) => {
      return {
        ...prev,
        question_order: order,
      };
    });

    delete sv.questions;
    updateSurvey(sv);
  };

  return (
    <>
      <div id="surveys-detail-template">
        <div id="hms-page-title">
          <div className="uk-flex">
            <button
              type="button"
              className="uk-button hms-img-btn go-back-arrow-btn"
              onClick={handleBack}
            >
              <span uk-icon="icon: arrow-left; ratio: 1"></span>
            </button>
            <div className="surveys-detail-body">
              <div className="uk-flex uk-flex-middle">
                {editName === true && (
                  <>
                    <input
                      type="text"
                      {...register("name")}
                      style={{ width: `${survey?.name.length + 10}ch` }}
                    />
                    <span onClick={handleEditName}>
                      <Icon options="check" button={true} />
                    </span>
                    <span onClick={() => setEditName(false)}>
                      <Icon options="close" button={true} />
                    </span>
                  </>
                )}
                {editName === false && (
                  <>
                    <h1 className="uk-heading-small">{survey?.name}</h1>
                    <button
                      type="button"
                      className="uk-button hms-simple-icon-btn"
                      onClick={() => {
                        setValue("name", survey?.name);
                        setEditName(true);
                      }}
                    >
                      <img src="/images/edit-icon.png" />
                    </button>
                  </>
                )}
              </div>
              <div className="uk-flex uk-flex-middle">
                {editIntro === true && (
                  <>
                    <input
                      type="text"
                      {...register("intro")}
                      style={{
                        width: `${
                          survey.intro !== "" && survey.intro
                            ? survey.intro.length + 10
                            : 30
                        }ch`,
                      }}
                    />
                    <span onClick={handleEditIntro}>
                      <Icon options="check" button={true} />
                    </span>
                    <span onClick={() => setEditIntro(false)}>
                      <Icon options="close" button={true} />
                    </span>
                  </>
                )}
                {editIntro === false &&
                  (survey?.intro == "" || survey.intro == null ? (
                    <h3>Add Intro Text for Your Survey</h3>
                  ) : (
                    <h3>{survey?.intro}</h3>
                  ))}
                {editIntro === false && (
                  <button
                    type="button"
                    className="uk-button hms-simple-icon-btn"
                    onClick={() => {
                      setEditIntro(true);
                      setValue(
                        "intro",
                        survey.intro == null ? "" : survey.intro
                      );
                    }}
                  >
                    <img src="/images/edit-icon.png" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #modal-question-add"
                onClick={handleAddQuestion}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> QUESTION
              </button>
            </div>
          </div>
          <SortableList
            questions={survey?.questions}
            order={survey?.question_order || ""}
            useDragHandle={true}
            onSortEnd={handleReorder}
          />
          <div className="s-msg-last-updated">
            {survey?.updated_at
              ? "Last Updated: " +
                moment(survey?.updated_at).format("MM/DD/YYYY hh:mm a")
              : ""}
          </div>
        </div>
      </div>
      <AddQuestion />
      <QuestionDelete />
    </>
  );
};

export default SurveyEdit;
