import Column from "./Column";

export const FormInputWrapper = ({ label, size = "auto", children }) => {
  return (
    <Column size={size}>
      <label className="uk-form-label">{label}</label>
      {children}
    </Column>
  );
};

export default FormInputWrapper;
