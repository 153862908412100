// Import package components
import { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Flex } from "uikit-react";
import { useCookies } from "react-cookie";

import Pagination from "../../common/Pagination";
import { AppContexts } from "../../../providers";
import RecordsPerPage from "../../common/RecordsPerPage";

const Macros = () => {
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { get, rentals } = useContext(AppContexts.RentalsContext);
  const { macros, getMacros } = ReportsContext;

  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const handlePageChange = useCallback((page) => setPage(page), []);

  useEffect(() => {
    get();
    getMacros();
  }, []);

  const page_rentals = useMemo(
    () => rentals?.slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [page, rentals, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Property Macros</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-hover uk-table-divider hms-table">
            <thead>
              <tr>
                <th>Property ID</th>
                {macros.map((item, index) => (
                  <th key={`macros_${index}`}>{item.macro}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {page_rentals.map((rental, r_index) => (
                <tr key={`rental_${r_index}`}>
                  <td>
                    <a href={"/properties/" + rental.rental_id}>
                      {rental.pms_id ? rental.pms_id : rental.rental_id}
                    </a>
                  </td>
                  {macros.map((macro, m_index) => (
                    <td className="reports-table-wrap-overflow">
                      {
                        macro.content.find(
                          (item) => item.rental_id == rental.rental_id,
                        )?.content
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={rentals.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default Macros;
