import { useEffect, useContext } from "react";

import CustomBreadcrumb from "../common/CustomBreadcrumb";
import ReportsNavbar from "./Layout/navbar";
// import Payment from "./payment";
// import Subscription from "./subscription";
// import Invoices from "./invoices";
// import Account from "./account";

import { AppContexts } from "../../providers";

const Billing = () => {
  const { loading, customerUrl, getCustomerUrl } = useContext(
    AppContexts.StripeContext,
  );

  useEffect(() => {
    document.title = "Billing - RueBaRue";
    getCustomerUrl();
  }, []);

  return (
    <section id="team-schedule-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Billing</h1>
      </div>
      <div id="hms-main-body">
        <div className="billing">
          <p>
            RueBaRue uses Stripe to manage billing. Go to the Billing Portal to
            view your current subscription plan and invoice history, and add or
            update your credit card information.
          </p>
          <div className="billing-button">
            {!loading && (
              <a href={customerUrl} target="_blank">
                <button className="uk-button hms-btn hms-red-btn">
                  Open Billing Portal
                </button>
              </a>
            )}
            {!!loading && <div data-uk-spinner="ratio: 0.5;"></div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Billing;
