import React from 'react';
import _classNames from 'classnames';
import { Link as RawLink } from 'react-router-dom';
import { setClassNames } from '../utils/set-class-names';

export function Link(props: LinkProps) {
  const _setClassNames = (): string => {
    return _classNames({
      [`uk-link-${props.type}`]: !!props.type,
      [`${setClassNames(props)}`]: true,
    });
  };

  let attrs = {...props}

  attrs["id"] = props.id || null
  attrs["style"] = props.style || null
  attrs["data-uk-toggle"] = props.toggleOptions || null
  attrs["href"] = props.href || null
  attrs["className"] = `${_setClassNames()}`

  return (
    <RawLink {...attrs}>
      {props.children}
    </RawLink>
  );
}