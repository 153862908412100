// Import package components
import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import moment from "moment";
import classnames from "classnames";

import { Link } from "../../ui";

import { AppContexts } from "../../providers";

// Import common components
import RecordsPerPage from "../common/RecordsPerPage";
import Pagination from "../common/Pagination";
import AddTextLine from "../common/AddTextLine";

// Import saved-responses components
import SavedResponseAdd from "./saved_response_add";
import SavedResponseDelete from "./delete";

const SavedReponses = () => {
  const [criteria, setCriteria] = useState({
    search_term: "",
    filters: "custom",
    preset: "departing",
    end_type: "check_in",
    sort: "updated_on",
    page: 0,
    tag: "all",
  });
  const {
    templatesCtx: {
      get,
      templates,
      setIsNew,
      initResponse,
      setResponse,
      response,
    },
  } = useContext(AppContexts.TemplatesContext);

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const responses = useMemo(() => {
    if (Object.keys(templates).length == 0) {
      return [];
    } else {
      if (!templates.saved_responses) {
        return [];
      } else {
        return templates.saved_responses
          .filter((response) => {
            if (
              response.name
                .toLowerCase()
                .search(criteria.search_term.toLowerCase()) >= 0
            )
              return true;
            return false;
          })
          .sort((prev, next) => {
            if (criteria.sort === "updated_on")
              return next.updated_at?.localeCompare(prev.updated_at);
            if (criteria.sort === "name")
              return prev.name.localeCompare(next.name);
            return true;
          });
      }
    }
  }, [templates, criteria]);

  const visibleResponses = useMemo(
    () =>
      responses.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [responses, criteria, recordsPerPage],
  );

  useEffect(() => {
    get(["saved_responses"]);
    document.title = "Saved Responses - RueBaRue";
  }, []);

  const handleAddResponse = useCallback(() => {
    setIsNew(true);
    initResponse();
  }, []);

  const handleEditResponse = useCallback((response) => {
    setIsNew(false);
    setResponse(response);
  });

  const handleDeleteResponse = useCallback((response) => {
    setResponse(response);
  });

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const responseRenderer = useCallback(
    (response, index) => (
      <tr key={index}>
        <td>
          <div className="semi-bold-font">{response.name}</div>
        </td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #saved-response-modal"
                onClick={() => handleEditResponse(response)}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </button>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-template-delete"
                onClick={() => handleDeleteResponse(response)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [],
  );

  return (
    <>
      <section id="schedule-a-message">
        <AddTextLine feature="Saved Responses" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Saved Responses</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #saved-response-modal"
                onClick={handleAddResponse}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Saved Response
              </button>
              <a
                href="https://knowledge.ruebarue.com/sample-saved-responses"
                style={{ color: "black" }}
                target="_blank"
              >
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Use saved responses in Messages. Click to view sample saved responses."
                />
              </a>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <Flex alignment="between top" className="guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {criteria.search_term && criteria.search_term !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_term", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name"
                          value={criteria.search_term}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "search_term",
                              event.target.value,
                            )
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "updated_on",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "updated_on")
                            }
                          >
                            Last Updated
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() => handleCriteriaChange("sort", "name")}
                          >
                            Name
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-expand">Name</th>
                    <th className="actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleResponses.length > 0 ? (
                    visibleResponses.map(responseRenderer)
                  ) : (
                    <tr>
                      <td colSpan={2} style={{ padding: "30px 25px" }}>
                        No saved responses matching your current search.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={responses.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <SavedResponseAdd />
      <SavedResponseDelete />
    </>
  );
};

export default SavedReponses;
