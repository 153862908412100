import React, { useContext, useEffect, useMemo } from "react";

import { Button } from "../../ui";
import { AppContexts } from "../../providers";

const TagsList = ({ all, disabled }) => {
  const { selectedContacts, updateContact } = useContext(
    AppContexts.ContactsContext,
  );

  const checkedAll = useMemo(() => {
    return all.map((tagValue) => {
      return selectedContacts.every((contact) =>
        contact.tags?.find((tag) => tag.id == tagValue.id),
      );
    });
  }, [selectedContacts]);

  const handleMultiTag = (event, tagValue) => {
    let contacts;
    if (event.target.checked) {
      contacts = selectedContacts.filter(
        (contact) => !contact.tags?.find((tag) => tag.id == tagValue.id),
      );
      contacts = contacts.map((contact) => {
        return contact.tags
          ? {
              ...contact,
              tags: [...contact.tags, tagValue],
            }
          : {
              ...contact,
              tags: [tagValue],
            };
      });
    } else {
      contacts = selectedContacts.filter((contact) =>
        contact.tags?.find((tag) => tag.id == tagValue.id),
      );
      contacts = contacts.map((contact) => {
        return {
          ...contact,
          tags: contact.tags.filter((tag) => tag.id != tagValue.id),
        };
      });
    }
    contacts.forEach(async (contact) => {
      await updateContact(contact);
    });
  };

  return (
    <>
      <button className="uk-button hms-btn hms-white-btn" disabled={disabled}>
        <span uk-icon="icon: tag; ratio: 0.7"></span> Tag
      </button>
      <div data-uk-dropdown="mode: click">
        <ul className="uk-nav uk-dropdown-nav tags-selector">
          {all.map((tagValue, index) => (
            <li key={`taglist_${index}`}>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={checkedAll[index]}
                  onChange={(event) => handleMultiTag(event, tagValue)}
                />
                {tagValue.name}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TagsList;
