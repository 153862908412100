import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { classes } from "../../../utils/classes";
import { AppContexts } from "../../../providers";
import { Link } from "../../ui-kit/Link";
import UIkit from "uikit";

const UpdateEmailForm = (props) => {
  const { handleSubmit } = useForm();
  const { reset_email } = useContext(AppContexts.AuthContext);

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("initial");

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleEmail = () => {
    setStatus("pending");
    reset_email(email)
      .then((resp) => {
        setStatus("complete");
        UIkit.notification("Email has been updated", "success");
      })
      .catch((err) => {
        setStatus("error");
        UIkit.notification(`Failed: ${err}`, "error");
      });
  };

  return (
    <form className="auth uk-form-stacked" onSubmit={handleSubmit(handleEmail)}>
      {status === "complete" && (
        <p>
          An email has been sent to the email you provided with instructions on
          how to complete updating your email.
        </p>
      )}

      {status === "error" && (
        <p>
          There was an error processing your request, please contact{" "}
          <a href="mainto:support@ruebarue.com">support@ruebarue.com</a> for
          assistance
        </p>
      )}

      <div className="uk-width-5-6">
        <label className="uk-form-label">New Email</label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="text"
            placeholder=""
            value={email}
            onInput={(e) => {
              updateEmail(e);
            }}
          />
        </div>
      </div>
      <div className="uk-width-1-1 uk-margin-small-bottom uk-margin-top">
        <button
          className={`uk-button hms-btn hms-red-btn ${
            status === "pending" ? "disabled" : ""
          }`}
          type="submit"
        >
          Change Email Address
        </button>
      </div>
    </form>
  );
};

export default UpdateEmailForm;
