// Import package components
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Image, Link, Flex } from "uikit-react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

// Import work-order components
// import WorkOrderAdd from "./work_order_add";

import { AppContexts } from "../../../providers";

import Loading from "../../common/Loading";
import WorkOrderComment from "../work_order_comment";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const schema = yup.object().shape({
  description: yup.string().required(),
  // summary: yup.string().required(),
  // status: yup.string().required(),
  // priority: yup.string().required(),
});

const WorkOrderUpdate = () => {
  const params = useParams();

  const workOrderContext = useContext(AppContexts.WorkOrderContext);
  const utilContext = useContext(AppContexts.UtilContext);
  const { refresh, user } = useContext(AppContexts.AuthContext);
  const { workOrder, loading } = workOrderContext;

  const [attachmentList, setAttachments] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    document.title = `Work Orders #${params.id} - RueBaRue`;
    // workOrderContext.getWorkOrderByAssigner(params.id, query.get("slug"));
    if (user) {
      if (params.id) {
        workOrderContext.getWorkOrder(params.id);
      }
    } else {
      if (params.id && params.slug) {
        workOrderContext.getWorkOrder(params.id, params.slug);
      }
    }
  }, [params]);

  const handleRegister = useCallback(
    async (data) => {
      try {
        if (attachmentList) {
          const files = [...attachmentList];
          const urls = await Promise.all(
            files.map((file) =>
              utilContext.uploadFile(
                file,
                `workorders/attachments/${utilContext.genUUID()}`,
              ),
            ),
          );
          const attachments = files.map((file, idx) => {
            return {
              type: "image",
              name: file.name,
              original_name: file.name,
              url: urls[idx],
            };
          });
          data["attachments"] = attachments;
        }

        await workOrderContext.commentWorkOrder(params.id, data, params.slug);
        setValue("description", "");
      } catch (err) {
        console.log(err);
      }
    },
    [workOrder, attachmentList, params],
  );

  const handleError = (err) => {
    console.log(err);
  };

  let { attachments, guest_reservation, rental } = workOrder || {};

  return (
    <div id="work-order-detail">
      {loading ? (
        <Loading />
      ) : (
        workOrder && (
          <>
            <div id="hms-page-title">
              <h1 className="uk-heading-small">Work Order #{workOrder.id}</h1>
            </div>
            <div id="hms-main-body">
              <div className="uk-modal-body uk-flex wo-background">
                <div className="wo-model-form-section">
                  <form onSubmit={handleSubmit(handleRegister, handleError)}>
                    <div className="uk-width-1-1">
                      <h3 style={{ textTransform: "capitalize" }}>
                        {workOrder.summary}
                      </h3>
                    </div>

                    <div className="uk-grid-small" data-uk-grid>
                      {workOrder.status && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Status</div>
                          <div style={{ textTransform: "capitalize" }}>
                            {workOrder.status}
                          </div>
                        </div>
                      )}

                      {workOrder.category && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Category</div>
                          <div style={{ textTransform: "capitalize" }}>
                            {workOrder.category}
                          </div>
                        </div>
                      )}
                      {workOrder.priority && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Priority</div>
                          <div style={{ textTransform: "capitalize" }}>
                            {workOrder.priority}
                          </div>
                        </div>
                      )}

                      {workOrder.reporter && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Reporter</div>
                          <div>
                            {workOrder.reporter?.first_name +
                              " " +
                              workOrder.reporter?.last_name}
                          </div>
                        </div>
                      )}
                    </div>

                    {workOrder.description && (
                      <div className="hms-search-result uk-width-1-1">
                        <div className="uk-form-label">Description</div>
                        <div>{workOrder.description}</div>
                      </div>
                    )}

                    <div className="uk-grid-small" data-uk-grid>
                      {guest_reservation && (
                        <div className="hms-search-result uk-width-1-2">
                          <div>
                            <div className="uk-form-label">Guest</div>
                            <div>
                              {`${workOrder.guest_reservation.first_name} ${workOrder.guest_reservation.last_name}`}
                            </div>
                            <div className="guests-secondary">
                              {moment
                                .utc(workOrder.guest_reservation.check_in)
                                .format("MMM DD, YYYY")}{" "}
                              -{" "}
                              {moment
                                .utc(workOrder.guest_reservation.check_out)
                                .format("MMM DD, YYYY")}
                            </div>
                            <div>{workOrder.guest_reservation.email}</div>
                            <div>
                              {workOrder.guest_reservation.formatted_phone}
                            </div>
                          </div>
                        </div>
                      )}
                      {rental && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Property</div>
                          <div>{workOrder.rental.pms_id}</div>
                          <div>{workOrder.rental.address}</div>
                        </div>
                      )}
                      {attachments && Array.isArray(attachments) && (
                        <div className="hms-search-result uk-width-1-2">
                          <div className="uk-form-label">Property</div>
                          <div className="uk-grid attachments">
                            {attachments.map((attachment) => {
                              return (
                                <div className="uk-card uk-card-default">
                                  <Image
                                    src={attachment.url}
                                    alt={attachment.name}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="uk-grid-small" data-uk-grid>
                      <div className="uk-width-1-1">
                        <div className="uk-form-label">Add a Comment</div>
                        <div className="uk-form-controls">
                          <textarea
                            className="uk-textarea"
                            rows="5"
                            {...register("description")}
                          ></textarea>
                        </div>
                      </div>

                      <div className="uk-width-1-1">
                        <div className="uk-form-label">Attachments</div>
                        <div className="js-upload uk-placeholder hms-file-uploader uk-flex uk-flex-middle">
                          <div>
                            <Image src="/images/upload-image.svg" />
                          </div>
                          <div className="uk-form-custom">
                            <input
                              type="file"
                              multiple
                              onChange={(evt) =>
                                setAttachments(evt.target.files)
                              }
                            />
                            Drag files&nbsp;
                            <span className="uk-link">
                              {!attachmentList
                                ? "browse"
                                : `${attachmentList.length} files attached`}
                            </span>{" "}
                            to upload
                          </div>
                        </div>
                        <progress
                          id="js-progressbar"
                          className="uk-progress"
                          value="0"
                          max="100"
                          hidden
                        ></progress>
                      </div>

                      {/*
                      <div className="uk-width-1-2">
                        <label className="uk-form-label">Priority</label>
                        <div className="uk-form-controls">
                          <div uk-form-custom="target: > * > span:first-child">
                            <select
                              className="uk-form-control"
                              defaultValue={workOrder.priority}
                              {...register("priority")}
                            >
                              <option value="high">High</option>
                              <option value="medium">Medium</option>
                              <option value="low">Low</option>
                            </select>
                            <button
                              className="uk-button uk-button-default"
                              type="button"
                              tabIndex="-1"
                            >
                              <span></span>
                              <span
                                uk-icon="icon: chevron-down"
                                className="hms-align-right"
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label className="uk-form-label">Status</label>
                        <div className="uk-form-controls">
                          <div uk-form-custom="target: > * > span:first-child">
                            <select
                              defaultValue={workOrder.status}
                              {...register("status")}
                            >
                              <option value="new">New</option>
                              <option value="in-progress">In-Progress</option>
                              <option value="resolved">Resolved</option>
                            </select>
                            <button
                              className="uk-button uk-button-default"
                              type="button"
                              tabIndex="-1"
                            >
                              <span></span>
                              <span
                                uk-icon="icon: chevron-down"
                                className="hms-align-right"
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      */}

                      <div className="uk-width-1-1">
                        <div className="uk-form-controls uk-flex">
                          <button
                            className="uk-button hms-btn hms-red-btn"
                            type="submit"
                          >
                            Add Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <WorkOrderComment order={workOrder} />
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default WorkOrderUpdate;
