import { createContext, useState } from "react";

import stripeService from "../services/stripe.service";

export const StripeContext = createContext();
export const StripeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [customerUrl, setCustomerUrl] = useState(null);

  let stripeContext = {
    loading,
    customerUrl,

    // Generates a random UUID
    getCustomerUrl: async () => {
      setLoading(true);
      let result = await stripeService.getCustomerUrl();
      let { url, error } = result.data;

      setLoading(false);
      if (error) {
        console.log(error);
        setCustomerUrl(null);
      } else {
        setCustomerUrl(url);
      }
    },
  };

  return (
    <StripeContext.Provider value={stripeContext}>
      {children}
    </StripeContext.Provider>
  );
};
