import { useContext, React } from "react";
import Dialog from "../../../common/Dialog";
import { AppContexts } from "../../../../providers";
import UIkit from "uikit";

const TagsDelete = ({ onDelete }) => {
  const { set, settings, deleted, setDeleted } = useContext(
    AppContexts.SettingsContext,
  );

  const cancelDelete = async () => {
    setDeleted(null);
  };

  const handleDelete = async () => {
    if (!deleted) {
      return UIkit.modal("#tag-delete").hide();
    }

    await set("tags", {type: 'deleted', data: deleted});

    return UIkit.modal("#tag-delete").hide();
  };
  return (
    <>
      <Dialog id="tag-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Tag</h2>
          <p>
            Are you sure you want to delete <b>{deleted?.name || ""}</b>?
            {deleted?.count > 0 ? (
            <div>
              <br/>
              This tag is attached to {deleted?.count || "0"} item(s).<br/><br/>
              <b>Warning: Deleting a tag from an automated message may have serious side effect.</b>
            </div>
            ) : (
              <div></div>
            )}
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default TagsDelete;
