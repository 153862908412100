// Import package components
import { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Image, InputContainer, Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";

import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import { AppContexts } from "../../../providers";
import clsx from "clsx";

const MessagesLog = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { history, startDate, endDate, getHistory } = ReportsContext;

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    } else {
      getHistory(startDate, endDate, page, filter ? filter.trim() : filter);
    }
  }, [startDate, endDate, filter, page]);

  const chunked = useMemo(
    () =>
      history
        ?.sort((prev, next) => next.sent_at.localeCompare(prev.sent_at))
        .slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [history, page, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Text Message Logs</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Sender/Recipient Name Or Property ID"
          onFilterChange={handleFilterChange}
        />
        <div className="secondary">
          {history?.length} Messages Sent/Recieved
        </div>
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-hover uk-table-divider hms-table uk-margin-small-top">
            <thead>
              <tr>
                <th className="rbr-history send-time">Sent At</th>
                <th className="rbr-history sender">Sent By</th>
                <th className="rbr-history send-name">Sender Name</th>
                <th className="rbr-history send-phone">Sender Phone</th>
                <th className="rbr-history rec-name">Recipient Name</th>
                <th className="rbr-history rec-phone">Recipient Phone</th>
                <th className="rbr-history unit">Unit</th>
                <th className="rbr-history stay">Check-In - Check-Out</th>
                <th className="rbr-history message">Message</th>
                <th className="rbr-history error">Error</th>
              </tr>
            </thead>
            <tbody>
              {chunked?.length ? (
                chunked?.map((item, index) => (
                  <tr 
                    className={clsx("error", {
                      undelivered: item.status === "undelivered",
                    })}
                    key={index}>
                    <td>
                      {item.status === "undelivered" ? (
                        <>
                          <img 
                            className="report-warning-icon"
                            data-src="/images/warning.svg" 
                            data-uk-img="" 
                            loading="lazy" 
                            src="/images/warning.svg" 
                            alt="Warning Icon" 
                            data-uk-tooltip={`title: ${item.status} - ${item.error_code} - ${item.error_message}`}
                          />
                        </>
                      ) : (
                        <span></span>
                      )}
                      {moment(item.sent_at).format("MM/DD/YYYY hh:mm A")}
                    </td>
                    <td>{item.sender_type}</td>
                    <td>{item.sender_name}</td>
                    <td>{item.sender_phone}</td>
                    <td>{item.reciever_name}</td>
                    <td>{item.reciever_phone}</td>
                    <td className="reports-table-wrap-overflow">
                      {item.rental_code}
                    </td>
                    <td>
                      {moment.utc(item.check_in).format("MM/DD/YYYY")} -{" "}
                      {moment.utc(item.check_out).format("MM/DD/YYYY")}
                    </td>
                    <td className="message">
                      <textarea
                        rows={1}
                        value={item.message_content}
                        disabled
                      />
                    </td>
                    <td>
                      {item.status === "undelivered"
                        ? `${item.status} - ${item.error_code} - ${item.error_message}`
                        : item.error_message}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="no-results">
                    No results for this date range
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={history?.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default MessagesLog;
