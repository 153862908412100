import { useMemo } from "react";

const Markdown = ({ input }) => {
  const stripTags = (input) => {
    input = (input || "").replace(/<br\s*[\/]?>/gi, "\n");

    return document
      .createRange()
      .createContextualFragment(input)
      .textContent.replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, "")
      .trim();
  };

  const getBlocks = (input) => {
    // Normalize linebreaks to \n.
    input = (input || "").replace(/\r\n?/g, "\n");
    return input.split("\n");
  };

  const processBlock = (tree, currentList, block) => {
    var listLevel = !!currentList ? currentList.level : 1;

    var listTest = new RegExp(
        "^\\s{" + (listLevel - 1) * 4 + "}((?:[*+-]|\\d+\\.)\\s)",
        "gi",
      ),
      innerListTest = new RegExp(
        "^\\s{" + listLevel * 4 + "}((?:[*+-]|\\d+\\.)\\s)",
        "gi",
      ),
      listTypeTest = /^(\s*[*+-])/gi;

    if (listTest.test(block)) {
      if (currentList === null) {
        var listType = listTypeTest.test(block) ? "unordered" : "ordered";
        currentList = {
          type: "list",
          level: listLevel,
          listType: listType,
          items: [],
        };
        tree.push(currentList);
      }

      currentList.items.push({
        type: "item",
        content: block.replace(listTest, ""),
      });
      return currentList;
    } else if (innerListTest.test(block) && currentList !== null) {
      var listType = listTypeTest.test(block) ? "unordered" : "ordered";
      var subList = {
        type: "list",
        level: listLevel + 1,
        listType: listType,
        items: [],
        parent: currentList,
      };
      currentList.items.push(subList);
      subList.items.push({
        type: "item",
        content: block.replace(innerListTest, ""),
      });
      return subList;
    } else {
      if (currentList !== null && block !== "") {
        return processBlock(tree, currentList.parent || null, block);
      } else {
        tree.push({ type: "content", content: block });
        return currentList;
      }
    }
  };

  const processNode = (node) => {
    if (node.type === "content") {
      if (node.content === "") {
        return <br />;
      } else {
        return (
          <div
            className="md-div"
            dangerouslySetInnerHTML={{ __html: processText(node.content) }}
          ></div>
        );
      }
    } else if (node.type === "item") {
      return (
        <li
          className="md-li"
          dangerouslySetInnerHTML={{ __html: processText(node.content) }}
        ></li>
      );
    } else if (node.type === "list") {
      if (node.listType === "ordered") {
        return (
          <ol className={"md-list md-list-" + node.level}>
            {node.items.map((n) => {
              return processNode(n);
            })}
          </ol>
        );
      } else {
        return (
          <ul className={"md-list md-list-" + node.level}>
            {node.items.map((n) => {
              return processNode(n);
            })}
          </ul>
        );
      }
    }
  };

  const processText = (content) => {
    var standardParse = (key, block) => {
      var test = rules[key].test;
      test.lastIndex = 0;

      var match = test.exec(block);

      while (match !== null) {
        var origLen = block.length;
        block = rules[key].replace(block, {
          start: match.index,
          end: match.index + match[0].length,
          content: match[1],
        });
        var dif = block.length - origLen;
        test.lastIndex = test.lastIndex + dif;
        match = test.exec(block);
      }

      return block;
    };

    var rules = {
      //TODO: Bold regex needs to fixed before italics will work
      italics: {
        test: /\*\*([^\s][^\*]*)\*\*/gi,
        parse: standardParse,
        replace: (block, match) => {
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            italicized = "<em style='color: black'>" + match.content + "</em>";

          block = start + italicized + end;

          return block;
        },
      },
      bold: {
        test: /\*([^\s][^\*]*)\*/gi,
        parse: standardParse,
        replace: (block, match) => {
          // for (var i=0; i<results.bold.length; i++){
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            bolded = "<strong>" + match.content + "</strong>";

          block = start + bolded + end;
          // }

          // console.log(block)

          return block;
        },
      },
      phone: {
        test: /((?:\s|^)(?:\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})(?:\b)/gi,
        parse: standardParse,
        replace: (block, match) => {
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            linked =
              "<a href='tel:" + match.content + "'>" + match.content + "</a>";

          block = start + linked + end;
          // console.log(block)

          return block;
        },
      },
      md_links: {
        test: /(\[[^\]]*\]\s*\([^\)]*\))/gi,
        parse: standardParse,
        replace: (block, match) => {
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            contentMatch = /\[(.+)\]/.exec(match.content),
            linkMatch = /\((.+)\)/.exec(match.content),
            link_title = linkMatch[1].split(" "),
            link = link_title.shift(),
            title = link_title.join(" "),
            className =
              title.charAt(0) === ":"
                ? "social " + (title || "").replace(/^:/, "")
                : "",
            linked =
              "<a target='_blank' href='" +
              link +
              "' class='" +
              className +
              "' title='" +
              (title || "").replace(/^:/, "") +
              "'>" +
              contentMatch[1] +
              "</a>";

          block = start + linked + end;

          return block;
        },
      },
      email: {
        test: /((?:(?:[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/gi,
        parse: standardParse,
        replace: (block, match) => {
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            linked =
              "<a href='mailto:" +
              match.content +
              "'>" +
              match.content +
              "</a>";

          block = start + linked + end;

          return block;
        },
      },
      links: {
        test: /([http:\/\/|https:\/\/]*[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&\/=]*))/gi,
        parse: (key, block) => {
          var test = rules[key].test;
          test.lastIndex = 0;

          var match = test.exec(block);
          while (match !== null) {
            if (
              ["href='", 'href="'].indexOf(
                block.substring(match.index - 6, match.index),
              ) === -1 &&
              !rules.email.test.test(match[0])
            ) {
              var origLen = block.length;
              block = rules[key].replace(block, {
                start: match.index,
                end: match.index + match[0].length,
                content: match[1],
              });
              var dif = block.length - origLen;
              test.lastIndex = test.lastIndex + dif;
            }

            match = test.exec(block);
          }

          return block;
        },
        replace: (block, match) => {
          var start = block.substring(0, match.start),
            end = block.substring(match.end, block.length),
            linked =
              "<a target='_blank' href='" +
              match.content +
              "'>" +
              match.content +
              "</a>";

          block = start + linked + end;

          return block;
        },
      },
      escaped: {
        test: /\\\*/gi,
        parse: standardParse,
        replace: (block, match) => {
          return block.replace(/\\\*/g, "*");
        },
      },
    };

    var keys = [
      "italics",
      "bold",
      "md_links",
      "phone",
      "email",
      "links",
      "escaped",
    ];
    keys.map((key) => {
      if (rules[key].test.test(content)) {
        content = rules[key].parse(key, content);
      }
    });

    return content;
  };

  let output = useMemo(() => {
    try {
      let tagless = stripTags(input);
      let blocks = getBlocks(tagless);
      let tree = [];
      let currentList = null;

      blocks.forEach((block) => {
        currentList = processBlock(tree, currentList, block);
      });

      return tree.map((node) => {
        return processNode(node);
      });
    } catch (e) {
      console.log(e);
      return input;
    }
  }, [input]);

  return <div className="rbr-md">{output}</div>;
};

export default Markdown;
