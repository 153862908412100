// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../../providers";

// Import common components
import Dialog from "../../common/Dialog";

const QuestionDelete = () => {
  const {
    survey,
    flagIsProcessing,
    setSurvey,
    setSurveys,
    questionNo,
    upsertQuestion,
  } = useContext(AppContexts.SurveysContext);

  const handleDelete = async () => {
    let result = await upsertQuestion({
      ...survey.questions[questionNo],
      deleted: true,
    });

    if (result) {
      let sv = { ...survey };
      let idx = sv.questions.findIndex(
        (q) =>
          q.survey_question_id.toString() ===
          result.survey_question_id.toString()
      );
      sv.questions[idx] = result;

      setSurveys((prev) =>
        prev.map((indexSurvey) =>
          indexSurvey.survey_id.toString() === result.survey_id.toString()
            ? sv
            : indexSurvey
        )
      );
      await setSurvey(sv);
      UIkit.modal("#question-delete-modal").hide();
    }
  };

  return (
    <>
      <Dialog id="question-delete-modal">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Question</h2>
          <p>Are you sure you want to delete?</p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
              disabled={flagIsProcessing}
            >
              {flagIsProcessing == true ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : (
                "Delete"
              )}
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default QuestionDelete;
