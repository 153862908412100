import { createContext, useState } from "react";
import SettingsService from "../services/settings.service";

export const SettingsContext = createContext();
export const SettingsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const [final, setFinal] = useState({});
  const [deleted, setDeleted] = useState(null);
  const [active, setActive] = useState(0);

  const get = (types) => {
    setLoading(true);
    return SettingsService.get(types)
      .then((resp, err) => {
        setLoading(false);
        setSettings((settings) => ({ ...settings, ...resp.data }));
        return resp.data;
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const set = async (types, data) => {
    setLoading(true);

    let updateData = {};
    if (Array.isArray(types)) {
      types.forEach((type) => {
        updateData[type] = data[type];
      });
    } else {
      updateData[types] = data;
    }
    try {
      let resp = await SettingsService.set(updateData);
      if (resp.data) {
        setLoading(false);
        setSettings({ ...settings, ...resp.data });
        return resp.data;
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getPmsFields = () => {
    return SettingsService.getPmsFields().then((resp, err) => {
      return Promise.resolve(resp.data);
    });
  };

  let settingsCtx = {
    loading,
    settings,
    final,
    setSettings,
    get,
    set,
    getPmsFields,
    active,
    setActive,
    deleted,
    setDeleted,
  };

  return (
    <SettingsContext.Provider value={settingsCtx}>
      {children}
    </SettingsContext.Provider>
  );
};
