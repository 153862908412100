import React, { useState, useEffect, useContext } from "react";
import GoogleAutoComplete from "../../common/GoogleAutoComplete";
import { AppContexts } from "../../../providers";
import { useHistory } from "react-router";

const AddPlace = (props) => {
  const UtilContext = useContext(AppContexts.UtilContext);
  const { destination, createRecommendation, getSelectedDestination } =
    useContext(AppContexts.DestinationContext);
  const [placeId, setPlaceId] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const history = useHistory();

  useEffect(async () => {
    if (placeId) {
      let place = await UtilContext.place(placeId);
      let newRecommendation = {
        google_place_id: place.place_id,
        address: place.formatted_address,
        name: place.name,
        lat: place?.geometry?.location?.lat,
        lng: place?.geometry?.location?.lng,
        destination_id: props.destinationId,
        tab_id: props.tabId.type,
        display_as: "place",
      };
      console.log(newRecommendation);
      let result = await createRecommendation(newRecommendation);
      history.push(`/areas_detail/${props.destinationId}`);
      if (result.data) {
        await getSelectedDestination(result.data.destination_id);
      }
      setPlaceId(null);
      setAutoCompleteValue("");
    }
  }, [placeId]);

  const hanleSelect = (_, value) => {
    setPlaceId(value.text);
  };

  return (
    <div className="uk-card uk-card-small uk-card-default hms-form-card">
      <GoogleAutoComplete
        width="100%"
        onSelect={(_, value) => setPlaceId(value.text)}
        placeholder="Begin typing and select a place..."
        onChange={(e) => setAutoCompleteValue(e)}
        location={`${destination?.lat || "0"},${destination?.lng || "0"}`}
        address={autoCompleteValue}
      />
    </div>
  );
};

export default AddPlace;
