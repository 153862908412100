// Import package components
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import UIkit from "uikit";
import { Link } from "uikit-react";
import { AppContexts } from "../../../providers";

const ReportsNavbar = () => {
  const { active, setActive } = useContext(AppContexts.ReportsContext);
  const history = useHistory();

  let pathArray = history.location.pathname.split("/");
  let path = pathArray.pop().replace("#", "");
  setActive(path);

  useEffect(() => {
    if (path === "reports") history.push("/reports/overview");
  }, [path]);

  return (
    <div className="uk-width-auto">
      <ul
        className="uk-tab-left hms-report-option-tabs hms-left-tabs"
        uk-tab="connect: #reports-tab-left; animation: uk-animation-fade"
      >
        <li
          className={active === "overview" ? "uk-active" : ""}
          onClick={() => history.push("/reports/overview")}
        >
          <Link href="#">Overview</Link>
        </li>
        <li
          className={active === "message-log" ? "uk-active" : ""}
          onClick={() => history.push("/reports/message-log")}
        >
          <Link href="#">Text Message Logs</Link>
        </li>
        <li
          className={active === "guestbook-views" ? "uk-active" : ""}
          onClick={() => history.push("/reports/guestbook-views")}
        >
          <Link href="#">Guestbook Views</Link>
        </li>
        <li
          className={active === "home-guide-views" ? "uk-active" : ""}
          onClick={() => history.push("/reports/home-guide-views")}
        >
          <Link href="#">Home Guide Views</Link>
        </li>
        <li
          className={active === "area-guide-views" ? "uk-active" : ""}
          onClick={() => history.push("/reports/area-guide-views")}
        >
          <Link href="#">Area Guide Views</Link>
        </li>
        <li
          className={active === "automated-sms-sent" ? "uk-active" : ""}
          onClick={() => history.push("/reports/automated-sms-sent")}
        >
          <Link href="#">Automated Text Sent</Link>
        </li>
        <li
          className={active === "automated-email-sent" ? "uk-active" : ""}
          onClick={() => history.push("/reports/automated-email-sent")}
        >
          <Link href="#">Automated Emails Sent</Link>
        </li>
        <li
          className={active === "macros" ? "uk-active" : ""}
          onClick={() => history.push("/reports/macros")}
        >
          <Link href="#">Property Macros</Link>
        </li>
        {/*
        <li
          className={active === "guests-imported" ? "uk-active" : ""}
          onClick={() => history.push("/reports/guests-imported")}
        >
          <Link href="#">Guests Imported</Link>
        </li>
        */}
        <li
          className={active === "fivestar-rating" ? "uk-active" : ""}
          onClick={() => history.push("/reports/fivestar-rating")}
        >
          <Link href="#">Five Star Ratings</Link>
        </li>
        <li
          className={active === "extend-stay" ? "uk-active" : ""}
          onClick={() => history.push("/reports/extend-stay")}
        >
          <Link href="#">Extend Stays Text Sent</Link>
        </li>
        <li
          className={active === "check-in-out" ? "uk-active" : ""}
          onClick={() => history.push("/reports/check-in-out")}
        >
          <Link href="#">Guest Check-In/Check-Out</Link>
        </li>
        <li
          className={active === "webchat" ? "uk-active" : ""}
          onClick={() => history.push("/reports/webchat")}
        >
          <Link href="#">Webchat Activities</Link>
        </li>
        {/*
        <li
          className={active === "guestbook-viewing-times" ? "uk-active" : ""}
          onClick={() => history.push("/reports/guestbook-viewing-times")}
        >
          <Link href="#">Guestbook Viewing Times</Link>
        </li>
        <li
          className={
            active === "message-sent-vs-guestbook-view" ? "uk-active" : ""
          }
          onClick={() =>
            history.push("/reports/message-sent-vs-guestbook-view")
          }
        >
          <Link href="#">Messages Sent Vs. Guestbook Views</Link>
        </li>
        */}
      </ul>
    </div>
  );
};

export default ReportsNavbar;
