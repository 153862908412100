// Import package components
import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import { AppContexts } from "../../providers";
import UIkit from "uikit";

const schema = yup.object().shape({
  contact: yup.string().required(),
});

const AlertShare = () => {
  const contextAlerts = useContext(AppContexts.contextAlerts);
  const { shareMessage } = useContext(AppContexts.UtilContext);
  const [status, setStatus] = useState("Send");
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const handleSendMsg = async (data) => {
    setStatus("Sending");
    shareMessage("alert", contextAlerts.objAlert.alert_id, data.contact)
      .then((res) => {
        console.log(res);
        setStatus("Sent");
        UIkit.notification("Shared successfully!", "success");
        setValue("contact", "");
      })
      .catch((err) => {
        setStatus("Sent");
        UIkit.notification("Failed", "error");
        setValue("contact", "");
      });
  };

  useEffect(() => {
    setStatus("Send");
    setValue("contact", "");
  }, [contextAlerts.objAlert]);

  const handleClose = useCallback(() => {
    reset();
  }, []);

  return (
    <div
      id="alert-share"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            onClick={handleClose}
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Share an Alert</h2>
          </div>
          <form onSubmit={handleSubmit(handleSendMsg)}>
            <div className="hms-msg-typing-box uk-margin">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.contact,
                })}
                type={
                  (contextAlerts.objAlert || {}).delivery === "email"
                    ? "email"
                    : "text"
                }
                placeholder={`${
                  (contextAlerts.objAlert || {}).delivery === "email"
                    ? "Email Address"
                    : "Mobile Number"
                }`}
                style={{ width: "100%" }}
                {...register("contact")}
              />
              <div className="uk-margin-small-top">
                <div className="uk-flex uk-flex-middle">
                  <div>
                    <button
                      className="uk-button hms-btn hms-red-btn"
                      type="submit"
                      disabled={status === "Sending"}
                    >
                      {status}
                    </button>
                  </div>
                </div>
              </div>
              <div className="secondary">
                This share feature does not convert the macros in the message.
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AlertShare;
