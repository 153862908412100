// Import package components
import { useContext, useState, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import moment from "moment";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import SMSEmailInputbox from "../common/sms-email-inputbox";
import Tags from "../common/Tags";

// Import alerts components
import SelectTemplate from "../common/Template";
import ConvertUTCDateToLocalDate from "../common/ConvertUTCDateToLocalDate";

const schema = yup.object().shape({
  name: yup.string().required(),
  delivery: yup.string().required(),
  body: yup.string().required(),
  scheduled: yup.boolean().notRequired(),
  scheduled_date: yup.string().when("scheduled", {
    is: true,
    then: yup.string().required(),
  }),
  scheduled_time: yup.string().when("scheduled", {
    is: true,
    then: yup.string().required(),
  }),
  from_email: yup.string().email().notRequired(),
  bbc_email: yup.string().email().notRequired(),
});

const AlertAdd = () => {
  const contextAlerts = useContext(AppContexts.contextAlerts);
  const { settings } = useContext(AppContexts.SettingsContext);
  const { user } = useContext(AppContexts.AuthContext);
  const offcanvasRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    guests,
    objAlert,
    setObjAlert,
    flagIsNew,
    isViewMode,
  } = contextAlerts;
  const {
    templatesCtx: { templates },
  } = useContext(AppContexts.TemplatesContext);

  const [formAlert, setAlert] = useState({});

  const timeSlots = [
    "12:00 AM",
    "12:15 AM",
    "12:30 AM",
    "12:45 AM",
    "1:00 AM",
    "1:15 AM",
    "1:30 AM",
    "1:45 AM",
    "2:00 AM",
    "2:15 AM",
    "2:30 AM",
    "2:45 AM",
    "3:00 AM",
    "3:15 AM",
    "3:30 AM",
    "3:45 AM",
    "4:00 AM",
    "4:15 AM",
    "4:30 AM",
    "4:45 AM",
    "5:00 AM",
    "5:15 AM",
    "5:30 AM",
    "5:45 AM",
    "6:00 AM",
    "6:15 AM",
    "6:30 AM",
    "6:45 AM",
    "7:00 AM",
    "7:15 AM",
    "7:30 AM",
    "7:45 AM",
    "8:00 AM",
    "8:15 AM",
    "8:30 AM",
    "8:45 AM",
    "9:00 AM",
    "9:15 AM",
    "9:30 AM",
    "9:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 PM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
    "1:00 PM",
    "1:15 PM",
    "1:30 PM",
    "1:45 PM",
    "2:00 PM",
    "2:15 PM",
    "2:30 PM",
    "2:45 PM",
    "3:00 PM",
    "3:15 PM",
    "3:30 PM",
    "3:45 PM",
    "4:00 PM",
    "4:15 PM",
    "4:30 PM",
    "4:45 PM",
    "5:00 PM",
    "5:15 PM",
    "5:30 PM",
    "5:45 PM",
    "6:00 PM",
    "6:15 PM",
    "6:30 PM",
    "6:45 PM",
    "7:00 PM",
    "7:15 PM",
    "7:30 PM",
    "7:45 PM",
    "8:00 PM",
    "8:15 PM",
    "8:30 PM",
    "8:45 PM",
    "9:00 PM",
    "9:15 PM",
    "9:30 PM",
    "9:45 PM",
    "10:00 PM",
    "10:15 PM",
    "10:30 PM",
    "10:45 PM",
    "11:00 PM",
    "11:15 PM",
    "11:30 PM",
    "11:45 PM",
  ];

  const [template, setTemplate] = useState("");

  const defaultValues = {
    name: null,
    chronology: "on",
    guest_set: "staying",
    delivery: "sms",
    body: null,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    scheduled: "0",
    scheduled_date: moment().format("YYYY-MM-DD"),
    scheduled_time: "",
    from_email: "",
    from_name: "",
    bbc_email: "",
    subject: "",
    tags: [],
  };

  const handleBeforeHide = (e) => {
    if (e.target === offcanvasRef.current) {
      clearErrors();
      setObjAlert({ name: null });
      setTemplate("");
    }
  };

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  useEffect(() => {
    console.log("alert:", objAlert);
    setAlert(objAlert);
    clearErrors();
    Object.keys(defaultValues).forEach((formname) => {
      if (formname == "scheduled") {
        setValue("scheduled", objAlert.scheduled == true ? "1" : "0");
      } else {
        setValue(
          formname,
          objAlert[formname]
            ? formname.slice(-4) === "date"
              ? isViewMode
                ? moment(objAlert[formname]).utc().format("YYYY-MM-DD")
                : moment(objAlert[formname]) > moment()
                ? moment(objAlert[formname]).utc().format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")
              : objAlert[formname]
            : defaultValues[formname],
        );
      }
    });
    setTemplate("");
    if (Object.keys(objAlert).length === 0) {
      clearForms();
    }
  }, [JSON.stringify(objAlert)]);

  const settingsBranding = useMemo(() => {
    if (!settings) {
      return {};
    } else {
      if (settings.branding) {
        return settings.branding;
      } else {
        return {};
      }
    }
  }, [settings]);

  const clearForms = () => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  };

  useEffect(() => {
    const guestPayload = {
      chronology: getValues("chronology"),
      guest_set: getValues("guest_set"),
      delivery: getValues("delivery"),
      scheduled: getValues("scheduled"),
      start_date: moment(getValues("start_date")),
    };

    if (guestPayload.chronology === "between") {
      guestPayload.end_date = moment(getValues("end_date"));
    }

    contextAlerts.getAlertGuests(guestPayload);
  }, [
    watch("chronology"),
    watch("start_date"),
    watch("end_date"),
    watch("guest_set"),
  ]);

  const guest_set = useMemo(() => getValues("guest_set"), [watch("guest_set")]);

  const handleRegister = async (formdata) => {
    if (guests && guests.length > 0) {
      const formAlert = {
        name: formdata.name,
        chronology: formdata.chronology,
        guest_set: formdata.guest_set,
        delivery: formdata.delivery,
        body: formdata.body,
        start_date: formdata.start_date,
        scheduled: formdata.scheduled,
        account_id: user.account_id,
        sent: false,
        tags: formdata.tags,
      };

      if (chronology === "between") {
        formAlert.end_date = formdata.end_date;
      }

      if (scheduled) {
        formAlert.scheduled_date = formdata.scheduled_date;
        formAlert.scheduled_time = formdata.scheduled_time;
      }

      if (delivery === "email") {
        formAlert.from_email = formdata.from_email;
        formAlert.from_name = formdata.from_name;
        formAlert.bcc_email = formdata.bcc_email;
        formAlert.subject = formdata.subject;
      }

      if (!flagIsNew && typeof objAlert === "object") {
        Object.keys(objAlert)
          .filter((field) => formAlert[field] === undefined)
          .forEach((field) => (formAlert[field] = objAlert[field]));
      }

      let result = await (flagIsNew === true
        ? contextAlerts.insertAlert(formAlert)
        : contextAlerts.updateAlert(formAlert));
      if (!!result) {
        await contextAlerts.getAlerts();
        UIkit.modal("#alert-add").hide();
        UIkit.notification(
          flagIsNew === true ? "Alert Added" : "Alert Updated",
          "success",
        );
        clearForms();
      }
    } else {
      UIkit.notification("There are 0 guests for this date range", "danger");
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  useEffect(() => console.log(errors), [
    errors?.body?.messsage,
    errors?.body?.name,
  ]);

  const delivery = useMemo(() => getValues("delivery"), [watch("delivery")]);
  const body = useMemo(() => getValues("body"), [watch("body")]);
  const scheduled = useMemo(() => getValues("scheduled"), [watch("scheduled")]);
  const scheduled_time = useMemo(() => getValues("scheduled_time"), [
    watch("scheduled_time"),
  ]);

  useEffect(() => {
    if (
      delivery &&
      settingsBranding &&
      Object.keys(settingsBranding).length > 0
    ) {
      setValue("from_name", settingsBranding.from_name);
      setValue("from_email", settingsBranding.from_email);
    }
  }, [settingsBranding, delivery]);

  const chronology = useMemo(() => getValues("chronology"), [
    watch("chronology"),
  ]);
  const tags = useMemo(() => getValues("tags"), [watch("tags")]);

  const filteredGuests = useMemo(() => {
    if (!guests) {
      return [];
    } else {
      if (tags && tags.length > 0) {
        return guests
          ?.map((guest) => {
            if (!guest.tags || guest.tags.length === 0) {
              return null;
            } else {
              let count = 0;
              tags.forEach((t) => {
                if (guest.tags.findIndex((a) => +a.id === +t.id) >= 0) {
                  count++;
                }
              });
              if (count > 0) {
                return guest;
              } else {
                return null;
              }
            }
          })
          .filter((item) => !!item);
      } else {
        return guests.filter((item) => !!item);
      }
    }
  }, [guests, tags]);

  const handleTemplateChange = (val) => {
    setValue("body", val?.body);
    setValue("subject", val?.subject);
    setTemplate(val);
  };

  useEffect(() => {
    if (scheduled == 1 && !scheduled_time) {
      let h = moment().add(30, "minutes").format("HH");
      let m = moment().add(30, "minutes").format("MM");
      let a = moment().add(30, "minutes").format("A");
      let result = "";
      if (parseInt(m) > 30) {
        result = `${(parseInt(h) + 1) % 12}:00 ${a}`;
      } else {
        result = `${parseInt(h) % 12}:30 ${a}`;
      }
      console.log(result);
      setValue("scheduled_time", result);
    }
  }, [scheduled, scheduled_time]);

  return (
    <>
      <div
        id="alert-add"
        ref={offcanvasRef}
        className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {isViewMode == true
                  ? "View"
                  : flagIsNew === true
                  ? "Add"
                  : "Edit"}{" "}
                an Alert
              </h2>
            </div>

            <div className="uk-modal-body uk-flex">
              <div className="addScheduleMsgForm">
                <form
                  className="uk-grid-small uk-margin-none-top"
                  onSubmit={handleSubmit(handleRegister, handleError)}
                  data-uk-grid
                >
                  <div className="uk-width-1-2 uk-first-column">
                    <label
                      className="uk-form-label"
                      htmlFor="txt-schedule-name"
                    >
                      Name*
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className={classnames("uk-input", {
                          error: errors.name,
                        })}
                        id="txt-schedule-name"
                        type="text"
                        placeholder=""
                        readOnly={isViewMode}
                        {...register("name", { required: true })}
                      />
                    </div>
                  </div>
                  <SelectTemplate
                    disabled={isViewMode}
                    onChange={handleTemplateChange}
                    template={template}
                  />
                  <div className="uk-width-1-1">
                    <div className="hms-add-tag">
                      <div className="uk-form-label">Tags</div>
                      <div className="uk-flex uk-flex-top">
                        <Tags
                          value={tags}
                          onChange={(editedTags) =>
                            setValue("tags", editedTags)
                          }
                          readOnly={isViewMode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-4">
                    <label className="uk-form-label">Guests</label>
                    <div className="uk-form-controls">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          className="uk-select"
                          {...register("guest_set")}
                          disabled={isViewMode}
                        >
                          <option value="staying">Staying</option>
                          <option value="arriving">Arriving</option>
                          <option value="departing">Departing</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span>
                            {guest_set
                              ?.toLowerCase()
                              .replace(/\b\w/g, (s) => s.toUpperCase())}
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-4 uk-last-column">
                    <label className="uk-form-label">On/Between</label>
                    <div className="uk-form-controls">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          className="uk-select"
                          {...register("chronology")}
                          disabled={isViewMode}
                        >
                          <option value="on">On</option>
                          <option value="between">Between</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span>
                            {chronology
                              ?.toLowerCase()
                              .replace(/\b\w/g, (s) => s.toUpperCase())}
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-4">
                    <label className="uk-form-label">
                      {chronology === "on" ? "Date" : "From"}
                    </label>
                    <div className="uk-form-controls">
                      <div className="hms-date-picker">
                        {isViewMode ? (
                          <input
                            className="uk-input"
                            type="date"
                            {...register("start_date")}
                            readOnly={true}
                          />
                        ) : (
                          <input
                            className="uk-input"
                            type="date"
                            min={moment().format("YYYY-MM-DD")}
                            {...register("start_date")}
                            readOnly={false}
                          />
                        )}
                        <div className="hms-date-placeholder">Pick a Date</div>
                      </div>
                    </div>
                  </div>
                  {chronology === "between" && (
                    <div className="uk-width-1-4">
                      <label className="uk-form-label">To</label>
                      <div className="uk-form-controls">
                        <div className="hms-date-picker">
                          <input
                            className="uk-input"
                            type="date"
                            min={moment().format("YYYY-MM-DD")}
                            {...register("end_date")}
                            readOnly={isViewMode}
                          />
                          <div className="hms-date-placeholder">
                            Pick a Date
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="uk-width-1-2">
                    <div className="uk-form-label uk-margin-small-bottom">
                      Send
                    </div>
                    <div className="uk-grid uk-form-controls">
                      <label className="uk-margin-right">
                        <input
                          type="radio"
                          className="uk-radio"
                          name="scheduled-radio"
                          value="0"
                          {...register("scheduled")}
                        />{" "}
                        Now
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="uk-radio"
                          name="scheduled-radio"
                          value="1"
                          {...register("scheduled")}
                        />{" "}
                        Later
                      </label>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-grid uk-grid-small">
                    {scheduled == "1" && (
                      <>
                        <div className="uk-margin-small-right">
                          {/* <label className="uk-form-label">Schedule Date</label> */}
                          <div className="uk-form-controls">
                            <div className="hms-date-picker">
                              <input
                                className={classnames(
                                  "uk-input uk-width-expand",
                                  { error: errors.scheduled_date },
                                )}
                                type="date"
                                min={moment().format("YYYY-MM-DD")}
                                {...register("scheduled_date")}
                                readOnly={isViewMode}
                              />
                              <div className="hms-date-placeholder">
                                Pick a Date
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hms-time-picker">
                          {/* <label className="uk-form-label">Schedule Time</label> */}
                          <div className="uk-form-controls">
                            <div uk-form-custom="target: > * > span:first-child">
                              <select {...register("scheduled_time")}>
                                <option value="">Select a Time</option>
                                {timeSlots.map((timeslot, index) => (
                                  <option value={timeslot} key={index}>
                                    {timeslot}
                                  </option>
                                ))}
                              </select>
                              <button
                                className={classnames(
                                  "uk-button uk-button-default",
                                  { error: errors.scheduled_time },
                                )}
                                type="button"
                                tabIndex="-1"
                              >
                                <span></span>
                                <span
                                  uk-icon="icon: chevron-down"
                                  className="hms-align-right"
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <div className="uk-form-label uk-margin-small-bottom">
                      Send By
                    </div>
                    <div className="uk-grid uk-form-controls">
                      <label className="uk-margin-right">
                        <input
                          type="radio"
                          className="uk-radio"
                          name="send-type-radio"
                          value="sms"
                          {...register("delivery")}
                        />{" "}
                        SMS
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="uk-radio"
                          name="send-type-radio"
                          value="email"
                          {...register("delivery")}
                        />{" "}
                        Email
                      </label>
                    </div>
                  </div>

                  {delivery === "email" && (
                    <>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          From Name
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            {...register("from_name")}
                            readOnly={isViewMode}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          From Email Address
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            {...register("from_email")}
                            readOnly={isViewMode}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          Subject
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            {...register("subject")}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          BCC Email
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            {...register("bbc_email")}
                            readOnly={isViewMode}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="uk-width-1-1">
                    <SMSEmailInputbox
                      type="sms"
                      value={body || ""}
                      error={errors.body}
                      onChange={(event) => {
                        setValue("body", event.target.value);
                        trigger("body");
                      }}
                      macrosOrResponse={true}
                      readOnly={isViewMode}
                      limit={delivery === "sms"}
                    />
                  </div>
                  {isViewMode == false && (
                    <div className="uk-width-1-1">
                      <button
                        className="uk-button hms-btn hms-red-btn"
                        type="submit"
                      >
                        {contextAlerts.flagIsProcessing == true ? (
                          <div data-uk-spinner="ratio: 0.5;"></div>
                        ) : (
                          "Save"
                        )}
                      </button>
                      <button
                        className="uk-button hms-btn uk-offcanvas-close"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
                <div className="s-msg-last-updated">
                  {flagIsNew === false ? (
                    <ConvertUTCDateToLocalDate
                      label="Last Updated: "
                      date={new Date(objAlert.updated_on)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!isViewMode && (
                <div className="schedule-modal-right">
                  <div className="schedule-modal-right-inner">
                    <div>
                      Message will be sent to the following{" "}
                      <b>
                        {filteredGuests && filteredGuests.length !== 0
                          ? filteredGuests.length
                          : "no"}{" "}
                        Guests
                      </b>
                      .
                    </div>
                    <div className="properties-w-home-icon">
                      {filteredGuests
                        ?.sort((prev, next) =>
                          prev["check_in"].localeCompare(next["check_in"]),
                        )
                        .map((guest, idx) => (
                          <div
                            className="uk-flex uk-flex-middle"
                            key={`alert_guest_add_${idx}`}
                          >
                            <div className="home-guest-icon-width">
                              <img
                                src="/images/guest.svg"
                                width="30px"
                                height="30px"
                              />
                            </div>
                            <div>
                              <span className="uk-form-label">{`${guest.first_name} ${guest.last_name}`}</span>
                              <div className="secondary">{`${guest.pms_id}`}</div>
                              <div className="secondary">
                                {moment
                                  .utc(guest.check_in)
                                  .format("MM/DD/YYYY")}{" "}
                                -{" "}
                                {moment
                                  .utc(guest.check_out)
                                  .format("MM/DD/YYYY")}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertAdd;
