import { useContext, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import moment from "moment";
import UIkit from "uikit";

import { AppContexts } from "../../../providers";

const schema = yup.object().shape({
  pms_id: yup.string().required(),
  reservation_id: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  check_in: yup.date().required(),
  check_out: yup.date().required(),
});

const GuestAdd = () => {
  const reservationContext = useContext(AppContexts.ReservationsContext);

  const {
    flagIsNew,
    reservation,
    insertReservation,
    updateReservation,
    initReservation,
  } = reservationContext;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = useCallback(
    async (data) => {
      try {
        data.check_in = moment(data.check_in).format("YYYY-MM-DD");
        data.check_out = moment(data.check_out).format("YYYY-MM-DD");

        const result = await (flagIsNew
          ? insertReservation(data)
          : updateReservation(reservation.guest_reservation_id, { ...data }));

        if (result == null) {
          UIkit.notification(
            flagIsNew === true
              ? "Guest Add Failed: Reservation number already exists"
              : "Guest Update Failed",
            "danger",
          );
        } else {
          UIkit.modal("#modal-guest-add").hide();
          UIkit.notification(
            flagIsNew === true
              ? "Guest Added Successfully"
              : "Guest Updated Successfully",
            "success",
          );
        }
      } catch (err) {
        UIkit.notification("Failed", "error");
      }
    },
    [flagIsNew, reservation],
  );

  useEffect(() => {
    document.body.style.overflow = "unset";
    const fields = [
      "pms_id",
      "reservation_id",
      "first_name",
      "last_name",
      "check_in",
      "check_out",
      "email",
      "phone",
      "door_code",
      "updated_at",
    ];

    fields.forEach((field) => {
      switch (field) {
        case "door_code":
          setValue(
            field,
            reservation["manual_door_code"] || reservation["door_code"],
          );
          break;
        case "check_in":
          setValue(
            field,
            moment.utc(reservation.check_in).format("YYYY-MM-DD"),
          );
          break;
        case "check_out":
          setValue(
            field,
            moment.utc(reservation.check_out).format("YYYY-MM-DD"),
          );
          break;
        default:
          setValue(field, reservation[field]);
          break;
      }
    });
  }, [reservation]);

  const checkIn = useMemo(() => getValues("check_in"), [watch("check_in")]);

  const handleClose = useCallback(() => {
    initReservation();
  }, []);

  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div
      id="modal-guest-add"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {flagIsNew ? "Add a" : "Edit"} Guest
            </h2>
          </div>
          <div className="uk-modal-body">
            <div className="">
              <form
                data-uk-grid
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="unique-pms-id">
                    Property ID*
                  </label>
                  <img
                    className="info-icon"
                    src="/images/info-icon.svg"
                    alt="info"
                    uk-tooltip="Property ID used in your property management software and/or assigned to the home guide"
                  />
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["pms_id"],
                      })}
                      id="unique-pms-id"
                      type="text"
                      {...register("pms_id")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="unique-reservation-id"
                  >
                    Reservation Number*
                  </label>
                  <img
                    className="info-icon"
                    src="/images/info-icon.svg"
                    alt="info"
                    uk-tooltip="Unique Reservation Number"
                  />
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["reservation_id"],
                        "input-disable-status": flagIsNew === false,
                      })}
                      id="unique-reservation-id"
                      type="text"
                      {...register("reservation_id")}
                      disabled={!!reservation.reservation_id}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-first-name">
                    First Name*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["first_name"],
                      })}
                      id="guest-first-name"
                      type="text"
                      {...register("first_name")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-last-name">
                    Last Name*
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["last_name"],
                      })}
                      id="guest-last-name"
                      type="text"
                      {...register("last_name")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-check-in">
                    Check-In*
                  </label>
                  <div className="uk-form-controls">
                    <div className="hms-date-picker">
                      <input
                        className={classnames("uk-input", {
                          error: errors["check_in"],
                        })}
                        type="date"
                        id="guest-check-in"
                        placeholder="Pick a Date"
                        {...register("check_in")}
                      />
                      <div className="hms-date-placeholder">Pick a Date</div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-check-out">
                    Check-Out*
                  </label>
                  <div className="uk-form-controls">
                    <div className="hms-date-picker">
                      <input
                        className={classnames("uk-input", {
                          error: errors["check_out"],
                        })}
                        type="date"
                        id="guest-check-out"
                        placeholder="Pick a Date"
                        {...register("check_out")}
                        min={checkIn}
                      />
                      <div className="hms-date-placeholder">Pick a Date</div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="guest-email-address"
                  >
                    Email
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["email"],
                      })}
                      id="guest-email-address"
                      type="email"
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label
                    className="uk-form-label"
                    htmlFor="guest-mobile-number"
                  >
                    Mobile Phone
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["phone"],
                      })}
                      id="guest-mobile-number"
                      type="text"
                      {...register("phone")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="guest-door-code">
                    Door Code
                    {flagIsNew !== true ? (
                      <img
                        className="info-icon"
                        src="/images/info-icon.svg"
                        alt="info"
                        uk-tooltip="The door code updated here will take precedence over the door code sync from the PMS. Changes made to any other fields may get replaced with guest reservation imports"
                      />
                    ) : (
                      <div></div>
                    )}
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["door_code"],
                      })}
                      id="guest-door-code"
                      type="text"
                      {...register("door_code")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-1">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
              {flagIsNew !== true ? (
                <div className="s-msg-last-updated">
                  {reservation?.updated_at
                    ? "Last Updated: " +
                      moment(reservation?.updated_at).format(
                        "MM/DD/YYYY hh:mm a",
                      )
                    : ""}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAdd;
