// Import base components
import http, { httpV2 } from "../http.js";

const AlertsService = {
  getAlerts: () => httpV2.get("/alerts"),
  insertAlert: (objAlert) => httpV2.post("/alerts", objAlert),
  updateAlert: (objAlert) =>
    httpV2.put(`/alerts/${objAlert.alert_id}`, objAlert),
  deleteAlert: (objAlert) => httpV2.delete(`/alerts/${objAlert.alert_id}`),
  getAlertGuests: (objAlert) => httpV2.post(`/alerts/guestlist`, objAlert),
};

export default AlertsService;
