import http, { httpV2 } from "../http.js";

const DestinationService = {
  getListDestinations: () => httpV2.get("/destinations"),
  createDestination: (objDestination) =>
    httpV2.post("/destinations", objDestination),
  getDestination: (destination_id) =>
    httpV2.get(`/destinations/${destination_id}`),
  updateDestination: (objDestination) =>
    httpV2.put(
      `/destinations/${objDestination.destination_id}`,
      objDestination,
    ),
  deleteDestination: (destination_id) =>
    httpV2.delete(`/destinations/${destination_id}`),
  createRecommendation: (recommendation) =>
    httpV2.post("/destinations/recommendations", recommendation),
  deleteRecommendation: (recommendation_id, destination_id) =>
    httpV2.delete(`/destinations/recommendations/${destination_id}/${recommendation_id}`),
  updateRecommendation: (recommendation) =>
    httpV2.put("destinations/recommendations/update", recommendation),
  updateRecommendationOrder: (order) =>
    httpV2.put("destinations/recommendations/ordering", order),
};

export default DestinationService;
