import React, { Fragment } from "react";

const Range = ({ question, index, type, disabled = true, answer }) => {
  console.log("Answer", answer, type);

  return (
    <div className="uk-form-controls uk-flex uk-margin-top">
      <div className="uk-margin-right">
        {question?.low_label == "" ? "Low" : question?.low_label}
      </div>
      <div className="hms-rating">
        {[...Array(type)].map((_, i) => {
          console.log(type, i, answer, i + 1 === +answer);
          return (
            <Fragment key={i}>
              <input
                type="radio"
                id={`range${type}-${index}-${i + 1}`}
                name={`range${type}-${index}`}
                value={i + 1}
                disabled={disabled}
                defaultChecked={i + 1 === +answer}
              />
              <label
                className={i + 1 === +answer ? "hms-checked" : ""}
                htmlFor={`range${type}-${index}-${i + 1}`}
                title="text"
              >
                {i + 1}
              </label>
            </Fragment>
          );
        })}
      </div>
      <div>{question?.high_label == "" ? "High" : question?.high_label}</div>
    </div>
  );
};

export default Range;
