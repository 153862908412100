import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PropertyIndex from "./property_index";
import PropertyGuideEditor from "./editor/index";

const PropertyGuide = () => {
  const { url, path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PropertyIndex />
      </Route>
      <Route exact path={`${path}/:property_id`}>
        <PropertyGuideEditor />
      </Route>
    </Switch>
  );
};

export default PropertyGuide;
