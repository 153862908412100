import React, { useContext, useEffect, useMemo } from "react";

import { Button } from "../../ui";
import { AppContexts } from "../../providers";
import UIkit from "uikit";

const TagsList = ({ all, disabled }) => {
  const { selectedRentals, updateRental } = useContext(
    AppContexts.RentalsContext,
  );

  const checkedAll = useMemo(() => {
    return all.map((tagValue) => {
      return selectedRentals.every((rental) =>
        rental.tags?.find((tag) => tag.id == tagValue.id),
      );
    });
  }, [selectedRentals]);

  const handleMultiTag = async (event, tagValue) => {
    let rentals;
    if (event.target.checked) {
      rentals = selectedRentals.filter(
        (rental) => !rental.tags?.find((tag) => tag.id == tagValue.id),
      );
      rentals = rentals.map((rental) => {
        return rental.tags
          ? {
              ...rental,
              tags: [...rental.tags, tagValue],
            }
          : {
              ...rental,
              tags: [tagValue],
            };
      });
    } else {
      rentals = selectedRentals.filter((rental) =>
        rental.tags?.find((tag) => tag.id == tagValue.id),
      );
      rentals = rentals.map((rental) => {
        return {
          ...rental,
          tags: rental.tags.filter((tag) => tag.id != tagValue.id),
        };
      });
    }
    rentals.forEach((rental) => {
      updateRental(rental.rental_id, rental);
    });
  };

  return (
    <>
      <button className="uk-button hms-btn hms-white-btn" disabled={disabled}>
        <span uk-icon="icon: tag; ratio: 0.7"></span> Tag
      </button>
      <div data-uk-dropdown="mode: click">
        <ul className="uk-nav uk-dropdown-nav tags-selector">
          {all.map((tagValue, index) => (
            <li key={`taglist_${index}`}>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={checkedAll[index]}
                  onChange={(event) => handleMultiTag(event, tagValue)}
                />
                {tagValue.name}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TagsList;
