export const FormRow = ({ children, ...props }) => {
  return (
    // <div className="uk-grid-small" {...props} data-uk-grid>
    <div className="uk-width-1-1" {...props} data-uk-grid>
      {children}
    </div>
  );
};

export default FormRow;
