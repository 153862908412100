import React, { useEffect, useContext, useMemo, memo } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AppContexts } from "../../providers";
import moment from "moment";
import UIkit from "uikit";
import SMSEmailInputbox from "./../common/sms-email-inputbox";

const defaultValue = {
  template_id: 0,
  guest_set: "ids",
  emails: "",
  reservation_ids: [],
  delivery: "sms",
  subject: "",
  body: "",
  from_name: "",
  from_email: "",
  send_date: moment().format("MM/DD/YYYY"),
  send_time: "10:00",
  hidden: true,
  orientation: "check_in",
  immediate: true,
};

const schema = yup.object().shape({
  body: yup.string().required(),
});

const Card = ({ selectedIds, setSelected }) => {
  const { handleSubmit, getValues, setValue, register, watch } = useForm({
    resolver: yupResolver(schema),
  });
  const { listReservations, setReservations } = useContext(
    AppContexts.ReservationsContext,
  );
  const {
    templatesCtx: { get, templates },
  } = useContext(AppContexts.TemplatesContext);
  const {
    transactionalCtx: { extensions, loading, isSuccessful, setIsSuccessful },
  } = useContext(AppContexts.TransactionalContext);
  const settingsContext = useContext(AppContexts.SettingsContext);

  useEffect(() => {
    get(["msg_templates"]);
    settingsContext.get(["branding"]);
    Object.keys(defaultValue).forEach((val) => {
      if (val == "immediate") {
        if (defaultValue[val]) {
          setValue("action", "now");
          setValue("send_date", defaultValue.send_date);
          setValue("send_time", defaultValue.send_time);
        } else {
          setValue("action", "schedule");
        }
      }
      setValue(val, defaultValue[val]);
    });
  }, []);

  useEffect(() => {
    if (getValues("template_id") == 0) {
      setValue("body", "");
    } else {
      let tmp = msgTemplates.find((v) => v.id == getValues("template_id"))
        ?.body;
      setValue("body", tmp);
    }
  }, [watch("template_id")]);

  useEffect(() => {
    if (isSuccessful != null) {
      isSuccessful == true
        ? UIkit.notification("Send Successfully", "success")
        : UIkit.notification("Sending Failed", "danger");
      setIsSuccessful(null);
    }
  }, [isSuccessful]);

  const msgTemplates = useMemo(() => {
    if (!templates) {
      return [];
    } else {
      if (!templates.msg_templates) {
        return [];
      } else {
        return templates.msg_templates;
      }
    }
  }, [templates]);

  const converted_msgTemplates = useMemo(
    () =>
      msgTemplates.map((template) => {
        switch (template.folder) {
          case "scheduledMsg":
            return { ...template, folder: "Scheduler" };
          case "alert":
            return { ...template, folder: "Alerts" };
          case "scheduleAhead":
            return { ...template, folder: "Schedule Ahead" };
          case "extendYourStay":
            return { ...template, folder: "Extend Guest Stays" };
          default:
            return template;
        }
      }),
    [msgTemplates],
  );

  const templates_folder = useMemo(() => {
    let folders = [];
    converted_msgTemplates.forEach((template) => {
      if (!folders.find((folder) => folder === template.folder)) {
        folders.push(template.folder);
      }
    });
    return folders;
  }, [converted_msgTemplates]);

  const settingsBranding = useMemo(() => {
    if (!settingsContext.settings) {
      return {};
    } else {
      if (settingsContext.settings.branding) {
        return settingsContext.settings.branding;
      } else {
        return {};
      }
    }
  }, [settingsContext.settings]);

  const action = useMemo(() => getValues("action"), [watch("action")]);
  const body = useMemo(() => getValues("body"), [watch("body")]);
  const delivery = useMemo(() => getValues("delivery"), [watch("delivery")]);
  const template_id = useMemo(() => getValues("template_id"), [
    watch("template_id"),
  ]);

  useEffect(() => {
    if (
      delivery == "email" &&
      settingsBranding &&
      Object.keys(settingsBranding).length > 0
    ) {
      setValue("from_name", settingsBranding.from_name);
      setValue("from_email", settingsBranding.from_email);
    }
  }, [delivery, settingsBranding]);

  const getDaysBetweenDates = function (startDate, endDate) {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("MM/DD/YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const timeOptions = useMemo(
    () =>
      [...new Array(96)].map((_, idx) => {
        const minQuarter = idx % 4;
        const min = `0${minQuarter * 15}`.slice(-2);
        const hour = (idx - minQuarter) / 4;
        const meridiem = hour < 12 ? "AM" : "PM";
        const hour12 = hour % 12;
        return (
          <option key={`time_options_${idx}`} value={`${hour}:${min}`}>{`${
            hour12 === 0 ? 12 : hour12
          }:${min} ${meridiem}`}</option>
        );
      }),
    [],
  );

  const handleRegister = async (form_data) => {
    let tmp = msgTemplates.find((v) => v.id == getValues("template_id"))
      ?.subject;
    let real_form_data = {
      ...defaultValue,
      ...form_data,
      immediate: form_data.action == "now",
      reservation_ids: selectedIds,
      subject: tmp,
    };
    delete real_form_data["action"];
    const result = await extensions(real_form_data);
    if (!!result?.length) {
      const reservations = listReservations.map((reservation) => {
        if (
          real_form_data.reservation_ids.includes(
            reservation.guest_reservation_id,
          )
        ) {
          return {
            ...reservation,
            flags: [
              ...(reservation.flags || []),
              {
                item_id: reservation.guest_reservation_id,
                name: real_form_data.orientation + "_extension_sent",
              },
            ],
          };
        }
        return reservation;
      });
      setReservations(reservations);
    }
  };

  useEffect(() => {
    if (isSuccessful != null) {
      handleReset();
    }
  }, [isSuccessful]);

  const handleReset = () => {
    Object.keys(defaultValue).forEach((val) => {
      if (val == "immediate") {
        if (defaultValue[val]) {
          setValue("action", "now");
          setValue("send_date", defaultValue.send_date);
          setValue("send_time", defaultValue.send_time);
        } else {
          setValue("action", "schedule");
        }
      }
      setValue(val, defaultValue[val]);
    });

    setSelected([]);
  };

  return (
    <div className="uk-card uk-card-small uk-card-default hms-form-card extend-stays-messaging">
      <div className="uk-overflow-auto">
        <div className="edit-stays-header">
          <div className="uk-flex uk-flex-between">
            {/*
            {selectedIds.length == 0 && (
              <div className="hms-note">
                <div>Select guests by clicking on blue lines</div>
              </div>
            )}
             */}
            <div>{`${selectedIds.length} Guests Selected`}</div>
          </div>
        </div>
        <div>
          <form
            className="uk-form hms-form"
            onSubmit={handleSubmit(handleRegister)}
          >
            <div className="uk-margin">
              <div className="uk-form-label">Action</div>
              <div className="uk-grid uk-form-controls">
                <label className="uk-margin-right">
                  <input
                    className="uk-radio"
                    type="radio"
                    name="checkin-extend-radio"
                    value="check_in"
                    {...register("orientation")}
                  />{" "}
                  Early Check-In
                </label>
                <label>
                  <input
                    className="uk-radio"
                    type="radio"
                    name="checkin-extend-radio"
                    value="check_out"
                    {...register("orientation")}
                  />{" "}
                  Late Check-Out
                </label>
              </div>
            </div>
            <div className="uk-margin">
              <div className="uk-form-label">Send By</div>
              <div className="uk-grid uk-form-controls">
                <label className="uk-margin-right">
                  <input
                    className="uk-radio"
                    type="radio"
                    name="send-type-radio"
                    value="sms"
                    {...register("delivery")}
                  />{" "}
                  SMS
                </label>
                <label>
                  <input
                    className="uk-radio"
                    type="radio"
                    name="send-type-radio"
                    value="email"
                    {...register("delivery")}
                  />{" "}
                  Email
                </label>
              </div>
            </div>
            {delivery === "email" && (
              <>
                <div className="uk-width-1-2">
                  <label className="uk-form-label" htmlFor="txt-schedule-name">
                    Subject
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="txt-from-name"
                      type="text"
                      placeholder=""
                      {...register("subject")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <label className="uk-form-label" htmlFor="txt-schedule-name">
                    From Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="txt-from-name"
                      type="text"
                      {...register("from_name")}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <label className="uk-form-label" htmlFor="txt-schedule-name">
                    From Email
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="txt-from-name"
                      type="text"
                      placeholder=""
                      {...register("from_email")}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="uk-margin">
              <label className="uk-form-label">
                Select Text From a Template
              </label>
              <div className="uk-form-controls">
                <div uk-form-custom="target: > * > span:first-child">
                  <select {...register("template_id")}>
                    <option value={0}>Select One</option>
                    {templates_folder.map((folder, idx) => {
                      return (
                        <React.Fragment key={`msgTemplates_${idx}`}>
                          <optgroup label={folder}></optgroup>
                          {converted_msgTemplates
                            .filter((msgtem) => msgtem.folder === folder)
                            .map((msg, index) => (
                              <option
                                value={msg.id}
                                key={`template_option_${index}`}
                              >
                                {msg.name}
                              </option>
                            ))}
                        </React.Fragment>
                      );
                    })}
                    <optgroup label="All"></optgroup>
                    {converted_msgTemplates.map((template, index) => (
                      <option
                        value={template.id}
                        key={`msgTemplates_all_${index}`}
                      >
                        {template.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    style={{ width: "100%", minWidth: "200px" }}
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {!!msgTemplates.find((item) => +item.id === +template_id)
                        ? msgTemplates.find((item) => +item.id === +template_id)
                            .name
                        : "Select One"}
                    </span>
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-margin">
              <SMSEmailInputbox
                value={body || ""}
                onChange={(event) => setValue("body", event.target.value)}
                macrosOrResponse={true}
                limit={delivery === "sms"}
              />
            </div>
            <div className="uk-margin">
              <div className="uk-form-label">Send</div>
              <div className="uk-grid uk-form-controls">
                <label className="uk-margin-right">
                  <input
                    className="uk-radio"
                    type="radio"
                    name="send-schedule-radio"
                    {...register("action")}
                    value="now"
                  />{" "}
                  Send Now
                </label>
                <label>
                  <input
                    className="uk-radio"
                    type="radio"
                    name="send-schedule-radio"
                    {...register("action")}
                    value="schedule"
                  />{" "}
                  Schedule For
                </label>
              </div>
            </div>
            {action == "schedule" && (
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <div uk-form-custom="target: > * > span:first-child">
                    <select {...register("send_date")}>
                      {getDaysBetweenDates(
                        moment(),
                        moment().add(1, "months"),
                      ).map((date, index) => (
                        <option value={date} key={`send_date_${index}`}>
                          {date}
                        </option>
                      ))}
                    </select>
                    <button
                      className="uk-button uk-button-default"
                      type="button"
                      tabIndex="-1"
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                  <div uk-form-custom="target: > * > span:first-child">
                    <select {...register("send_time")}>
                      <option value="">Select a time</option>
                      {timeOptions}
                    </select>
                    <button
                      className="uk-button uk-button-default"
                      type="button"
                      tabIndex="-1"
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="uk-margin">
              <button
                className="uk-button hms-btn hms-red-btn"
                type="submit"
                disabled={selectedIds.length == 0}
              >
                {loading && <div data-uk-spinner="ratio: 0.5;"></div>}
                &nbsp;&nbsp;SEND
              </button>
              <button
                className="uk-button hms-btn hms-btn-transparent"
                type="button"
                onClick={handleReset}
                // disabled={selectedIds.length == 0}
              >
                RESET
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(Card);
