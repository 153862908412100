// Import base components
import http, { httpV2 } from "../http.js";

const ContactsService = {
  getContacts: () => httpV2.get("/contacts"),
  getContactById: (contactId) => httpV2.get(`/contacts/${contactId}`),
  insertContact: (objContact) => httpV2.post("/contacts", objContact),
  updateContact: (objContact) =>
    httpV2.put(`/contacts/${objContact.id}`, objContact),
  deleteContact: (objContact) => httpV2.delete(`/contacts/${objContact.id}`),
  importContacts: (contactsArr) => httpV2.post(`/contacts/import`, contactsArr),
  sendMessageToContacts: (objMessage) =>
    httpV2.post("/contacts/message", objMessage),
};

export default ContactsService;
