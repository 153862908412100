import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";

const NewSubscription = () => {
  return (
    <li className="left-nav-tab-content">
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <div className="uk-width-1-1 uk-margin-bottom hms-headings">
          <h2>Account</h2>
        </div>
        <form action="#">
          <div className="uk-grid-small hms-billing-form" data-uk-grid>
            <div className="uk-width-1-1">
              <label className="uk-form-label">Company Name</label>
              <div className="uk-form-controls">
                <input className="uk-input" type="text" placeholder="" />
              </div>
            </div>
            <div className="uk-width-1-1">
              <label className="uk-form-label">Account Email</label>
              <div className="uk-form-controls">
                <input className="uk-input" type="text" placeholder="" />
              </div>
            </div>
            <div className="uk-width-1-1">
              <label className="uk-form-label">Billing Email</label>
              <div className="uk-form-controls">
                <input className="uk-input" type="text" placeholder="" />
              </div>
            </div>

            <div className="uk-width-1-1">
              <button className="uk-button hms-btn hms-red-btn" type="button">
                Save
              </button>
              <button
                className="uk-button hms-btn hms-red-outline-btn"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </li>
  );
};

export default NewSubscription;
