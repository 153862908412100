import moment from "moment";

const hasFlag = (reservation, flag) => {
  return !!(reservation?.flags || []).filter((f) => f.name === flag)[0];
};

const ReservationType = ({ reservation }) => {
  if (reservation?.category === "CXL") {
    return "Cancelled";
  }
  if (hasFlag(reservation, "late_check_out")) {
    return "Late Check-Out Requested";
  }
  if (hasFlag(reservation, "early_check_in")) {
    return "Early Check-In Requested";
  }
  if (moment() > moment(reservation?.check_out)) {
    if (moment() < moment(reservation?.check_out).add(1, "day")) {
      return "Departing Today";
    }
    return "Past Stay";
  } else if (moment() > moment(reservation?.check_in)) {
    if (moment() < moment(reservation?.check_in).add(1, "day")) {
      return "Arriving Today";
    }
    return "Currently Staying";
  } else {
    return "Upcoming Stay";
  }
};

export default ReservationType;
