import { Route, Switch, useRouteMatch } from "react-router-dom";

import LoginForm from "./forms/login";
import SignupForm from "./forms/signup";
import JoinForm from "./forms/join";
import ResetForm from "./forms/reset";
import UpdateForm from "./forms/update";
import EmailForm from "./forms/email";

const Auth = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={LoginForm} />
      <Route path={`${path}/login`} component={LoginForm} />
      <Route path={`${path}/signup`} component={SignupForm} />
      <Route path={`${path}/join`} component={JoinForm} />
      <Route path={`${path}/reset`} component={ResetForm} />
      <Route path={`${path}/reset-password`} component={UpdateForm} />
      <Route path={`${path}/reset-email`} component={EmailForm} />
    </Switch>
  );
};

export default Auth;
