// Import base components
import { httpV2 } from "../http.js";

const WorkOrderService = {
  selectWorkOrderList: () => httpV2.get("/workorders"),
  insertWorkOrder: (order) => httpV2.post("/workorders", order),
  updateWorkOrder: (id, obj) => httpV2.put(`/workorders/${id}`, obj),
  deleteWorkOrder: (id) => httpV2.delete(`/workorders/${id}`),
  getWorkOrder: (id, slug = undefined) => {
    if (slug) {
      return httpV2.get(`/workorders/${slug}/${id}`);
    } else {
      return httpV2.get(`/workorders/${id}`);
    }
  },
  getWorkOrders: (slug = undefined, criteria, recordsPerPage) => {
    if (slug) {
      return httpV2.get(
        `/workorders/${slug}?page=${criteria.page}&filters=${criteria.filters}&per=${recordsPerPage}&search_term=${criteria.search_term}&priority=${criteria.priority}&category=${criteria.category}&assignee=${criteria.assignee}&tag=${criteria.tag}`,
      );
    } else {
      return httpV2.get(
        `/workorders?page=${criteria.page}&filters=${criteria.filters}&per=${recordsPerPage}&search_term=${criteria.search_term}&priority=${criteria.priority}&category=${criteria.category}&assignee=${criteria.assignee}&tag=${criteria.tag}`,
      );
    }
  },
  commentWorkOrder: (id, data, slug = undefined) => {
    if (slug) {
      return httpV2.post(`/workorders/${slug}/${id}/comment`, data);
    } else {
      return httpV2.post(`/workorders/${id}/comment`, data);
    }
  },
  getAssigners: (slug = "") => httpV2.get(`/workorders/assigners?slug=${slug}`),
  getTags: (slug = "") => httpV2.get(`workorders/tags?slug=${slug}`),
};

export default WorkOrderService;
