// Import package components
import { useContext, useEffect, useState } from "react";
import UIkit from "uikit";
import CopyToClipboard from "react-copy-to-clipboard";
import { AppContexts } from "../../../providers";

const MacrosReservationKeys = () => {
  let { allSurveys, getSurveyList } = useContext(AppContexts.SurveysContext);
  let { settings, get } = useContext(AppContexts.SettingsContext);

  const macrosValues = [
    { value: "[FirstName]", label: "Guest's first name" },
    { value: "[LastName]", label: "Guest's last name" },
    { value: "[CheckIn]", label: "Check-in date" },
    { value: "[CheckOut]", label: "Check-out date" },
    { value: "[Email]", label: "Email" },
    { value: "[PhoneNumber]", label: "Phone Number" },
    { value: "[ReservationId]", label: "Reservation Number" },
    { value: "[CompanyName]", label: "Company Name" },
    { value: "[PropertyGuideLink]", label: "Link to home guide" },
    { value: "[DestinationGuideLink]", label: "Link to area guide" },
    { value: "[PropertyAddress]", label: "Property address" },
    { value: "[PropertyId]", label: "Property Id" },
    { value: "[DoorCode]", label: "Reservation's door code" },
    { value: "[FiveStarLink]", label: "Five-star review link" },
    { value: "[GuestCheckIn]", label: "Link to check-in" },
    { value: "[GuestCheckOut]", label: "Link to check-out" },
    { value: "[EarlyCheckIn]", label: "Link to request early check-in" },
    { value: "[LateCheckOut]", label: "Link to request late check-out" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    getSurveyList();
    get(["custom_macros"]);

    const dropdownElement = document.getElementById("macros-reservation");
    if (dropdownElement) {
      dropdownElement.addEventListener("beforehide", handleDropdownClose);
    }
    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("beforehide", handleDropdownClose);
      }
    };
  }, []);

  const handleDropdownClose = () => {
    const dropdownElement = document.getElementById("macros-reservation");
    if (dropdownElement) {
      dropdownElement.scrollTop = 0;
    }
  };

  const handleCopy = () => {
    UIkit.notification("Copied", "success");
  };
  return (
    <>
      <div id="macros-reservation" data-uk-dropdown="mode: click">
        <ul className="uk-nav uk-dropdown-nav tags-selector">
          <div className="uk-form-label uk-margin-top uk-margin-tiny-bottom uk-margin-small-left">
            Booking Macros
          </div>
          {macrosValues.map((macros, index) => (
            <li
              key={`macros_${index}`}
              className="messages-li"
              uk-tooltip="Click to Copy Macro"
            >
              <CopyToClipboard text={macros.value} onCopy={handleCopy}>
                <div>
                  <span className="macro-value">{macros.value}</span>{" "}
                  <span>{macros.label}</span>
                </div>
              </CopyToClipboard>
            </li>
          ))}
          <div className="uk-form-label uk-margin-top uk-margin-tiny-bottom uk-margin-small-left">
            Property Macros
          </div>
          {settings.custom_macros?.map((macros, index) => (
            <li
              key={`custom_macros_${index}`}
              className="messages-li"
              uk-tooltip="Click to Copy Macro"
            >
              <CopyToClipboard text={macros.macro} onCopy={handleCopy}>
                <div>
                  <div>{macros.macro}</div>
                  <div uk-line-clamp="lines: 1">{macros.label}</div>
                </div>
              </CopyToClipboard>
            </li>
          ))}
          <div className="uk-form-label uk-margin-top uk-margin-tiny-bottom uk-margin-small-left">
            Survey Macros
          </div>
          {allSurveys?.map((survey, index) => (
            <li
              key={`survey_${index}`}
              className="messages-li"
              uk-tooltip="Click to Copy Macro"
            >
              <CopyToClipboard
                text={`[Survey-${survey.survey_id}]`}
                onCopy={handleCopy}
              >
                <div>
                  <span className="macro-value">{`[Survey-${survey.survey_id}]`}</span>
                  <span>
                    {survey.name?.length > 30
                      ? `${survey.name.slice(0, 30)}...`
                      : survey.name}
                  </span>
                </div>
              </CopyToClipboard>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MacrosReservationKeys;
