// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const SurveyDelete = () => {
  const { deleteSurvey, survey, flagIsProcessing, setSurveys, setSurvey } =
    useContext(AppContexts.SurveysContext);

  const handleDelete = async () => {
    let res = await deleteSurvey(survey);
    if (res) {
      setSurveys((prev) =>
        prev.map((indexSurvey) =>
          indexSurvey.survey_id === res.survey_id ? res : indexSurvey,
        ),
      );
      setSurvey(res);
      UIkit.modal("#survey-delete-modal").hide();
    }
  };

  return (
    <>
      <Dialog id="survey-delete-modal">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Survey</h2>
          <p>
            Are you sure you want to delete <b>{survey.name}</b>?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
              disabled={flagIsProcessing}
            >
              {flagIsProcessing == true ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : (
                "Delete"
              )}
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default SurveyDelete;
