import Resize from "../mixins/resize";

export default {
  mixins: [Resize],

  props: {
    footerEl: String,
    headerEl: String,
  },

  data: {
    footerEl: null,
    headerEl: null,
  },

  resizeTargets() {
    return [];
  },

  update: {
    read() {},

    write() {
      let footerHeight = 0;
      let headerHeight = 0;

      if (!!this.headerEl) {
        (() => {
          let header = document.querySelector(this.headerEl);
          let bounds = header.getBoundingClientRect();
          let { marginTop, marginBottom } = window.getComputedStyle(header);

          headerHeight =
            bounds.height + parseInt(marginTop) + parseInt(marginBottom) || 0;
        })();
      }

      if (!!this.footerEl) {
        (() => {
          let footer = document.querySelector(this.footerEl);
          let bounds = footer.getBoundingClientRect();
          let { marginTop, marginBottom } = window.getComputedStyle(footer);

          footerHeight =
            bounds.height + parseInt(marginTop) + parseInt(marginBottom) || 0;
        })();
      }

      let offsetHeight = this.$el.getBoundingClientRect().top + window.scrollY;
      this.$el.style.height = `calc(100vh - 57px - ${
        offsetHeight + footerHeight + headerHeight
      }px`;
    },

    events: ["resize"],
  },
};
