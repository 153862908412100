import { Switch, Route, useRouteMatch } from "react-router-dom";

import SurveyIndex from "./survey_index";
import SurveyEdit from "./survey_edit";
import SurveyPreview from "./survey_preview";
import Responses from "./responses";

const Survey = () => {
  const { url, path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SurveyIndex />
      </Route>
      <Route exact path={`${path}/:surveyId/edit`}>
        <SurveyEdit />
      </Route>
      <Route exact path={`${path}/:surveyId/result`}>
        <Responses />
      </Route>
      <Route exact path={`${path}/:surveyId/preview`}>
        <SurveyPreview />
      </Route>
    </Switch>
  );
};

export default Survey;
