// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import Dialog from "../common/Dialog";

const EmailDelete = () => {
  const guidesContext = useContext(AppContexts.GuidesContext);
  const { guestEmail, selectedEmails, batchFlag } = guidesContext;

  const handleDelete = () => {
    if (batchFlag) {
      selectedEmails.forEach(
        async (guestEmail) => await guidesContext.deleteEmail(guestEmail),
      );
    } else {
      guidesContext.deleteEmail(guestEmail);
    }
    UIkit.modal("#modal-contact-delete").hide();
  };

  return (
    <>
      <Dialog id="modal-contact-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">
            Delete
            {batchFlag ? " Guest Emails" : " Guest Email"}
          </h2>
          <p>
            Are you sure you want to delete{" "}
            <b>
              {batchFlag
                ? `${selectedEmails.length} email${
                    selectedEmails.length === 1 ? "" : "s"
                  }`
                : `${guestEmail?.email}`}
            </b>
            ?
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default EmailDelete;
