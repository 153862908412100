export default {
  connected() {
    let el = this.$el;
    let parent = el.parentNode;

    const resizeObserver = new ResizeObserver((entries) => {
      this.$emit("resize");
    });

    while (parent) {
      if (parent.nodeType === 1) {
        resizeObserver.observe(parent);
      }

      parent = parent.parentNode;
    }
  },
};
