import { useCallback, useMemo, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import moment from "moment";

import { AppContexts } from "../../../providers";
import { InputRadio, Form, FormRow, FormInputWrapper } from "../../../ui";

// Import common components
import SMSEmailInputbox from "../../common/sms-email-inputbox";

const schema = yup.object().shape({
  name: yup.string().required(),
  response: yup.string().required(),
});

const defaultValues = {
  name: "",
  response: "",
};

const SavedResponseAdd = () => {
  const {
    templatesCtx: { response, setResponse, isNew, set },
  } = useContext(AppContexts.TemplatesContext);
  const offcanvasRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const responseBody = useMemo(() => getValues("response"), [
    watch("response"),
  ]);

  const handleBeforeHide = (event) => {
    if (event.target === offcanvasRef.current) {
      clearErrors();
      setResponse({});
    }
  };

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  useEffect(() => {
    clearErrors();
    Object.keys(defaultValues).forEach((formname) => {
      setValue(
        formname,
        response[formname] ? response[formname] : defaultValues[formname],
      );
    });

    if (Object.keys(response).length === 0) {
      clearForms();
    }
  }, [response]);

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  const handleRegister = async (formdata) => {
    let formResponse = formdata;
    try {
      const result = await (isNew
        ? set({ saved_responses: [formResponse] }, "saved_responses")
        : set(
            {
              saved_responses: [
                {
                  ...formResponse,
                  saved_response_id: response.saved_response_id,
                },
              ],
            },
            "saved_responses",
          ));

      UIkit.modal("#saved-response-modal").hide();
      UIkit.notification(
        isNew === true ? "Response Added" : "Response Updated",
        "success",
      );
    } catch (err) {
      UIkit.notification("Failed", "error");
    }
  };

  const handleError = (err) => console.error(err);

  return (
    <>
      <div
        id="saved-response-modal"
        ref={offcanvasRef}
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {isNew === true ? "Add a" : "Edit"} Saved Response
              </h2>
            </div>
            <div className="uk-modal-body uk-flex">
              <Form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                <FormRow>
                  <FormInputWrapper label="Name*">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.name,
                        })}
                        id="txt-schedule-name"
                        type="text"
                        placeholder=""
                        {...register("name")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1">
                    <SMSEmailInputbox
                      value={responseBody || ""}
                      error={errors.response}
                      onChange={(evt) => setValue("response", evt.target.value)}
                      onSavedReply={(val) => setValue("response", val)}
                      macrosOrResponse={false}
                    />
                  </div>
                </FormRow>

                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="s-msg-last-updated">
                  {isNew === false ? (
                    <div className="s-msg-last-updated">
                      {"Last Updated: " +
                        moment(response?.updated_at).format(
                          "MM/DD/YYYY hh:mm a",
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedResponseAdd;
