// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { Image, InputContainer, Flex } from "uikit-react";

import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { AppContexts } from "../../../providers";
import AddTextLine from "../../common/AddTextLine";

const max_welcome_msg_length = 144;
const max_auto_response_msg_length = 144;
const max_out_of_office_msg_length = 144;

const ChatTag = () => {
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const { handleSubmit } = useForm();

  const [dirty, setDirty] = useState(false);
  const [chat, setChat] = useState({});

  useEffect(() => {
    document.title = "Settings | Webchat - RueBaRue";
    if (!!settings.chat) {
      setChat(settings.chat);
    }
  }, [settings]);

  const handleRegister = (data) => {
    set("chat", chat).then((res) => {
      setDirty(false);
    });
  };

  const updateChat = (field, e) => {
    setChat({ ...chat, [field]: e.target.value });
    setDirty(true);
  };

  const updateToggle = (field, e) => {
    setChat({ ...chat, [field]: !chat[field] });
    setDirty(true);
  };

  return (
    <li
      className={`setting-tab-content ${active === "chat" ? "uk-active" : ""}`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <AddTextLine feature="Webchat" />
        <h2>Webchat</h2>
        <form
          className="uk-grid-small"
          data-uk-grid
          onSubmit={handleSubmit(handleRegister)}
        >
          <div className="uk-width-1-2@s"></div>
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-middle">
              <div className="autosend-checkbox">
                <label for="useWebChat" className="setting-switch">
                  <input
                    type="checkbox"
                    id="useWebChat"
                    checked={chat.web_active}
                    onInput={() => {
                      updateToggle("web_active");
                    }}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <div className="uk-form-label uk-margin-small-left">Enable</div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="uk-form-label">Embed Script</div>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                rows="3"
                disabled="true"
                value={`<script id='rbr-chat' src='https://www.ruebarue.com/chat?account_id=${settings.account_id}'></script>`}
              ></textarea>
            </div>
            <p className=" secondary">
              Place the code right before the end of the &lt;body&gt; tag on any
              page that you want to enable Webchat on.
            </p>
            <div className="uk-align-right uk-text-small">
              <CopyToClipboard
                text={`<script id='rbr-chat' src='https://www.ruebarue.com/chat?account_id=${settings.account_id}'></script>`}
              >
                <div
                  style={{ cursor: "pointer" }}
                  uk-tooltip="Copy to Clipboard"
                >
                  <span className="uk-text-small uk-margin-tiny-right">
                    Copy
                  </span>
                  <Image
                    src="/images/copy-macro-icon.svg"
                    style={{ width: "18px", height: "18px" }}
                  />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <div className="uk-width-1-1">
            <div className="uk-form-label">Welcome Message</div>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                rows="3"
                maxLength={max_welcome_msg_length}
                value={chat.welcome_msg}
                onInput={(e) => {
                  updateChat("welcome_msg", e);
                }}
              ></textarea>
            </div>
            <p className="secondary">
              Characters Remaining:{" "}
              {max_welcome_msg_length - chat.welcome_msg?.length}
            </p>
          </div>

          <div className="uk-width-1-1">
            <div className="uk-form-label">Auto Response</div>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                rows="3"
                maxLength={max_auto_response_msg_length}
                value={chat.auto_response_msg}
                onInput={(e) => {
                  updateChat("auto_response_msg", e);
                }}
              ></textarea>
            </div>
            <p className="secondary">
              Characters Remaining:{" "}
              {max_auto_response_msg_length - chat.auto_response_msg?.length}
            </p>
          </div>

          <div className="uk-width-1-1">
            <div className="uk-form-label">Out-of-Office Response</div>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                rows="3"
                maxLength={max_out_of_office_msg_length}
                value={chat.out_of_office_msg}
                onInput={(e) => {
                  updateChat("out_of_office_msg", e);
                }}
              ></textarea>
            </div>
            <p className="secondary">
              Characters Remaining:{" "}
              {max_out_of_office_msg_length - chat.out_of_office_msg?.length}
            </p>
          </div>

          <div className="uk-width-1-1 uk-margin-top">
            <button
              className="uk-button hms-btn hms-red-btn"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : dirty ? (
                "Save"
              ) : (
                "Saved"
              )}
            </button>
          </div>
        </form>
      </div>
    </li>
  );
};

export default ChatTag;
