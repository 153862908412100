import { useContext, useState, useEffect } from "react";
import UIkit from "uikit";
import { useForm } from "react-hook-form";

import { classes } from "../../../utils/classes";
import { AppContexts } from "../../../providers";
import { Link } from "../../ui-kit/Link";
import Dialog from "../Dialog";

const defaultImg = "/images/upload-image.svg";

const ImageInput = (props) => {
  let [status, setStatus] = useState("initial");
  let [image, setImage] = useState(defaultImg);
  let [upload, setUpload] = useState(null);

  useEffect(() => {
    console.log(props);
    if (!!props.attachment) {
      setImage(
        `${props.attachment.url}?nocache=${(Math.random() * 10000000) | 0}`,
      );
    }
  }, [props.attachment]);

  const removeImage = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setImage(defaultImg);
    setUpload(null);
    setStatus("dirty");

    const handler = props.handler || (() => {});
    handler(null);
  };

  const updateImage = (e) => {
    var files = e.target.files;

    if (files.length) {
      var oFReader = new FileReader();
      oFReader.readAsDataURL(files[0]);
      oFReader.onload = function (oFREvent) {
        setImage(oFREvent.target.result);
      }.bind(this);

      setStatus("dirty");
      setUpload(files[0] || null);

      const handler = props.handler || (() => {});
      handler(files[0]);
    }
  };

  const getImage = () => {
    if (status === "dirty" && upload === null) {
      return defaultImg;
    } else {
      return image;
    }
  };

  return (
    <div className="uk-width-1-1">
      <div className="image-upload">
        {getImage() !== defaultImg && (
          <div className="remove-img">
            <div uk-tooltip="Delete">
              <img src="/images/delete.svg" />
            </div>
            <div uk-drop="mode: click">
              <div className="uk-card uk-card-body uk-card-default hms-confirm-drop">
                <h2 className="uk-modal-title uk-flex uk-flex-left">Confirm</h2>
                <p>Are you sure you want to delete?</p>
                <p className="uk-text-right">
                  <button
                    className="uk-button hms-btn hms-white-btn uk-drop-close"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="uk-button hms-btn hms-gray-btn"
                    type="button"
                    onClick={removeImage}
                  >
                    Delete
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <label>
        <div className="uk-form-label">{props.label}</div>
        <div className="js-upload uk-placeholder hms-file-uploader uk-text-center">
          <div>
            <img className="bg-img" src={getImage()} />
          </div>
          {getImage() === defaultImg && (
            <div uk-form-custom>
              <input
                type="file"
                onChange={updateImage}
                style={{
                  opacity: 0,
                  height: 0,
                }}
              />
                Drag a file or <span className="uk-link">browse</span> to upload
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default ImageInput;
