// Import package components
import { useContext, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

// Import base components
import { AppContexts } from "../../../providers";
import { Form, FormRow, FormInputWrapper } from "../../../ui";

const schema = yup.object().shape({
  name: yup.string().required(),
});

const defaultValues = {
  name: "",
};

const SurveyAdd = () => {
  const history = useHistory();
  const surveysContext = useContext(AppContexts.SurveysContext);
  const { survey, flagIsNew, insertSurvey, updateSurvey, flagIsProcessing } =
    surveysContext;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrors();
    Object.keys(defaultValues).forEach((formname) => {
      setValue(
        formname,
        survey[formname] ? survey[formname] : defaultValues[formname],
      );
    });

    if (Object.keys(survey).length === 0) {
      clearForms();
    }
  }, [JSON.stringify(survey)]);

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  const handleRegister = useCallback(
    async (formdata) => {
      try {
        let res = await (flagIsNew
          ? insertSurvey({
              ...formdata,
              questions: [],
              deleted: false,
              question_order: "",
            })
          : updateSurvey({
              ...formdata,
              question_order: "",
              questions: [],
              id: survey.id,
              deleted: false,
            }));
        UIkit.modal("#survey-add-modal").hide();
        UIkit.notification(
          flagIsNew === true ? "Survey Added" : "Survey Updated",
          "success",
        );
        history.push(`surveys/${res.survey_id}/edit`);
      } catch (err) {
        UIkit.notification("Failed", "error");
      }
    },
    [flagIsNew, survey.id],
  );
  const handleError = useCallback((err) => console.error(err), []);

  return (
    <>
      <div
        id="survey-add-modal"
        className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large uk-icon uk-close"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {flagIsNew === true ? "Add a" : "Edit"} Survey
              </h2>
            </div>
            <div className="uk-modal-body">
              <Form
                className="uk-grid"
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                {/* <form className="uk-grid-small" uk-grid> */}
                <FormRow>
                  <FormInputWrapper label="Name*" size="two">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.name,
                        })}
                        type="text"
                        {...register("name")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <div className="uk-modal-footer">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={flagIsProcessing}
                  >
                    {flagIsProcessing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyAdd;
