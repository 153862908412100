const ReservationNumber = ({ hostID, pms, resevationID, nativeID }) => {
  let result;

  // Logic built for Bluewater Vacation Homes
  if (pms === "Track" && hostID === 5191095186096128) {
    result = (
      <div className="secondary">
        <a
          href={`https://bluewatervh.trackhs.com/pms/reservations/view/${resevationID}`}
          target="_blank"
        >
          {resevationID}
        </a>
      </div>
    );
  } else if (hostID === 6413949926899712) {
    result = (
      <div className="secondary">
        <a
          href={`https://bluewatervh.trackhs.com/pms/reservations/view/${resevationID}`}
          target="_blank"
        >
          {resevationID}
        </a>
      </div>
    );
  } else if (pms === "Streamline") {
    result = nativeID;
  } else {
    result = resevationID;
  }

  return <div>{result}</div>;
};

export default ReservationNumber;
