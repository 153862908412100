import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContexts } from "../../../providers";
import classnames from "classnames";
import UIkit from "uikit";

const AddTab = () => {
  const { set, get, settings } = useContext(AppContexts.SettingsContext);
  const { refresh } = useContext(AppContexts.AuthContext);

  let [label, setLabel] = useState("");
  let [errors, setErrors] = useState([]);
  let [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    get([
      ...Object.keys(settings),
      "welcome_tabs",
      "welcome_order",
      "guestbook",
    ]).then(() => {
      setRefreshing(false);
    });
  }, []);

  const { handleSubmit } = useForm();

  const submit = () => {
    if (label.trim() === "") {
      setErrors(["label"]);
    }

    let temp_tabs = JSON.parse(JSON.stringify(settings.welcome_tabs));
    let temp_order = JSON.parse(JSON.stringify(settings.welcome_order));
    let temp_guestbook = JSON.parse(JSON.stringify(settings.guestbook));

    let new_tab_id = Math.round(Math.random() * Math.pow(10, 16)).toString();

    temp_tabs.push({
      type: new_tab_id,
      label: label,
      show_on_guide: true,
      show_on_embed: true,
    });

    temp_order.push({
      type: new_tab_id,
      ordering: [],
    });

    temp_guestbook.tab_order = [
      ...temp_guestbook.tab_order.split(","),
      new_tab_id,
    ].join(",");

    set(["welcome_tabs", "welcome_order", "guestbook"], {
      welcome_tabs: temp_tabs,
      welcome_order: temp_order,
      guestbook: temp_guestbook,
    }).then(async () => {
      UIkit.modal("#addTab-modal").hide();
      UIkit.notification("Added Successfully", "success");
      setLabel("");

      await refresh();
    });
  };

  const edit = (e) => {
    setLabel(e.target.value);
    setErrors([]);
  };

  const handleCancel = () => {
    setLabel("");
  };

  return (
    <div
      id="addTab-modal"
      className="uk-modal-full hms-modals uk-modal"
      uk-offcanvas="overlay: true; flip: true;"
    >
      <div className="uk-offcanvas-bar">
        <div>
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Add Tab</h2>
          </div>
          <div className="uk-modal-body">
            <div>
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleSubmit(submit)}
              >
                <div className="uk-width-1-1">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">Name*</label>
                    <input
                      className={classnames("uk-input", { error: errors.type })}
                      type="text"
                      value={label}
                      onInput={edit}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    {refreshing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleCancel}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTab;
