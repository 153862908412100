import { createContext, useState } from "react";

import destinationService from "../services/destination.service";

export const DestinationContext = createContext();

export const DestinationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [destinationId, setDestinationId] = useState(0);
  const [tabId, setTabId] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [listDestinations, setListDestinations] = useState([]);
  const [destination, setDestination] = useState({});
  const [section, setSection] = useState(null);
  const [selectedId, setSelectedId] = useState(0);
  const [shared, setShared] = useState(null);

  const [deletedRecommendation, setDeletedRecommendation] = useState(null);

  let contextDestination = {
    loading,
    listDestinations,
    destination,
    setDestination,
    selectedId,
    setSelectedId,
    shared,
    setShared,
    destinationId,
    setDestinationId,
    tabId,
    setTabId,
    isNew,
    setIsNew,
    section,
    setSection,

    deletedRecommendation,
    setDeletedRecommendation,

    get: async () => {
      setLoading(true);
      try {
        const result = await destinationService.getListDestinations();
        if (result.data) {
          setListDestinations(result.data);
        }
      } catch (error) {
        throw error;
      }
      setLoading(false);
    },
    initSection: async () => {
      setSection({
        google_place_id: null,
        address: null,
        lat: null,
        lng: null,
        display_as: "event",
        name: null,
        tip: null,
        external_link: null,
        attachments: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      });
    },
    getSelectedDestination: async (destination_id) => {
      try {
        setLoading(true);
        const result = await destinationService.getDestination(destination_id);
        setDestination((prev) => result.data);
        setLoading(false);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    createDestination: async (objDestination) => {
      setLoading(true);
      try {
        let result = await destinationService.createDestination(objDestination);
        setLoading(false);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    updateDestination: async (destination) => {
      await destinationService.updateDestination(destination);
      return "success";
    },
    deleteDestination: async (destination_id) => {
      await destinationService.deleteDestination(destination_id);
      return "success";
    },
    createRecommendation: async (objRecommendation) => {
      let result =
        await destinationService.createRecommendation(objRecommendation);
      return result;
    },
    deleteRecommendation: async (recommendation_id, destination_id) => {
      let resp =
        await destinationService.deleteRecommendation(recommendation_id, destination_id);

      if (!resp.error) {
        setDestination(resp.data);
      }
      return resp;
    },
    updateRecommendation: async (recommendation) => {
      let result =
        await destinationService.updateRecommendation(recommendation);
      setDestination(result.data);
      return result;
    },
    updateRecommendationOrder: async (order) => {
      let result = await destinationService.updateRecommendationOrder(order);
      return result;
    },
  };

  return (
    <DestinationContext.Provider value={contextDestination}>
      {children}
    </DestinationContext.Provider>
  );
};
