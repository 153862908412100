import { useContext, React } from "react";
import Dialog from "../../../common/Dialog";
import { AppContexts } from "../../../../providers";
import UIkit from "uikit";

const MacroDelete = () => {
  const { set, settings, deleted, setDeleted } = useContext(
    AppContexts.SettingsContext,
  );

  const cancelDelete = async () => {
    setDeleted(null);
  };

  const handleDelete = async () => {
    if (!deleted) {
      return UIkit.modal("#tag-delete").hide();
    }

    await set(["custom_macros"], {
      custom_macros: (settings.custom_macros || []).filter(
        (cm) => cm.id !== deleted.id,
      ),
    });

    return UIkit.modal("#macro-delete").hide();
  };

  return (
    <>
      <Dialog id="macro-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">
            Delete Property Macro {deleted?.macro || ""}
          </h2>
          <p>
            Are you sure you want to delete?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default MacroDelete;
