// Import package components
import { createContext, useState } from "react";

import AlertsService from "../services/alerts.service";

export const AlertsContext = createContext();
export const AlertsProvider = ({ children }) => {
  const [flagIsEditing, setIsEditing] = useState(false);
  const [flagIsNew, setIsNew] = useState(false);
  const [flagIsProcessing, setIsProcessing] = useState(false);
  const [listAlerts, setAlerts] = useState([]);
  const [objAlert, setObjAlert] = useState({});
  const [isViewMode, setIsViewMode] = useState(false);
  const [guests, setGuests] = useState([]);

  const contextAlerts = {
    flagIsEditing,
    setIsEditing,
    flagIsNew,
    setIsNew,
    flagIsProcessing,
    listAlerts,
    objAlert,
    setObjAlert,
    isViewMode,
    setIsViewMode,
    guests,
    getAlerts: async () => {
      setIsProcessing(true);
      try {
        let res = await AlertsService.getAlerts();
        setIsProcessing(false);
        setAlerts(res.data);
      } catch (err) {
        console.error(err);
      }
    },

    insertAlert: async (objAlert) => {
      setIsProcessing(true);
      try {
        let res = await AlertsService.insertAlert(objAlert);
        setIsProcessing(false);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
    updateAlert: async (objAlert) => {
      try {
        let res = await AlertsService.updateAlert(objAlert);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    deleteAlert: async (objAlert) => {
      setIsProcessing(true);
      try {
        let res = await AlertsService.deleteAlert(objAlert);
        setIsProcessing(false);
        if (res.data) {
          setAlerts(
            listAlerts.filter(
              (indexAlert) => indexAlert.alert_id !== res.data.alert_id,
            ),
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    getAlertGuests: (objAlert) => {
      AlertsService.getAlertGuests(objAlert)
        .then((res, err) => setGuests(res.data ? res.data : []))
        .catch((err) => console.log(err));
    },
  };

  return (
    <AlertsContext.Provider value={contextAlerts}>
      {children}
    </AlertsContext.Provider>
  );
};
