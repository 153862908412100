import React, { useState, useContext } from "react";
import Tags from "../../property_guide/tags";
import { useHistory } from "react-router";
import { AppContexts } from "../../../providers";
import moment from "moment";
import QRPart from "../../common/Qrcode";

const TableRow = ({ destination, allTags }) => {
  const history = useHistory();
  const { setSelectedId, setDestination, updateDestination, setShared } =
    useContext(AppContexts.DestinationContext);
  const [tags, setTags] = useState(destination.tags || []);

  const handleDetail = (destination_id) => {
    setSelectedId(destination_id);
    history.push(`/areas_detail/${destination_id}`);
  };

  const handleDeleteDestination = () => {
    setDestination(destination);
  };

  const handleSelectedTags = (selectedTags) => {
    setTags(selectedTags);
    let temp = { ...destination, tags: selectedTags };
    delete temp.recommendations;
    delete temp.recommendation_ordering;
    delete temp.account_id;
    updateDestination(temp);
  };

  const handleShare = (destination) => {
    setShared(destination);
  };

  return (
    <tr>
      <td>
        <div
          className="semi-bold-font"
          onClick={() => handleDetail(destination.destination_id)}
        >
          {destination.name}
        </div>
        <div className="secondary">{destination.address}</div>
      </td>
      <td className="hms-add-tag">
        <Tags value={tags} all={allTags} onChange={handleSelectedTags} />
      </td>
      <td className="td-w-240 hms-action-btn">
        <div className="uk-inline">
          <button
            className="uk-button"
            type="button"
            onClick={() => handleDetail(destination.destination_id)}
            uk-tooltip="Edit"
          >
            <img src="/images/edit.svg" />
          </button>
        </div>
        <div className="uk-inline">
          <a
            href={`https://guide.ruebarue.com/destination/${destination.destination_id}`}
            target="_blank"
          >
            <button
              className="uk-button"
              type="button"
              uk-tooltip="View Area Guide"
            >
              <img src="/images/guide.svg" />
            </button>
          </a>
        </div>
        <div className="uk-inline">
          <QRPart value={destination.destination_id} guide="destination" />
        </div>
        <div className="uk-inline">
          <button
            className="uk-button"
            type="button"
            uk-toggle="target: #shareDestinationGuide-modal"
            onClick={() => {
              handleShare(destination);
            }}
            uk-tooltip="Share"
          >
            <img src="/images/share.svg" />
          </button>
        </div>
        <div className="uk-inline">
          <a
            href={`https://guide.ruebarue.com/destination/${destination.destination_id}/print`}
            target="_blank"
          >
            <button className="uk-button" type="button" uk-tooltip="Print">
              <img src="/images/print.svg" />
            </button>
          </a>
        </div>
        <div className="uk-inline">
          <button
            className="uk-button"
            uk-toggle="target: #destination-guide-delete"
            onClick={handleDeleteDestination}
            uk-tooltip="Delete"
          >
            <img src="/images/delete.svg" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
